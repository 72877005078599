import React, { useEffect } from "react";
import GeneralScriptList from "./GeneralScriptList";
import { useDispatch, useSelector } from "react-redux";
import useBoolean from "../../hooks/useBoolean";
import BasicDrawer from "../../common/Drawer/BasicDrawer";
import UpdateGeneralScript from "./UpdateGeneralScript";
import { selectDefaultCallSetting } from "../../state/features/listDefaultSetting/defaultListSettingSelector";
import {
  getGeneralScripts,
  hasMoreGeneralScripts,
  resetGeneralScriptEditData,
} from "../../state/features/listDefaultSetting/defaultCallSetting/defaultCallSettingSlice";

const GeneralScriptBody = () => {
  const dispatch = useDispatch();
  const {
    generalScripts: { data: scripts, perPage, page, search, isLoading, hasMore, editingData: script, editingIndex },
  } = useSelector(selectDefaultCallSetting);

  const {
    value: isShowAddContactModal,
    setTrue: setShowAddContactModal,
    setFalse: setCloseAddContactModal,
  } = useBoolean(false);

  useEffect(() => {
    dispatch(
      getGeneralScripts({
        limit: perPage,
        page: page,
        search: search,
      })
    );
  }, [search]);

  useEffect(() => {
    if (script) setShowAddContactModal();
  }, [script]);

  const handleCloseModal = () => {
    setCloseAddContactModal();
    dispatch(resetGeneralScriptEditData());
  };

  const handleScroll = (e) => {
    if (
      hasMore &&
      !isLoading &&
      Math.round(e.target.scrollTop + e.target.clientHeight, 10) >= Math.round(e.target.scrollHeight, 10)
    ) {
      dispatch(
        hasMoreGeneralScripts({
          limit: perPage,
          page: page,
          search: search,
        })
      );
    }
  };

  return (
    <div>
      <GeneralScriptList scripts={scripts} isLoading={isLoading} handleOnScroll={(e) => handleScroll(e)} />

      <BasicDrawer open={isShowAddContactModal} toggleDrawer={handleCloseModal} title={"Edit Script"}>
        <UpdateGeneralScript onCancel={handleCloseModal} selectedData={script} index={editingIndex} />
      </BasicDrawer>
    </div>
  );
};

export default GeneralScriptBody;
