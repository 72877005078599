import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./state/store";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./assets/scss/main.scss";

window.renderPowerDialerModule = (containerId, history) => {
  ReactDOM.render(
    <React.Fragment>
      <Provider store={store}>
        <App history={history} />
      </Provider>
    </React.Fragment>,
    document.getElementById(containerId)
  );
  serviceWorker.unregister();
};

window.unmountPowerDialerModule = (containerId) => {
  if (document.getElementById(containerId)) {
    ReactDOM.unmountComponentAtNode(document.getElementById(containerId));
  }
};

if (process.env.REACT_APP_PRODUCTION_MODE !== true && document.getElementById("PowerDialer-root")) {
  document.getElementById("fbp_loading")?.remove();
  ReactDOM.render(
    <>
      <Provider store={store}>
        <App />
      </Provider>
    </>,
    document.getElementById("PowerDialer-root")
  );
  serviceWorker.unregister();
}
