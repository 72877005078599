import React from "react";
import FilterOptions from "./FilterOptions";
import ContactList from "./ContactList";

const AddContactFromList = ({ onClose }) => {
  return (
    <>
      <FilterOptions />
      <ContactList onClose={onClose} />
    </>
  );
};
export default AddContactFromList;
