import { Box, Stack, styled, Typography } from "@mui/material";
import {
  CallReceivedIcon,
  EmailIcon,
  GiftIcon,
  GrettingIcon,
  LetterIcon,
  PostcardIcon,
  SMSIcon,
  VideoCamIcon,
  VoiceIcon,
} from "../../../../common/icon/Icons";
import PreviewImageFile from "../../../../common/imageLoadComponent/PreviewImageFile";
import { TimelineContentTypes } from "../../../../helpers/constant/Constants";
import { getAccountData } from "../../../../helpers/utils/getAccountData";
import { getContactAvatar } from "../../../../helpers/utils/getContactAvatar";

const StyledAudio = styled("audio")(() => ({
  width: "404px",
  height: "32px",
  "&::-webkit-media-controls-panel": { padding: 2 },
  "&::-webkit-media-controls-timeline": {
    padding: "0 4px 0 8px",
  },
  "&::-webkit-media-controls-current-time-display": {
    display: "none",
  },
  "&::-webkit-media-controls-time-remaining-display": {
    display: "none",
  },
}));

const EachConversation = ({ item, index, Styles, contact }) => {
  const inOut = item.inOut; //in = 1
  const type = item.type;

  const renderContentHeader = () => {
    return (
      <div className={Styles.contentHeader} style={{ display: "none" }}>
        <Typography sx={{ color: "action.active", fontSize: "11px" }} variant='chipLight'>
          Shahil,
        </Typography>
        <Typography sx={{ color: "text.secondary", fontSize: "11px" }} variant='chipLight'>
          8:20 AM
        </Typography>
      </div>
    );
  };
  const renderContactAvatar = () => {
    if (inOut != 1) {
      if (item.userInfo != null) {
        try {
          let useData = JSON.parse(item.userInfo);
          if (useData) {
            return getContactAvatar(useData["full_name"]);
          }
        } catch (err) {
          console.log(err);
          return getContactAvatar(getAccountData("fullName"));
        }
      }
      return getContactAvatar(getAccountData("fullName"));
    }
    if (contact) {
      return getContactAvatar(contact.firstName, contact.lastName, contact.email, contact.number);
    }
    return "N/A";
  };
  const replaceAllHtmlTag = (text) => {
    let styleData = 'type="text/css">';
    let messageBody = text ? text : "";

    let iof = messageBody.indexOf(styleData);
    try {
      if (iof > 0) {
        do {
          let anotherof = messageBody.indexOf("</style>", iof);
          if (anotherof > 0) {
            messageBody = messageBody.slice(0, iof) + " " + messageBody.slice(anotherof);
          }
          iof = messageBody.indexOf(styleData);
        } while (iof > 0);
      }
    } catch (e) {
      console.log(e);
      messageBody = "";
    }
    return messageBody;
  };
  const renderView = () => {
    if (type == 1) {
      return renderSms();
    } else if (type == 2) {
      return renderMms();
    } else if (type == 4) {
      return renderEmail();
    } else if (type == 3) {
      return renderVoice();
    } else if (type == 5) {
      return renderCall();
    } else if (type == 25) {
      return renderCallRecord();
    } else if (type == 14) {
      return renderVideoMail();
    } else if (
      type == TimelineContentTypes.postcard ||
      type == TimelineContentTypes.letter ||
      type == TimelineContentTypes.greetingCard ||
      type == TimelineContentTypes.gift
    ) {
      return renderDirectMail();
    }
    return null;
  };

  const renderIcon = () => {
    if (type == TimelineContentTypes.postcard) {
      return <PostcardIcon height='12' width='12' fill='#949DB2' />;
    } else if (type == TimelineContentTypes.letter) {
      return <LetterIcon height='12' width='12' fill='#949DB2' />;
    } else if (type == TimelineContentTypes.greetingCard) {
      return <GrettingIcon height='12' width='12' fill='#949DB2' />;
    } else if (type == TimelineContentTypes.gift) {
      return <GiftIcon height='12' width='12' fill='#949DB2' />;
    }
    return null;
  };
  const processFileUrl = (messageUrl) => {
    let fileDataOutput = [];
    try {
      let fileData = JSON.parse(messageUrl);
      if (Array.isArray(fileData)) {
        for (let i = 0; i < fileData.length; i++) {
          if (fileData[i].file_url && fileData[i].file_name) {
            fileDataOutput.push(
              <a key={i} className={Styles.attachmentItem} target='_blank' href={fileData[i].file_url} rel='noreferrer'>
                {fileData[i].file_name}
              </a>
            );
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
    if (fileDataOutput.length != 0) {
      return (
        <Box mt={2} className={Styles.attachment} style={{ display: "flex", flexDirection: "column" }}>
          <Typography sx={{ fontSize: "11px", fontWeight: 400 }} variant='body1'>
            Attachments:
          </Typography>
          {fileDataOutput}
        </Box>
      );
    }
    return null;
  };
  const renderAttachment = () => {
    if (type == 14 || type == 4) {
      if (item.messageUrl) {
        return processFileUrl(item.messageUrl);
      }
    }
    return null;
  };
  const renderDirectMailFileView = (row) => {
    const cardPdf = row.fileUrl;
    let front_image =
      row.templateThumbnailPath !== undefined && row.templateThumbnailPath != null && row.templateThumbnailPath !== ""
        ? row.templateThumbnailPath
        : "";

    if (cardPdf == null && front_image == null) {
      return null;
    }

    if (cardPdf != null) {
      if (cardPdf.split(".").pop() === "pdf" || cardPdf.split(".").pop() === "PDF") {
        return (
          <Box mb={2}>
            <iframe
              height={140.63}
              width={250}
              src={row.fileUrl}
              frameBorder='0'
              loading={"eager"}
              name={"PDF"}
              allowFullScreen
              title='PDF'
            />
          </Box>
        );
      } else {
        return (
          <Stack mb={2} direction='row' alignContent='center' spacing={1}>
            {front_image != null && front_image !== "" && (
              <img height={100} width={100} src={front_image} alt={front_image} />
            )}
            {cardPdf != null && <img height={100} width={100} src={cardPdf} alt={cardPdf} />}
          </Stack>
        );
      }
    } else {
      return (
        <Stack mb={2} direction='row' alignContent='center' spacing={1}>
          {front_image != null && front_image !== "" && (
            <img height={100} width={100} src={front_image} alt={front_image} />
          )}
          {cardPdf != null && <img height={100} width={100} src={cardPdf} alt={cardPdf} />}
        </Stack>
      );
    }
  };

  const renderSms = () => {
    return (
      <div className={inOut == 1 ? Styles.smsReceived : Styles.smsSend} key={index}>
        <div className={Styles.avatarWrap}>
          <span className={Styles.avatar}>{renderContactAvatar()}</span>
        </div>

        <div className={Styles.content} style={{ maxWidth: "75%" }}>
          {renderContentHeader()}

          <div className={Styles.contentBody}>
            <div className={Styles.textWrap}>
              <Typography variant='body2' dangerouslySetInnerHTML={{ __html: replaceAllHtmlTag(item.message) }} />
            </div>
          </div>

          <div className={Styles.contentFooter}>
            <Stack direction='row' alignItems='center' spacing={0.6} mt={0.8}>
              <SMSIcon height='12' width='12' fill='#949DB2' />
              {item.from !== undefined && item.from != null && (
                <>
                  <Typography sx={{ color: "action.active", fontSize: "11px" }} variant='chipLight'>
                    Send {inOut == 1 ? "to" : "from"}:
                  </Typography>
                  <Typography sx={{ color: "text.secondary", fontSize: "11px" }} variant='chipLight'>
                    {item.from}
                  </Typography>
                </>
              )}
            </Stack>
            {typeof item.time !== "undefined" && window.globalTimezoneConversionToDifferentTimezone !== undefined && (
              <Stack direction='row' alignItems='center' spacing={0.6} mt={0.8}>
                <Typography sx={{ color: "action.active", fontSize: "11px" }} variant='chipLight'>
                  {window.globalTimezoneConversionToDifferentTimezone(item.time, "UTC", "", "ll h:mm A")}
                </Typography>
              </Stack>
            )}
          </div>
        </div>
      </div>
    );
  };
  const renderMms = () => {
    return (
      <div className={inOut == 1 ? Styles.smsReceived : Styles.smsSend} key={index}>
        <div className={Styles.avatarWrap}>
          <span className={Styles.avatar}>{renderContactAvatar()}</span>
        </div>

        <div className={Styles.content} style={{ maxWidth: "75%" }}>
          {renderContentHeader()}

          <div className={Styles.contentBody}>
            <div className={Styles.textWrap}>
              <Typography variant='body2' dangerouslySetInnerHTML={{ __html: replaceAllHtmlTag(item.message) }} />
            </div>

            {item.messageUrl != null && (
              <div>
                <PreviewImageFile link={item.messageUrl} />
              </div>
            )}
          </div>

          <div className={Styles.contentFooter}>
            <Stack direction='row' alignItems='center' spacing={0.6} mt={0.8}>
              <SMSIcon height='12' width='12' fill='#949DB2' />
              {item.from !== undefined && item.from != null && (
                <>
                  <Typography sx={{ color: "action.active", fontSize: "11px" }} variant='chipLight'>
                    Send {inOut == 1 ? "to" : "from"}:
                  </Typography>
                  <Typography sx={{ color: "text.secondary", fontSize: "11px" }} variant='chipLight'>
                    {item.from}
                  </Typography>
                </>
              )}
            </Stack>
            {typeof item.time !== "undefined" && window.globalTimezoneConversionToDifferentTimezone !== undefined && (
              <Stack direction='row' alignItems='center' spacing={0.6} mt={0.8}>
                <Typography sx={{ color: "action.active", fontSize: "11px" }} variant='chipLight'>
                  {window.globalTimezoneConversionToDifferentTimezone(item.time, "UTC", "", "ll h:mm A")}
                </Typography>
              </Stack>
            )}
          </div>
        </div>
      </div>
    );
  };
  const renderEmail = () => {
    return (
      <div className={inOut == 1 ? Styles.emailReceived : Styles.emailSend} key={index}>
        <div className={Styles.avatarWrap}>
          <span className={Styles.avatar}>{renderContactAvatar()}</span>
        </div>

        <div className={Styles.content} style={{ maxWidth: "75%" }}>
          {renderContentHeader()}

          <div className={Styles.contentBody}>
            <div className={Styles.subject}>
              <Typography sx={{ color: "action.active", fontSize: "11px", fontWeight: 400 }} variant='chipLight'>
                Subject:
              </Typography>
              <Typography component={"span"} variant='body2Semibold'>
                {item.subject}
              </Typography>
            </div>
            <div className={Styles.textWrap}>
              <Typography variant='body2' dangerouslySetInnerHTML={{ __html: item.message }} />
              {/* attachment */}
              {renderAttachment()}
            </div>
          </div>

          <div className={Styles.contentFooter}>
            <Stack direction='row' alignItems='center' spacing={0.6} mt={0.8}>
              <EmailIcon height='12' width='12' fill='#949DB2' />
              {item.from !== undefined && item.from != null && (
                <>
                  <Typography sx={{ color: "action.active", fontSize: "11px" }} variant='chipLight'>
                    Send {inOut == 1 ? "to" : "from"}:
                  </Typography>
                  <Typography sx={{ color: "text.secondary", fontSize: "11px" }} variant='chipLight'>
                    {item.from}
                  </Typography>
                </>
              )}
            </Stack>
            {typeof item.time !== "undefined" && window.globalTimezoneConversionToDifferentTimezone !== undefined && (
              <Stack direction='row' alignItems='center' spacing={0.6} mt={0.8}>
                <Typography sx={{ color: "action.active", fontSize: "11px" }} variant='chipLight'>
                  {window.globalTimezoneConversionToDifferentTimezone(item.time, "UTC", "", "ll h:mm A")}
                </Typography>
              </Stack>
            )}
          </div>
        </div>
      </div>
    );
  };
  const renderVoice = () => {
    return (
      <div className={inOut == 1 ? Styles.voiceReceived : Styles.voiceSend} key={index}>
        <div className={Styles.avatarWrap}>
          <span className={Styles.avatar}>{renderContactAvatar()}</span>
        </div>

        <div className={Styles.content} style={{ maxWidth: "75%" }}>
          {renderContentHeader()}

          <div className={Styles.contentBody}>
            {item.messageUrl !== undefined && item.messageUrl != null && <StyledAudio src={item.messageUrl} controls />}
          </div>
          <div className={Styles.contentBody}>
            {item.fileUrl !== undefined && item.fileUrl != null && <StyledAudio src={item.fileUrl} controls />}
          </div>

          <div className={Styles.contentFooter}>
            <Stack direction='row' alignItems='center' spacing={0.6} mt={0.8}>
              <VoiceIcon height='12' width='12' fill='#949DB2' />
              {item.from !== undefined && item.from != null && (
                <>
                  <Typography sx={{ color: "action.active", fontSize: "11px" }} variant='chipLight'>
                    Send {inOut == 1 ? "to" : "from"}:
                  </Typography>
                  <Typography sx={{ color: "text.secondary", fontSize: "11px" }} variant='chipLight'>
                    {item.from}
                  </Typography>
                </>
              )}
            </Stack>
            {typeof item.time !== "undefined" && window.globalTimezoneConversionToDifferentTimezone !== undefined && (
              <Stack direction='row' alignItems='center' spacing={0.6} mt={0.8}>
                <Typography sx={{ color: "action.active", fontSize: "11px" }} variant='chipLight'>
                  {window.globalTimezoneConversionToDifferentTimezone(item.time, "UTC", "", "ll h:mm A")}
                </Typography>
              </Stack>
            )}
          </div>
        </div>
      </div>
    );
  };
  const renderCall = () => {
    return (
      <div className={inOut == 1 ? Styles.voiceReceived : Styles.voiceSend} key={index}>
        <div className={Styles.avatarWrap}>
          <span className={Styles.avatar}>{renderContactAvatar()}</span>
        </div>

        <div className={Styles.content} style={{ maxWidth: "75%" }}>
          {renderContentHeader()}

          <div className={Styles.contentBody}>
            {item.messageUrl !== undefined && item.messageUrl != null && <StyledAudio src={item.messageUrl} controls />}
          </div>
          <div className={Styles.contentBody}>
            {item.fileUrl !== undefined && item.fileUrl != null && <StyledAudio src={item.fileUrl} controls />}
          </div>

          <div className={Styles.contentFooter}>
            <Stack direction='row' alignItems='center' spacing={0.6} mt={0.8}>
              <CallReceivedIcon height='12' width='12' fill='#949DB2' />
              {item.from !== undefined && item.from != null && (
                <>
                  <Typography sx={{ color: "action.active", fontSize: "11px" }} variant='chipLight'>
                    Send {inOut == 1 ? "to" : "from"}:
                  </Typography>
                  <Typography sx={{ color: "text.secondary", fontSize: "11px" }} variant='chipLight'>
                    {item.from}
                  </Typography>
                </>
              )}
            </Stack>
            {typeof item.time !== "undefined" && window.globalTimezoneConversionToDifferentTimezone !== undefined && (
              <Stack direction='row' alignItems='center' spacing={0.6} mt={0.8}>
                <Typography sx={{ color: "action.active", fontSize: "11px" }} variant='chipLight'>
                  {window.globalTimezoneConversionToDifferentTimezone(item.time, "UTC", "", "ll h:mm A")}
                </Typography>
              </Stack>
            )}
          </div>
        </div>
      </div>
    );
  };
  const renderCallRecord = () => {
    return (
      <div className={inOut == 1 ? Styles.voiceReceived : Styles.voiceSend} key={index}>
        <div className={Styles.avatarWrap}>
          <span className={Styles.avatar}>{renderContactAvatar()}</span>
        </div>

        <div className={Styles.content} style={{ maxWidth: "75%" }}>
          {renderContentHeader()}

          <div className={Styles.contentBody}>
            {item.messageUrl !== undefined && item.messageUrl != null && <StyledAudio src={item.messageUrl} controls />}
          </div>
          <div className={Styles.contentBody}>
            {item.fileUrl !== undefined && item.fileUrl != null && <StyledAudio src={item.fileUrl} controls />}
          </div>

          <div className={Styles.contentFooter}>
            <Stack direction='row' alignItems='center' spacing={0.6} mt={0.8}>
              <SMSIcon height='12' width='12' fill='#949DB2' />
              {item.from !== undefined && item.from != null && (
                <>
                  <Typography sx={{ color: "action.active", fontSize: "11px" }} variant='chipLight'>
                    Send {inOut == 1 ? "to" : "from"}:
                  </Typography>
                  <Typography sx={{ color: "text.secondary", fontSize: "11px" }} variant='chipLight'>
                    {item.from}
                  </Typography>
                </>
              )}
            </Stack>
            {typeof item.time !== "undefined" && window.globalTimezoneConversionToDifferentTimezone !== undefined && (
              <Stack direction='row' alignItems='center' spacing={0.6} mt={0.8}>
                <Typography sx={{ color: "action.active", fontSize: "11px" }} variant='chipLight'>
                  {window.globalTimezoneConversionToDifferentTimezone(item.time, "UTC", "", "ll h:mm A")}
                </Typography>
              </Stack>
            )}
          </div>
        </div>
      </div>
    );
  };
  const renderDirectMail = () => {
    return (
      <div className={inOut == 1 ? Styles.directMailReceived : Styles.directMailSend} key={index}>
        <div className={Styles.avatarWrap}>
          <span className={Styles.avatar}>{renderContactAvatar()}</span>
        </div>

        <div className={Styles.content} style={{ maxWidth: "75%" }}>
          {renderContentHeader()}

          <div className={Styles.contentBody}>
            <div className={Styles.subject}>
              <Typography sx={{ color: "action.active", fontSize: "11px", fontWeight: 400 }} variant='chipLight'>
                Subject:
              </Typography>
              <Typography component={"span"} variant='body2Semibold'>
                {item.subject}
              </Typography>
            </div>
            <div className={Styles.textWrap}>
              <Typography variant='body2' dangerouslySetInnerHTML={{ __html: item.message }} />
              <Box mt={2} className={Styles.attachmentPreview}>
                {renderDirectMailFileView(item)}
              </Box>
            </div>
          </div>

          <div className={Styles.contentFooter}>
            <Stack direction='row' alignItems='center' spacing={0.6} mt={0.8}>
              {renderIcon()}

              {item.from !== undefined && item.from != null && (
                <>
                  <Typography sx={{ color: "action.active", fontSize: "11px" }} variant='chipLight'>
                    Send {inOut == 1 ? "to" : "from"}:
                  </Typography>
                  <Typography sx={{ color: "text.secondary", fontSize: "11px" }} variant='chipLight'>
                    {item.from}
                  </Typography>
                </>
              )}
            </Stack>
            {typeof item.time !== "undefined" && window.globalTimezoneConversionToDifferentTimezone !== undefined && (
              <Stack direction='row' alignItems='center' spacing={0.6} mt={0.8}>
                <Typography sx={{ color: "action.active", fontSize: "11px" }} variant='chipLight'>
                  {window.globalTimezoneConversionToDifferentTimezone(item.time, "UTC", "", "ll h:mm A")}
                </Typography>
              </Stack>
            )}
          </div>
        </div>
      </div>
    );
  };
  const renderVideoMail = () => {
    return (
      <div className={inOut == 1 ? Styles.directMailReceived : Styles.directMailSend} key={index}>
        <div className={Styles.avatarWrap}>
          <span className={Styles.avatar}>{renderContactAvatar()}</span>
        </div>

        <div className={Styles.content} style={{ maxWidth: "75%" }}>
          {renderContentHeader()}

          <div className={Styles.contentBody}>
            <div className={Styles.subject}>
              <Typography sx={{ color: "action.active", fontSize: "11px", fontWeight: 400 }} variant='chipLight'>
                Subject:
              </Typography>
              <Typography component={"span"} variant='body2Semibold'>
                {item.subject}
              </Typography>
            </div>
            <div className={Styles.textWrap}>
              <Typography variant='body2' dangerouslySetInnerHTML={{ __html: item.message }} />

              {/* attachment */}
              {renderAttachment()}

              {item.videoLink != undefined && item.videoLink != null && (
                <Box mt={2} className={Styles.attachmentPreview}>
                  <Box mb={2}>
                    <video height={140.63} width={250} controls preload='metadata' poster={item?.gifLink}>
                      <source src={item.videoLink} type='video/mp4' />
                      Your browser does not support the video tag.
                    </video>
                  </Box>
                </Box>
              )}
            </div>
          </div>

          <div className={Styles.contentFooter}>
            <Stack direction='row' alignItems='center' spacing={0.6} mt={0.8}>
              <VideoCamIcon height='12' width='12' fill='#949DB2' />
              {item.from !== undefined && item.from != null && (
                <>
                  <Typography sx={{ color: "action.active", fontSize: "11px" }} variant='chipLight'>
                    Send {inOut == 1 ? "to" : "from"}:
                  </Typography>
                  <Typography sx={{ color: "text.secondary", fontSize: "11px" }} variant='chipLight'>
                    {item.from}
                  </Typography>
                </>
              )}
            </Stack>
            {typeof item.time !== "undefined" && window.globalTimezoneConversionToDifferentTimezone !== undefined && (
              <Stack direction='row' alignItems='center' spacing={0.6} mt={0.8}>
                <Typography sx={{ color: "action.active", fontSize: "11px" }} variant='chipLight'>
                  {window.globalTimezoneConversionToDifferentTimezone(item.time, "UTC", "", "ll h:mm A")}
                </Typography>
              </Stack>
            )}
          </div>
        </div>
      </div>
    );
  };
  return renderView();
};

export default EachConversation;
