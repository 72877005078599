import React from "react";
import { BrowserRouter, Route, Router, Switch } from "react-router-dom";
import ErrorPage from "../common/ErrorPage";
import IconPreview from "../common/icon/IconView";
import PowerDialerIndex from "../components/PowerDialer/PowerDialerIndex";
import GeneralScript from "../components/GeneralScript/GeneralScript";

const Routes = ({ history }) => {
  const RouteComponent = history ? Router : BrowserRouter;
  return (
    <RouteComponent history={history}>
      <Switch>
        <Route exact path='/power-dialer' component={PowerDialerIndex} />
        <Route exact path={"/power-dialer/icons"} component={IconPreview} />
        <Route exact path='/power-dialer/general-scripts' component={GeneralScript} />
        <Route exact component={ErrorPage} />
      </Switch>
    </RouteComponent>
  );
};

export default Routes;
