import httpRequest from "../api/httpRequest";

class ContactNoteService {
  getContactLastNote(params) {
    return httpRequest.get(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/call/contact-last-note",
      params
    );
  }
  addContactNote(params) {
    return httpRequest.post(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/call/note-create",
      params
    );
  }
}

export default new ContactNoteService();
