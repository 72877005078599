import { createSlice } from "@reduxjs/toolkit";
import { ConversationTabs, TimelineContentTypes } from "../../../helpers/constant/Constants";

export const DIALER_STEP = {
  STOP: 0,
  STARTING: 1,
  SHOW_TERM_CONDITION: 2,
  SHOW_RESUME: 3,
  SHOW_SETTING: 4,
  SHOW_CONNECTING_SCREEN: 5,
  SHOW_CONNETED_SINGLE_SCREEN: 14,
  SHOW_CONNECTED_SCREEN: 6,
  SHOW_WAITING_FOR_NEXT: 7,
  WAITING: 8,
  FETCHING: 9,
  CONNECTING: 10,
  CONNECTED: 11,
  CALL_END: 12,
  SHOW_MINIMIZED_SCREEN: 13,
  HAS_CONNECTING_ERROR: 15,
};
export const CALL_START_TYPE = {
  FROM_LEFT: 1,
  FROM_BEGINNING: 2,
};
export const SEND_OPTIONS = {
  default: 0,
  note: 1,
  sms: 2,
  email: 3,
  voiceMessage: 4,
  addToCampaign: 5,
  addToTag: 6,
  addToAnotherList: 7,
  directMail: 8,
};

export const callTimer = {
  ringingTime: 0,
  callingTime: 0,
  sessionTime: 0,
};

const initialState = {
  init: {
    listInfo: null,
    listId: null,
    sessionId: null,
    connectionLine: 3,
    virtualNumber: null,
    virtualNumberId: null,
    step: DIALER_STEP.STOP,
    callStep: DIALER_STEP.WAITING,
    callStartType: CALL_START_TYPE.FROM_LEFT,
    showMinimizedHandler: false,
    mainScreen: false,
    sessionCount: 0,
    nextCall: false,
    /* for session */
    sessionInfo: null,
    creatingNewSession: false,
    connectedEmail: null,
  },
  settingOverview: {
    data: null,
    gettingData: false,
    gettingError: "",
  },
  connecting: {
    stats: null,
    gettingStats: false,
    gettingStatsError: "",
    actualContacts: [],
    contacts: [],
    contactsId: [],
    gettingContacts: false,
    gettingContactError: "",
    callingError: "",
    credit: 0,
    updateData: false,
    hasStats: false,
  },
  connected: {
    contact: null,
    contactId: null,
    disconnectedIds: [],
    activeScreen: null,
    tab: ConversationTabs[6].id,
    lastConversation: null,
    lastConnectedId: null,
  },
  send: {
    showAction: false,
    option: SEND_OPTIONS.default,
    isShowActivity: false,
    isShowVideoModal: false,
  },
  conversation: {
    data: [],
    gettingData: false,
    getDataError: "",
    needToLoadMore: false,
    loadingMore: false,
    pageNo: 1,
    pageSize: 20,
    type: TimelineContentTypes.sms,
    script: null,
    gettingScript: false,
    gettingScriptError: "",
  },
  contactTags: {
    tags: [],
    gettingTag: false,
    getTagError: "",
  },
  contactNote: {
    note: null,
    gettingNote: false,
    gettingNoteError: "",
  },
  socketData: {
    number: [],
    needReload: false,
  },
  users: {
    data: [],
    outcomes: [],
    gettingOutcome: false,
    selectedOutcome: null,
    savedOutcomeId: null,
  },
  contactScript: {
    mainScript: null,
    mainScriptTitle: null,
    mainScriptId: null,
    data: null,
    gettingAllScripts: false,
    allScripts: [],
  },
};

const dialerSlice = createSlice({
  name: "dialer",
  initialState: initialState,
  reducers: {
    /* init */
    handleStartCall: (state) => {
      state.init = { ...state.init, step: DIALER_STEP.STARTING };
      state.connected = { ...state.connected, lastContactedId: null };
    },
    handleEndCall: (state) => {
      state.init = {
        ...state.init,
        step: DIALER_STEP.STOP,
        listInfo: null,
        listId: null,
        sessionId: null,
        mainScreen: false,
      };
    },
    storeBasicInit: (state, action) => {
      state.init = { ...state.init, ...action.payload };
    },
    handleInitStep: (state, action) => {
      state.init = { ...state.init, step: action.payload };
    },
    handleMainScreen: (state, action) => {
      state.init = { ...state.init, mainScreen: action.payload };
    },
    handleCallStep: (state, action) => {
      state.init = { ...state.init, callStep: action.payload };
    },
    handleNextCall: (state, action) => {
      state.init = { ...state.init, nextCall: action.payload };
      if (action.payload) {
        state.connecting = { ...state.connecting, actualContacts: [], contacts: [], contactsId: [], credit: 0 };
        state.connected = { ...state.connected, contact: null, contactId: null, tab: ConversationTabs[6].id };
        state.users = { ...state.users, selectedOutcome: null, savedOutcomeId: null };
        state.send = { ...state.send, option: SEND_OPTIONS.default, isShowActivity: false, isShowVideoModal: false };
      }
    },
    /* setting overview */
    getSettingOverviewOnBackground: () => {},
    getSettingOverview: (state) => {
      state.init = { ...state.init, virtualNumber: null };
      state.settingOverview = { ...state.settingOverview, gettingData: true, data: null, gettingError: "" };
    },
    getSettingOverviewSuccess: (state, action) => {
      state.settingOverview = { ...state.settingOverview, data: action.payload, gettingData: false, gettingError: "" };
    },
    getSettingOverviewError: (state, action) => {
      state.settingOverview = { ...state.settingOverview, gettingError: action.payload, gettingData: false };
    },
    /* connecting */
    getStats: (state) => {
      state.connecting = { ...state.connecting, gettingStats: true, gettingStatsError: "" };
    },
    getStatsSuccess: (state, action) => {
      state.connecting = { ...state.connecting, gettingStats: false, gettingStatsError: "", stats: action.payload };
    },
    getStatsError: (state, action) => {
      state.connecting = { ...state.connecting, gettingStats: false, gettingStatsError: action.payload, stats: null };
    },
    getContacts: (state) => {
      state.connecting = { ...state.connecting, gettingContacts: true, gettingContactError: "" };
    },
    getContactsSuccess: (state, action) => {
      state.connecting = {
        ...state.connecting,
        contacts: action.payload.contactInfos,
        actualContacts: action.payload.contactInfos,
        gettingContacts: false,
        credit: action.payload.current_credit,
      };
      let ids = [];
      action.payload.contactInfos.forEach((item) => {
        ids.push(item.id);
      });
      state.connecting = { ...state.connecting, contactsId: ids, gettingContacts: false };
    },
    getContactsError: (state, action) => {
      state.connecting = {
        ...state.connecting,
        contacts: [],
        actualContacts: [],
        gettingContacts: false,
        gettingContactError: action.payload,
      };
    },
    modifyContactsData: (state, action) => {
      state.connecting = {
        ...state.connecting,
        contacts: action.payload /* updateData: !state.connecting.updateData */,
      };
    },
    restoreContactData: (state) => {
      state.connecting = { ...state.connecting, contacts: [], actualContacts: [], contactsId: [] };
    },
    restoreConnectedData: (state) => {
      state.connected = { ...state.connected, contact: null, contactId: null, disconnectedIds: [] };
    },
    handleConnectingState: (state, action) => {
      state.connecting = { ...state.connecting, ...action.payload };
    },
    /* connected */
    storeConnectedContact: (state, action) => {
      state.connected = {
        ...state.connected,
        contact: action.payload,
        contactId: action.payload?.id,
        lastContactedId: action.payload?.id,
      };
    },
    storeDisConnectedContact: (state, action) => {
      state.connected = { ...state.connected, disconnectedIds: action.payload };
    },
    handleConnectedScreenComponent: (state, action) => {
      state.connected = { ...state.connected, activeScreen: action.payload };
    },
    handleConnectedData: (state, action) => {
      state.connected = { ...state.connected, ...action.payload };
    },
    /* contact tags */
    geContactTags: (state) => {
      state.contactTags = { ...state.contactTags, gettingTag: true, getTagError: "" };
    },
    geContactTagSuccess: (state, action) => {
      state.contactTags = { ...state.contactTags, gettingTag: false, getTagError: "", tags: action.payload };
    },
    geContactTagError: (state, action) => {
      state.contactTags = { ...state.contactTags, gettingTag: false, getTagError: action.payload, tags: [] };
    },
    /* contact last note */
    geContactLastNote: (state) => {
      state.contactNote = { ...state.contactNote, gettingNote: true, gettingNoteError: "" };
    },
    geContactNoteSuccess: (state, action) => {
      state.contactNote = { ...state.contactNote, gettingNote: false, gettingNoteError: "", note: action.payload };
    },
    geContactNoteError: (state, action) => {
      state.contactNote = { ...state.contactNote, gettingNote: false, gettingNoteError: action.payload, note: [] };
    },
    /* Conversation*/
    getConversation: (state) => {
      state.conversation = { ...state.conversation, gettingData: true, getDataError: "" };
    },
    getConversationSuccess: (state, action) => {
      // let data = [...state.conversation.data];
      // data = [...action.payload, ...data];
      state.conversation = {
        ...state.conversation,
        gettingData: false,
        getDataError: "",
        // data: data,
        data: action.payload,
        pageNo: state.conversation.pageNo + 1,
      };
    },
    getConversationError: (state, action) => {
      state.conversation = {
        ...state.conversation,
        gettingData: false,
        getDataError: action.payload,
        loadingMore: false,
      };
    },
    gettingMoreConversation: (state) => {
      state.conversation = { ...state.conversation, needToLoadMore: false, loadingMore: true };
    },
    needToGetMoreConversation: (state, action) => {
      state.conversation = {
        ...state.conversation,
        needToLoadMore: action.payload,
      };
    },
    getContactScript: (state) => {
      state.conversation = { ...state.conversation, gettingScript: true, gettingScriptError: "", script: null };
    },
    getContactScriptSuccess: (state, action) => {
      state.conversation = {
        ...state.conversation,
        gettingScript: false,
        gettingScriptError: "",
        script: action.payload,
      };
    },
    getContactScriptError: (state, action) => {
      state.conversation = {
        ...state.conversation,
        gettingScript: false,
        gettingScriptError: action.payload,
        script: null,
      };
    },
    handleConversationState: (state, action) => {
      state.conversation = { ...state.conversation, ...action.payload };
    },
    /* handle socket */
    handleSocket: (state, action) => {
      state.socketData = { ...state.socketData, ...action.payload };
    },
    /* session */
    createNewSession: (state) => {
      state.init = { ...state.init, creatingNewSession: true };
    },
    storeConnectedContactByDelay: () => {},
    /* reset */
    resetStatesInitialize: (/* state */) => {
      // for (const property in initialState) {
      //   if (typeof property === "object" && !Array.isArray(property) && property != null) {
      //     for (const propertyInner in property) {
      //       state[property][propertyInner] = property[propertyInner];
      //     }
      //   } else {
      //     state[property] = initialState[property];
      //   }
      // }
    },
    resetForEndPowerDialerCall: (state) => {
      state.init = {
        ...state.init,
        virtualNumber: null,
        virtualNumberId: null,
        callStep: DIALER_STEP.WAITING,
        callStartType: CALL_START_TYPE.FROM_LEFT,
        sessionInfo: null,
      };
      state.settingOverview = {
        ...state.settingOverview,
        data: null,
      };
      state.connecting = {
        ...state.connecting,
        stats: null,
        contacts: [],
        actualContacts: [],
        contactsId: [],
        credit: 0,
        hasStats: false,
      };
      state.connected = {
        ...state.connected,
        contact: null,
        contactId: null,
        disconnectedIds: [],
        activeScreen: null,
        tab: ConversationTabs[6].id,
        lastConversation: null,
        lastContactedId: null,
      };
      state.users = { ...state.users, selectedOutcome: null, savedOutcomeId: null };
    },
    resetForEndSingleCall: (state) => {
      state.connecting = {
        ...state.connecting,
        contacts: [],
        actualContacts: [],
        contactsId: [],
        credit: 0,
      };
      state.connected = {
        ...state.connected,
        contact: null,
        contactId: null,
        disconnectedIds: [],
        activeScreen: null,
        tab: ConversationTabs[6].id,
        lastConversation: null,
      };
    },
    handleSendOption: (state, action) => {
      state.send = { ...state.send, ...action.payload };
    },
    storeUserData: (state, action) => {
      state.users = { ...state.users, data: action.payload };
    },
    handleUserData: (state, action) => {
      state.users = { ...state.users, ...action.payload };
    },
    updateContactOutcome: (state, action) => {
      state.users = { ...state.users, selectedOutcome: action.payload };
    },
    updateContactOutcomeSuccess: (state, action) => {
      state.users = { ...state.users, savedOutcomeId: action.payload };
    },
    /* contact script */
    storeDialerMainScript: (state, action) => {
      state.contactScript = { ...state.contactScript, mainScript: action.payload };
    },
    storeDialerMainScriptFullData: (state, action) => {
      state.contactScript = {
        ...state.contactScript,
        mainScript: action.payload.script,
        mainScriptTitle: action.payload.title,
        mainScriptId: action.payload.id,
      };
    },
    addContactScriptData: (state, action) => {
      let scripts = { ...state.contactScript.data };
      scripts[action.payload.contactId] = action.payload.script;
      state.contactScript = { ...state.contactScript, data: scripts };
    },
    restoreContactScriptData: (state) => {
      state.contactScript = { ...state.contactScript, data: null };
    },
    getAllScripts: (state) => {
      state.contactScript = { ...state.contactScript, gettingAllScripts: true };
    },
    storeAllScripts: (state, action) => {
      state.contactScript = { ...state.contactScript, gettingAllScripts: false, allScripts: action.payload };
    },
    storeAllScriptsError: (state) => {
      state.contactScript = { ...state.contactScript, gettingAllScripts: false, allScripts: [] };
    },
  },
});

export const {
  handleStartCall,
  handleEndCall,
  storeBasicInit,
  handleInitStep,
  handleMainScreen,
  handleCallStep,
  handleNextCall,
  getSettingOverviewOnBackground,
  getSettingOverview,
  getSettingOverviewSuccess,
  getSettingOverviewError,
  getStats,
  getStatsSuccess,
  getStatsError,
  getContacts,
  getContactsSuccess,
  getContactsError,
  modifyContactsData,
  restoreContactData,
  restoreConnectedData,
  handleConnectingState,
  storeConnectedContact,
  storeDisConnectedContact,
  handleConnectedScreenComponent,
  handleConnectedData,
  geContactTags,
  geContactTagSuccess,
  geContactTagError,
  geContactLastNote,
  geContactNoteSuccess,
  geContactNoteError,
  getConversation,
  getConversationSuccess,
  getConversationError,
  gettingMoreConversation,
  needToGetMoreConversation,
  getContactScript,
  getContactScriptSuccess,
  getContactScriptError,
  handleConversationState,
  handleSocket,
  createNewSession,
  storeConnectedContactByDelay,
  resetStatesInitialize,
  handleSendOption,
  resetForEndPowerDialerCall,
  resetForEndSingleCall,
  storeUserData,
  updateContactOutcome,
  updateContactOutcomeSuccess,
  handleUserData,
  addContactScriptData,
  restoreContactScriptData,
  storeDialerMainScript,
  storeDialerMainScriptFullData,
  getAllScripts,
  storeAllScripts,
  storeAllScriptsError,
} = dialerSlice.actions;

export default dialerSlice.reducer;
