import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import {
  callTimer,
  DIALER_STEP,
  handleCallStep,
  handleConnectingState,
  handleEndCall,
  resetForEndPowerDialerCall,
  // storeConnectedContact,
} from "../../state/features/dialer/dialerSlice";
import { selectDialerState } from "../../state/features/dialer/dialerSelector";
import DialerBasicInfo from "../MakeCall/DialerBasicInfo/DialerBasicInfo";
import DialingLines from "../MakeCall/DialingLines/DialingLines";
import CallConnected from "../CallConnected/CallConnected";
import WaitingScreen from "../MakeCall/WaitingScreen/WaitingScreen";
import { errorHandler } from "../../helpers/utils/errorHandler";
import { getAccountData } from "../../helpers/utils/getAccountData";
import Styles from "./callScreen.module.scss";
import InitializingService from "../../services/call/Initializing.service";
import ConntectedService from "../../services/call/Conntected.service";
import { reFetchDialerContacts } from "../../state/features/dialerContacts/dialerContactsSlice";
import { TIME_LIMIT_CLOSE_CALL } from "../../helpers/constant/Constants";

const userId = getAccountData("userId");
const agencyId = getAccountData("agencyId");
const parentId = getAccountData("parentId");
let createCallTimeout = null;

const CallScreen = ({ deviceError, isDeviceReady, setUpDevice, device, callEvent }) => {
  const dispatch = useDispatch();
  const {
    init: { step, virtualNumber, sessionId, listId, callStep },
    connecting: { contacts, callingError },
    settingOverview: { data: setting },
  } = useSelector(selectDialerState);

  const [callState, setCallState] = useState({
    mute: false,
    hold: false,
  });

  // useEffect(() => {
  //   dispatch(
  //     storeConnectedContact({
  //       callRecord: "",
  //       call_duration: 45,
  //       call_outcome: null,
  //       company_name: "",
  //       email: null,
  //       first_name: "crmstealth",
  //       id: 20901297,
  //       last_name: "",
  //       number: "13023054337",
  //     })
  //   );
  // }, []);

  const onClickCall = async () => {
    if (!isDeviceReady) {
      return;
    }
    if (callStep == DIALER_STEP.CONNECTING) {
      return;
    }
    let to = [];
    for (let j = 0; j < contacts.length; j++) {
      to.push(contacts[j].number);
    }

    let params;
    if (to.length > 0) {
      params = {
        To: to,
        fromNumber: virtualNumber,
        timeLimit: setting?.maximumCallTime,
        powerDial: true,
        userId: userId,
        parentId: parentId,
        sessionId: sessionId,
        record: setting?.callRecording,
        agencyId: agencyId,
        powerDialerListId: listId,
        ringingTime: setting?.ringTime,
        closeCallWhenTimeLimitExceed:
          setting?.actionForMaxCallDuration == TIME_LIMIT_CLOSE_CALL.showAlert ? false : true,
      };
    } else {
      dispatch(handleCallStep(DIALER_STEP.HAS_CONNECTING_ERROR));
      dispatch(handleConnectingState({ callingError: "No number selected !" }));
      errorHandler("no number selected");
      return;
    }

    if (!sessionStorage.getItem("power-dialer-register")) {
      setUpDevice();
    } else {
      createCallTimeout = setTimeout(() => {
        console.log("------81: calling--------");
        console.log(device);
        if (step == DIALER_STEP.STOP) {
          return;
        }
        window.callEventsCallback(params);
      }, 5000);
    }
  };

  const handleEndPowerDialer = () => {
    clearTimeout(createCallTimeout);
    try {
      device.disconnectAll();
    } catch (error) {
      console.log(error);
    }
    dispatch(handleEndCall());
    dispatch(resetForEndPowerDialerCall());
    InitializingService.storePowerDialerCallEnd({ powerDialerListId: listId });
    updateCallInformation(callTimer.sessionTime);
    callTimer.sessionTime = 0;
    // contacts re-fetch
    dispatch(reFetchDialerContacts());
    setCallState({
      mute: false,
      hold: false,
    });
  };
  const handleEndSigleCall = () => {
    callTimer.callingTime = 0;
    clearTimeout(createCallTimeout);
    try {
      device.disconnectAll();
      // contacts re-fetch
      dispatch(reFetchDialerContacts());
    } catch (error) {
      console.log(error);
    }
    setCallState({
      mute: false,
      hold: false,
    });
  };
  const sendDigit = (digit) => {
    if (callEvent) {
      callEvent.then((call) => {
        call.sendDigits(digit);
      });
    }
  };
  const handleHold = () => {
    let flag = !callState.hold;
    setCallState({ ...callState, hold: flag });
    if (callEvent) {
      callEvent.hold(flag);
    }
  };
  const handleMute = () => {
    let flag = !callState.mute;
    setCallState({ ...callState, mute: flag });
    if (callEvent) {
      callEvent.then((call) => {
        call.mute(flag);
      });
    }
  };

  const updateCallInformation = (time) => {
    ConntectedService.updateCallInformation({
      powerDialerListId: parseInt(listId),
      sessionId: sessionId,
      total_session_time: time,
    }).then((res) => {
      console.log(res);
      callTimer.sessionTime = 0;
    });
  };

  const handleScreen = () => {
    if (!isDeviceReady) {
      if (deviceError !== "") {
        return (
          <div className={Styles.emptyWrp}>
            <p>Sorry !!!</p>
            <p>{deviceError}</p>
          </div>
        );
      }
      return (
        <div className={Styles.emptyWrp}>
          <p>Please wait...</p>
          <p>Call environment is setting up...</p>
        </div>
      );
    }
    if (step === DIALER_STEP.HAS_CONNECTING_ERROR) {
      return (
        <div className={Styles.connectingWrp}>
          <DialerBasicInfo />
          <div className={Styles.emptyWrp}>
            <p>{callingError}</p>
            <p onClick={handleEndPowerDialer}>Close the window.</p>
          </div>
        </div>
      );
    }
    if (step === DIALER_STEP.SHOW_CONNECTING_SCREEN) {
      return (
        <div className={Styles.connectingWrp}>
          <DialerBasicInfo />
          <DialingLines onClickCall={onClickCall} />
        </div>
      );
    }
    if (step === DIALER_STEP.SHOW_CONNETED_SINGLE_SCREEN) {
      return "this is single connected screen";
    }
    if (step === DIALER_STEP.SHOW_CONNECTED_SCREEN) {
      return <CallConnected />;
    }
    if (step === DIALER_STEP.SHOW_WAITING_FOR_NEXT) {
      return <WaitingScreen />;
    }
    return (
      <div className={Styles.emptyWrp}>
        <p>Start the call from scratch !</p>
        <p onClick={handleEndPowerDialer}>Close the window. </p>
      </div>
    );
  };

  let test = false;
  if (test) {
    return (
      <div className={Styles.wrapper}>
        <Header />
        <div>{handleScreen()}</div>
        <Footer
          handleEndSigleCall={handleEndSigleCall}
          handleEndPowerDialer={handleEndPowerDialer}
          sendDigit={sendDigit}
          callState={callState}
          handleMute={handleMute}
          handleHold={handleHold}
        />
      </div>
    );
  }

  return (
    <div className={Styles.wrapper}>
      <Header />
      <div>{handleScreen()}</div>
      {/* <CallConnected /> */}
      <Footer
        handleEndSigleCall={handleEndSigleCall}
        handleEndPowerDialer={handleEndPowerDialer}
        sendDigit={sendDigit}
        callState={callState}
        handleMute={handleMute}
        handleHold={handleHold}
      />
    </div>
  );
};
export default React.memo(CallScreen);
