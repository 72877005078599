import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  alpha,
  Box,
  Divider,
  FormControl,
  MenuItem,
  Popover,
  Select,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ClearIcon from "@mui/icons-material/Clear";
import {
  addDialerContactConnectedStatus,
  addDialerContactOutcomeStatus,
  addDialerContactSession,
  resetDialerContactFilters,
} from "../../state/features/dialerContacts/dialerContactsSlice";
import { selectDialerContactsFilters } from "../../state/features/dialerContacts/dialerContactsSelector";
import { selectSelectedDialerList } from "../../state/features/dialerList/dialerListSelector";
import { useGetDialerSessionListQuery } from "../../state/features/common/dialerSession/dialerSessionApiSlice";
import { useGetCallOutcomeListQuery } from "../../state/features/common/callOutcomeList/callOutcomeApiSlice";
import ButtonForIcon from "../../common/Button/ButtonForIcon";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const connectedList = [
  { value: 1, title: "Connected" },
  { value: 0, title: "Not Connected" },
];

const FormControlCSS = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(1.3),
  gap: theme.spacing(0.5),
  "& .MuiSelect-select": {
    padding: "4px 14px",
  },
}));
const NumberBox = styled(Box)(({ theme }) => ({
  fontSize: "13px",
  backgroundColor: alpha(theme.palette.text.secondary, 0.4),
  borderRadius: "12px",
  padding: "0 9px",
  margin: "0 2px",
}));

const DialerContactMultiFilter = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const { id: selectedDialerId } = useSelector(selectSelectedDialerList);
  const { data: dialerSessionState } = useGetDialerSessionListQuery({ id: selectedDialerId });
  const { data: dialerSessionList = [] } = dialerSessionState?.data || {};

  const { data: callOutcomeState } = useGetCallOutcomeListQuery();
  const { data: callOutcomeList = [] } = callOutcomeState || {};

  const { session, outcome, connected } = useSelector(selectDialerContactsFilters);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleChangeSession = (e) => {
    dispatch(addDialerContactSession(e.target.value));
  };

  const handleChangeOutcome = (e) => {
    dispatch(addDialerContactOutcomeStatus(e.target.value));
  };

  const handleChangeConnectStatus = (e) => {
    dispatch(addDialerContactConnectedStatus(e.target.value));
  };

  const handleResetFilter = (e) => {
    e.stopPropagation();
    dispatch(resetDialerContactFilters());
  };

  let totalFilter = 0;
  if (session) totalFilter += 1;
  if (outcome) totalFilter += 1;
  if (typeof connected === "number") totalFilter += 1;

  const textColor = totalFilter > 0 ? theme.palette.common.white : theme.palette.text.secondary;
  const ButtonBackgroundColor = totalFilter > 0 ? theme.palette.secondary.main : theme.palette.other.dividerFillColor;

  return (
    <>
      <ButtonForIcon
        variant='contained'
        size={"small"}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        startIcon={<FilterAltIcon />}
        textColor={textColor}
        backgroundColor={ButtonBackgroundColor}
        sx={{ height: "34px" }}
      >
        {totalFilter > 0 && (
          <>
            <NumberBox>{totalFilter}</NumberBox>
            <Box
              sx={{
                display: "inline-flex",
                cursor: "pointer",
                "&:hover": { transform: "rotate(180deg)", transition: "all 0.4s ease" },
              }}
            >
              <ClearIcon onClick={handleResetFilter} />
            </Box>
          </>
        )}
      </ButtonForIcon>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        elevation={6}
      >
        <Box p={2} sx={{ width: "260px" }}>
          <Divider textAlign='left'>
            <Typography variant={"subtitle1"} color={"text.primary"}>
              Filters
            </Typography>
          </Divider>

          <FormControlCSS fullWidth size={"small"}>
            <Typography variant='body2'>Filter By Session</Typography>
            <Select
              displayEmpty
              value={session}
              onChange={handleChangeSession}
              inputProps={{ "aria-label": "Without label" }}
              MenuProps={MenuProps}
            >
              <MenuItem value=''>None</MenuItem>
              {dialerSessionList.map((session) => (
                <MenuItem key={session.id} value={session.id}>
                  {session.name}
                </MenuItem>
              ))}
            </Select>
          </FormControlCSS>

          <FormControlCSS fullWidth size={"small"}>
            <Typography variant='body2'>Filter By Outcome</Typography>
            <Select
              displayEmpty
              value={outcome}
              onChange={handleChangeOutcome}
              inputProps={{ "aria-label": "Without label" }}
              MenuProps={MenuProps}
            >
              <MenuItem value=''>None</MenuItem>
              {callOutcomeList.map((outcome) => (
                <MenuItem key={outcome.id} value={outcome.id}>
                  {outcome.outcome}
                </MenuItem>
              ))}
            </Select>
          </FormControlCSS>

          <FormControlCSS fullWidth size={"small"}>
            <Typography variant='body2'>Filter By Connection</Typography>
            <Select
              displayEmpty
              value={connected}
              onChange={handleChangeConnectStatus}
              inputProps={{ "aria-label": "Without label" }}
              MenuProps={MenuProps}
            >
              <MenuItem value=''>None</MenuItem>
              {connectedList.map((connect) => (
                <MenuItem key={connect.title} value={connect.value}>
                  {connect.title}
                </MenuItem>
              ))}
            </Select>
          </FormControlCSS>
        </Box>
      </Popover>
    </>
  );
};

export default DialerContactMultiFilter;
