import React from "react";
import { useCountdown } from "../../hooks/useCountDown";

const formater = (value) => value;

const CountDown = ({
  startValue = 0,
  endValue = 60,
  increment = 1,
  callback = () => {},
  className = "",
  format = formater,
  callbackOnEveryUpdate = () => {},
}) => {
  const newValue = useCountdown({
    startValue: parseInt(startValue),
    endValue: endValue,
    increment: parseFloat(increment),
    callback: callback,
    callbackOnEveryUpdate: callbackOnEveryUpdate,
  });
  return <span className={`${className}`}>{format(newValue)}</span>;
};
export default CountDown;

/* 
props: 
  startValue: number,
  endValue: number,
  increment: number,
  callback: function,
  className: string,
  format: function
*/
