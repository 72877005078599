import React from "react";
import { ArrowBack } from "@mui/icons-material";
import { Box, styled, Typography } from "@mui/material";
import ButtonBack from "../../../screens/auto-dialer-ongoing/global/ButtonBack/ButtonBack";
import { handleSendOption, SEND_OPTIONS } from "../../../../state/features/dialer/dialerSlice";
import { useDispatch, useSelector } from "react-redux";
import { getAccountData } from "../../../../helpers/utils/getAccountData";
import { selectDialerState } from "../../../../state/features/dialer/dialerSelector";
import { Link } from "react-router-dom";
import Styles from "./addTag.module.scss";

const StyledActionItem = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.body2.fontFamily,
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.other.outlinedBorder,
  border: `1px solid ${theme.palette.other.outlinedBorderDarker}`,
  borderRadius: "4px",
  display: "grid",
  placeContent: "center",
  textAlign: "center",
  padding: "0.5rem 1rem",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: theme.palette.other.outlinedBorderDarker,
  },
}));

const thanksIoActive =
  getAccountData("customMenu")["63"] &&
  getAccountData("customMenu")["63"] &&
  getAccountData("customMenu")["63"] !== null &&
  getAccountData("customMenu")["63"] !== ""
    ? true
    : false;

const zenDirectActive =
  getAccountData("customMenu")["61"] &&
  getAccountData("customMenu")["61"] &&
  getAccountData("customMenu")["61"] !== null &&
  getAccountData("customMenu")["61"] !== ""
    ? true
    : false;

const SendDirectMail = () => {
  const dispatch = useDispatch();
  const {
    connected: { contactId },
  } = useSelector(selectDialerState);

  return (
    <div className={Styles.addTagWrapper}>
      <ButtonBack
        backHandler={() => {
          dispatch(handleSendOption({ option: SEND_OPTIONS.default }));
        }}
        title='Send Direct Mail/Gifts'
        icon={<ArrowBack sx={{ color: "action.active" }} />}
      />

      <Box sx={{ padding: "20px" }}>
        <Typography variant='h6' color={"text.secondary"} sx={{ textAlign: "center", padding: "10px 5px" }}>
          Pick a Card Type
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "8px",
            marginTop: "10px",
          }}
        >
          {thanksIoActive && (
            <>
              <Link
                to={{
                  pathname: `/directmail-send/${contactId}`,
                  search: `contactId=${contactId}&card_type=postcard&for=power-dialer-send`,
                  hash: "power-dialer-send",
                }}
                target='_blank'
                className={Styles.hreftext}
              >
                <StyledActionItem>Postcard</StyledActionItem>
              </Link>

              <Link
                to={{
                  pathname: `/directmail-send/${contactId}`,
                  search: `contactId=${contactId}&card_type=letter&for=power-dialer-send`,
                  hash: "power-dialer-send",
                }}
                target='_blank'
                className={Styles.hreftext}
              >
                <StyledActionItem>Letter</StyledActionItem>
              </Link>

              <Link
                to={{
                  pathname: `/directmail-send/${contactId}`,
                  search: `contactId=${contactId}&card_type=notecard&for=power-dialer-send`,
                  hash: "power-dialer-send",
                }}
                target='_blank'
                className={Styles.hreftext}
              >
                <StyledActionItem>Notecard</StyledActionItem>
              </Link>
            </>
          )}
          {zenDirectActive && (
            <Link
              to={{
                pathname: `/directmail-send/${contactId}`,
                search: `contactId=${contactId}&card_type=zendirect_gift&for=power-dialer-send`,
                hash: "power-dialer-send",
              }}
              target='_blank'
              className={Styles.hreftext}
            >
              <StyledActionItem variant='body2'>Gift</StyledActionItem>
            </Link>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default SendDirectMail;
