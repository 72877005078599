import React from "react";
import { Chip, IconButton, List, ListItem, ListItemText, Paper, Stack, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import { DeleteIcon } from "../../../common/icon/Icons";
import CustomTooltip from "../../../common/Tooltip/CustomTooltip";
import { useDispatch } from "react-redux";
import { deleteScript, makeDefaultScript } from "../../../state/features/listSetting/scriptSetting/scriptSettingSlice";
import { customDeleteAlert } from "../../../common/custom-alert/customDeleteAlert";

const ScriptList = ({ onSetEdit, scripts = [] }) => {
  const dispatch = useDispatch();

  const handleMakeDefault = (id) => {
    dispatch(makeDefaultScript({ script_id: id }));
  };

  const handleDelete = (id) => {
    customDeleteAlert({
      subTitle: "Are you sure to delete this script?",
      onConfirm: () => {
        dispatch(deleteScript({ script_id: id }));
        onSetEdit(null);
      },
    });
  };

  return (
    <Paper elevation={0} sx={{ bgcolor: "action.actionHover", overflow: "hidden", my: 2 }}>
      {scripts.length === 0 && (
        <Stack alignItems={"center"} justifyContent={"center"} py={3}>
          <Typography variant={"body2"} color={"text.primary"}>
            No scripts Found
          </Typography>
        </Stack>
      )}
      <List sx={{ py: 0, maxHeight: 300 }} className={"pdad-scroll-bar"}>
        {scripts.map((script) => {
          return (
            <ListItem
              selected={script.is_default}
              key={script.id}
              sx={{ py: 0 }}
              secondaryAction={
                <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
                  {script.is_default ? (
                    <Chip
                      size={"small"}
                      label={"Default"}
                      color={"secondary"}
                      onClick={() => {}}
                      onDelete={() => {}}
                      deleteIcon={<DoneIcon />}
                      sx={{ mr: 0.5 }}
                    />
                  ) : (
                    <CustomTooltip title={"Set as default"} arrow placement='top'>
                      <IconButton edge='start' aria-label='default' onClick={() => handleMakeDefault(script.id)}>
                        <DoneIcon />
                      </IconButton>
                    </CustomTooltip>
                  )}
                  <CustomTooltip title={"Edit"} arrow placement='top'>
                    <IconButton edge='end' aria-label='edit' onClick={() => onSetEdit(script)}>
                      <EditIcon sx={{ fontSize: "20px" }} />
                    </IconButton>
                  </CustomTooltip>
                  {!script.is_default && (
                    <CustomTooltip title={"Delete"} arrow placement='top'>
                      <IconButton edge='end' aria-label='delete' onClick={() => handleDelete(script.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </CustomTooltip>
                  )}
                </Stack>
              }
            >
              <ListItemText
                sx={{ maxWidth: "80%", my: 2.5 }}
                primary={
                  <Typography
                    variant={"body2medium"}
                    color={"text.primary"}
                    className={"text-truncate"}
                    title={script.title}
                  >
                    {script.title}
                  </Typography>
                }
                secondary={
                  <p className='text-truncate' title={script.description}>
                    {script.description}
                  </p>
                }
              />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );
};
export default ScriptList;
