import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
  isLoading: false,
  isError: false,
  error: "",

  selectedSession: {},
};

const dialerAnalyticsSlice = createSlice({
  name: "dialerAnalytics",
  initialState: initialState,
  reducers: {
    getDialerAnalytics: (state) => {
      state.isLoading = true;
    },
    getDialerAnalyticsSuccess: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    },
    getDialerAnalyticsFailed: (state, action) => {
      state.data = [];
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },

    setDialerAnalyticsSession: (state, action) => {
      state.selectedSession = action.payload;
    },
  },
});

export const { getDialerAnalytics, getDialerAnalyticsSuccess, getDialerAnalyticsFailed, setDialerAnalyticsSession } =
  dialerAnalyticsSlice.actions;

export default dialerAnalyticsSlice.reducer;
