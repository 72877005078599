import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  FormControl,
  FormControlLabel,
  InputAdornment,
  OutlinedInput,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { selectTriggerSetting } from "../../../state/features/listSetting/listSettingSelector";
import { ActionForMoveNextCallOptions, Texts } from "../../../helpers/constant/Constants";
import { updateTriggerSetting } from "../../../state/features/listSetting/triggerSetting/triggerSettingSlice";
import CustomRadioGroup from "../../../common/Radio/CustomRadioGroup";
import CustomRadio from "../../../common/Radio/CustomRadio";

const StylesStack = styled(Stack)(({ theme }) => ({
  borderRadius: "4px",
  border: `1px solid ${theme.palette.other.outlinedBorder}`,
}));

const InputSideText = styled(Typography)(({ theme }) => ({
  background: theme.palette.action.selected,
  padding: "6px 10px",
  marginRight: "-14px",
  borderRadius: "0 4px 4px 0",
}));

const ActionMoveNextCall = () => {
  const dispatch = useDispatch();
  const [hasError, setHasError] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const {
    settings: { move_next_call_action, interval_between_calls },
  } = useSelector(selectTriggerSetting);

  useEffect(() => {
    setInputValue(interval_between_calls);
  }, [interval_between_calls]);

  const changeSetting = (objData) => {
    dispatch(updateTriggerSetting(objData));
  };

  const onChangeInput = (e, name) => {
    let value = parseInt(e.target.value);
    setInputValue(value);

    if (!Number.isInteger(value) || value < 1 || value > 120) {
      setHasError(true);
      return;
    }

    changeSetting({ [name]: value });
    setHasError(false);
  };

  return (
    <Box px={4} py={2}>
      <Stack direction={"column"}>
        <Typography variant='body2medium'>{Texts.actionForMoveNextCall}</Typography>
        <Typography color='text.secondary' variant='chipLight'>
          {Texts.actionForMoveNextCallHints}
        </Typography>
      </Stack>

      <Box p={2}>
        <FormControl>
          <CustomRadioGroup
            value={move_next_call_action}
            onChange={(e) => changeSetting({ move_next_call_action: e.target.value })}
          >
            {ActionForMoveNextCallOptions.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<CustomRadio color='secondary' />}
                label={
                  <Typography variant={"body2"} color={"text.primary"}>
                    {option.label}
                  </Typography>
                }
              />
            ))}
          </CustomRadioGroup>
        </FormControl>
      </Box>
      {move_next_call_action === ActionForMoveNextCallOptions[0].value && (
        <StylesStack direction={"row"} alignItems={"center"} justifyContent={"space-between"} p={2}>
          <Stack>
            <Typography variant={"body2medium"} color={"text.primary"}>
              {Texts.intervalBetCalls}
            </Typography>
            <Typography variant={"chipLight"} color={"text.secondary"}>
              {Texts.durationBetCalls}
            </Typography>
          </Stack>

          <Box>
            <OutlinedInput
              id='outlined-adornment-weight'
              endAdornment={
                <InputAdornment position='end'>
                  <InputSideText variant={"inputText"} color={"text.secondary"}>
                    Sec
                  </InputSideText>
                </InputAdornment>
              }
              aria-describedby='outlined-weight-helper-text'
              type={"number"}
              value={inputValue}
              onChange={(e) => onChangeInput(e, "interval_between_calls")}
              sx={{
                width: "150px",
                "& .MuiOutlinedInput-input": { height: "36px !important", padding: "1px 0 1px 14px !important" },
              }}
              inputProps={{ min: 1, max: 120 }}
            />

            {hasError && (
              <Typography color='error.main' variant='body2medium' component='p'>
                Invalid duration
              </Typography>
            )}
          </Box>
        </StylesStack>
      )}
    </Box>
  );
};

export default ActionMoveNextCall;
