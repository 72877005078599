import React, { useEffect, useState } from "react";
import EmptyState from "./EmptyState";
import VoiceTemplateList from "./VoiceTemplateList";
import { showGlobalNotification } from "../../../helpers/utils/showGlobalNotification";
import VoicemailTemplateService from "../../../services/call/VoicemailTemplate.service";
import VoiceTemplateListSkeleton from "./VoiceTemplateListSkeleton";

const VoiceMailSetting = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    VoicemailTemplateService.getVoicemailTemplates({})
      .then((response) => {
        if (response.success) {
          setData(response.data);
        } else {
          setData([]);
          showGlobalNotification(response.message);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        showGlobalNotification("Something went wrong! Try again later");
      });
  };

  const handleUpdateItem = (item) => {
    if (item && item.id) {
      const updatedData = data.map((dataItem) => (dataItem.id === item.id ? { ...dataItem, ...item } : dataItem));
      setData(updatedData);
    } else {
      getData();
    }
  };

  const removeItem = (removeId) => {
    const updatedData = data.filter((item) => item.id !== removeId);
    setData(updatedData);
  };

  return isLoading ? (
    <VoiceTemplateListSkeleton />
  ) : (
    <>
      {data.length === 0 && <EmptyState handleUpdateItem={(item) => handleUpdateItem(item)} />}

      {data.length > 0 && (
        <VoiceTemplateList
          data={data}
          handleUpdateItem={(item) => handleUpdateItem(item)}
          removeItem={(removeId) => removeItem(removeId)}
        />
      )}
    </>
  );
};

export default VoiceMailSetting;
