import React from "react";
import { Typography } from "@mui/material";
import Styles from "./buttonBack.module.scss";

const ButtonBack = ({ border = true, backHandler = () => {}, icon, title = "New SMS" }) => {
  return (
    <div className={`${Styles.buttonBackWrapper} ${!border && Styles.noBorder}`} onClick={backHandler}>
      <div className={Styles.backButton}>
        {icon ? icon : ""}
        <Typography variant='body2medium' color='text.secondary'>
          {title}
        </Typography>
      </div>
    </div>
  );
};

export default ButtonBack;
