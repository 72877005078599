import React from "react";
import { List } from "@mui/material";
import { Add } from "@mui/icons-material";
import AddOrEditDialerList from "./AddOrEditDialerList";
import CoreButton from "../../common/Button/CoreButton";
import BasicModalWithHeader from "../../common/Modal/BasicModalWithHeader";
import useBoolean from "../../hooks/useBoolean";
import Styles from "../PowerDialer/DialerBody.module.scss";

const CreateDialer = () => {
  const { value: isOpenModal, setTrue: onOpenModal, setFalse: onCloseModal } = useBoolean(false);

  return (
    <>
      <List className={Styles.CreatNewFolder} sx={{ pt: 0.5, pb: 2 }}>
        <CoreButton fullWidth variant={"contained"} color={"secondary"} startIcon={<Add />} onClick={onOpenModal}>
          Create New List
        </CoreButton>
      </List>

      <BasicModalWithHeader title={"Create New Power Dialer List"} open={isOpenModal} onClose={onCloseModal}>
        <AddOrEditDialerList onClose={onCloseModal} isEditMode={false} />
      </BasicModalWithHeader>
    </>
  );
};

export default CreateDialer;
