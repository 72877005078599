import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Close, DeleteOutline, Done } from "@mui/icons-material";
import {
  alpha,
  Avatar,
  Box,
  Checkbox,
  IconButton,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import ContactShareContent from "./ContactShareContent";
import ContactDeleteContent from "./ContactDeleteContent";
import DialerContactEmptyPage from "./DialerContactEmptyPage";
import TableLoaderSkeleton from "../../common/LoadingView/TableLoaderSkeleton";
import GlobalTablePagination from "../../common/Pagination/GlobalTablePagination";
import BasicModal from "../../common/Modal/BasicModal";
import HrefLink from "../../common/Link/HrefLink";
import CustomTooltip from "../../common/Tooltip/CustomTooltip";
import DialPhoneIcon from "../../common/Icons/DialPhoneIcon";
import BasicModalWithHeader from "../../common/Modal/BasicModalWithHeader";
import { EmptyPowerDialerListIcon } from "../../common/Icons/EmptyDataIcons";
import { selectDialerContactsState } from "../../state/features/dialerContacts/dialerContactsSelector";
import useBoolean from "../../hooks/useBoolean";
import { castArray } from "../../helpers/utils/castArray";
import getFullName from "../../helpers/utils/getFullName";
import {
  deletedDialerContact,
  deselectAllContact,
  getDialerContacts,
  selectAllContact,
  toggleSelectSingleContact,
} from "../../state/features/dialerContacts/dialerContactsSlice";
import { selectSelectedDialerList } from "../../state/features/dialerList/dialerListSelector";
import removeFalsyObjectProperty from "../../helpers/utils/removeFalsyObjectProperty";
import secondsToTime from "../../helpers/utils/secondsToTime";

const StyledAvatar = styled(Avatar)(({ theme, connected }) => ({
  backgroundColor: connected === "true" ? alpha(theme.palette.success.main, 0.2) : alpha(theme.palette.error.main, 0.2),
  width: "28px",
  height: "28px",
  margin: "0 auto",
}));

const StyledTable = styled(Table)(({ theme }) => ({
  minWidth: 750,
  border: `1px solid ${theme.palette.other.outlinedBorder}`,
  borderBottom: "none",
  "& .MuiTableRow-root": {
    "& .MuiTableCell-root": { borderBottom: `1px solid ${theme.palette.other.outlinedBorder}` },
  },
}));

const StyledAudio = styled("audio")(({ theme, isItemSelected }) => ({
  width: "180px",
  height: "32px",
  "&::-webkit-media-controls-panel": {
    padding: 2,
    background: isItemSelected ? theme.palette.common.white : "inherit",
  },
  "&::-webkit-media-controls-timeline": {
    padding: "0 4px 0 8px",
  },
  "&::-webkit-media-controls-current-time-display": {
    display: "none",
  },
  "&::-webkit-media-controls-time-remaining-display": {
    display: "none",
  },
}));

const headCells = [
  {
    label: "Contact",
  },
  {
    label: "Total Calls",
    align: "center",
  },
  {
    label: "Date",
    align: "center",
  },
  {
    label: "Connected?",
    align: "center",
  },
  {
    label: "Call Duration",
    align: "center",
  },
  {
    label: "Call Outcome",
    align: "center",
  },
  {
    label: "Call Recording",
    align: "center",
  },
  {
    label: "Action",
    align: "center",
  },
];

// Table Head
function ContactTableHead({ selectedCount = 0, rowCount = 0, onSelectAllClick }) {
  const theme = useTheme();

  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: theme.palette?.other.dividerFillColor }}>
        <TableCell padding='checkbox'>
          <Checkbox
            color='primary'
            indeterminate={selectedCount > 0 && selectedCount < rowCount}
            checked={rowCount > 0 && selectedCount === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
            sx={{
              "& .PrivateSwitchBase-input": {
                pointerEvents: "auto !important",
              },
            }}
          />
        </TableCell>

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.label}
            padding={"normal"}
            align={headCell.align}
            sx={{ minWidth: { xs: "180px", lg: "140px" } }}
          >
            <Typography variant={"TableHeader"} color={"text.secondary"}>
              {headCell.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const DialerListContacts = () => {
  const dispatch = useDispatch();
  const {
    data: dialerContactList,
    isLoading,
    page,
    limit,
    count,
    renderId,
    selectedContactIds,
    filters,
  } = useSelector(selectDialerContactsState);
  const { searchText, session: sessionId, outcome: callOutcomeId, connected: connectedStatus } = filters || {};
  const { id: selectedDialerId } = useSelector(selectSelectedDialerList);

  const [selectedContact, setSelectedContact] = useState({});
  const { value: isOpenDeleteModal, setTrue: onOpenDeleteModal, setFalse: onCloseDeleteModal } = useBoolean(false);
  const {
    value: isOpenShareContactModal,
    setTrue: onOpenShareContactModal,
    setFalse: onCloseShareContactModal,
  } = useBoolean(false);
  const theme = useTheme();

  useEffect(() => {
    let currentPage = 1;
    if (renderId) {
      if (count > limit && count <= page * limit) currentPage = page - 1;
      else currentPage = page;
    }

    const payload = removeFalsyObjectProperty({
      powerDialerListId: selectedDialerId,
      limit,
      page: currentPage || 1,
      searchText,
      sessionId,
      callOutcomeId,
    });
    if (typeof connectedStatus === "number") payload.connectedStatus = connectedStatus;

    dispatch(getDialerContacts(payload));
  }, [selectedDialerId, renderId, searchText, sessionId, callOutcomeId, connectedStatus]);

  // ## Pagination
  const onChangePerPage = (perPage, newPage) => {
    const payload = removeFalsyObjectProperty({
      powerDialerListId: selectedDialerId,
      limit: perPage,
      page: newPage,
      searchText,
      sessionId,
      callOutcomeId,
    });
    if (typeof connectedStatus === "number") payload.connectedStatus = connectedStatus;
    dispatch(getDialerContacts(payload));
  };

  const onChangePage = (newPage) => {
    const payload = removeFalsyObjectProperty({
      powerDialerListId: selectedDialerId,
      limit,
      page: newPage,
      searchText,
      sessionId,
      callOutcomeId,
    });
    if (typeof connectedStatus === "number") payload.connectedStatus = connectedStatus;
    dispatch(getDialerContacts(payload));
  };

  // ## handle toggle columns select all
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      dispatch(selectAllContact());
    } else {
      dispatch(deselectAllContact());
    }
  };

  // ## handle toggle columns select single
  const handleToggleSelect = (event, id) => {
    dispatch(toggleSelectSingleContact(id));
  };

  const handleAddContactToList = (data) => {
    onOpenShareContactModal();
    setSelectedContact(data);
  };

  const handleDeleteContact = (data) => {
    onOpenDeleteModal();
    setSelectedContact(data);
  };

  const onConfirmDelete = () => {
    dispatch(
      deletedDialerContact({ ids: JSON.stringify(castArray(selectedContact.id)), dialerListId: selectedDialerId })
    );
    setSelectedContact({});
    onCloseDeleteModal();
  };

  // decide what to render
  let content = null;
  if (isLoading) {
    content = <TableLoaderSkeleton numberOfRows={10} columns={6} />;
  } else if (!isLoading && dialerContactList.length === 0) {
    content = (
      <DialerContactEmptyPage
        icon={<EmptyPowerDialerListIcon />}
        title={"No contact found!"}
        description={"You can start adding contacts to fill up this list"}
      />
    );
  } else if (!isLoading && dialerContactList.length > 0) {
    const isSelected = (id) => selectedContactIds.indexOf(id) !== -1;
    content = (
      <StyledTable aria-labelledby='tableTitle' size={"medium"} stickyHeader>
        <ContactTableHead
          selectedCount={selectedContactIds.length}
          rowCount={dialerContactList.length}
          onSelectAllClick={handleSelectAllClick}
        />
        <TableBody>
          {dialerContactList.map((row, index) => {
            const isItemSelected = isSelected(row.id);
            const labelId = `enhanced-table-checkbox-${index}`;

            let {
              id,
              first_name,
              last_name,
              number,
              email,
              avatar,
              company_name = "",
              call_duration,
              call_outcome,
              callRecord,
              total_contacted,
              total_called = 0,
              last_calling_time,
            } = row || {};

            const connected = total_contacted > 0;
            const totalCalled = total_called ? total_called + `${total_called === 1 ? " time" : " times"}` : "-";
            let fullName = getFullName(first_name, last_name, "");
            if (!fullName) fullName = email || "N/A";
            call_duration = call_duration ? secondsToTime(call_duration || 0) : "-";

            const convertDate = (date) => {
              if (date) {
                return window.globalTimezoneConversionToDifferentTimezone(date, "UTC", "", "ll h:mm A");
              }
              return "-";
            };
            return (
              <TableRow key={row.id} hover={true} selected={isItemSelected}>
                <TableCell padding='checkbox' onClick={(event) => handleToggleSelect(event, row.id)}>
                  <Checkbox checked={isItemSelected} inputProps={{ "aria-labelledby": labelId }} color='primary' />
                </TableCell>
                <TableCell align='left'>
                  <Stack direction={"row"} alignItems={"center"} spacing={1}>
                    <Avatar rounded={"true"} src={avatar} alt={"avatar"}>
                      {fullName[0]}
                    </Avatar>
                    <Stack>
                      <Typography
                        variant={"body2medium"}
                        color={"text.primary"}
                        sx={{ maxWidth: "200px", "&:hover": { textDecoration: "underline" } }}
                        className={"text-truncate"}
                        title={fullName}
                      >
                        <HrefLink href={"/contacts/" + id} target={"_blank"}>
                          {fullName}
                        </HrefLink>
                      </Typography>
                      <Typography
                        variant={"chipLight"}
                        color={"text.secondary"}
                        sx={{ maxWidth: "200px" }}
                        className={"text-truncate"}
                        title={company_name}
                      >
                        {number}
                      </Typography>
                      <Typography
                        variant={"chipLight"}
                        color={"text.secondary"}
                        sx={{ maxWidth: "200px" }}
                        className={"text-truncate"}
                        title={company_name}
                      >
                        {company_name}
                      </Typography>
                    </Stack>
                  </Stack>
                </TableCell>
                <TableCell align='center'>
                  <Typography variant={"body1medium"} color={"text.secondary"}>
                    {totalCalled}
                  </Typography>
                </TableCell>
                <TableCell align='center'>
                  <Typography variant={"body1medium"} color={"text.secondary"}>
                    {convertDate(last_calling_time)}
                  </Typography>
                </TableCell>
                <TableCell align='center'>
                  <StyledAvatar size={"small"} connected={connected.toString()}>
                    {connected ? (
                      <Done color={"success"} fontSize={"small"} />
                    ) : (
                      <Close color={"error"} fontSize={"small"} />
                    )}
                  </StyledAvatar>
                </TableCell>

                <TableCell align='center'>
                  <Typography variant={"body1medium"} color={"text.secondary"}>
                    {call_duration || "-"}
                  </Typography>
                </TableCell>

                <TableCell align='center'>
                  <Typography variant={"body1medium"} color={"text.secondary"}>
                    {call_outcome || "-"}
                  </Typography>
                </TableCell>

                <TableCell align='center'>
                  {callRecord ? <StyledAudio isItemSelected={isItemSelected} src={callRecord} controls /> : "-"}
                </TableCell>

                <TableCell align='center'>
                  <Stack direction={"row"} spacing={0.5} justifyContent={"center"}>
                    <CustomTooltip
                      arrow
                      title='Add to a list'
                      placement='top'
                      backgroundcolor={theme.palette.secondary.dark}
                    >
                      <IconButton color={"secondary"} onClick={() => handleAddContactToList(row)} size={"small"}>
                        <DialPhoneIcon />
                      </IconButton>
                    </CustomTooltip>

                    <CustomTooltip arrow title='Delete' placement='top' backgroundcolor={theme.palette.error.dark}>
                      <IconButton color={"error"} onClick={() => handleDeleteContact(row)} size={"small"}>
                        <DeleteOutline />
                      </IconButton>
                    </CustomTooltip>
                  </Stack>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </StyledTable>
    );
  }

  return (
    <Box sx={{ width: "100%", height: "calc(100% - 124px)" }}>
      <Paper sx={{ width: "100%", height: { xs: "calc(100% - 70px)", lg: "calc(100% - 8px)" } }} elevation={0}>
        <TableContainer className='pdad-scroll-bar'>{content}</TableContainer>
        {!isLoading && dialerContactList?.length > 0 && (
          <GlobalTablePagination
            count={count}
            page={page}
            rowsPerPage={limit}
            onChangePage={onChangePage}
            onChangePerPage={onChangePerPage}
          />
        )}
      </Paper>

      <BasicModalWithHeader
        title={"Add to other power dialler list"}
        open={isOpenShareContactModal}
        onClose={onCloseShareContactModal}
      >
        <ContactShareContent onClose={onCloseShareContactModal} contactIds={castArray(selectedContact?.id)} />
      </BasicModalWithHeader>

      <BasicModal open={isOpenDeleteModal} onClose={onCloseDeleteModal}>
        <ContactDeleteContent
          contactIds={castArray(selectedContact?.id)}
          buttonName={"Remove From List"}
          onClose={onCloseDeleteModal}
          onConfirm={onConfirmDelete}
        />
      </BasicModal>
    </Box>
  );
};
export default DialerListContacts;
