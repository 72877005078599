import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, styled } from "@mui/material";
import DialerList from "../DialerList/DialerList";
import RightSideHeader from "./RightSideHeader";
import DialerContact from "../DialerContact/DialerContact";
import CircleLoader from "../../common/LoadingView/CircleLoader";
import GlobalEmptyPage from "../../common/GlobalEmptyPage/GlobalEmptyPage";
import { EmptyPowerDialerListIcon } from "../../common/Icons/EmptyDataIcons";
import { selectDialerListState } from "../../state/features/dialerList/dialerListSelector";
import Styles from "./DialerBody.module.scss";
import { selectListSettingModal } from "../../state/features/listSetting/listSettingSelector";
import BasicDrawer from "../../common/Drawer/BasicDrawer";
import { Texts } from "../../helpers/constant/Constants";
import { toggleListSettingModal } from "../../state/features/listSetting/listSettingModal/listSettingModalSlice";
import DialerListSettings from "../DialerListSettings/DialerListSettings";

const MainContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.other.outlinedBorderDarker}`,
  height: "calc(100% - 85px)",
  width: "100%",
  borderRadius: "4px",
  display: "flex",
}));

const ListTableBox = styled(Box)(({ theme }) => ({
  flex: 1,
  height: "100%",
  backgroundColor: theme.palette.background.paper,
  overflow: "hidden",
  borderRadius: "4px",
  padding: "16px",
}));

const PowerDialerBody = ({ deviceError, isDeviceReady, setUpDevice, device, callEvent }) => {
  const dispatch = useDispatch();
  const { isLoading, selectedDialerList } = useSelector(selectDialerListState);
  const { open: isOpenListSetting } = useSelector(selectListSettingModal);

  const { id: selectedDialerId } = selectedDialerList || {};
  const isHideLeftSideSaveValue = localStorage.getItem("isHideLeftSide")
    ? JSON.parse(localStorage.getItem("isHideLeftSide"))
    : false;

  const [isReadyPage, setReadyPage] = useState(false);
  const [isHideLeftSide, setIsHideLeftSide] = useState(isHideLeftSideSaveValue);
  const [isShowLeftSideTab, setIsShowLeftSideTab] = useState(false);

  useEffect(() => {
    setReadyPage(true);
  }, []);

  const toggleLeftSidePanel = useCallback(() => {
    const width = window?.innerWidth;

    if (width > 1200) {
      setIsHideLeftSide(!isHideLeftSide);
      localStorage.setItem("isHideLeftSide", JSON.stringify(!isHideLeftSide));
    } else {
      setIsHideLeftSide(false);
      localStorage.setItem("isHideLeftSide", JSON.stringify(!isHideLeftSide));
      setIsShowLeftSideTab(!isShowLeftSideTab);
    }
  }, [isHideLeftSide, isShowLeftSideTab]);

  let content = null;
  if (isLoading || !isReadyPage) {
    content = <CircleLoader color={"secondary"} />;
  } else if (!isLoading && !selectedDialerId) {
    content = (
      <GlobalEmptyPage
        icon={<EmptyPowerDialerListIcon />}
        title={"Power Dialer List is empty"}
        description={"You can start adding Power Dialer List on top this page"}
      />
    );
  } else if (!isLoading && selectedDialerId) {
    content = (
      <>
        <RightSideHeader
          deviceError={deviceError}
          isDeviceReady={isDeviceReady}
          setUpDevice={setUpDevice}
          device={device}
          onChangeLeftSideTab={toggleLeftSidePanel}
          callEvent={callEvent}
        />
        <DialerContact />
      </>
    );
  }

  const leftSideWidth = isHideLeftSide ? "56px" : "268px";

  return (
    <MainContainer
      className={`${isHideLeftSide ? Styles.fbpHideSidebar : ""} ${isShowLeftSideTab ? Styles.showTabSidebar : ""}`}
    >
      <Box className={Styles.leftSide} sx={{ width: { xs: "auto", lg: leftSideWidth } }}>
        <DialerList isHideLeftSide={isHideLeftSide} toggleLeftSide={toggleLeftSidePanel} />
      </Box>

      <ListTableBox>{content}</ListTableBox>

      <BasicDrawer
        open={isOpenListSetting}
        toggleDrawer={() => dispatch(toggleListSettingModal({ open: false }))}
        title={Texts.settings}
      >
        <DialerListSettings />
      </BasicDrawer>
    </MainContainer>
  );
};
export default PowerDialerBody;
