// import { getCookie } from "../../helpers/Cookie";
import httpRequest from "../api/httpRequest";

class ConversationService {
  getContactConversation(params) {
    return httpRequest.get(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/call/conversation",
      params
    );
    // const rootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/inbox`;
    // params = { ...params, "api-key": getCookie(process.env.REACT_APP_ACCESS_TOKEN) };
    // return httpRequest.post(rootUrl + "/contact-conversation-v2", params); //power-dialer/call/conversation
  }
}

export default new ConversationService();
