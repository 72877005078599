import {
  AddIcon,
  AddToAListIcon,
  AddUserIcon,
  AnalyticIcon,
  ApiIcon,
  ArrowDown,
  AttachedFileIcon,
  BookmarkIcon,
  CallDialingIcon,
  CallReceivedIcon,
  CallStopIcon,
  CampaignIcon,
  CancelCircleIcon,
  CancelIcon,
  CarrotIcon,
  CarrotLeftIcon,
  CarrotRightIcon,
  CheckBoxIcon,
  CheckedBoxIcon,
  CheckIcon,
  CheckRadioIcon,
  CollapseIcon,
  ColorPickerIcon,
  ContactIcon,
  ContactNoIcon,
  ContactYesIcon,
  CopyIcon,
  CrossSeparatorIcon,
  DeleteIcon,
  DeleteIconOutlined,
  DialingSessionIcon,
  EditCircleIcon,
  EditIcon,
  EmailIcon,
  EstimatedSessionIcon,
  FilterIcon,
  GraphViewIcon,
  ListIcon,
  MenuIcon,
  MinimizedIcon,
  MusicIcon,
  MuteIcon,
  PauseIcon,
  PlayIcon,
  RecordIcon,
  SearchIcon,
  SettingIcon,
  SMSIcon,
  StartDialertIcon,
  StatsIcon,
  StopIcon,
  TagIcon,
  UncheckBoxIcon,
  UncheckRadioIcon,
  UploadIcon,
  UsersIcon,
  VoiceIcon,
} from "./Icons";

import Styles from "./iconView.module.scss";

const IconPreview = () => {
  return (
    <div className={Styles.wrapper}>
      <span className={Styles.eachIcon}>
        AddIcon: <AddIcon />
      </span>
      <span className={Styles.eachIcon}>
        CollapseIcon: <CollapseIcon />
      </span>
      <span className={Styles.eachIcon}>
        ListIcon: <ListIcon />
      </span>
      <span className={Styles.eachIcon}>
        MenuIcon: <MenuIcon />
      </span>
      <span className={Styles.eachIcon}>
        GraphViewIcon: <GraphViewIcon />
      </span>
      <span className={Styles.eachIcon}>
        SettingIcon: <SettingIcon />
      </span>
      <span className={Styles.eachIcon}>
        ContactIcon: <ContactIcon />
      </span>
      <span className={Styles.eachIcon}>
        CarrotIcon: <CarrotIcon />
      </span>
      <span className={Styles.eachIcon}>
        StartDialertIcon: <StartDialertIcon fill='#000' />
      </span>
      <span className={Styles.eachIcon}>
        SearchIcon: <SearchIcon />
      </span>
      <span className={Styles.eachIcon}>
        FilterIcon: <FilterIcon />
      </span>
      <span className={Styles.eachIcon}>
        EditCircleIcon: <EditCircleIcon circle='#000' />
      </span>
      <span className={Styles.eachIcon}>
        EditIcon: <EditIcon />
      </span>
      <span className={Styles.eachIcon}>
        AnalyticIcon: <AnalyticIcon />
      </span>
      <span className={Styles.eachIcon}>
        CopyIcon: <CopyIcon />
      </span>
      <span className={Styles.eachIcon}>
        DeleteIcon: <DeleteIcon />
      </span>
      <span className={Styles.eachIcon}>
        DeleteIconOutlined: <DeleteIconOutlined />
      </span>
      <span className={Styles.eachIcon}>
        ColorPickerIcon: <ColorPickerIcon />
      </span>
      <span className={Styles.eachIcon}>
        UncheckBoxIcon: <UncheckBoxIcon />
      </span>
      <span className={Styles.eachIcon}>
        CheckedBoxIcon: <CheckedBoxIcon />
      </span>
      <span className={Styles.eachIcon}>
        CheckBoxIcon: <CheckBoxIcon />
      </span>
      <span className={Styles.eachIcon}>
        CancelIcon: <CancelIcon />
      </span>
      <span className={Styles.eachIcon}>
        UsersIcon: <UsersIcon />
      </span>
      <span className={Styles.eachIcon}>
        CallReceivedIcon: <CallReceivedIcon />
      </span>
      <span className={Styles.eachIcon}>
        EstimatedSessionIcon: <EstimatedSessionIcon />
      </span>
      <span className={Styles.eachIcon}>
        DialingSessionIcon: <DialingSessionIcon />
      </span>
      <span className={Styles.eachIcon}>
        AddUserIcon: <AddUserIcon />
      </span>
      <span className={Styles.eachIcon}>
        UploadIcon: <UploadIcon />
      </span>
      <span className={Styles.eachIcon}>
        ApiIcon: <ApiIcon />
      </span>
      <span className={Styles.eachIcon}>
        ContactYesIcon: <ContactYesIcon />
      </span>
      <span className={Styles.eachIcon}>
        ContactNoIcon: <ContactNoIcon />
      </span>
      <span className={Styles.eachIcon}>
        PlayIcon: <PlayIcon />
      </span>
      <span className={Styles.eachIcon}>
        AddToAListIcon: <AddToAListIcon />
      </span>
      <span className={Styles.eachIcon}>
        CarrotLeftIcon: <CarrotLeftIcon />
      </span>
      <span className={Styles.eachIcon}>
        CarrotRightIcon: <CarrotRightIcon />
      </span>
      <span className={Styles.eachIcon}>
        CrossSeparatorIcon: <CrossSeparatorIcon />
      </span>
      <span className={Styles.eachIcon}>
        PauseIcon: <PauseIcon />
      </span>
      <span className={Styles.eachIcon}>
        TagIcon: <TagIcon />
      </span>
      <span className={Styles.eachIcon}>
        CampaignIcon: <CampaignIcon />
      </span>
      <span className={Styles.eachIcon}>
        StatsIcon: <StatsIcon />
      </span>
      <span className={Styles.eachIcon}>
        CancelCircleIcon: <CancelCircleIcon />
      </span>
      <span className={Styles.eachIcon}>
        CheckRadioIcon: <CheckRadioIcon />
      </span>
      <span className={Styles.eachIcon}>
        UncheckRadioIcon: <UncheckRadioIcon />
      </span>
      <span className={Styles.eachIcon}>
        SMSIcon: <SMSIcon />
      </span>
      <span className={Styles.eachIcon}>
        EmailIcon: <EmailIcon />
      </span>
      <span className={Styles.eachIcon}>
        VoiceIcon: <VoiceIcon />
      </span>
      <span className={Styles.eachIcon}>
        CheckIcon: <CheckIcon />
      </span>
      <span className={Styles.eachIcon}>
        ArrowDown: <ArrowDown />
      </span>
      <span className={Styles.eachIcon}>
        StopIcon: <StopIcon />
      </span>
      <span className={Styles.eachIcon}>
        MusicIcon: <MusicIcon />
      </span>
      <span className={Styles.eachIcon}>
        MinimizedIcon: <MinimizedIcon />
      </span>
      <span className={Styles.eachIcon}>
        MuteIcon: <MuteIcon />
      </span>
      <span className={Styles.eachIcon}>
        CallStopIcon: <CallStopIcon fill='red' />
      </span>
      <span className={Styles.eachIcon}>
        CallDialingIcon: <CallDialingIcon />
      </span>
      <span className={Styles.eachIcon}>
        BookmarkIcon: <BookmarkIcon />
      </span>
      <span className={Styles.eachIcon}>
        AttachedFileIcon: <AttachedFileIcon />
      </span>
      <span className={Styles.eachIcon}>
        RecordIcon: <RecordIcon />
      </span>
    </div>
  );
};
export default IconPreview;
