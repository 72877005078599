import React from "react";
import { SvgIcon } from "@mui/material";

const AddToListIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.1404 0H18.3509V3.31579H21.6667V5.52632H18.3509V8.83105C18.3509 8.83105 16.1514 8.8421 16.1404 8.83105V5.52632H12.8246C12.8246 5.52632 12.8356 3.32684 12.8246 3.31579H16.1404V0ZM2.87721 19.0326H16.1404V11.0526H18.3509V18.7895C18.3509 20.0053 17.3562 21 16.1404 21H2.87721C1.66142 21 0.666687 20.0053 0.666687 18.7895V5.52632C0.666687 4.31053 1.66142 3.31579 2.87721 3.31579H10.6141V5.52632H2.87721V19.0326ZM10.677 14.2269C9.28684 13.5146 8.14719 12.3799 7.43982 10.9897L8.28077 10.4828C8.41832 10.3452 8.45762 10.1536 8.40358 9.98171C8.22183 9.43153 8.12358 8.84206 8.12358 8.22802C8.12358 7.95785 7.90253 7.73679 7.63235 7.73679H5.57902C5.30885 7.73679 5.08779 7.95785 5.08779 8.22802C5.08779 12.8407 8.82604 16.5789 13.4387 16.5789C13.7088 16.5789 13.9299 16.3578 13.9299 16.0877V14.0343C13.9299 13.7642 13.7088 13.5431 13.4387 13.5431C12.8295 13.5431 12.2352 13.4449 11.685 13.2631C11.5131 13.2042 11.3166 13.2484 11.1839 13.381L10.677 14.2269Z'
      />
    </SvgIcon>
  );
};

export default AddToListIcon;
