import React from "react";
import { FormControlLabel, styled, Switch } from "@mui/material";

const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: 44,
  height: 24,
  padding: 0,
  "& .MuiSwitch-track": {
    borderRadius: 24 / 2,
    opacity: 0.28,
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.common.white,
    boxShadow: "none",
    width: 20,
    height: "20px !important",
    paddingLeft: "0 !important",
  },
  "& .MuiSwitch-switchBase": {
    padding: "2px",
    "&.Mui-checked": {
      "&+.MuiSwitch-track": {
        opacity: 1,
      },
    },
  },
}));

const CustomSolidSwitch = ({ checked = false, onChange = () => {} }) => {
  return <FormControlLabel control={<StyledSwitch color={"secondary"} checked={checked} onChange={onChange} />} />;
};

export default CustomSolidSwitch;
