import React from "react";
import { Box, Stack, styled, Typography } from "@mui/material";
// import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';

const WrapperBox = styled(Box)(() => ({
  width: "100vw",
  height: "100vh",
  display: "grid",
  placeItems: "center",
}));
const ErrorBox = styled(Stack)(() => ({
  position: "relative",
  "&:before": {
    content: '"404"',
    position: "absolute",
    backgroundColor: "transparent",
    fontSize: "12rem",
    top: "0",
    left: "100%",
    color: "#006df50f",
    transform: "rotate(13deg)",
  },
  "&:after": {
    content: '"404"',
    position: "absolute",
    backgroundColor: "transparent",
    fontSize: "12rem",
    bottom: "-80px",
    right: "100%",
    color: "#006df50f",
    transform: "rotate(-13deg)",
  },
}));

const ErrorPage = () => {
  return (
    <WrapperBox>
      <ErrorBox direction={"column"} alignItems='center' spacing={1}>
        <Typography variant={"h1"} color={"secondary.main"} sx={{ fontSize: "14rem", lineHeight: "normal" }}>
          404
        </Typography>
        <Typography variant={"h5"} color={"secondary.main"} sx={{ display: "inline-flex", gap: "5px" }}>
          Page Not Found <span>{/*<SentimentDissatisfiedIcon />*/}</span>
        </Typography>
      </ErrorBox>
    </WrapperBox>
  );
};
export default ErrorPage;
