import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Stack, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import CoreTextField from "../../../common/TextField/CoreTextField";
import BasicEditor from "../../../common/Editor/BasicEditor";
import Personalized from "../../../common/Personalized/Personalized";
import CoreButton from "../../../common/Button/CoreButton";
import { useGetAllPersonalizedTagsQuery } from "../../../state/features/common/personalizedTag/personalizedTagApiSlice";
import { SCRIPT_SETTING_PERSONALIZED_TAGS, Texts } from "../../../helpers/constant/Constants";
import { insertPersonalizedTag } from "../../../helpers/utils/insertPersonalizedTag";
import { scriptPattern } from "../../../helpers/constant/registerPattern";
import { updateScriptSetting } from "../../../state/features/listSetting/scriptSetting/scriptSettingSlice";
import AsteriskIcon from "../../../common/Icons/AsterickIcon";

const ScriptUpdate = ({ onCancel, selectedData }) => {
  const dispatch = useDispatch();
  const editorRef = useRef(null);
  const [personalizedTags, setPersonalizedTags] = useState(null);
  const { data: personalizedQueryTags = [], isSuccess } = useGetAllPersonalizedTagsQuery();
  const { handleSubmit, setValue, control } = useForm();

  useEffect(() => {
    let filteredTags = personalizedQueryTags.filter((eachGroup) => eachGroup.isCustomField === true);
    setPersonalizedTags([...SCRIPT_SETTING_PERSONALIZED_TAGS, ...filteredTags]);
  }, [isSuccess]);

  useEffect(() => {
    setValue("title", selectedData?.title || "");
    setValue("description", selectedData?.description || "");
    setValue("script", selectedData?.script || "");
  }, [selectedData]);

  const onSubmit = (data) => {
    const payload = {
      title: data.title,
      description: data.description,
      script: data.script,
    };

    if (selectedData.id) {
      payload.script_id = selectedData.id;
    }

    dispatch(updateScriptSetting(payload));
    onCancel();
  };

  return (
    <Box component='form' noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)} px={4} py={2}>
      <Stack direction={"row"} spacing={2} mb={0.5}>
        <Stack spacing={1} sx={{ width: "100%" }}>
          <Controller
            control={control}
            name='title'
            defaultValue=''
            render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
              <>
                <Typography variant={"body2medium"} color={"text.primary"}>
                  Script title
                  <AsteriskIcon />
                </Typography>
                <CoreTextField
                  fullWidth
                  required
                  autoFocus
                  value={value}
                  onChange={onChange}
                  type={"text"}
                  size={"small"}
                  placeholder={"Script title here"}
                  inputProps={{ maxlength: 30 }}
                  error={invalid}
                  helperText={invalid && error?.message}
                />
              </>
            )}
            rules={scriptPattern.title}
          />
        </Stack>
        <Stack spacing={1} sx={{ width: "100%" }}>
          <Controller
            control={control}
            name='description'
            defaultValue=''
            render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
              <>
                <Typography variant={"body2medium"} color={"text.primary"}>
                  Script description
                </Typography>
                <CoreTextField
                  type={"text"}
                  size={"small"}
                  value={value}
                  onChange={onChange}
                  fullWidth
                  multiline
                  minRows={1}
                  maxRows={3}
                  placeholder={"Script description here"}
                  defaultValue={""}
                  inputProps={{ maxlength: 255 }}
                  sx={{ "& .MuiOutlinedInput-root": { padding: "7px 14px" } }}
                  error={invalid}
                  helperText={invalid && error?.message}
                />{" "}
              </>
            )}
            rules={scriptPattern.description}
          />
        </Stack>
      </Stack>
      <Stack spacing={1}>
        <Typography variant={"body2medium"} color={"text.primary"} mt={1.5}>
          {Texts.writeScript}
          <AsteriskIcon />
        </Typography>
        <Controller
          control={control}
          name='script'
          defaultValue=''
          render={({ field: { value }, fieldState: { invalid, error } }) => (
            <>
              <BasicEditor
                ref={editorRef}
                autoFocus={false}
                body={value}
                onChangeBody={(value) => setValue("script", value, { shouldValidate: true })}
              />
              {invalid && (
                <Typography color='error.main' variant='helperText'>
                  {error?.message}
                </Typography>
              )}
              <Stack direction={"row"} alignItems={"center"} spacing={2}>
                <Personalized
                  data={personalizedTags}
                  onClick={(personalizedTag) =>
                    insertPersonalizedTag(personalizedTag, editorRef, (value) =>
                      setValue("script", value, { shouldValidate: true })
                    )
                  }
                />
                <Stack direction={"row"} alignItems={"center"} spacing={2}>
                  <CoreButton variant={"outlined"} color={"error"} size={"small"} onClick={onCancel}>
                    Cancel
                  </CoreButton>
                  <CoreButton
                    type='submit'
                    variant={"contained"}
                    color={"success"}
                    size={"small"}
                    sx={{ "&.MuiButton-sizeSmall": { padding: "5px 10px" } }}
                  >
                    Save Script
                  </CoreButton>
                </Stack>
              </Stack>
            </>
          )}
          rules={scriptPattern.script}
        />
      </Stack>
    </Box>
  );
};

export default ScriptUpdate;
