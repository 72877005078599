import React, { useRef, useState } from "react";
import { ArrowBack } from "@mui/icons-material";
import { Box, Button, Stack, styled, Typography } from "@mui/material";
import CustomMenuPype from "../../../../common/custom-menu/CustomMenuPype";
import { CarrotIcon, UncheckBoxIcon, CheckBoxIcon } from "../../../../common/icon/Icons";
import ButtonBack from "../../../screens/auto-dialer-ongoing/global/ButtonBack/ButtonBack";
import { useDispatch, useSelector } from "react-redux";
import { selectDialerState } from "../../../../state/features/dialer/dialerSelector";
import { geContactTags, handleSendOption, SEND_OPTIONS } from "../../../../state/features/dialer/dialerSlice";
import ConntectedService from "../../../../services/call/Conntected.service";
import CustomCircleLoader from "../../../../common/Loader/CustomCircleLoader";
import { showGlobalNotification } from "../../../../helpers/utils/showGlobalNotification";
import Styles from "./addTag.module.scss";
import AnimatedIcon from "../../../../common/icon/AnimatedIcon";
import useDelayCallback from "../../../../hooks/useDelayCallback";

const StyledBox = styled(Box)(({ theme }) => ({
  padding: "16px 24px",
  borderTop: `1px solid ${theme.palette.other.outlinedBorderDarker}`,
}));

const AddTag = () => {
  const tagsRef = useRef(null);
  const dispatch = useDispatch();
  const {
    connected: { contactId },
  } = useSelector(selectDialerState);

  const [states, setStates] = useState({
    tags: [],
    isGetting: false,
    getError: "",
    search: "",
    loadingMore: false,
    needToLoad: false,
    contactTags: [],
    isAdding: false,
    page: 1,
    selectedTags: [],
  });

  useDelayCallback(() => {
    if (states.isGetting) {
      return;
    }
    getTags();
  }, [states.search]);

  const handleStateData = (payload) => {
    setStates({ ...states, ...payload });
  };

  const getTags = (loadingMore = false) => {
    if (loadingMore) {
      handleStateData({ loadingMore: true, needToLoad: false, getError: "" });
    } else {
      handleStateData({ isGetting: true, needToLoad: false, getError: "" });
    }

    ConntectedService.getAllTags({
      page: states.page,
      search: states.search,
      contactId: contactId,
    })
      .then((res) => {
        try {
          if (res.success) {
            let data = [...states.tags, ...res.data.tags];
            let hasMore = false,
              page = states.page,
              contactTags = [...states.contactTags];
            if (states.page == 1) {
              data = [...res.data.tags];
              if (contactTags.length == 0) {
                let ids = [];
                res.data.contactTags.forEach((item) => ids.push(item.id));
                contactTags = [...ids];
              }
            }

            if (res.data.tags.length > 19) {
              hasMore = true;
              page = page + 1;
            }
            handleStateData({
              tags: data,
              isGetting: false,
              getError: "Can not get data",
              loadingMore: false,
              needToLoad: hasMore,
              page: page,
              contactTags: contactTags,
            });
          } else {
            handleStateData({ isGetting: false, getError: "Can not get data", loadingMore: false });
          }
        } catch (error) {
          handleStateData({ isGetting: false, getError: "Can not get data", loadingMore: false });
        }
      })
      .catch((error) => {
        console.log(error);
        handleStateData({ isGetting: false, gettingError: "Something went wrong", loadingMore: false });
      });
  };
  const getMoreTags = (e) => {
    if (states.isGetting || states.loadingMore) {
      return;
    }
    if (!states.needToLoad) {
      return;
    }
    const scrollTop = e.target.scrollTop;
    if (Math.round(e.target.scrollHeight - scrollTop) <= Math.round(e.target.clientHeight + 20)) {
      handleStateData({ isGetting: true, needToLoad: false, getError: "" });
      getTags(true);
    }
  };
  const addTagsToContact = () => {
    if (states.selectedTags.length == 0 && states.search.trim() == "") {
      showGlobalNotification("Please select a tag or type to create new", "warning");
      return;
    }
    if (states.isAdding) {
      return;
    }
    const formData = {
      contactId: contactId,
    };
    if (states.search.trim() != "") {
      formData["newTag"] = states.search;
    } else {
      formData["tagIds"] = states.selectedTags;
    }
    handleStateData({ isAdding: true });
    ConntectedService.assignTagToContact(formData)
      .then((res) => {
        try {
          if (res.success) {
            handleStateData({ isAdding: false });
            showGlobalNotification(res.message, "success");
            dispatch(geContactTags());
            if (tagsRef.current !== undefined && tagsRef.current != null) {
              tagsRef.current.closeFromOutside();
            }
          } else {
            handleStateData({ isAdding: false });
            showGlobalNotification(res.message);
          }
        } catch (error) {
          showGlobalNotification("Can not add to campaign !");
          handleStateData({ isAdding: false });
        }
      })
      .catch((error) => {
        console.log(error);
        showGlobalNotification("Can not add to campaign !");
        handleStateData({ isAdding: false });
      });
  };

  const handleSearch = (e) => {
    handleStateData({ search: e.target.value, page: 1 });
  };
  const handleAddTag = (id) => {
    if (states.selectedTags.includes(id)) {
      let filterData = states.selectedTags.filter((item) => item != id);
      handleStateData({ selectedTags: filterData });
    } else {
      let data = [...states.selectedTags];
      data.push(id);
      handleStateData({ selectedTags: data });
    }
  };

  const renderText = () => {
    let length = states.selectedTags.length;
    if (length == 0) {
      return "No tag selected";
    }
    if (length == 1) {
      return "A tag selected";
    }
    if (length > 1) {
      return `${length} tags selected`;
    }
  };
  const renderTags = () => {
    if (states.isGetting) {
      return (
        <Box
          component={"span"}
          sx={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }}
        >
          Please wait...
        </Box>
      );
    }
    const view = [];
    states.tags.forEach((item, index) => {
      if (states.contactTags.includes(item.value)) {
        return;
      }
      let checkFlag = false;
      if (states.selectedTags.includes(item.value)) {
        checkFlag = true;
      }
      view.push(
        <Box key={`${item.value}-${index}`} sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
          <AnimatedIcon
            icon={checkFlag ? <CheckBoxIcon /> : <UncheckBoxIcon />}
            onClick={() => handleAddTag(item.value)}
          />
          <Typography variant='caption'>{item.title}</Typography>
        </Box>
      );
    });
    if (view.length == 0) {
      if (states.search.trim() != "") {
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              sx={{
                marginBottom: "20px",
                backgroundColor: "secondary.main",
                ":hover": { backgroundColor: "secondary.main" },
              }}
              variant='contained'
              onClick={addTagsToContact}
            >
              Create and assign
            </Button>
            <Typography variant='caption'>
              Currently no tag created with
              <Typography variant='caption' sx={{ color: "secondary.main", paddingLeft: "5px", fontStyle: "italic" }}>
                {states.search}
              </Typography>
            </Typography>
          </Box>
        );
      }
      return (
        <Box
          component={"span"}
          sx={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }}
        >
          No tag found
        </Box>
      );
    }
    return view;
  };

  return (
    <div className={Styles.addTagWrapper}>
      <ButtonBack
        backHandler={() => {
          handleStateData({ isAdding: false });
          dispatch(handleSendOption({ option: SEND_OPTIONS.default }));
        }}
        title='Add Tags'
        icon={<ArrowBack sx={{ color: "action.active" }} />}
      />

      <StyledBox>
        <div className={Styles.personalized}>
          <CustomMenuPype
            popup={{
              id: "tag-add-call-connected-id",
              wrapperClass: Styles.popDlvnWrapper,
              placement: "center",
              height: "auto",
              maxHeight: "400px",
              width: "__parent__",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center",
              },
              renderCustomContainer: (
                <Box sx={{ padding: "10px", display: "flex", flexDirection: "column" }}>
                  <input
                    className={Styles.semscInput}
                    value={states.search}
                    type='text'
                    placeholder='Search'
                    onChange={handleSearch}
                  />
                  <div
                    style={{
                      padding: "10px 0px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                      maxHeight: "330px",
                    }}
                    className='pdad-scroll-bar'
                    onScroll={getMoreTags}
                  >
                    {renderTags()}
                    {states.loadingMore && (
                      <Typography variant='caption' sx={{ textAlign: "center" }}>
                        Loading more...
                      </Typography>
                    )}
                  </div>
                </Box>
              ),
              wrapperClassInner: Styles.tagPopoverWrp,
            }}
            onClose={() => {}}
            label={{
              renderCustom: (
                <div className={Styles.personalizedInput} style={{ height: "auto" }}>
                  <Box sx={{ padding: "10px 0px" }}>
                    <Typography variant='body1' sx={{ marginBottom: "5px", color: "text.primary" }}>
                      Select tags to assign
                    </Typography>
                    <Typography variant='caption'>{renderText()}</Typography>
                  </Box>
                  <Box component={"span"} sx={{ display: "flex" }}>
                    <CarrotIcon height='24' width='24' />
                  </Box>
                </div>
              ),
            }}
            ref={tagsRef}
          />
        </div>
      </StyledBox>

      <Stack
        direction={"row"}
        justifyContent='space-between'
        alignItems='center'
        sx={{ paddingInline: "24px" }}
        className={Styles.footer}
      >
        <Typography
          variant='buttonMedium'
          color='text.secondary'
          onClick={() => {
            handleStateData({ isAdding: false });
            dispatch(handleSendOption({ option: SEND_OPTIONS.default }));
          }}
          sx={{ cursor: "pointer" }}
        >
          Cancel
        </Typography>

        <Button
          sx={{ backgroundColor: "secondary.main", ":hover": { backgroundColor: "secondary.main" } }}
          variant='contained'
          onClick={addTagsToContact}
        >
          {states.isAdding && (
            <CustomCircleLoader fill='#fff' size='extra-small' style={{ display: "flex", marginRight: "5px" }} />
          )}
          {states.isAdding ? "Saving" : "Save"} Change
        </Button>
      </Stack>
    </div>
  );
};

export default AddTag;
