import httpRequest from "../api/httpRequest";

class TagService {
  getTags(params) {
    return httpRequest.get(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/list/settings/tags",
      params
    );
  }

  createTag(params) {
    return httpRequest.post(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/list/settings/tag/create",
      params
    );
  }
}

export default new TagService();
