import { all, put } from "redux-saga/effects";
import { call, takeEvery } from "@redux-saga/core/effects";
import { createTagFailed, createTagSuccess, getTagsFailed, getTagsSuccess } from "./tagSlice";
import TagService from "../../../../services/common/Tag.service";
import { showErrorToaster } from "../../../../helpers/utils/toaster";
import { ADD_TAG_FOR } from "../../../../helpers/constant/Constants";
import { appendNewSelectedTag } from "../../listSetting/triggerSetting/triggerSettingSlice";

function* tagWatcher() {
  yield takeEvery("tag/getTags", getTagSaga);
  yield takeEvery("tag/createTag", createTagSaga);
}

function* getTagSaga(action) {
  try {
    const response = yield call(TagService.getTags, action.payload);

    if (response.success) {
      yield put(getTagsSuccess(response.data?.data));
    } else {
      yield put(getTagsFailed(response.message));
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

function* createTagSaga(action) {
  try {
    const response = yield call(TagService.createTag, action.payload);

    if (response.success) {
      yield put(createTagSuccess(response.data));
      if (action.payload.for === ADD_TAG_FOR.TRIGGER) {
        yield put(appendNewSelectedTag(response.data));
      }
    } else {
      yield put(createTagFailed(response.message));
      showErrorToaster(response.message);
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

export default function* tagSaga() {
  yield all([tagWatcher()]);
}
