import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Divider } from "@mui/material";
import ActionForIncomingCall from "./ActionForIncomingCall";
import ActionMoveNextCall from "./ActionMoveNextCall";
import DisconnectCallTrigger from "./DisconnectCallTrigger";
import { getTriggerSetting } from "../../../state/features/listSetting/triggerSetting/triggerSettingSlice";
import { selectTriggerSetting } from "../../../state/features/listSetting/listSettingSelector";
import TriggerSettingSkeleton from "../../../common/LoadingView/TriggerSettingSkeleton";

const TriggerSettingTab = () => {
  const dispatch = useDispatch();
  const { isLoading, isLoaded } = useSelector(selectTriggerSetting);

  useEffect(() => {
    if (!isLoaded) {
      dispatch(getTriggerSetting());
    }
  }, []);

  if (isLoading) {
    return <TriggerSettingSkeleton />;
  }

  return (
    <Box>
      {/* action for incoming call */}
      <ActionForIncomingCall />
      <Divider />
      {/* how to move next call */}
      <ActionMoveNextCall />
      <Divider />
      {/* What will happen if the call is not picked or gets disconnected */}
      <DisconnectCallTrigger />
    </Box>
  );
};
export default TriggerSettingTab;
