import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, Stack, styled, Tabs, Typography } from "@mui/material";
import { SkeletonRectangle } from "../../../common/Skeleton/SkeletonRectangle";
import { getContactAvatar } from "../../../helpers/utils/getContactAvatar";
import { selectDialerState } from "../../../state/features/dialer/dialerSelector";
import {
  addContactScriptData,
  DIALER_STEP,
  getContacts,
  handleCallStep,
  handleNextCall,
} from "../../../state/features/dialer/dialerSlice";
import { ScriptIcon } from "../../../common/icon/Icons";
import { formatUSNumber } from "../../../helpers/utils/formatUSNumber";
import OngoingAnimation from "../../../common/ongoingAnimation/OngoingAnimation";
import HrefLink from "../../../common/Link/HrefLink";
import { replacePersonalized } from "../../../helpers/utils/replacePersonalized";
import Styles from "./dialingLines.module.scss";
import CustomTooltip from "../../../common/Tooltip/CustomTooltip";

const ScriptButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.secondary,
  backgroundColor: `${theme.palette.other.dividerFillColor} !important`,
}));

const DialingLines = ({ onClickCall }) => {
  const dispatch = useDispatch();
  const [showScript, setShowScript] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);

  const {
    connecting: { actualContacts, contacts, /* updateData, */ gettingContacts: isLoading, gettingContactError: error },
    connected: { contactId },
    init: { step, callStep },
    contactScript: { mainScript },
  } = useSelector(selectDialerState);

  useEffect(() => {
    dispatch(handleNextCall(false));
    dispatch(getContacts());
  }, []);

  useEffect(() => {
    if (actualContacts != null && actualContacts.length !== 0) {
      if (step === DIALER_STEP.STOP) {
        return;
      }
      if (callStep === DIALER_STEP.CONNECTING) {
        return;
      }
      onClickCall();
      dispatch(handleCallStep(DIALER_STEP.CONNECTING));
      replaceContactScriptContent(actualContacts);
    }
  }, [actualContacts]);

  const replaceContactScriptContent = (contacts) => {
    if (mainScript) {
      contacts.forEach((item) => {
        let contactScript = replacePersonalized(mainScript, item);
        dispatch(addContactScriptData({ contactId: item?.id, script: contactScript }));
      });
    }
  };

  const totalItem = contacts.length;
  let gridClassName = "";
  if (step === DIALER_STEP.SHOW_CONNETED_SINGLE_SCREEN) {
    gridClassName = Styles.gridOneActive;
  } else if (totalItem === 1) {
    gridClassName = Styles.gridOne;
  } else if (totalItem === 3) {
    gridClassName = Styles.gridThree;
  } else if (totalItem === 5) {
    gridClassName = Styles.gridFive;
  } else if (totalItem === 7) {
    gridClassName = Styles.gridSeven;
  } else if (totalItem === 9) {
    gridClassName = Styles.gridNine;
  } else if (totalItem === 10) {
    gridClassName = Styles.GridTen;
  } else {
    gridClassName = Styles.dialerFlex;
  }

  const renderContactLines = () => {
    const view = [];
    contacts.forEach((item, index) => {
      let classes = `${Styles.singleCard}`;
      if (callStep === DIALER_STEP.CONNECTED && contactId !== item.id) {
        classes = `${Styles.singleCard} ${Styles.dialerCardOpacityThree}`;
      }
      let connectedFlag = "Waiting";
      if (callStep === DIALER_STEP.FETCHING) {
        connectedFlag = "Fetching";
      }
      if (callStep === DIALER_STEP.CONNECTING) {
        connectedFlag = "Connecting";
        if (item.connectionFlag != undefined && item.connectionFlag == "Disconnected") {
          classes = `${Styles.singleCard} ${Styles.dialerCardOpacityThree}`;
          connectedFlag = "Disconnected";
        } else if (item.connectionFlag != undefined && item.connectionFlag == "Ringing") {
          connectedFlag = "Ringing";
        } else if (item.connectionFlag != undefined && item.connectionFlag == "Not available") {
          classes = `${Styles.singleCard} ${Styles.dialerCardOpacityThree}`;
          connectedFlag = "Not available";
        }
        // if (item.hasDisconnect !== undefined && item.hasDisconnect) {
        //   classes = `${Styles.singleCard} ${Styles.dialerCardOpacityThree}`;
        //   connectedFlag = "Disconnected";
        // }
      } else if (callStep === DIALER_STEP.CONNECTED && contactId === item.id) {
        connectedFlag = "Connected";
      } else if (callStep === DIALER_STEP.CONNECTED && contactId !== item.id) {
        connectedFlag = "Disconnected";
      }

      if (item.number == undefined || item.number == null) {
        connectedFlag = "No number !";
        classes = `${Styles.singleCard} ${Styles.dialerCardOpacityThree}`;
      }

      let alimationFlag = connectedFlag == "Connecting" || connectedFlag == "Ringing";

      if (showScript) {
        let contactLabel = item.first_name + " " + item.last_name;
        if (contactLabel != null && contactLabel.trim() == "") {
          contactLabel = item.number;
        }

        view.push(
          <div
            key={`${item.id}-${item?.connectionFlag}`}
            className={`${selectedContact?.id == item.id ? Styles.active : ""} ${Styles.contactCardUser}`}
            onClick={() => setSelectedContact(item)}
          >
            <Stack direction='row' alignItems='center' spacing={2}>
              <span className={Styles.avatar}>
                <Typography variant='body1'>
                  {getContactAvatar(item.first_name, item.last_name, item.email, item.number)}
                </Typography>
              </span>
              <Box
                sx={{
                  minWidth: "96px",
                }}
              >
                <CustomTooltip arrow title={contactLabel} placement='top'>
                  <Typography
                    sx={{
                      width: "110px",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                    variant='body2'
                  >
                    {contactLabel}
                  </Typography>
                </CustomTooltip>
                <Typography sx={{ fontSize: "11px" }} variant='chipLight' color='action.active'>
                  {connectedFlag}
                </Typography>
              </Box>
            </Stack>

            <Box mr={1}>{alimationFlag && <OngoingAnimation />}</Box>
          </div>
        );
      } else {
        view.push(
          <div className={classes} key={`${index}-${item.id}-${item?.connectionFlag}`}>
            <div className={Styles.singleCardContent}>
              <div className={Styles.singleCardAvatar}>
                <Typography variant='body1medium'>
                  {getContactAvatar(item.first_name, item.last_name, item.email, item.number)}
                </Typography>
              </div>
              <Typography variant='body1medium' mt={"12px"} sx={{ display: "block" }}>
                {item.first_name} {item.last_name}
              </Typography>
              <Typography variant='chipLight' sx={{ mt: "2px" }} color='text-secondary'>
                {formatUSNumber(item.number)}
              </Typography>
              <Typography variant='chipLight' sx={{ mt: "2px" }} color='text-secondary'>
                {item.company}
              </Typography>
              <Typography
                variant='body1medium'
                sx={{
                  display: "block",
                  cursor: "pointer",
                  color: "#039be5",
                  mt: "2px",
                  "&:hover": { textDecoration: "underline" },
                }}
                color='text-secondary'
              >
                <HrefLink href={`/contacts/${item.id}`} target={"_blank"}>
                  Go to profile
                </HrefLink>
              </Typography>
            </div>
            <div className={Styles.singleCardFooter}>
              <span className={Styles.cardFooterConnect}>
                <Typography variant='body2' color='text.secondary'>
                  {connectedFlag}
                </Typography>
              </span>
              <span className={Styles.cardFooterDot}>{alimationFlag && <OngoingAnimation />}</span>
            </div>
          </div>
        );
      }
    });
    return view;
  };

  /* contact script */
  const renderContactScript = () => {
    if (mainScript != null) {
      if (selectedContact == null) {
        return (
          <div className={Styles.scriptWrapper}>
            <div className={`${Styles.scriptContent} pdad-scroll-bar`}>
              <Typography variant='body2' dangerouslySetInnerHTML={{ __html: mainScript }} />
            </div>
          </div>
        );
      }
      return (
        <div className={Styles.scriptWrapper}>
          <div className={`${Styles.scriptContent} pdad-scroll-bar`}>
            <Typography
              variant='body2'
              dangerouslySetInnerHTML={{ __html: replacePersonalized(mainScript, selectedContact) }}
            />
          </div>
        </div>
      );
    }
    return (
      <Typography sx={{ background: "#fafafa", padding: "10px" }} variant='body2'>
        No script found !
      </Typography>
    );
  };

  const renderView = () => {
    if (isLoading) {
      return (
        <div className={`${gridClassName} ${Styles.cards}`} style={{ height: "100%" }}>
          <div className={Styles.loaderWrp}>
            <p>Please wait...</p>
            <p>We are getting contact lines</p>
          </div>
        </div>
      );
    }
    if (error !== "") {
      return (
        <div className={`${gridClassName} ${Styles.cards}`} style={{ height: "100%" }}>
          <div className={Styles.loaderWrp}>
            <p>{error}</p>
          </div>
        </div>
      );
    }
    if (contacts.length === 0) {
      return (
        <div className={`${gridClassName} ${Styles.cards}`} style={{ height: "100%" }}>
          <div className={Styles.loaderWrp}>
            <p>Your session is ended !</p>
          </div>
        </div>
      );
    }
    if (showScript) {
      return (
        <Box sx={{ height: "calc(100vh - 403px)" }}>
          <div className={Styles.cardWrapper}>
            <Tabs variant='scrollable' scrollButtons='auto' aria-label='scrollable-contact-line-tabs'>
              <div
                className={`${selectedContact?.id == null ? Styles.active : ""} ${Styles.contactCard}`}
                onClick={() => setSelectedContact(null)}
              >
                <Typography sx={{ width: "150px", textAlign: "center" }} variant='body2medium' color='text.secondary'>
                  General script
                </Typography>
              </div>
              {renderContactLines()}
            </Tabs>
          </div>

          <Box sx={{ height: "100%" }}>{renderContactScript()}</Box>
        </Box>
      );
    }
    return <div className={`${gridClassName} ${Styles.cards}`}>{renderContactLines()}</div>;
  };

  return (
    <div className={Styles.dialingLinesWrapper}>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Typography variant='body2medium' color='text.secondary' sx={{ display: "flex", gap: "5px" }}>
          Dialing {isLoading ? <SkeletonRectangle height='15px' width='20px' /> : contacts.length} contact(s)
        </Typography>

        <ScriptButton
          startIcon={<ScriptIcon />}
          variant='contained'
          onClick={() => setShowScript(!showScript)}
          disableElevation
          sx={{ marginTop: "-10px" }}
        >
          {showScript ? "Hide Script" : "Show Script"}
        </ScriptButton>
      </Stack>

      <div className={`${Styles.innerWrp} pdad-scroll-bar`}>{renderView()}</div>
    </div>
  );
};

export default React.memo(DialingLines);
