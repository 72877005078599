import { all, put } from "redux-saga/effects";
import { /* call, */ takeEvery, select, delay } from "@redux-saga/core/effects";
import { errorHandler } from "../../../../helpers/utils/errorHandler";
import { getContactTagsFailed, getContactTagsSuccess } from "./contactTagsSlice";
// import ContactTagsService from "../../../../services/call/ContactTags.service";
import { selectConnectedState } from "../connected/connectedSelector";
import { showGlobalNotification } from "../../../../helpers/utils/showGlobalNotification";

function* contactTagsWatcher() {
  yield takeEvery("contactTags/getContactTags", getContactTagsSaga);
}

function* getContactTagsSaga() {
  try {
    yield delay(3000);
    const response = {
      responseCode: 200,
      message: "",
      success: true,
      data: [
        {
          id: 1,
          title: "tag 1",
        },
        {
          id: 2,
          title: "tag 2",
        },
      ],
    };

    const { contactId } = yield select(selectConnectedState);
    console.log(contactId); /* will remove this line later */
    // const response = yield call(ContactTagsService.getContactTags, { contactId: contactId });

    if (response) {
      if (response.success) {
        yield put(getContactTagsSuccess(response.data));
      } else {
        yield put(getContactTagsFailed(response.message));
      }
    } else {
      yield put(getContactTagsFailed("Something went wrong !"));
      showGlobalNotification("Something went wrong! Try again later");
    }
  } catch (err) {
    errorHandler(err, "contactTagsSaga > getContactTagsSaga");
  }
}

export default function* contactTagsSaga() {
  yield all([contactTagsWatcher()]);
}
