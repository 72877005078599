import { getAccountData } from "./getAccountData";

export const replacePersonalized = (script, contact) => {
  let userInformation = {
    full_name: getAccountData("fullName"),
    email: getAccountData("email"),
    phone_number: getAccountData("phone_number"),
  };
  let contactInformation = {
    first_name: "",
    last_name: "",
    hasDisconnect: false,
    connectionFlag: "Waiting",
    address: "",
    email: "",
    id: 1,
    number: "",
    power_dialer_list_contact_id: 1,
    state: "",
    country: "",
    city: "",
    company_name: "",
    url: "",
    zip_code: "",
    deal_value: 0,
    birth_date: null,
    anniversary_date: null,
    customFields: [], //{tag: '', value: ''}
  };
  contactInformation = { ...contactInformation, ...contact };

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
  };

  const replaceAll = (str, find, replace) => {
    return str.replace(new RegExp(escapeRegExp(find), "g"), replace);
  };

  /* for user */
  script = script.replace(/\[\[my_name\]\]/g, userInformation["full_name"]);
  script = script.replace(/\[\[my_email\]\]/g, userInformation["email"]);
  script = script.replace(/\[\[my_phone\]\]/g, userInformation["phone_number"]);

  /* for contact */
  if (contact) {
    script = script.replace(/\[\[first_name\]\]/g, contactInformation["first_name"]);
    script = script.replace(/\[\[last_name\]\]/g, contactInformation["last_name"]);
    script = script.replace(/\[\[email\]\]/g, contactInformation["email"]);
    script = script.replace(/\[\[contact_phone_number\]\]/g, contactInformation["number"]);
    script = script.replace(/\[\[contact_country\]\]/g, contactInformation["country"]);
    script = script.replace(/\[\[contact_state\]\]/g, contactInformation["state"]);
    script = script.replace(/\[\[contact_city\]\]/g, contactInformation["city"]);
    script = script.replace(/\[\[contact_street_address\]\]/g, contactInformation["address"]);
    script = script.replace(/\[\[contact_company_name\]\]/g, contactInformation["company_name"]);
    script = script.replace(/\[\[contact_url\]\]/g, contactInformation["url"]);
    script = script.replace(/\[\[contact_zip_code\]\]/g, contactInformation["zip_code"]);
    script = script.replace(/\[\[contact_deal_value\]\]/g, contactInformation["deal_value"]);
    script = script.replace(/\[\[contact_birth_date\]\]/g, contactInformation["birth_date"]);
    script = script.replace(/\[\[contact_anniversary_date\]\]/g, contactInformation["anniversary_date"]);

    if (contact.customFields && Array.isArray(contact.customFields)) {
      contact.customFields.forEach((item) => {
        script = replaceAll(script, item["tag"], item["value"]);
      });
    }
  }
  return script;
};
