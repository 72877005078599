import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { PhoneCallBack, PhoneDisabled, SuperUser, Timelapse } from "../IconsOngoing";
import { SkeletonRectangle } from "../../../common/Skeleton/SkeletonRectangle";
import { selectDialerState } from "../../../state/features/dialer/dialerSelector";
import Styles from "./dialerBasicInfo.module.scss";
import { getStats /* handleConnectingState */ } from "../../../state/features/dialer/dialerSlice";
import secondToReadableTime from "../../../helpers/utils/secondToReadableTime";

const DialerBasicInfo = () => {
  const dispatch = useDispatch();
  const {
    connecting: { stats: data, gettingStats: isLoading, gettingStatsError: error },
  } = useSelector(selectDialerState);

  useEffect(() => {
    dispatch(getStats());
    // if (data == null) {
    //   dispatch(getStats());
    // } else {
    //   dispatch(handleConnectingState({ gettingStats: false }));
    // }
  }, []);

  // const handleTimeLabel = (value) => {
  //   if (value < 60) {
  //     return "secs";
  //   }
  //   if (value >= 60 && value < 3600) {
  //     return "mins";
  //   }
  //   return "hr(s)";
  // };

  const renderStats = () => {
    return (
      <div className={Styles.innerWrapper}>
        <div className={Styles.singleInfo}>
          <span>
            <SuperUser />
          </span>
          <span>
            <Typography variant='h5'>
              {isLoading ? <SkeletonRectangle height='20px' width='30px' /> : data?.total_contacts}
            </Typography>
            <Typography variant='body2' color='text.secondary'>
              Total Contacts
            </Typography>
          </span>
        </div>

        <div className={Styles.singleInfo}>
          <span>
            <PhoneCallBack />
          </span>
          <span>
            <Typography variant='h5'>
              {isLoading ? <SkeletonRectangle height='20px' width='30px' /> : data?.total_call_received}
            </Typography>
            <Typography variant='body2' color='text.secondary'>
              Call Received
            </Typography>
          </span>
        </div>

        <div className={Styles.singleInfo}>
          <span>
            <PhoneDisabled />
          </span>
          <span>
            <Typography variant='h5'>
              {isLoading ? <SkeletonRectangle height='20px' width='30px' /> : data?.total_call_disconnect}
            </Typography>
            <Typography variant='body2' color='text.secondary'>
              Call Disconnected
            </Typography>
          </span>
        </div>

        <div className={Styles.singleInfo}>
          <span>
            <Timelapse />
          </span>
          <span>
            <Typography variant='h5'>
              {isLoading ? (
                <SkeletonRectangle height='20px' width='50px' />
              ) : (
                `${secondToReadableTime(data?.total_estimate_session_time, true)}`
              )}
            </Typography>
            <Typography variant='body2' color='text.secondary'>
              Total Session Time
            </Typography>
          </span>
        </div>
      </div>
    );
  };
  const renderError = (message) => {
    return <div className={Styles.error}>{message}</div>;
  };
  const renderView = () => {
    if (error !== "") {
      return renderError(error);
    }
    if (!isLoading && data == null) {
      return renderError("Can not load stats data");
    }
    return renderStats();
  };

  return (
    <div className={Styles.dialerBasicInfoWrapper}>
      <Typography variant='body2medium' color='action.active' mb={"20px"} display='block'>
        Important Stats
      </Typography>

      <div className={`${Styles.innerScroll} pdad-scroll-bar`}>{renderView()}</div>
    </div>
  );
};

export default DialerBasicInfo;
