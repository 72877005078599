import React from "react";
import { Skeleton } from "@mui/material";

const DialerListSkeleton = ({ count = 1 }) => {
  return Array.from({ length: count }).map((rowNum, index) => (
    <Skeleton key={index} variant='rect' style={{ marginTop: "8px" }} height={40} />
  ));
};

export default DialerListSkeleton;
