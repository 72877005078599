import React from "react";
import { useTheme } from "@mui/material";

const AsteriskIcon = () => {
  const theme = useTheme();

  return (
    <span
      style={{
        color: theme.palette.error.main,
      }}
    >
      *
    </span>
  );
};

export default AsteriskIcon;
