import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  isError: false,
  error: "",
  isLoaded: false,
  settings: {
    callsAtATime: "",
    ringTime: "",
    maxCallDuration: "",
    transferCall: true,
    callRecording: false,
    actionForMaxCallDuration: 1,
  },
};

const callSettingSlice = createSlice({
  name: "callSetting",
  initialState: initialState,
  reducers: {
    getCallSetting: (state) => {
      state.isLoading = true;
    },
    getCallSettingSuccess: (state, action) => {
      state.settings = { ...state.settings, ...action.payload };
      state.isLoading = false;
      state.isError = false;
      state.error = "";
      state.isLoaded = true;
    },
    getCallSettingFailed: (state, action) => {
      state.data = [];
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },
    updateCallSetting: (state, action) => {
      state.settings = { ...state.settings, ...action.payload };
    },
    resetCallSettingData: (state) => {
      state.isLoaded = false;
    },
  },
});

export const { getCallSetting, getCallSettingSuccess, getCallSettingFailed, updateCallSetting, resetCallSettingData } =
  callSettingSlice.actions;

export default callSettingSlice.reducer;
