import cogoToast from "cogo-toast";
import Typography from "@mui/material/Typography";

const validateMessage = (message) => {
  return typeof message === "string" ? message : "";
};

const options = {
  position: "bottom-right",
  hideAfter: 4,
};

export const showSuccessToaster = (message = "") => {
  cogoToast.success(
    <div>
      <Typography variant={"subtitle1"}>Success,</Typography>
      <Typography variant={"body2"}>{validateMessage(message)}</Typography>
    </div>,
    options
  );
};

export const showErrorToaster = (message = "") => {
  cogoToast.error(
    <div>
      <Typography variant={"subtitle1"}>Failed,</Typography>
      <Typography variant={"body2"}>{validateMessage(message)}</Typography>
    </div>,
    options
  );
};

export const showInfoToaster = (message = "") => {
  cogoToast.info(
    <div>
      <Typography variant={"body2"}>{validateMessage(message)}</Typography>
    </div>,
    options
  );
};
