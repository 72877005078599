import { all, put } from "redux-saga/effects";
import { call, takeEvery, select, delay } from "@redux-saga/core/effects";
import {
  getContactDetailsFailed,
  getContactDetailsSuccess,
  getContactScriptFailed,
  getContactScriptSuccess,
  getConversationFailed,
  getConversationMore,
  getConversationSuccess,
  getMoreDetailsFailed,
  getMoreDetailsSuccess,
  toogleGettingMoreConversation,
} from "./connectedSlice";
import { errorHandler } from "../../../../helpers/utils/errorHandler";
import ConntectedService from "../../../../services/call/Conntected.service";
import { selectConnectedState } from "./connectedSelector";
import { selectInitializeState } from "../initialize/initializeSelector";
import { showGlobalNotification } from "../../../../helpers/utils/showGlobalNotification";

function* connectedWatcher() {
  yield takeEvery("connected/getContactDetails", getContactDetailsSaga);
  yield takeEvery("connected/getMoreDetails", getMoreDetailsSaga);
  yield takeEvery("connected/getContactScript", getContactScriptSaga);
  yield takeEvery("connected/getConversation", getConversationSaga);
}

function* getContactDetailsSaga(action) {
  try {
    const response = yield call(ConntectedService.getContactDetails, action.payload);

    if (response.success) {
      yield put(getContactDetailsSuccess(response.data));
    } else {
      yield put(getContactDetailsFailed(response.message));
    }
  } catch (err) {
    errorHandler(err, "connectedSaga > getContactDetailsSaga");
  }
}
function* getMoreDetailsSaga(action) {
  try {
    const response = yield call(ConntectedService.getMoreDetails, action.payload);

    if (response.success) {
      yield put(getMoreDetailsSuccess(response.data));
    } else {
      yield put(getMoreDetailsFailed(response.message));
    }
  } catch (err) {
    errorHandler(err, "connectedSaga > getMoreDetailsSaga");
  }
}
function* getContactScriptSaga() {
  try {
    yield delay(3000);
    const response = {
      responseCode: 200,
      message: "success",
      success: true,
      data: {
        id: 1,
        message: "this is script with replaced personalized",
      },
    };

    const { contactId } = yield select(selectConnectedState);
    const { initPowerDialerId } = yield select(selectInitializeState);

    const responsePrd = yield call(ConntectedService.getContactScript, {
      contactId: contactId,
      powerDialerId: initPowerDialerId,
    });
    console.log(responsePrd);

    if (response) {
      if (response.success) {
        yield put(getContactScriptSuccess(response.data));
      } else {
        yield put(getContactScriptFailed(response.message));
      }
    } else {
      yield put(getContactScriptFailed("Something went wrong !"));
      showGlobalNotification("Something went wrong! Try again later");
    }
  } catch (err) {
    errorHandler(err, "connectedSaga > getContactScriptSaga");
    showGlobalNotification("Something went wrong! Try again later");
  }
}
function* getConversationSaga() {
  try {
    yield delay(3000);
    const response = {
      responseCode: 200,
      message: "No data found !",
      success: true,
      data: [],
    };

    const { contactId, conversationType, conversationPage } = yield select(selectConnectedState);

    const responsePrd = yield call(ConntectedService.getConversation, {
      contactId: contactId,
      type: conversationType,
      pageSize: 20,
      pageNo: conversationPage,
    });
    console.log(responsePrd);

    if (response) {
      if (response.success) {
        yield put(getConversationSuccess(response.data));
        if (response.data && response.data.length >= 20) {
          yield put(toogleGettingMoreConversation(true));
        }
      } else {
        yield put(getConversationFailed(response.message));
      }
      yield put(getConversationMore(false));
    } else {
      yield put(getConversationFailed("Something went wrong !"));
      showGlobalNotification("Something went wrong! Try again later");
    }
  } catch (err) {
    errorHandler(err, "connectedSaga > getConversationSaga");
    showGlobalNotification("Something went wrong! Try again later");
  }
}

export default function* connectedSaga() {
  yield all([connectedWatcher()]);
}
