import { all } from "redux-saga/effects";
import { delay, takeEvery } from "@redux-saga/core/effects";
// import { getDefaultCallSettingFailed, getDefaultCallSettingSuccess } from "./defaultCallSettingSlice";
// import { showErrorToaster } from "../../../../helpers/utils/toaster";

function* generalScriptSagaWatcher() {
  yield takeEvery("generalScript/addGeneralScript", addGeneralScriptSaga);
}

function* addGeneralScriptSaga() {
  try {
    yield delay(3000);
    // const response = yield call(DefaultListSettingService.getCallSetting);

    // if (response.success) {
    //   yield put(getDefaultCallSettingSuccess(response.data));
    // } else {
    //   yield put(getDefaultCallSettingFailed(response.message));
    // }
  } catch (err) {
    console.log("Error: ", err);
  }
}

export default function* generalScriptSaga() {
  yield all([generalScriptSagaWatcher()]);
}
