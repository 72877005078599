import React from "react";
import { confirmAlert } from "react-confirm-alert";
import DeleteModalContent from "../WarningModalContent/WarningModalContent";
import "react-confirm-alert/src/react-confirm-alert.css";
import Styles from "./customDeleteAlert.module.scss";

export const customDeleteAlert = ({
  title = "Are you sure?",
  subTitle = "Are you sure to delete this",
  onConfirm = () => {},
}) => {
  return confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <DeleteModalContent
          title={title}
          subTitle={subTitle}
          onConfirm={() => {
            onConfirm();
            onClose();
          }}
          onClose={onClose}
        />
      );
    },
    overlayClassName: Styles.deleteOverlay,
  });
};
