import { createSlice } from "@reduxjs/toolkit";
import { TimelineContentTypes } from "../../../../helpers/constant/Constants";

const initialState = {
  isShowTemplate: false,
  /* sms */
  sms: {
    type: TimelineContentTypes.sms,
    message: "",
    attachment: "",
    attachmentFile: null,
    emojiExist: false,
    smslength: 0,
    smsCount: 0,
    sending: false,
  },
  email: {
    type: TimelineContentTypes.email,
    subject: "",
    body: "",
    attachmentFile: [],
    sending: false,
  },
  voice: {
    type: TimelineContentTypes.voice,
    attachmentFile: "",
    blobFile: null,
    sending: false,
  },
  addToCampaign: {
    contactCampaigns: [],
    campaigns: [],
    gettingCampaign: false,
    pageNo: 1,
    search: "",
    needToGetMore: false,
    gettingMoreCampaign: false,
    selectedCampaign: null,
    isAdding: false,
  },
};

const sendSlice = createSlice({
  name: "send",
  initialState: initialState,
  reducers: {
    resetStatesSend: (state) => {
      for (const property in initialState) {
        state[property] = initialState[property];
      }
    },
    toogleGetTemplate: (state, action) => {
      state.isShowTemplate = action.payload;
    },
    storeSms: (state, action) => {
      state.sms = { ...state.sms, ...action.payload };
    },
    sendSms: (state, action) => {
      state.sms = { ...state.sms, sending: action.payload };
    },
    storeEmail: (state, action) => {
      state.email = { ...state.email, ...action.payload };
    },
    sendEmail: (state, action) => {
      state.email = { ...state.email, sending: action.payload };
    },
    storeVoice: (state, action) => {
      state.voice = { ...state.voice, ...action.payload };
    },
    sendVoice: (state, action) => {
      state.voice = { ...state.voice, sending: action.payload };
    },
    /* for campaign */
    getCampaigns: (state) => {
      state.addToCampaign = { ...state.addToCampaign, gettingCampaign: true };
    },
    getMoreCampaigns: (state) => {
      state.addToCampaign = { ...state.addToCampaign, needToGetMore: false, gettingMoreCampaign: true };
    },
    storeCampaign: (state, action) => {
      state.addToCampaign = { ...state.addToCampaign, ...action.payload };
    },
    storeSelectedCampaign: (state, action) => {
      state.addToCampaign = { ...state.addToCampaign, selectedCampaign: action.payload };
    },
    addToCampaign: (state, action) => {
      state.addToCampaign = { ...state.addToCampaign, isAdding: action.payload };
    },
  },
});

export const {
  resetStatesSend,
  toogleGetTemplate,
  storeSms,
  sendSms,
  storeEmail,
  sendEmail,
  storeVoice,
  sendVoice,
  getCampaigns,
  getMoreCampaigns,
  storeCampaign,
  storeSelectedCampaign,
  addToCampaign,
} = sendSlice.actions;

export default sendSlice.reducer;
