import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isShowCallScreen: false,
  isMinimized: false,
  isGettingContacts: false,
  contactIds: [],
  contacts: [],
  isStarting: false,
  isDialing: false,
  isConnected: false,
  isShowConnected: false,
  isShowConnectedDetails: false,
  hasGettingError: false,
  gettingError: "",
  isGettingStats: false,
  hasGetStatsError: false,
  getStatsError: "",
  dialerStats: null,

  /* for waiting call */
  showNextCallWaiting: true,
};

const connectingSlice = createSlice({
  name: "connecting",
  initialState: initialState,
  reducers: {
    toggleMinimizedScreen: (state, action) => {
      state.isMinimized = action.payload;
    },
    toggleStarting: (state, action) => {
      state.isStarting = action.payload;
    },
    toggleDialing: (state, action) => {
      state.isDialing = action.payload;
    },
    toggleConnected: (state, action) => {
      state.isConnected = action.payload;
    },
    toggleShowCallScreen: (state, action) => {
      state.isShowCallScreen = action.payload;
    },
    toggleShowConnectedDetails: (state, action) => {
      state.isShowConnectedDetails = action.payload;
    },
    toggleShowConnected: (state, action) => {
      state.isShowConnected = action.payload;
    },

    getContacts: (state) => {
      state.isStarting = false;
      state.isShowCallScreen = true;
      state.isGettingContacts = true;
      state.isDialing = true;
    },
    getContactSuccess: (state, action) => {
      state.isGettingContacts = false;
      const ids = [];
      action.payload.forEach((item) => {
        ids.push(item.id);
      });
      state.contactIds = ids;
      state.contacts = action.payload;
    },
    getContactFailed: (state, action) => {
      state.isGettingContacts = false;
      state.hasGettingError = true;
      state.gettingError = action.payload;
    },
    getStats: (state) => {
      state.isGettingStats = true;
    },
    getStatsSuccess: (state, action) => {
      state.isGettingStats = false;
      state.hasGetStatsError = false;
      state.getStatsError = "";
      state.dialerStats = action.payload;
    },
    getStatsFailed: (state, action) => {
      state.isGettingStats = false;
      state.hasGetStatsError = true;
      state.getStatsError = action.payload;
      state.dialerStats = null;
    },
    resetStatesConnecting: (state) => {
      for (const property in initialState) {
        state[property] = initialState[property];
      }
    },
  },
});

export const {
  toggleMinimizedScreen,
  storeContactIds,
  storeContacts,
  toggleStarting,
  toggleDialing,
  toggleConnected,
  getContacts,
  getContactSuccess,
  getContactFailed,
  toggleShowCallScreen,
  toggleShowConnected,
  resetStatesConnecting,
  getStats,
  getStatsSuccess,
  getStatsFailed,
  toggleShowConnectedDetails,
} = connectingSlice.actions;

export default connectingSlice.reducer;
