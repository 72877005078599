import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import BasicSelect from "../../../../common/Select/BasicSelect";
import { useGetAllDialersQuery } from "../../../../state/features/allDialerList/allDialerApiSlice";

const AddToContactList = ({ selectedOption = "", onChange = () => {} }) => {
  const { data: allDialersState } = useGetAllDialersQuery({ limit: 1000 });
  const { data: dialerList = [] } = allDialersState?.data || {};

  return (
    <Box p={2}>
      <Stack spacing={1} mb={2}>
        <Typography variant={"body2medium"}>Select a power dialer list</Typography>
        <BasicSelect
          placeholder='Please select a power dialer list'
          data={dialerList}
          value={selectedOption}
          onChange={(e) => onChange(e.target.value)}
          valueKey='id'
        />
      </Stack>
    </Box>
  );
};

export default AddToContactList;
