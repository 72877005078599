import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import BasicSelect from "../../../../common/Select/BasicSelect";
import { useGetAllCampaignsQuery } from "../../../../state/features/common/campaign/campaignApiSlice";

const AddToCampaign = ({ selectedOption = "", onChange = () => {} }) => {
  const { data: campaignState } = useGetAllCampaignsQuery();
  const { data: campaignList = [] } = campaignState?.data || {};

  return (
    <Box p={2}>
      <Stack spacing={1} mb={2}>
        <Typography variant={"body2medium"}>Select a campaign</Typography>
        <BasicSelect
          data={campaignList}
          value={selectedOption}
          onChange={(e) => onChange(e.target.value)}
          placeholder='Please select a campaign'
        />
      </Stack>
    </Box>
  );
};

export default AddToCampaign;
