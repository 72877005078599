import React from "react";
import BasicDrawer from "../../common/Drawer/BasicDrawer";
import useBoolean from "../../hooks/useBoolean";
import AddNewGeneralScript from "./AddNewGeneralScript";
import GeneralScriptBody from "./GeneralScriptBody";
import GeneralScriptHeader from "./GeneralScriptHeader";

const GeneralScript = () => {
  const {
    value: isShowAddContactModal,
    setTrue: setShowAddContactModal,
    setFalse: setCloseAddContactModal,
  } = useBoolean(false);
  return (
    <>
      <GeneralScriptHeader openModal={setShowAddContactModal} />

      <GeneralScriptBody />

      <BasicDrawer open={isShowAddContactModal} toggleDrawer={setCloseAddContactModal} title={"Add New Script"}>
        <AddNewGeneralScript onCancel={setCloseAddContactModal} />
      </BasicDrawer>
    </>
  );
};

export default GeneralScript;
