import httpRequest from "../api/httpRequest";

class ConnectingService {
  getConnectingContacts(params) {
    return httpRequest.get(process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/call/contacts", params);
  }
  getDialerStats(params) {
    return httpRequest.get(process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/list/analytics", params);
  }
  logTermAndConditios(params) {
    return httpRequest.post(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/call/agreement-update",
      params
    );
  }
}

export default new ConnectingService();
