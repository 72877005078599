import React from "react";
import { Skeleton, Stack } from "@mui/material";

const AddToContactListSkeleton = ({ count = 1 }) => {
  return Array.from({ length: count }).map((_, i) => (
    <Stack key={i} direction={"row"} alignItems={"center"} spacing={1} sx={{ p: 1 }}>
      <Skeleton variant='circular' width={40} height={40} />
      <div>
        <Skeleton variant='text' width={120} />
        <Skeleton variant='text' width={320} />
      </div>
    </Stack>
  ));
};

export default AddToContactListSkeleton;
