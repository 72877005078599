import { getCookie } from "../../helpers/Cookie";
import httpRequest from "../api/httpRequest";
const containerUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/container`;

class InitializingService {
  checkValidation(params) {
    return httpRequest.get(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/call/check-validation",
      params
    );
  }
  getSettingOverview(params) {
    return httpRequest.get(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/call/setting-overview",
      params
    );
  }
  dialerTokenRequest(params) {
    params = { ...params, "api-key": getCookie(process.env.REACT_APP_ACCESS_TOKEN) };

    return httpRequest.post(`${containerUrl}/powerdialer/token`, params);
  }
  submitError(params) {
    params = { ...params, "api-key": getCookie(process.env.REACT_APP_ACCESS_TOKEN) };

    return httpRequest.post(`${containerUrl}/log/react-error`, params);
  }
  createNewSession(params) {
    return httpRequest.post(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/call/create-new-session",
      params
    );
  }
  getUserListForMention(params) {
    return httpRequest.get(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/call/mention-users",
      params
    );
  }
  getUserOutcome(params) {
    return httpRequest.get(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/list/user-call-outcomes",
      params
    );
  }
  updateContactOutcome(params) {
    return httpRequest.post(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/call/save-call-outcome",
      params
    );
  }
  storePowerDialerCallEnd(params) {
    return httpRequest.post(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/call/power-dialer-call-end",
      params
    );
  }
  updateSettingOverview(params) {
    return httpRequest.post(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/call/update-setting",
      params
    );
  }
  getAllCallScriptForList(params) {
    return httpRequest.get(process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/call/all-script", params);
  }
  updateCallScriptForDialerList(params) {
    return httpRequest.post(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/list/settings/script/make-default",
      params
    );
  }
}

export default new InitializingService();
