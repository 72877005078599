import { useRef } from "react";
import "./style.css";

const AnimatedIcon = ({ className = "", icon, onClick = () => {} }) => {
  const ref = useRef(null);
  const handleClick = (e) => {
    e.preventDefault();
    onClick();
    const element = ref.current;
    element.classList.remove("meis_ai_clicked"); // reset animation
    void element.offsetWidth; // trigger reflow
    element.classList.add("meis_ai_clicked"); // start animation
  };
  return (
    <div ref={ref} className={`meis_animated_icon ${className}`} onClick={handleClick}>
      <span className='meis_ai_icon'>{icon}</span>
      <span className='meis_ai_wave'></span>
    </div>
  );
};
export default AnimatedIcon;
