import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { TimelineContentTypes } from "../../../../helpers/constant/Constants";
import { selectDialerState } from "../../../../state/features/dialer/dialerSelector";
import { handleConnectedData } from "../../../../state/features/dialer/dialerSlice";
import { ArrowBack } from "../../../MakeCall/IconsOngoing";

const CallScript = ({ Styles, showOnlyData = false }) => {
  const dispatch = useDispatch();
  const {
    conversation: { script, gettingScript, gettingScriptError },
    connected: { lastConversation, contactId },
    contactScript: { data: contactScript },
  } = useSelector(selectDialerState);

  // useEffect(() => {
  //   if (userId != 1) {
  //     if (script != null) {
  //       dispatch(getContactScript());
  //     }
  //   }
  // }, []);

  const handleBack = () => {
    if (lastConversation && lastConversation != TimelineContentTypes.script) {
      dispatch(handleConnectedData({ tab: lastConversation }));

      if (window.handleTabScreen != undefined) {
        window.handleTabScreen();
      }
    } else {
      dispatch(handleConnectedData({ tab: TimelineContentTypes.sms }));
    }
  };

  const renderContactScript = () => {
    if (contactScript[contactId]) {
      return <Typography variant='body2' dangerouslySetInnerHTML={{ __html: contactScript[contactId] }} />;
    }
    return <Typography variant='body2'>No script found !</Typography>;
  };

  const renderScript = () => {
    if (contactScript) {
      return renderContactScript();
    }
    if (gettingScript) {
      return <Typography variant='body2'>Please wait...</Typography>;
    }
    if (gettingScriptError !== "") {
      return <Typography variant='body2'>{gettingScriptError}</Typography>;
    }
    if (script) {
      return <Typography variant='body2' dangerouslySetInnerHTML={{ __html: script }} />;
    }
    return <Typography variant='body2'>No script found !</Typography>;
  };

  return (
    <div className={`${Styles.pdadDialerOmcMiddleImWrapper}`}>
      <div className={Styles.pdadDialerOmcMcallScript}>
        {!showOnlyData && (
          <Box className={Styles.scriptHeader}>
            <Box onClick={handleBack} className={`${Styles.pdadDialerOmcMsBtn} ${Styles.pdadTypoBody2Medium}`}>
              <ArrowBack />
              <Typography variant='body2medium' color='text.primary'>
                Call Script
              </Typography>
            </Box>
          </Box>
        )}

        <Box
          sx={{ padding: "20px", "&::-webkit-scrollbar": { width: "4px" } }}
          className={`${Styles.pdadDialerOmcMcallScontent}`}
        >
          <div className={`${Styles.pdadDialerOmcMcallSinner} pdad-scroll-bar`}>{renderScript()}</div>
        </Box>
      </div>
    </div>
  );
};

export default CallScript;
