import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  isLoaded: false,
  isError: false,
  error: "",
  settings: {
    scripts: [],
    /* for adding from existing general script */
    selectedIds: [],
    isAdding: false,
    needToRefresh: false,
  },
};

const scriptSettingSlice = createSlice({
  name: "scriptSetting",
  initialState: initialState,
  reducers: {
    getScriptSetting: (state) => {
      state.isLoading = true;
      state.settings.scripts = [];
      state.settings.needToRefresh = false;
    },
    getScriptSettingSuccess: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.settings.scripts = action.payload;
      }
      state.isLoading = false;
      state.isError = false;
      state.error = "";
      state.isLoaded = true;
    },
    getScriptSettingFailed: (state, action) => {
      state.settings.scripts = [];
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },
    updateScriptSetting: (state, action) => {
      if (!action.payload.script_id) {
        state.isLoading = true;
      }
    },
    createScriptSettingSuccess: (state, action) => {
      state.isLoading = false;
      state.settings.scripts = [action.payload, ...state.settings.scripts];
    },
    updateScriptSettingSuccess: (state, action) => {
      let payloadObj = action.payload;
      let scriptObj = state.settings.scripts.find((obj) => obj.id === payloadObj.script_id);
      if (scriptObj) {
        scriptObj.title = payloadObj.title;
        scriptObj.description = payloadObj.description;
        scriptObj.script = payloadObj.script;
      }
    },
    makeDefaultScript: () => {},
    makeDefaultScriptSuccess: (state, action) => {
      //reseting current default script
      const currentDefaultScript = state.settings.scripts.find((obj) => obj.is_default === 1);
      if (currentDefaultScript) {
        currentDefaultScript.is_default = 0;
      }

      //Adding is default to new one
      const makeDefaultObj = state.settings.scripts.find((obj) => obj.id === action.payload.script_id);
      if (makeDefaultObj) {
        makeDefaultObj.is_default = 1;
      }
    },
    deleteScript: () => {},
    deleteScriptSuccess: (state, action) => {
      state.settings.scripts = state.settings.scripts.filter((obj) => obj.id !== action.payload.script_id);
    },
    resetScriptSettingData: (state) => {
      state.isLoaded = false;
    },

    /* for adding script from existing general script */

    addScriptFromExisting: (state) => {
      state.settings.isAdding = true;
    },

    addScriptFromExistingSuccess: (state) => {
      state.settings.selectedIds = [];
      state.settings.needToRefresh = true;
      state.settings.isAdding = false;
    },

    /*  */
    handleSelectGeneralScriptForList: (state, action) => {
      state.settings.selectedIds = action.payload;
    },
  },
});

export const {
  getScriptSetting,
  getScriptSettingSuccess,
  getScriptSettingFailed,
  updateScriptSetting,
  updateScriptSettingSuccess,
  createScriptSettingSuccess,
  makeDefaultScript,
  makeDefaultScriptSuccess,
  deleteScript,
  deleteScriptSuccess,
  resetScriptSettingData,

  addScriptFromExisting,
  addScriptFromExistingSuccess,

  /*  */
  handleSelectGeneralScriptForList,
} = scriptSettingSlice.actions;

export default scriptSettingSlice.reducer;
