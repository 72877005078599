import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  isLoading: false,
  isError: false,
  error: "",
};

const tagSlice = createSlice({
  name: "tag",
  initialState: initialState,
  reducers: {
    getTags: (state) => {
      state.isLoading = true;
    },
    getTagsSuccess: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    },
    getTagsFailed: (state, action) => {
      state.data = [];
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },
    createTag: () => {},
    createTagSuccess: (state, action) => {
      state.data.push(action.payload);
    },
    createTagFailed: () => {},
  },
});

export const { getTags, getTagsSuccess, getTagsFailed, createTag, createTagSuccess, createTagFailed } =
  tagSlice.actions;

export default tagSlice.reducer;
