import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
  listId: null,
};

const listSettingModalSlice = createSlice({
  name: "listSettingModal",
  initialState: initialState,
  reducers: {
    toggleListSettingModal: (state, action) => {
      if (action.payload.open && action.payload.listId) {
        state.open = true;
        state.listId = action.payload.listId;
      } else {
        state.open = false;
        state.listId = null;
      }
    },
  },
});

export const { toggleListSettingModal } = listSettingModalSlice.actions;

export default listSettingModalSlice.reducer;
