import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import AllActions from "./AllActions/AllActions";
import SendSms from "./SendSms/SendSms";
import AddNote from "./AddNote/AddNote";
import SendEmail from "./SendEmail/SendEmail";
import SendVoice from "./SendVoice/SendVoice";
import AddCampaign from "./AddCampaign/AddCampaign";
import { ArrowBack } from "@mui/icons-material";
import Styles from "./rightside.module.scss";
import AddAnotherList from "./AddAnotherList/AddAnotherList";
import { selectDialerState } from "../../../state/features/dialer/dialerSelector";
import { SEND_OPTIONS } from "../../../state/features/dialer/dialerSlice";
import AddTag from "./AddTag/AddTag";
import SendDirectMail from "./SendDirectMail/SendDirectMail";

const RightSide = ({ className = "" }) => {
  const {
    send: { option },
  } = useSelector(selectDialerState);

  return (
    <div className={`${Styles.wrapper} ${className}`}>
      <div
        className={`${Styles.pdadDialerOmcMiddleIrIn} ${className === "" ? "pdad-scroll-bar" : ""} ${
          Styles.pdadDialerOmcMiddleIrInScroll
        }`}
      >
        {option == SEND_OPTIONS.default && (
          <Box sx={{ padding: "24px" }}>
            <Typography component='p' variant='body2medium' sx={{ color: "text.secondary" }} className={Styles.backBtn}>
              {option !== SEND_OPTIONS.default && <ArrowBack />}
              Actions
            </Typography>

            <Typography
              variant='body2'
              sx={{ color: "action.active", margin: "4px 0 28px 0" }}
              className={Styles.tabHidden}
            >
              Actions
            </Typography>
            <AddNote Styles={Styles} />
            <AllActions Styles={Styles} />
          </Box>
        )}
        {option == SEND_OPTIONS.sms && <SendSms />}
        {option == SEND_OPTIONS.email && <SendEmail />}
        {option == SEND_OPTIONS.voiceMessage && <SendVoice />}
        {option == SEND_OPTIONS.addToCampaign && <AddCampaign />}
        {option == SEND_OPTIONS.addToAnotherList && <AddAnotherList />}
        {option == SEND_OPTIONS.addToTag && <AddTag />}
        {option == SEND_OPTIONS.directMail && <SendDirectMail />}
      </div>
    </div>
  );
};

export default RightSide;
