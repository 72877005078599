import { useCallback, useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import PowerDialerHeader from "./PowerDialerHeader";
import PowerDialerBody from "./PowerDialerBody";
// import MinimizedCall from "../MinimizedCall/MinimizedCall";
import { initModule } from "../../helpers/utils/initModule";

import socketIOClient from "socket.io-client";
import { getAccountData } from "../../helpers/utils/getAccountData";
// import removeDuplicates from "../../helpers/utils/removeDuplicates";
import { selectDialerState } from "../../state/features/dialer/dialerSelector";
import { useDispatch, useSelector } from "react-redux";
import {
  DIALER_STEP,
  handleCallStep,
  handleInitStep,
  handleNextCall,
  handleStartCall,
  // handleInitStep,
  modifyContactsData,
  resetForEndSingleCall,
  storeBasicInit,
  storeConnectedContact,
  storeConnectedContactByDelay,
} from "../../state/features/dialer/dialerSlice";
import { Device } from "@twilio/voice-sdk";
import InitializingService from "../../services/call/Initializing.service";
import CustomModal from "../../common/Modal/CustomModal";
import { selectDialerListState } from "../../state/features/dialerList/dialerListSelector";
import OutsideCall from "../OutsideCall/OutsideCall";
import ConntectedService from "../../services/call/Conntected.service";
import { withTransaction } from "@elastic/apm-rum-react";

const userId = getAccountData("userId");
// const socket = socketIOClient(process.env.REACT_APP_NODE_SERVER);
const socket = socketIOClient(process.env.REACT_APP_POWER_DIALER_NODE_SERVER);
// let device;
const PowerDialerIndex = () => {
  const dispatch = useDispatch();
  const {
    connecting: { contacts },
    init: { callStep, nextCall, step },
  } = useSelector(selectDialerState);
  const { selectedDialerList } = useSelector(selectDialerListState);
  const [socketNumber, setSocketNumber] = useState(null);
  const [modifiedSocketNumber, setModifiedSocketNumber] = useState([]);
  const [deviceError, setDeviceError] = useState("");
  const [isDeviceReady, setIsDeviceReady] = useState(false);

  /* for connected */
  const [connected, setConnected] = useState(null);
  const [endCall, setEndCall] = useState(null);
  const [maximumTryToGetToken, setMaximumTryToGetToken] = useState(0);
  const [ringingCall, setRingingCall] = useState([]);

  /* for outside sessiong start */
  const [isConfigForOutside, setIsConfigForOutside] = useState(false);
  const [outsideData, setOutsideData] = useState(null);
  /* 
  {
    for: "startSession",
    from: "tag-list",
    listId: 1,
    listName: "list name",
    tagName: "tag name",
    tags: "1",
  }
  */
  const [readyToStart, setReadyToStart] = useState(false);
  const [dropSession, setDropSession] = useState(false);

  const device = useRef(null);
  const callEvent = useRef(null);
  const outsideDataToCallForSocket = useRef(null);

  useEffect(() => {
    initModule();
  }, []);

  /* for outside session start */
  useEffect(() => {
    /* 
    dummy link for session start
    http://localhost:3000/power-dialer?listId=123&listName=new%20list&tags=117569&from=tagList&tagName=hello&for=startSession
    */
    const searchFromUrl = window.location.search;
    if (searchFromUrl != undefined && searchFromUrl != null && searchFromUrl != "" && searchFromUrl.trim() != "") {
      setIsConfigForOutside(true);
      const params = new URLSearchParams(searchFromUrl);
      try {
        const fromFor = params.get("for");
        if (fromFor && fromFor == "startSession") {
          if (params.get("from") == "tagList") {
            let data = {
              for: "startSession",
              from: params.get("from"),
              listId: params.get("listId"),
              listName: params.get("listName"),
              tagName: params.get("tagName"),
              tags: params.get("tags").split(),
            };
            handleSessionStartWaitingModalData(data, "single-tag");
          } else if (params.get("from") == "myList") {
            let data = {
              for: "startSession",
              from: params.get("from"),
              listId: params.get("listId"),
              listName: params.get("listName"),
              myListName: params.get("myListName"),
            };
            handleSessionStartWaitingModalData(data, "single-list");
          } else if (params.get("from") == "dealStage") {
            let data = {
              for: "startSession",
              from: params.get("from"),
              listId: params.get("listId"),
              listName: params.get("listName"),
              dealStageName: params.get("dealStageName"),
            };
            handleSessionStartWaitingModalData(data, "deal-stage");
          } else if (params.get("from") == "contactList") {
            let data = {
              for: "startSession",
              from: params.get("from"),
              listId: params.get("listId"),
              listName: params.get("listName"),
            };
            handleSessionStartWaitingModalData(data, "contact-list");
          } else {
            setOutsideData(null);
            setIsConfigForOutside(false);
            outsideDataToCallForSocket.current = null;
          }
        }
      } catch (error) {
        setOutsideData(null);
        setIsConfigForOutside(false);
        outsideDataToCallForSocket.current = null;
      }
    } else {
      setOutsideData(null);
      setIsConfigForOutside(false);
      const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
      window.history.replaceState({ path: newurl }, "", newurl);
      outsideDataToCallForSocket.current = null;
    }
  }, []);

  useEffect(() => {
    // console.log("userId", userId);
    socket.on("connect", () => {
      socket.emit("join-call-room", "powerDialerRoom:" + userId);
      console.log("socket connected");
    });
    socket.on("power-dialer-call-disconnect", callDisconnectHandler);
    socket.on("power-dialer-call-ringing", callRingingHandler);
    socket.on("power-dialer-call-connected", callConnectHandler);
    socket.on("power-dialer-call-ended", callEndHandler);
    socket.on("power-dialer-call-initiate", callInitiateHandler);
    /* for contact add to list notification */
    socket.on("power-dialer-instant-call-process", powerDailerContactAddListner);
  }, []);

  useEffect(() => {
    if (callStep == DIALER_STEP.CONNECTED) {
      if (step != DIALER_STEP.SHOW_CONNECTED_SCREEN) {
        dispatch(storeConnectedContactByDelay());
      }
      return;
    }
    if (modifiedSocketNumber.includes(socketNumber)) {
      return;
    }
    if (socketNumber != null) {
      let contactModifyData = [...contacts];

      for (let contactCount = 0; contactCount < contacts.length; contactCount++) {
        if (socketNumber == modifyContactNumber(contacts[contactCount].number)) {
          setModifiedSocketNumber((prev) => {
            let arr = [...prev];
            arr.push(socketNumber);
            return arr;
          });
          contactModifyData[contactCount] = {
            ...contactModifyData[contactCount],
            hasDisconnect: true,
            connectionFlag: "Disconnected",
          };
          break;
        }
      }
      dispatch(modifyContactsData(contactModifyData));
    }
  }, [socketNumber]);

  useEffect(() => {
    if (callStep == DIALER_STEP.CONNECTED) {
      if (step != DIALER_STEP.SHOW_CONNECTED_SCREEN) {
        dispatch(storeConnectedContactByDelay());
      }
      return;
    }
    if (modifiedSocketNumber.length > 0 && modifiedSocketNumber.length == contacts.length) {
      setTimeout(() => {
        dispatch(handleNextCall(true));
        dispatch(handleInitStep(DIALER_STEP.SHOW_WAITING_FOR_NEXT));
        setModifiedSocketNumber([]);
        setConnected(null);
        setSocketNumber(null);
      }, 2000);
    }
  }, [modifiedSocketNumber]);

  useEffect(() => {
    if (connected == "" || !connected) {
      return;
    }
    let contactModifyData = [...contacts];
    for (let i = 0; i < contacts.length; i++) {
      if (connected == modifyContactNumber(contacts[i].number)) {
        contactModifyData[i] = {
          ...contactModifyData[i],
          hasDisconnect: false,
          connectionFlag: "Connected",
        };

        dispatch(storeConnectedContact(contacts[i]));
        dispatch(handleCallStep(DIALER_STEP.CONNECTED));
        dispatch(storeConnectedContactByDelay());
        setConnected(null);
        break;
      }
    }
    dispatch(modifyContactsData(contactModifyData));
  }, [connected]);

  useEffect(() => {
    if (nextCall) {
      setSocketNumber(null);
      setModifiedSocketNumber([]);
      setRingingCall([]);
      dispatch(handleNextCall(false));
    }
  }, [nextCall]);

  useEffect(() => {
    if (step == DIALER_STEP.STARTING) {
      setSocketNumber(null);
      setModifiedSocketNumber([]);
      setConnected(null);
    }
  }, [step]);

  useEffect(() => {
    if (endCall != null) {
      setEndCall(null);
      setRingingCall([]);
      dispatch(handleCallStep(DIALER_STEP.CALL_END));
      try {
        device.current.disconnectAll();
      } catch (error) {
        console.log(error);
      }
    }
  }, [endCall]);

  useEffect(() => {
    const ringingCallLength = ringingCall.length;
    const contactLength = contacts.length;
    if (ringingCallLength != 0) {
      let contactModifyData = [...contacts];

      for (let contactCount = 0; contactCount < contactLength.length; contactCount++) {
        if (ringingCall.includes(modifyContactNumber(contacts[contactCount].number))) {
          if (
            contactModifyData[contactCount]?.connectionFlag != "Ringing" &&
            contactModifyData[contactCount]?.connectionFlag != "Disconnected" &&
            contactModifyData[contactCount]?.connectionFlag != "Not available"
          ) {
            contactModifyData[contactCount] = {
              ...contactModifyData[contactCount],
              connectionFlag: "Ringing",
            };
          }
          // break;
        }
      }
      dispatch(modifyContactsData(contactModifyData));
    }
  }, [ringingCall]);

  window.handleCallAfterRinging = () => {
    /* check ringing number */
    if (callStep == DIALER_STEP.CONNECTED) {
      if (step != DIALER_STEP.SHOW_CONNECTED_SCREEN) {
        dispatch(storeConnectedContactByDelay());
      }
      return;
    } else {
      console.log("call end for max ringing");
    }

    if (ringingCall.length > 0) {
      let contactModifyData = [...contacts];
      contacts.forEach((item, index) => {
        if (!ringingCall.includes(modifyContactNumber(item.number))) {
          contactModifyData[index] = {
            ...contactModifyData[index],
            connectionFlag: "Not available",
          };
        }
      });
      dispatch(modifyContactsData(contactModifyData));
    }

    try {
      setTimeout(() => {
        if (callStep == DIALER_STEP.CONNECTED) {
          if (step != DIALER_STEP.SHOW_CONNECTED_SCREEN) {
            dispatch(storeConnectedContactByDelay());
          }
        } else {
          console.log("disconnect call after max ringing");
          device.current.disconnectAll();
          dispatch(handleNextCall(true));
          dispatch(handleInitStep(DIALER_STEP.SHOW_WAITING_FOR_NEXT));
          dispatch(resetForEndSingleCall());
          setRingingCall([]);
        }
      }, 2000);
    } catch (error) {
      console.log(error);
    }
  };

  /* set up device */
  useEffect(() => {
    if (!isDeviceReady) {
      setUpDevice();
    }
  }, []);

  const setUpDevice = useCallback(() => {
    setMaximumTryToGetToken((prev) => {
      return prev + 1;
    });
    InitializingService.dialerTokenRequest()
      .then((response) => {
        try {
          setDeviceError("");
          setIsDeviceReady(true);
          device.current = new Device(response.token, {
            closeProtection: true,
            fakeLocalDTMF: true,
          });
          device.current.register().catch((/* err */) => {
            setDeviceError("Can not set up call environment. You can not make call!");
            setIsDeviceReady(false);
          });
        } catch (ex) {
          setDeviceError("Can not set up call environment. You can not make call!");
          setIsDeviceReady(false);
          sessionStorage.setItem("power-dialer-register", false);
        }
        try {
          device.current.on("registered", () => {
            setDeviceError("");
            setIsDeviceReady(true);
            setMaximumTryToGetToken(0);
            console.log("device registered");
            sessionStorage.setItem("power-dialer-register", true);
          });
          device.current.on("error", (/* twilioError, call */) => {
            setDeviceError("Can not set up call environment. You can not make call!");
            setIsDeviceReady(true);
          });
          device.current.on("connect", () => {
            setDeviceError("");
          });
          device.current.on("accept", () => {
            // console.log("156 call accept: ", [callInfo]);
          });
          device.current.on("unregistered", () => {
            try {
              if (maximumTryToGetToken < 10) {
                setUpDevice();
              }
            } catch (e) {
              console.log(e);
            }
          });
        } catch (e) {
          setDeviceError("Can not set up call environment. You can not make call!");
          setIsDeviceReady(true);
          try {
            setDeviceError("Can not set up call environment. You can not make call!");
            //   sendErrorMessageToDevelopers({subject : 'dialer issue',body : `globaldialer 301 (userId : ${Utils.getAccountData('userId')}) : ${e}`}).catch((err) => {
            // 	  // console.group("sendErrorMessageToDevelopers 365")
            // 	  // console.log(err)
            // 	  // console.groupEnd()
            //   })
          } catch (e) {
            setDeviceError("Can not set up call environment. You can not make call!");
            console.log(e);
          }
        }
      })
      .catch((reason) => {
        setDeviceError("Can not set up call environment. You can not make call!");
        setIsDeviceReady(true);
        console.group(" token ");
        console.log(reason);
        console.groupEnd();
      });
  }, []);

  window.callEventsCallback = (params) => {
    try {
      callEvent.current = device.current.connect({ params: params });
    } catch (e) {
      console.log("globaldialer366 : " + e);
    }
  };

  const modifyContactNumber = (number) => {
    if (number == undefined || number == null) {
      return number;
    }
    number = number.replace(/\+/g, "");
    number = number.replace(/\(/g, "");
    number = number.replace(/\)/g, "");
    number = number.replace(/ /g, "");
    return number;
  };

  const callDisconnectHandler = (data) => {
    if (data.room === "powerDialerRoom:" + userId) {
      // console.log("call disconnect data ", [data]);
      setSocketNumber(() => {
        return modifyContactNumber(data.params.called);
      });
    }
  };
  const callRingingHandler = (data) => {
    // console.log("call ringing data 334", [data]);
    if (data.room === "powerDialerRoom:" + userId) {
      // console.log("call ringing data ", [data]);
      // ringingCall.push(modifyContactNumber(data.params.called));
      setRingingCall((prev) => {
        let arr = [...prev];
        arr.push(modifyContactNumber(data.params.called));
        return arr;
      });
    }
  };
  const callConnectHandler = (data) => {
    // console.log("call connect data 340", [data]);
    if (data.room === "powerDialerRoom:" + userId) {
      // console.log("call connect data ", [data]);
      setConnected(() => {
        return modifyContactNumber(data.params.called);
      });
    }
  };
  const callEndHandler = (data) => {
    if (data.room === "powerDialerRoom:" + userId) {
      // console.log("call end data ", [data]);
      setEndCall(() => {
        return modifyContactNumber(data.params.called);
      });
    }
  };
  const callInitiateHandler = (data) => {
    // console.log("call initiate : ", [data]);
    if (data.room === "powerDialerRoom:" + userId) {
      // console.log("call initiate", [data]);
    }
  };

  /* call start from outside */
  const checkContactAddedForOutsideCall = (data, source = "single-tag") => {
    ConntectedService.checkContactAddedToListForOutside({
      source: source,
      powerDialerListId: data["listId"],
    })
      .then((res) => {
        if (res.success) {
          setReadyToStart(true);
        } else {
          setReadyToStart(false);
        }
      })
      .catch((error) => {
        setReadyToStart(false);
        console.log(error);
      });
  };
  const handleStartSessionFromOutside = () => {
    if (dropSession) {
      return;
    }
    setIsConfigForOutside(false);
    if (step === DIALER_STEP.STOP) {
      dispatch(
        storeBasicInit({
          listInfo: selectedDialerList,
          listId: selectedDialerList?.id,
          virtualNumberId: selectedDialerList?.virtual_number_id,
        })
      );
      dispatch(
        handleStartCall({
          listId: selectedDialerList?.id,
          sessionId: null,
          virtualNumberId: selectedDialerList?.virtual_number_id,
        })
      );
    }
    setOutsideData(null);
    setReadyToStart(false);
    outsideDataToCallForSocket.current = null;
  };
  const powerDailerContactAddListner = (data) => {
    // console.log("get notification from add contact: ", [data]);
    if (data.room === "powerDialerRoom:" + userId) {
      if (outsideDataToCallForSocket.current != null) {
        if (outsideDataToCallForSocket.current.listId == data?.params.powerDialerListId) {
          setReadyToStart(true);
        }
      }
    }
  };
  const handleSessionStartWaitingModalData = (data, source = "single-tag") => {
    outsideDataToCallForSocket.current = data;
    checkContactAddedForOutsideCall(data, source);
    setOutsideData(data);
    const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
    window.history.replaceState({ path: newurl }, "", newurl);
  };

  return (
    <Box
      sx={{
        height: "calc(100vh - 96px)",
        background: "linear-gradient(0deg, rgba(148, 157, 178, 0.12), rgba(148, 157, 178, 0.12)), #FFFFFF",
        "@media (max-width:992px)": { width: "calc(100vw - 40px)" },
      }}
    >
      {/* ## Dialer Main Header ## */}
      <PowerDialerHeader />

      {/* ## Dialer Main Screen ## */}
      <PowerDialerBody
        setUpDevice={setUpDevice}
        deviceError={deviceError}
        isDeviceReady={isDeviceReady}
        device={device.current}
        callEvent={callEvent.current}
      />

      {/* ## Dialer Call Screen ## */}
      {/* <MinimizedCall /> */}

      <CustomModal disableOutSideClick={true} open={outsideData && isConfigForOutside}>
        <OutsideCall
          handleStartCall={handleStartSessionFromOutside}
          readyToStart={readyToStart}
          data={outsideData}
          isLoading={isConfigForOutside}
          onClose={() => {
            setIsConfigForOutside(false);
            setOutsideData(null);
            setReadyToStart(false);
            setDropSession(true);
            outsideDataToCallForSocket.current = null;
          }}
        />
      </CustomModal>
    </Box>
  );
};
export default withTransaction("powerdialer-index", "component")(PowerDialerIndex);
