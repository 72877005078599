import React, { useState } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import CoreButton from "../../../common/Button/CoreButton";
import AudioPlayer from "../../../common/VoiceManager/AudioPlayer";
import AddOrEditVoiceTemplate from "./AddOrEditVoiceTemplate";
import VoicemailTemplateService from "../../../services/call/VoicemailTemplate.service";
import { customDeleteAlert } from "../../../common/custom-alert/customDeleteAlert";
import { showGlobalNotification } from "../../../helpers/utils/showGlobalNotification";

const VoiceTemplateItem = ({ item, handleUpdateItem, removeItem }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const handleOnClose = (updateItem) => {
    setIsOpenModal(false);
    handleUpdateItem(updateItem);
  };

  const handleDelete = () => {
    if (!deleting) {
      customDeleteAlert({
        subTitle: "Are you sure to delete this voicemail?",
        onConfirm: () => {
          setDeleting(true);
          try {
            VoicemailTemplateService.deleteVoicemailTemplate({ id: item.id }).then((response) => {
              setDeleting(false);
              if (response.success) {
                removeItem(item.id);
                showGlobalNotification(response.message, "success");
              } else {
                showGlobalNotification(response.message);
              }
            });
          } catch (error) {
            console.error(error);
            setDeleting(false);
            showGlobalNotification("Something went wrong! Try again later");
          }
        },
      });
    }
  };

  return (
    <>
      <Grid item xs={12} border={"1px solid #E0E0E0"} borderRadius={2} p={2}>
        <Stack gap={2}>
          <Stack direction={"row"} alignItems={"flex-start"} justifyContent={"space-between"}>
            <Stack gap={1}>
              <Typography variant={"body2"} color={"text.primary"}>
                {item.title}
              </Typography>
              <Typography
                variant={"chipLight"}
                fontWeight={400}
                color={"text.secondary"}
                sx={{
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {item?.description}
              </Typography>
            </Stack>
            <Stack direction={"row"} alignItems={"flex-start"} justifyContent={"space-between"} gap={1}>
              <CoreButton variant='outlined' onClick={() => setIsOpenModal(true)}>
                Edit
              </CoreButton>
              <CoreButton variant='outlined' color='error' onClick={() => handleDelete()}>
                Delete
              </CoreButton>
            </Stack>
          </Stack>
          <AudioPlayer src={item.url} />
        </Stack>
      </Grid>

      {isOpenModal && (
        <AddOrEditVoiceTemplate open={isOpenModal} onClose={(updateItem) => handleOnClose(updateItem)} edit={item} />
      )}
    </>
  );
};

export default VoiceTemplateItem;
