import { useState } from "react";
import { Box, styled, Typography } from "@mui/material";

const StyledBox = styled(Box)(() => ({
  width: "200px",
  height: "200px",
  "& img": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
}));

const PreviewImageFile = ({ link, alt = "Inbox mms" }) => {
  const [isShowImage, setIsShowImage] = useState(false);

  const handleImgLoad = (event) => {
    if (event !== undefined && event != null) {
      if (event.type !== undefined && event.type === "load") {
        setIsShowImage(true);
      } else {
        setIsShowImage(false);
      }
    } else {
      setIsShowImage(false);
    }
  };
  const handleImgError = (event) => {
    if (event !== undefined && event != null) {
      if (event.type !== undefined && event.type === "error") {
        setIsShowImage(false);
      }
    }
  };
  return (
    <>
      {isShowImage ? (
        <StyledBox>
          <img alt={alt} src={link} onLoad={handleImgLoad} onError={handleImgError} id='PreviewImageFile_id' />
        </StyledBox>
      ) : (
        <Typography variant={"caption"} color={"text.secondary"}>
          Invalid file ! Can not open file.
          <a href={link} target='_blank' rel='noreferrer'>
            <Typography variant={"caption"} color={"secondary.main"}>
              Link
            </Typography>
          </a>
        </Typography>
      )}
    </>
  );
};
export default PreviewImageFile;
