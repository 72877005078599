import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const useQueryParam = (param) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [value, setValue] = useState(params.get(param));

  useEffect(() => {
    const updatedParams = new URLSearchParams(location.search);
    setValue(updatedParams.get(param));
  }, [location.search, param]);

  return [value, setValue];
};

export default useQueryParam;
