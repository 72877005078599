export const getModifiedCustomFields = (customFields) => {
  let allFieldsArr = [];
  let groupName = null,
    groupIndex = -1;

  customFields.forEach((eachField) => {
    if (eachField.group_name !== groupName) {
      groupName = eachField.group_name;
      groupIndex++;
      allFieldsArr.push({ title: groupName, isCustomField: true, tags: [] });
    }
    allFieldsArr[groupIndex].tags.push({ title: eachField.title, value: eachField.personalize_tag });
  });

  return allFieldsArr;
};
