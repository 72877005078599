import React from "react";
import { FormControlLabel, styled, Switch } from "@mui/material";

const DefaultStyledSwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 28,
  padding: 0,
  backgroundColor: theme.palette.common.white,
  "& .MuiSwitch-track": {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.action.disabled}`,
    borderRadius: 28 / 2,
    opacity: 1,
    "&:before, &:after": {
      content: '""',
      fontSize: "12px",
      position: "absolute",
      transform: "translateY(30%)",
    },
    "&:before": {
      left: 10,
      color: theme.palette.secondary.main,
    },
    "&:after": {
      content: "'OFF'",
      right: 10,
      color: theme.palette.text.secondary,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 22,
    height: "22px !important",
    backgroundColor: theme.palette.text.secondary,
    paddingLeft: "0 !important",
  },
  "& .MuiSwitch-switchBase": {
    padding: "3px",
    "&.Mui-checked": {
      transform: "translateX(34px)",
      "&+.MuiSwitch-track": {
        opacity: 1,
        border: `1px solid ${theme.palette.secondary.main}`,
        backgroundColor: theme.palette.common.white,
        "&:before": {
          content: "'ON'",
        },
        "&:after": { content: "none" },
      },
      "& .MuiSwitch-thumb": {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
}));

const CustomDefaultSwitch = ({ checked = false, onChange = () => {} }) => {
  return <FormControlLabel control={<DefaultStyledSwitch color={"default"} checked={checked} onChange={onChange} />} />;
};

export default CustomDefaultSwitch;
