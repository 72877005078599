import React from "react";
import { useDispatch } from "react-redux";
import { Box, IconButton, Stack, styled, Typography } from "@mui/material";
import { BarChart, ColorLens, ContentCopyRounded, Delete, Edit, MoreVertRounded, Settings } from "@mui/icons-material";
import DialerAnalyticReport from "../DialerAnalyticReport/DialerAnalyticReport";
import AddOrEditDialerList from "./AddOrEditDialerList";
import CustomPopoverMenu, {
  CustomPopoverMenuButton,
  CustomPopoverMenuItem,
} from "../../common/PopoverMenu/CustomPopoverMenu";
import FolderIcon from "../../common/Icons/FolderIcon";
import BasicModal from "../../common/Modal/BasicModal";
import BasicModalWithHeader from "../../common/Modal/BasicModalWithHeader";
import DeleteModalContent from "../../common/WarningModalContent/WarningModalContent";
import useBoolean from "../../hooks/useBoolean";
import { FOLDER_COLORS } from "../../helpers/constant/folderContant";
import compressString from "../../helpers/utils/compressString";
import {
  cloneDialer,
  deleteDialer,
  onSelectDialerList,
  updateDialerColor,
} from "../../state/features/dialerList/dialerListSlice";
import { resetDialerContacts } from "../../state/features/dialerContacts/dialerContactsSlice";
import { toggleListSettingModal } from "../../state/features/listSetting/listSettingModal/listSettingModalSlice";

const ListItemStyled = styled(Box)(({ theme, active }) => ({
  display: "flex",
  alignItems: "center",
  paddingLeft: "0px",
  paddingRight: "0px",
  borderRadius: "4px",
  marginBottom: "4px",
  cursor: "pointer",
  transition: "all 0.4s ease-in",
  ...(active === "true" ? { backgroundColor: theme.palette.action.selected } : {}),
  "&:hover": {
    backgroundColor: theme.palette.action.selected,
  },
}));

const ColorBox = styled(Box)(() => ({
  gap: "10px",
  display: "grid",
  gridTemplateColumns: "repeat(5, 1fr)",
  gridTemplateRows: "repeat(2, 1fr)",
  padding: "8px 16px",
}));

const ColorBoxItem = styled(Box)(({ theme, backgroundColor }) => ({
  width: "32px",
  height: "32px",
  borderRadius: "4px",
  display: "inline-flex",
  cursor: "pointer",
  backgroundColor: backgroundColor ? backgroundColor : theme.palette.other.outlinedBorder,
}));

const DialerListItem = ({ dialerData, selected }) => {
  const dispatch = useDispatch();
  const { id, title, color } = dialerData || {};

  const {
    value: openDialerEditForm,
    setTrue: onOpenDialerEditForm,
    setFalse: onCloseDialerEditForm,
  } = useBoolean(false);

  const { value: openDeleteModal, setTrue: onOpenDeleteModal, setFalse: onCloseDeleteModal } = useBoolean(false);
  const { value: openGraphModal, setTrue: onShowGraphModal, setFalse: onCloseGraphModal } = useBoolean(false);

  const {
    value: showSelectedDialer,
    setTrue: onShowSelectedDialer,
    setFalse: onHideSelectedDialer,
  } = useBoolean(false);

  const handleSelectDialer = () => {
    dispatch(onSelectDialerList(dialerData));
    if (!selected) dispatch(resetDialerContacts(dialerData));
  };

  // handle Change Dialer
  const handleDialerEdit = () => {
    onOpenDialerEditForm();
  };

  // handle Change Dialer
  const onDialerClone = () => {
    dispatch(cloneDialer(dialerData));
  };

  // handle Delete With confirmation
  const handleDeleteDialer = () => {
    onOpenDeleteModal();
  };

  const onConfirmDelete = () => {
    onCloseDeleteModal();
    dispatch(
      deleteDialer({
        id: dialerData.id,
      })
    );
  };

  // handle Change Dialer Color
  const handleChangeDialerColor = (id, color) => {
    dispatch(updateDialerColor({ id: id, color }));
  };

  return (
    <>
      <ListItemStyled active={selected || showSelectedDialer ? "true" : "false"}>
        <Stack
          direction='row'
          alignItems='center'
          spacing={1}
          sx={{ flex: 1, width: "80%", height: "40px", pl: 1 }}
          onClick={handleSelectDialer}
        >
          <FolderIcon fontSize={"small"} sx={{ color: color }} />
          <Typography variant='buttonMedium' color={"text.primary"} className='text-truncate' title={title}>
            {compressString(title, 20)}
          </Typography>
        </Stack>

        <div>
          <CustomPopoverMenu wrapperClass='fbpMoreOptions' callbackOnClose={onHideSelectedDialer}>
            <CustomPopoverMenuButton>
              <IconButton onClick={onShowSelectedDialer} size={"small"}>
                <MoreVertRounded color={"action"} />
              </IconButton>
            </CustomPopoverMenuButton>

            <CustomPopoverMenuItem
              title='Edit'
              icon={<Edit color={"action"} />}
              onClick={handleDialerEdit}
              onClickHide={true}
            />
            <CustomPopoverMenuItem
              title='Settings'
              icon={<Settings color={"action"} />}
              onClick={() => dispatch(toggleListSettingModal({ open: true, listId: id }))}
              onClickHide={true}
            />
            <CustomPopoverMenuItem
              title='View Analytics'
              icon={<BarChart color={"action"} />}
              onClick={onShowGraphModal}
              onClickHide={true}
            />
            <CustomPopoverMenuItem
              title='Duplicate'
              icon={<ContentCopyRounded color={"action"} />}
              onClick={onDialerClone}
              onClickHide={true}
            />
            <CustomPopoverMenuItem
              title='Delete'
              icon={<Delete color={"action"} />}
              onClick={handleDeleteDialer}
              onClickHide={true}
            />
            <Box>
              <Stack direction='row' spacing={2} alignItems={"center"} px={2} py={1} sx={{ cursor: "pointer" }}>
                <ColorLens color={"action"} />
                <Typography variant='body1' color='text.primary'>
                  Change Color
                </Typography>
              </Stack>

              <ColorBox>
                {FOLDER_COLORS.map((color, i) => (
                  <ColorBoxItem
                    key={i}
                    backgroundColor={color}
                    onClick={() => handleChangeDialerColor(dialerData?.id, color)}
                  />
                ))}
              </ColorBox>
            </Box>
          </CustomPopoverMenu>
        </div>
      </ListItemStyled>

      <BasicModalWithHeader title={"Edit Power Dialer List"} open={openDialerEditForm} onClose={onCloseDialerEditForm}>
        <AddOrEditDialerList onClose={onCloseDialerEditForm} dialerInfo={dialerData} isEditMode={true} />
      </BasicModalWithHeader>

      <BasicModal open={openDeleteModal} onClose={onCloseDeleteModal} width={500}>
        <DeleteModalContent
          title={"Delete!"}
          subTitle={"Power dialer list : (" + dialerData.title + ")"}
          description={"Are you sure to remove the dialer list?"}
          buttonName={"Remove List"}
          onClose={onCloseDeleteModal}
          onConfirm={onConfirmDelete}
        />
      </BasicModal>

      <BasicModal open={openGraphModal} onClose={onCloseGraphModal}>
        <DialerAnalyticReport dialerListId={dialerData.id} onClose={onCloseGraphModal} />
      </BasicModal>
    </>
  );
};

export default React.memo(DialerListItem);
