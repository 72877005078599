import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Divider, Paper, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { selectScriptSetting } from "../../../state/features/listSetting/listSettingSelector";
import {
  addScriptFromExisting,
  getScriptSetting,
  handleSelectGeneralScriptForList,
} from "../../../state/features/listSetting/scriptSetting/scriptSettingSlice";
import ScriptSettingSkeleton from "../../../common/LoadingView/ScriptSettingSkeleton";
import ScriptList from "./ScriptList";
import CoreButton from "../../../common/Button/CoreButton";
import ScriptUpdate from "./ScriptUpdate";
import { selectDefaultCallSetting } from "../../../state/features/listDefaultSetting/defaultListSettingSelector";
import {
  getGeneralScripts,
  hasMoreGeneralScripts,
} from "../../../state/features/listDefaultSetting/defaultCallSetting/defaultCallSettingSlice";
import GeneralScriptList from "../../GeneralScript/GeneralScriptList";
import { selectSelectedDialerList } from "../../../state/features/dialerList/dialerListSelector";

const ScriptSettingTab = () => {
  const dispatch = useDispatch();
  const [isWriting, setIsWriting] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [isSelectExisting, setIsSelectExisting] = useState(false);
  const {
    isLoading,
    isLoaded,
    settings: { scripts, isAdding, needToRefresh, selectedIds: listSettingIds },
  } = useSelector(selectScriptSetting);

  const {
    generalScripts: {
      data,
      perPage,
      page,
      search,
      hasMore: generalScriptsHasMore,
      isLoading: generalScriptsIsLoading,
      // editingData: script,
      // editingIndex,
    },
  } = useSelector(selectDefaultCallSetting);

  const { id: selectedPowerDialerListId } = useSelector(selectSelectedDialerList);

  useEffect(() => {
    if (!isLoaded) {
      dispatch(getScriptSetting());
    }
  }, []);

  useEffect(() => {
    if (isSelectExisting) {
      dispatch(
        getGeneralScripts({
          limit: perPage,
          page: page,
          search: search,
          powerDialerListId: selectedPowerDialerListId,
        })
      );
    }
  }, [search, isSelectExisting]);

  useEffect(() => {
    if (needToRefresh) {
      dispatch(getScriptSetting());
    }
  }, [needToRefresh]);

  const handleSetData = (data) => {
    setSelectedData(data);
    setIsWriting(data !== null);
  };

  if (isLoading) {
    return <ScriptSettingSkeleton />;
  }

  const handleSaveExisting = () => {
    if (listSettingIds.length > 0) {
      // const ids = listSettingIds?.map((item) => item.id);
      console.log(listSettingIds);
      dispatch(
        addScriptFromExisting({
          payload: {
            powerDialerListId: selectedPowerDialerListId,
            generalScriptIds: listSettingIds,
          },
          callback: () => setIsSelectExisting(false),
        })
      );
    }
  };

  const handleScroll = (e) => {
    if (
      generalScriptsHasMore &&
      !generalScriptsIsLoading &&
      Math.round(e.target.scrollTop + e.target.clientHeight, 10) >= Math.round(e.target.scrollHeight, 10)
    ) {
      dispatch(
        hasMoreGeneralScripts({
          limit: perPage,
          page: page,
          search: search,
        })
      );
    }
  };

  return isSelectExisting ? (
    generalScriptsIsLoading ? (
      <Box p={2}>
        <Paper elevation={0} sx={{ bgcolor: "action.actionHover", overflow: "hidden", mb: 2, mx: "auto" }}>
          <Stack alignItems={"center"} justifyContent={"center"} py={3}>
            <Typography variant={"body2"} color={"text.primary"}>
              Loading... Please wait
            </Typography>
          </Stack>
        </Paper>
      </Box>
    ) : (
      <Box p={2}>
        <GeneralScriptList
          scripts={data}
          scriptsIds={[...scripts.map((each) => each.id)]}
          isLoading={isLoading}
          listSetting
          handleOnScroll={(e) => handleScroll(e)}
        />

        <Stack direction={"row"} justifyContent={"center"} spacing={2}>
          <CoreButton
            variant={"outlined"}
            color={"error"}
            size={"large"}
            disabled={isAdding}
            // startIcon={<AddIcon />}
            onClick={() => {
              dispatch(handleSelectGeneralScriptForList([]));

              setIsSelectExisting(false);
            }}
          >
            Back
          </CoreButton>

          <CoreButton
            variant={"contained"}
            color={"secondary"}
            size={"large"}
            // startIcon={<AddIcon />}
            onClick={handleSaveExisting}
            disabled={isAdding || data.length === 0}
          >
            {isAdding ? "adding" : "Add"}
          </CoreButton>
        </Stack>
      </Box>
    )
  ) : (
    <>
      <Box px={4} py={2}>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
          <Typography variant={"body2medium"} color={"text.primary"}>
            {scripts.length > 1 && "Choose Script"}
          </Typography>

          <Stack direction={"row"} spacing={2}>
            <CoreButton
              variant={"outlined"}
              color={"secondary"}
              size={"small"}
              startIcon={<AddIcon />}
              onClick={() => setIsSelectExisting(true)}
              // disabled={isWriting && true}
            >
              Select From Existing
            </CoreButton>
            <CoreButton
              variant={"contained"}
              color={"secondary"}
              size={"small"}
              startIcon={<AddIcon />}
              onClick={() => handleSetData({})}
              disabled={isWriting && true}
            >
              New Script
            </CoreButton>
          </Stack>
        </Stack>
        <ScriptList onSetEdit={handleSetData} scripts={scripts} />
      </Box>
      {isWriting && (
        <>
          <Divider lighter />
          <ScriptUpdate onCancel={() => setIsWriting(false)} selectedData={selectedData} />
        </>
      )}
    </>
  );
};

export default ScriptSettingTab;
