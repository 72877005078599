import httpRequest from "./api/httpRequest";

class ContactService {
  getContactList(params) {
    return httpRequest.get(process.env.REACT_APP_BACKEND_URL_CONTACT_API, params);
  }

  addMainContactOnDialerList(body) {
    return httpRequest.post(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/list/copy/from/main/contacts",
      body
    );
  }
}

export default new ContactService();
