import React from "react";
import { SvgIcon } from "@mui/material";

const DialPhoneIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.474 0H19.684V3.316H23V5.526H19.684V8.831C19.684 8.831 17.485 8.842 17.474 8.831V5.526H14.158C14.158 5.526 14.169 3.327 14.158 3.316H17.474V0ZM4.211 19.033H17.474V11.053H19.684V18.789C19.684 20.005 18.689 21 17.474 21H4.211C2.995 21 2 20.005 2 18.789V5.526C2 4.311 2.995 3.316 4.211 3.316H11.947V5.526H4.211V19.033ZM12.01 14.227C10.62 13.515 9.481 12.38 8.773 10.99L9.614 10.483C9.752 10.345 9.791 10.154 9.737 9.982C9.555 9.432 9.457 8.842 9.457 8.228C9.457 7.958 9.236 7.737 8.966 7.737H6.912C6.642 7.737 6.421 7.958 6.421 8.228C6.421 12.841 10.159 16.579 14.772 16.579C15.042 16.579 15.263 16.358 15.263 16.088V14.034C15.263 13.764 15.042 13.543 14.772 13.543C14.163 13.543 13.569 13.445 13.018 13.263C12.846 13.204 12.65 13.248 12.517 13.381L12.01 14.227Z'
      />
    </SvgIcon>
  );
};

export default DialPhoneIcon;
