import React from "react";
import { Box, Paper, Stack } from "@mui/material";
import AnalyticsPieChart from "./AnalyticsPieChart";

const AnalyticReportRight = () => {
  return (
    <Paper elevation={0}>
      <Stack direction={"column"} justifyContent={"center"} sx={{ height: "100%" }}>
        <Box sx={{ p: 3 }}>
          <AnalyticsPieChart />
        </Box>
      </Stack>
    </Paper>
  );
};

export default AnalyticReportRight;
