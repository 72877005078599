import { createSlice } from "@reduxjs/toolkit";
import { ATTACHMENT_LOADING_KEY } from "../../../../helpers/constant/Constants";

const initialState = {
  isLoading: false,
  isLoaded: false,
  isError: false,
  isLoadingSmsAttachment: false,
  isLoadingEmailAttachment: false,
  error: "",
  settings: {
    incoming_call_action: "1",
    incoming_call_forward_number: "",
    incoming_call_voice_url: "",
    move_next_call_action: "1",
    interval_between_calls: "",
    send_sms_trigger: false,
    send_sms_trigger_body: "",
    send_sms_trigger_attachment: null,
    send_email_trigger: false,
    send_email_trigger_subject: "",
    send_email_trigger_body: "",
    send_email_trigger_attachment: null,
    send_voice_mail_trigger: false,
    send_voice_mail_trigger_url: null,
    add_to_campaign_trigger: false,
    add_to_campaign_trigger_value: "",
    add_tag_trigger: false,
    add_tag_trigger_values: [],
    add_to_another_list_trigger: false,
    add_to_another_list_trigger_value: "",
  },
};

const triggerSettingSlice = createSlice({
  name: "triggerSetting",
  initialState: initialState,
  reducers: {
    getTriggerSetting: (state) => {
      state.isLoading = true;
      state.isLoadingSmsAttachment = false;
      state.isLoadingEmailAttachment = false;
    },
    getTriggerSettingSuccess: (state, action) => {
      let data = { ...action.payload };

      data.add_tag_trigger_values = data.add_tag_trigger_values === null ? [] : JSON.parse(data.add_tag_trigger_values);

      if (data.send_sms_trigger_body === null) {
        data.send_sms_trigger_body = "";
      }
      state.settings = { ...state.settings, ...data };
      state.isLoading = false;
      state.isError = false;
      state.error = "";
      state.isLoaded = true;
    },
    getTriggerSettingFailed: (state, action) => {
      state.data = [];
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },
    updateTriggerSetting: (state, action) => {
      state.settings = { ...state.settings, ...action.payload };
    },
    updateTriggerSettingWithoutSaga: (state, action) => {
      state.settings = { ...state.settings, ...action.payload };
    },

    updateTriggerSettingLoading: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },

    appendNewSelectedTag: (state, action) => {
      state.settings.add_tag_trigger_values.push(action.payload.value);
    },
    resetTriggerSettingData: (state) => {
      state.isLoaded = false;
    },
    uploadAttachment: (state, action) => {
      const updateLoadingKey = ATTACHMENT_LOADING_KEY[action.payload.key];

      if (updateLoadingKey) {
        state[updateLoadingKey] = true;
      }
    },
    uploadVoiceFile: () => {},
    updateTriggerSettingOnCreateTag: () => {},
  },
});

export const {
  getTriggerSetting,
  getTriggerSettingSuccess,
  getTriggerSettingFailed,
  updateTriggerSettingWithoutSaga,
  updateTriggerSettingLoading,
  updateTriggerSetting,
  appendNewSelectedTag,
  resetTriggerSettingData,
  uploadAttachment,
  uploadVoiceFile,
  updateTriggerSettingOnCreateTag,
} = triggerSettingSlice.actions;

export default triggerSettingSlice.reducer;
