import httpRequest from "../api/httpRequest";

class PersonalizedTagService {
  getPersonalizedTags(params) {
    return httpRequest.get(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/list/settings/personalization",
      params
    );
  }
}

export default new PersonalizedTagService();
