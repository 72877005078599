import React, { useEffect, useState } from "react";
import { ArrowBack } from "@mui/icons-material";
import { Button, Grid, Stack, styled, Typography } from "@mui/material";
import ButtonBack from "../../../screens/auto-dialer-ongoing/global/ButtonBack/ButtonBack";
import CustomCircleLoader from "../../../../common/Loader/CustomCircleLoader";
import Styles from "./sendVoice.module.scss";
import SendService from "../../../../services/common/Send.service";
import { showErrorToaster, showSuccessToaster } from "../../../../helpers/utils/toaster";
import { useDispatch, useSelector } from "react-redux";
import { selectDialerState } from "../../../../state/features/dialer/dialerSelector";
import { getCookie } from "../../../../helpers/Cookie";
import { handleSendOption, SEND_OPTIONS } from "../../../../state/features/dialer/dialerSlice";
import AudioPlayer from "../../../../common/VoiceManager/AudioPlayer";
import VoicemailTemplateService from "../../../../services/call/VoicemailTemplate.service";
import VoiceTemplateSkeleton from "./VoiceTemplateSkeleton";
import VoiceManagerVmail from "../../../../common/VoiceManager/VoiceManagerVmail";

const StyledFooter = styled(Stack)(() => ({
  backgroundColor: "white",
  width: "100%",
  // marginTop: "24px",
  padding: "16px 24px",
  boxShadow: "0px -4px 4px rgba(0, 0, 0, 0.08)",
  // borderTop: `1px solid ${theme.palette.other.outlinedBorderDarker}`
}));

const SendVoice = () => {
  const dispatch = useDispatch();
  const {
    init: { virtualNumberId },
    connected: { contactId },
  } = useSelector(selectDialerState);
  const [attachmentFile, setAttachmentFile] = useState(null);
  const [sending, setSending] = useState(false);
  const [blob, setBlob] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    VoicemailTemplateService.getVoicemailTemplates({})
      .then((response) => {
        if (response.success) {
          setData(response.data);
        } else {
          setData([]);
          showErrorToaster(response.message);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        showErrorToaster("Something went wrong! Try again later");
        setIsLoading(false);
      });
  };

  const hanldeOnSend = async () => {
    if (!blob && !selectedTemplate) {
      showErrorToaster("A voice message is required. Please record or select one.");
      return;
    }

    const formData = new FormData();
    formData.append("messageLevel", "voice");
    formData.append("virtual_number_id", virtualNumberId);
    formData.append("contact_id", contactId);
    formData.append("origin", 3);
    formData.append("schedule_type", 1);
    if (selectedTemplate) {
      formData.append("url", selectedTemplate.url);
    } else {
      formData.append("voice_file", blob);
    }

    formData.append("api-key", getCookie(process.env.REACT_APP_ACCESS_TOKEN));

    if (blob || selectedTemplate) {
      setSending(true);
      SendService.makeVoiceConversation(formData)
        .then((response) => {
          setSending(false);
          if (response.status === "success") {
            showSuccessToaster(response.html);
            dispatch(handleSendOption({ option: SEND_OPTIONS.default }));
          } else {
            showErrorToaster(response.html);
          }
        })
        .catch((error) => {
          setSending(false);
          console.error("Error sending message:", error);
          showErrorToaster("Something went wrong! Try again later");
        });
    } else {
      showErrorToaster("Please record a voice message or select a message");
    }
  };

  const handleSelectTemplate = (selectedItem) => {
    setSelectedTemplate(selectedItem);
    if (blob) {
      setBlob(null);
      setAttachmentFile("");
    }
  };

  return (
    <div className={Styles.sendVoiceWrapper}>
      {/* Header */}
      <ButtonBack
        backHandler={() => {
          setSending(false);
          dispatch(handleSendOption({ option: SEND_OPTIONS.default }));
        }}
        title='Send a Ringless Voicemail'
        icon={<ArrowBack sx={{ color: "action.active" }} />}
      />

      {/* Body */}
      <div className={Styles.modalBody} id='sendVoiceMessageWrp'>
        <Stack mb={1}>
          <Typography variant='body2medium' component={"p"} color={"text.primary"} sx={{ pb: 1 }}>
            Record A New Message
          </Typography>

          <span>
            <VoiceManagerVmail
              size='small'
              showHeader={false}
              blob={blob}
              src={attachmentFile}
              onRemove={() => {
                setAttachmentFile("");
                setBlob(null);
              }}
              onChange={(url, blob) => {
                setAttachmentFile(url);
                setBlob(blob);
              }}
              onAction={() => {
                setSelectedTemplate(null);
              }}
            />
          </span>
        </Stack>
        <Stack>
          <Typography variant='body2medium' component={"p"} color={"text.primary"} sx={{ pb: 1 }}>
            Select A Message From Your Library
          </Typography>

          <Stack className='pdad-scroll-bar' sx={{ height: "calc(100vh - 600px)" }}>
            {isLoading ? (
              <VoiceTemplateSkeleton />
            ) : (
              <Grid container gap={1}>
                {data.length === 0 && (
                  <Stack p={2} mt={2} sx={{ textAlign: "center", width: "100%" }}>
                    <Typography variant={"chipLight"} color={"text.secondary"}>
                      No voicemail found!
                    </Typography>
                  </Stack>
                )}
                {data?.map((item, index) => (
                  <Grid
                    key={index}
                    item
                    xs={12}
                    borderRadius={2}
                    p={1}
                    sx={{
                      cursor: "pointer",
                      border: selectedTemplate?.id === item.id ? "1px solid #006df54f" : "1px solid #E0E0E0",
                      bgcolor: selectedTemplate?.id === item.id ? "#006df526" : "#e0e0e017",
                    }}
                    onClick={() => handleSelectTemplate(item)}
                  >
                    <Stack gap={1}>
                      <Stack direction={"row"} alignItems={"flex-start"} justifyContent={"space-between"}>
                        <Stack gap={1}>
                          <Typography variant={"body2"} color={"text.primary"}>
                            {item.title}
                          </Typography>
                          <Typography
                            variant={"chipLight"}
                            fontWeight={400}
                            color={"text.secondary"}
                            sx={{
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 1,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "330px",
                            }}
                          >
                            {item?.description}
                          </Typography>
                        </Stack>
                      </Stack>
                      <AudioPlayer sx={{ height: 35 }} src={item.url} />
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            )}
          </Stack>
        </Stack>
      </div>

      {/* Footer */}
      <StyledFooter direction='row' justifyContent='space-between' alignItems='center'>
        <Typography
          onClick={() => {
            setSending(false);
            dispatch(handleSendOption({ option: SEND_OPTIONS.default }));
          }}
          sx={{ cursor: "pointer" }}
          variant='buttonMedium'
          color='text.secondary'
        >
          Cancel
        </Typography>

        <Button
          onClick={hanldeOnSend}
          sx={{ width: 120, backgroundColor: "secondary.main", ":hover": { backgroundColor: "secondary.main" } }}
          variant='contained'
        >
          {sending && (
            <CustomCircleLoader fill='#fff' size='extra-small' style={{ display: "flex", marginRight: "5px" }} />
          )}
          {sending ? "Sending..." : "Send"}
        </Button>
      </StyledFooter>
    </div>
  );
};

export default SendVoice;
