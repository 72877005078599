import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, FormControl, FormControlLabel, Stack, Typography } from "@mui/material";
import CustomRadioGroup from "../../../common/Radio/CustomRadioGroup";
import CustomRadio from "../../../common/Radio/CustomRadio";
import VoiceManager from "../../../common/VoiceManager/VoiceManager";
import { selectTriggerSetting } from "../../../state/features/listSetting/listSettingSelector";
import {
  updateTriggerSetting,
  uploadVoiceFile,
} from "../../../state/features/listSetting/triggerSetting/triggerSettingSlice";
import { ActionForIncomingCallOptions, Texts } from "../../../helpers/constant/Constants";
import CustomPhoneInput from "../../../common/Input/CustomPhoneInput";

const ActionForIncomingCall = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const {
    settings: { incoming_call_action, incoming_call_forward_number, incoming_call_voice_url },
  } = useSelector(selectTriggerSetting);
  const [forwardNumber, setForwardNumber] = useState(incoming_call_forward_number);

  const onChangeForwardNumber = (value) => {
    setForwardNumber(value);
    if (value.length < 11) {
      setError(true);
      return;
    }

    changeSetting({ incoming_call_forward_number: "+" + value });
    setError(false);
  };

  const changeSetting = (objData) => {
    dispatch(updateTriggerSetting(objData));
  };

  return (
    <Box px={4} py={2}>
      <Stack direction={"column"}>
        <Typography variant='body2medium' color={"text.primary"}>
          {Texts.actionForIncomingCall}
        </Typography>
        <Typography color='text.secondary' variant='chipLight'>
          {Texts.actionForIncomingCallHints}
        </Typography>
      </Stack>

      <Box p={2}>
        <FormControl>
          <CustomRadioGroup
            value={incoming_call_action}
            onChange={(e) => changeSetting({ incoming_call_action: e.target.value })}
          >
            {ActionForIncomingCallOptions.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<CustomRadio color='secondary' />}
                label={
                  <Typography variant={"body2"} color={"text.primary"}>
                    {option.label}
                  </Typography>
                }
              />
            ))}
          </CustomRadioGroup>
        </FormControl>
      </Box>
      <Box>
        {incoming_call_action === ActionForIncomingCallOptions[0].value && (
          <Stack direction={"column"} spacing={1} pr={8}>
            <Typography variant={"body2medium"} color={"text.primary"}>
              Forward to
            </Typography>
            <CustomPhoneInput
              placeholder='Please input forward number'
              value={forwardNumber}
              onChange={onChangeForwardNumber}
            />
            {error && (
              <Typography variant='body2medium' color='error.main'>
                Invalid US phone number
              </Typography>
            )}
          </Stack>
        )}

        {incoming_call_action === ActionForIncomingCallOptions[1].value && (
          <VoiceManager
            src={incoming_call_voice_url}
            onRemove={() => changeSetting({ incoming_call_voice_url: "" })}
            onChange={(url, file) => dispatch(uploadVoiceFile({ file, url, key: "incoming_call_voice_url" }))}
          />
        )}
      </Box>
    </Box>
  );
};
export default ActionForIncomingCall;
