import { useEffect } from "react";
import { Box, Stack } from "@mui/material";
import CoreButton from "../../../common/Button/CoreButton";
import { useDispatch, useSelector } from "react-redux";
import {
  addCallScriptForDefault,
  getGeneralScripts,
  handleSelectDefaultSettingForList,
  toggleGeneralScriptPreview,
} from "../../../state/features/listDefaultSetting/defaultCallSetting/defaultCallSettingSlice";
import GeneralScriptList from "../../GeneralScript/GeneralScriptList";
import { selectDefaultCallSetting } from "../../../state/features/listDefaultSetting/defaultListSettingSelector";

const GeneralScriptIndex = ({ handleOnScroll, refreshCallScript }) => {
  const dispatch = useDispatch();
  const {
    generalScripts: {
      data: scriptList,
      isLoading: isGeneralScriptLoading,
      perPage,
      page,
      search,
      selectedScriptsIds: selectedValues,
    },
    callScripts: { isAdding: isCallScriptsAdding, scriptsIds },
  } = useSelector(selectDefaultCallSetting);

  useEffect(() => {
    if (scriptList.length === 0) {
      /* api call */
      dispatch(
        getGeneralScripts({
          limit: perPage,
          page: page,
          search: search,
        })
      );
    }
  }, []);

  const handleSave = () => {
    dispatch(
      addCallScriptForDefault({
        payload: selectedValues,
        callback: () => {
          refreshCallScript();
          dispatch(toggleGeneralScriptPreview(false));
          dispatch(handleSelectDefaultSettingForList([]));
        },
      })
    );
  };

  // const filteredScript = (array1, array2) => {
  //   const array2Ids = array2.map((obj) => obj.id);
  //   return array1.filter((obj) => !array2Ids.includes(obj.id));
  // };

  return (
    <Stack direction={"column"} justifyContent={"space-between"} px={4} py={2} height={"100%"}>
      {/* <div onClick={() => dispatch(toggleGeneralScriptPreview(false))}>back to setting</div> */}

      <GeneralScriptList
        scripts={scriptList}
        scriptsIds={scriptsIds}
        // selectedValues={[selectedValues, setSelectedValues]}
        defaultSetting={true}
        isLoading={isGeneralScriptLoading}
        isAdding={isCallScriptsAdding}
        handleOnScroll={handleOnScroll}
      />

      <Box>
        <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} spacing={2}>
          <CoreButton
            variant='outlined'
            size='large'
            color={"error"}
            // startIcon={<SettingsIcon />}
            sx={{ minWidth: "max-content" }}
            onClick={() => {
              dispatch(toggleGeneralScriptPreview(false));
              dispatch(handleSelectDefaultSettingForList([]));
            }}
            disabled={isCallScriptsAdding}
          >
            Back Button
          </CoreButton>
          <CoreButton
            variant='contained'
            size='large'
            color={"secondary"}
            // startIcon={<Add />}
            sx={{ minWidth: "max-content" }}
            onClick={handleSave}
            disabled={isCallScriptsAdding || scriptList.length === 0 || selectedValues.length === 0}
          >
            {isCallScriptsAdding ? "adding" : "Add"}
          </CoreButton>
        </Stack>
      </Box>
    </Stack>
  );
};
export default GeneralScriptIndex;
