export const isValidSmsAttachment = (type) => {
  return (
    type.match(/jpg/g) ||
    type.match(/jpeg/g) ||
    type.match(/png/g) ||
    type.match(/svg/g) ||
    type.match(/tiff/g) ||
    type.match(/pdf/g)
  );
};
