import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { Box, Checkbox, FormControlLabel, FormGroup, Stack, styled, Typography } from "@mui/material";
import SingleContact from "./SingleContact";
import CoreButton from "../../../common/Button/CoreButton";
import GlobalEmptyPage from "../../../common/GlobalEmptyPage/GlobalEmptyPage";
import { LightCoreButton } from "../../../common/Button/LightCoreButton";
import AddToContactListSkeleton from "../../../common/LoadingView/AddToContactListSkeleton";
import LoadingButton from "../../../common/Button/LoadingButton";
import { Buttons } from "../../../helpers/constant/Constants";
import {
  addMainContactOnDialerList,
  addMainContactOnDialerListReset,
  deselectAllContact,
  getContacts,
  getMoreContacts,
  resetContactSlice,
  selectAllContact,
  toggleSelectSingleContact,
} from "../../../state/features/contacts/contactsSlice";
import { selectContactsState } from "../../../state/features/contacts/contactsSelector";
import { selectSelectedDialerList } from "../../../state/features/dialerList/dialerListSelector";

const StyledFormControlLabel = styled(FormControlLabel)(({ theme, checked }) => ({
  margin: 0,
  justifyContent: "space-between",
  padding: theme.spacing(1),
  background: checked ? theme.palette.other.dividerFillColor : "transparent",
}));

const ContactList = ({ onClose }) => {
  const dispatch = useDispatch();
  const {
    data: contactList,
    isLoading,
    size,
    hasMore,
    currentPage,
    selectedContactIds = [],
    filters,
    isAdding,
    isAddSuccess,
  } = useSelector(selectContactsState);
  const { searchText } = filters || {};
  const selectedDialerList = useSelector(selectSelectedDialerList);
  const { id } = selectedDialerList || {};

  const [isReadyToShow, setReadyToShow] = useState(false);

  useEffect(() => {
    dispatch(getContacts({ page: 1, size: size, search: searchText, dialer: true }));
    setReadyToShow(true);
  }, [searchText]);

  useEffect(() => {
    return () => dispatch(resetContactSlice());
  }, []);

  useEffect(() => {
    if (isAddSuccess) {
      dispatch(addMainContactOnDialerListReset());
      onClose();
    }
  }, [isAddSuccess]);

  const fetchMore = () => {
    dispatch(getMoreContacts({ page: currentPage + 1, size: size, search: searchText, dialer: true }));
  };

  // ## handle select and deselect all
  const handleSelectAllClick = () => {
    dispatch(selectAllContact());
  };
  const handleDeselectAllClick = () => {
    dispatch(deselectAllContact());
  };

  // ## handle toggle columns select single
  const handleToggleSelect = (id) => {
    dispatch(toggleSelectSingleContact(id));
  };

  // ## handle add contact to dialer list
  const handleAddContactToDialerList = () => {
    dispatch(
      addMainContactOnDialerList({ listId: id, contactIds: JSON.stringify(selectedContactIds), searchKey: searchText })
    );
  };

  // decide what to render
  let content = null;
  if (isLoading || !isReadyToShow) {
    content = <AddToContactListSkeleton count={10} />;
  } else if (!isLoading && contactList?.length === 0) {
    content = (
      <GlobalEmptyPage title={"Search results not found"} description={"You can try searching with right keywords"} />
    );
  } else if (!isLoading && contactList?.length >= 0) {
    content = (
      <InfiniteScroll
        dataLength={contactList.length}
        next={fetchMore}
        hasMore={hasMore}
        loader={<AddToContactListSkeleton count={3} />}
        scrollableTarget='contactListScrollBar'
      >
        <FormGroup sx={{ gap: "8px" }}>
          {contactList.map((contact) => {
            const { id } = contact || {};

            const isSelected = selectedContactIds.indexOf(id) !== -1;
            return (
              <StyledFormControlLabel
                key={contact.id}
                checked={isSelected}
                control={<Checkbox color={"secondary"} checked={isSelected} onChange={() => handleToggleSelect(id)} />}
                label={<SingleContact contact={contact} />}
                labelPlacement={"start"}
              />
            );
          })}
        </FormGroup>
      </InfiniteScroll>
    );
  }

  const totalContactList = contactList.length;
  const totalSelectedContact = selectedContactIds.length;
  // const isAllSelected = totalContactList > 0 && totalSelectedContact === totalContactList;

  return (
    <Box px={3}>
      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ mb: 2 }}>
        <Typography variant={"body2medium"} color={"text.secondary"}>
          {totalContactList} contacts found
        </Typography>
        <Stack direction={"row"} spacing={1}>
          <LightCoreButton variant={"text"} color={"secondary"} size={"small"} onClick={handleSelectAllClick}>
            Select All
          </LightCoreButton>
          {totalSelectedContact > 0 && (
            <CoreButton variant={"text"} color={"inherit"} size={"small"} onClick={handleDeselectAllClick}>
              Clear
            </CoreButton>
          )}
        </Stack>
      </Stack>

      <Box sx={{ height: "calc(100vh - 248px)" }} className={"pdad-scroll-bar"} id={"contactListScrollBar"}>
        {content}
      </Box>

      <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mt: 1.5 }}>
        <CoreButton type={"button"} size={"large"} color={"inherit"} onClick={onClose}>
          Cancel
        </CoreButton>

        <div>
          <Typography variant={"body2medium"} color={"text.secondary"} sx={{ mr: 2 }}>
            {totalSelectedContact} contacts selected
          </Typography>
          <LoadingButton
            isLoading={isAdding}
            type={"submit"}
            color={"secondary"}
            variant={"contained"}
            startIcon={<GroupAddIcon />}
            disabled={totalSelectedContact === 0}
            onClick={handleAddContactToDialerList}
          >
            {Buttons.addToTheList}
          </LoadingButton>
        </div>
      </Stack>
    </Box>
  );
};
export default ContactList;
