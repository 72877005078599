import React, { forwardRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
// import BuilderService from "../../services/Builder.service";

const emailUploadFileApi = `${process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API}/${process.env.REACT_APP_API_VERSION}/calendar/upload-email-file`;

const CustomEditor = forwardRef(
  (
    {
      body,
      onChangeBody,
      simpleVersion = false,
      onBlur = () => { },
      placeholder = "Compose your mail message",
      ...rest
    },
    ref
  ) => {
    const handleUploadFile = (blobInfo, success, failure) => {
      console.log(success, failure);
      const formData = new FormData();
      formData.append("files", blobInfo.blob(), blobInfo.filename());
      // BuilderService.uploadFile(formData).then((res) => {
      //   if (res.success === true) {
      //     success(res.urls[0]);
      //   } else {
      //     failure("Unable to upload the file. Please try again later");
      //   }
      // });
    };

    const apiKeys = () => {

      // let agencyId = getAccountData('agencyId');
      // if (agencyId == 1 && process.env.REACT_APP_PRODUCTION_MODE && process.env.REACT_APP_PRODUCTION_MODE !== "true") {
      //   return "ok2pl6dwqcu3dz9bbq14a5nj2wl3eskhae5npcza4vp7yy19"; //shahin@orangetoolz.com
      // } else if (agencyId == 1 && getAccountData('userId') < 10000) {
      //   return "qi8op9298ay3enamb02a5l3547fw5v0ta0xcafoj3tfi8smg"; //info@quickconnect
      // } else if (agencyId == 1) {
      //   return "2rxgmv1zyxbpvgzr5n6m9cixelzls9wdckt1d3etfub3xmjm"; //mehedi@orangetoolz
      // } else if (agencyId == 53) {
      //   return "vg1npymq1chv67z1a10ove031pomuia8dis2xd016c4au9gc"; //dev.salespype
      // } else if (agencyId == 125) {
      //   return "bl5f40flknaw1bqbtz37clw421lj6oza0aa330fmzko60avh";  //salespype.gdev
      // } else if (agencyId > 50 && agencyId < 200) {
      //   return "mkobqgy2lg21yecbury3yaftxadek52xavr8k0frcr7j17pi";  //atish@orangetoolz.com
      // }
      // return "ok2pl6dwqcu3dz9bbq14a5nj2wl3eskhae5npcza4vp7yy19";
      return 'f2zuqw07g06pnduwafiv7wehs2nc44j426n4nq0rjdhs8w1x'
    }

    return (
      <Editor
        ref={ref}
        apiKey={apiKeys()}
        // onInit={(evt, editor) => editorRef.current = editor}
        value={body}
        cloudChannel='5-dev'
        textareaName='email_reply'
        init={{
          height: 300,
          auto_focus: true,
          browser_spellcheck: true,
          menubar: !simpleVersion && "favs file edit view insert format tools table help",
          menu: {
            favs: {
              title: "My Favorites",
              items: "code visualaid | searchreplace | emoticons",
            },
          },
          plugins: [
            "advlist autolink lists link image imagetools hr charmap print preview anchor pagebreak",
            "searchreplace visualblocks visualchars code fullscreen media nonbreaking",
            "insertdatetime media table contextmenu code help wordcount template",
          ],
          convert_urls: false,
          toolbar:
            "insertfile undo redo | styleselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | link image | bullist numlist outdent indent | removeformat | preview media fullpage",
          imagetools_toolbar: "rotateleft rotateright | flipv fliph | editimage imageoptions",
          imagetools_proxy: emailUploadFileApi,
          images_upload_handler: handleUploadFile,
          automatic_uploads: true,
          file_picker_types: "file image media",
          target_list: [
            { title: "Same page", value: "_self" },
            { title: "New page", value: "_blank" },
          ],
          content_style: "p { margin: 0px; }",
          placeholder: placeholder,
        }}
        onEditorChange={onChangeBody}
        onBlur={(e) => onBlur(e)}
        id='email-setting-body-input-field'
        {...rest}
      />
    );
  }
);

CustomEditor.displayName = "CustomEditor";

export default CustomEditor;
