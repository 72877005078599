import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  contactId: null,
  contactDetails: null,
  detailsError: "",

  moreInfo: null,
  isLoadingMore: false,
  moreInfoError: "",

  /* for conversation part */
  isShowDetails: false,
  isShowCommunication: false,
  isShowScript: false,
  conversationType: 7, //script
  conversationTab: 0,
  isHideAction: false,
  isShowAddNote: false,
  isShowSmsSend: false,
  isShowEmailSend: false,
  isShowVoiceSend: false,
  isShowAddCampaign: false,
  isShowAddTag: false,
  isShowAddAnotherList: false,

  /* for contact script */
  isGettingScript: false,
  script: null,
  gettingScriptError: "",

  /* for conversation data */
  isGettingConversation: false,
  conversation: [],
  gettingConversationError: "",
  conversationPage: 1,
  isGettingConversationMore: false,
  needGettingMoreConversation: false,
};

const connectedSlice = createSlice({
  name: "connected",
  initialState: initialState,
  reducers: {
    storeContact: (state, action) => {
      state.contactId = action.payload.id;
      state.contactDetails = action.payload.contact;
    },

    getContactDetails: (state) => {
      state.isLoading = true;
    },
    getContactDetailsSuccess: (state, action) => {
      state.contactDetails = action.payload;
      state.isLoading = false;
      state.detailsError = "";
    },
    getContactDetailsFailed: (state, action) => {
      state.contactDetails = null;
      state.isLoading = false;
      state.detailsError = action.payload;
    },

    getMoreDetails: (state) => {
      state.isLoadingMore = true;
    },
    getMoreDetailsSuccess: (state, action) => {
      state.moreInfo = action.payload;
      state.isLoadingMore = false;
      state.moreInfoError = "";
    },
    getMoreDetailsFailed: (state, action) => {
      state.moreInfo = null;
      state.isLoadingMore = false;
      state.moreInfoError = action.payload;
    },

    /* for conversation part flag change */
    toggleShowScript: (state, action) => {
      state.isShowScript = action.payload;
    },
    updateConversationType: (state, action) => {
      if (action.payload === 7) {
        state.conversationTab = state.conversationType;
      }
      state.conversationType = action.payload;
    },
    toggleHideAction: (state, action) => {
      state.isHideAction = action.payload;
    },
    toggleShowAddNote: (state, action) => {
      state.isShowAddNote = action.payload;
      state.isShowSmsSend = false;
      state.isShowEmailSend = false;
      state.isShowVoiceSend = false;
      state.isShowAddCampaign = false;
      state.isShowAddTag = false;
      state.isShowAddAnotherList = false;
    },
    toggleShowSmsSend: (state, action) => {
      state.isShowAddNote = false;
      state.isShowSmsSend = action.payload;
      state.isShowEmailSend = false;
      state.isShowVoiceSend = false;
      state.isShowAddCampaign = false;
      state.isShowAddTag = false;
      state.isShowAddAnotherList = false;
      state.isHideAction = action.payload;
      state.isShowTemplate = false;
    },
    toggleShowEmailSend: (state, action) => {
      state.isShowAddNote = false;
      state.isShowSmsSend = false;
      state.isShowEmailSend = action.payload;
      state.isShowVoiceSend = false;
      state.isShowAddCampaign = false;
      state.isShowAddTag = false;
      state.isShowAddAnotherList = false;
      state.isHideAction = action.payload;
      state.isShowTemplate = false;
    },
    toggleShowVoiceSend: (state, action) => {
      state.isShowAddNote = false;
      state.isShowSmsSend = false;
      state.isShowEmailSend = false;
      state.isShowVoiceSend = action.payload;
      state.isShowAddCampaign = false;
      state.isShowAddTag = false;
      state.isShowAddAnotherList = false;
      state.isHideAction = action.payload;
      state.isShowTemplate = false;
    },
    toggleShowAddCampaign: (state, action) => {
      state.isShowAddNote = false;
      state.isShowSmsSend = false;
      state.isShowEmailSend = false;
      state.isShowVoiceSend = false;
      state.isShowAddCampaign = action.payload;
      state.isShowAddTag = false;
      state.isShowAddAnotherList = false;
      state.isHideAction = action.payload;
      state.isShowTemplate = false;
    },
    toggleShowAddTag: (state, action) => {
      state.isShowAddNote = false;
      state.isShowSmsSend = false;
      state.isShowEmailSend = false;
      state.isShowVoiceSend = false;
      state.isShowAddCampaign = false;
      state.isShowAddTag = action.payload;
      state.isShowAddAnotherList = false;
    },
    toggleShowAddAnoterhList: (state, action) => {
      state.isShowAddNote = false;
      state.isShowSmsSend = false;
      state.isShowEmailSend = false;
      state.isShowVoiceSend = false;
      state.isShowAddCampaign = false;
      state.isShowAddTag = false;
      state.isShowAddAnotherList = action.payload;
      state.isHideAction = action.payload;
      state.isShowTemplate = false;
    },
    resetStatesConnected: (state) => {
      for (const property in initialState) {
        state[property] = initialState[property];
      }
    },

    /* for contact script */
    getContactScript: (state) => {
      state.isGettingScript = true;
    },
    getContactScriptSuccess: (state, action) => {
      state.isGettingScript = false;
      state.script = action.payload;
      state.gettingScriptError = "";
    },
    getContactScriptFailed: (state, action) => {
      state.isGettingScript = false;
      state.script = null;
      state.gettingScriptError = action.payload;
    },

    /* for conversation data */
    getConversation: (state) => {
      state.isGettingConversation = true;
      state.needGettingMoreConversation = false;
    },
    getConversationSuccess: (state, action) => {
      state.isGettingConversation = false;
      state.conversation = action.payload;
      state.gettingConversationError = "";
      state.conversationPage = state.conversationPage + 1;
    },
    getConversationFailed: (state, action) => {
      state.isGettingConversation = false;
      state.conversation = null;
      state.gettingConversationError = action.payload;
    },
    getConversationMore: (state, action) => {
      state.isGettingConversationMore = action.payload;
      state.needGettingMoreConversation = false;
    },
    toogleGettingMoreConversation: (state, action) => {
      state.needGettingMoreConversation = action.payload;
    },
  },
});

export const {
  storeContact,

  getContactDetails,
  getContactDetailsSuccess,
  getContactDetailsFailed,

  getMoreDetails,
  getMoreDetailsSuccess,
  getMoreDetailsFailed,

  toggleShowScript,
  updateConversationType,
  toggleHideAction,
  toggleShowAddNote,
  toggleShowSmsSend,
  toggleShowEmailSend,
  toggleShowVoiceSend,
  toggleShowAddCampaign,
  toggleShowAddTag,
  toggleShowAddAnoterhList,
  resetStatesConnected,

  getContactScript,
  getContactScriptSuccess,
  getContactScriptFailed,

  getConversation,
  getConversationSuccess,
  getConversationFailed,
  getConversationMore,
  toogleGettingMoreConversation,
} = connectedSlice.actions;

export default connectedSlice.reducer;
