import { Button, styled, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import CountDown from "../../../common/Countdown/CountDown";
import {
  DIALER_STEP,
  handleInitStep,
  handleNextCall,
  restoreConnectedData,
  restoreContactData,
} from "../../../state/features/dialer/dialerSlice";
import Styles from "./waitingScreen.module.scss";

const CustomButton = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.main,
  borderColor: theme.palette.secondary.main,
  "&:hover": {
    borderColor: theme.palette.secondary.main,
  },
}));

const WaitingScreen = () => {
  const dispatch = useDispatch();

  const goToNextCall = () => {
    // dispatch(storeBasicInit({ time: 0 }));
    dispatch(restoreContactData());
    dispatch(restoreConnectedData(true));
    dispatch(handleNextCall(true));
    dispatch(handleInitStep(DIALER_STEP.SHOW_CONNECTING_SCREEN));
  };

  return (
    <div className={Styles.wrapper}>
      {/* {setting?.moveToNextCall?.type && setting?.moveToNextCall?.type == 1 && (
        <>
          <Typography variant='body1'>Dialing starts in...</Typography>
          <div className={Styles.countWrapper}>
            <div className={Styles.count}>
              <Typography variant='h1' color='secondary.main'>
                <CountDown
                  startValue={setting?.moveToNextCall?.value}
                  endValue={0}
                  increment={-1}
                  callback={goToNextCall}
                />
              </Typography>
            </div>
          </div>
        </>
      )} */}
      <Typography variant='body1'>Dialing starts in...</Typography>
      <div className={Styles.countWrapper}>
        <div className={Styles.count}>
          <Typography variant='h1' color='secondary.main'>
            <CountDown startValue={3} endValue={0} increment={-1} callback={goToNextCall} />
          </Typography>
        </div>
      </div>

      <CustomButton onClick={goToNextCall} variant='outlined' size='medium'>
        Skip to next call
      </CustomButton>
    </div>
  );
};

export default WaitingScreen;
