import { Box, Skeleton, Stack } from "@mui/material";
import React from "react";

const TriggerSettingSkeleton = () => {
  return (
    <Box>
      <Box px={4} py={1} pt={2}>
        <Skeleton width={100} />
        <Skeleton width={200} />
        <Box my={2}>
          <Stack direction='row' spacing={1} my={1}>
            <Skeleton variant='circular' height={20} width={20} />
            <Skeleton height={20} width={100} />
          </Stack>
          <Stack direction='row' spacing={1} my={1}>
            <Skeleton variant='circular' height={20} width={20} />
            <Skeleton height={20} width={100} />
          </Stack>
          <Stack direction='row' spacing={1} my={1}>
            <Skeleton variant='circular' height={20} width={20} />
            <Skeleton height={20} width={100} />
          </Stack>
        </Box>
      </Box>
      <Box px={4} py={1} pt={2}>
        <Skeleton width={100} />
        <Skeleton width={200} />
        <Box my={2}>
          <Skeleton height={40} />
        </Box>
      </Box>
      <Box px={4} py={1}>
        <Skeleton width={100} />
        <Skeleton width={200} />
        <Box my={2}>
          <Stack direction='row' spacing={1} my={1}>
            <Skeleton variant='circular' height={20} width={20} />
            <Skeleton height={20} width={100} />
          </Stack>
          <Stack direction='row' spacing={1} my={1}>
            <Skeleton variant='circular' height={20} width={20} />
            <Skeleton height={20} width={100} />
          </Stack>
          <Stack direction='row' spacing={1} my={1}>
            <Skeleton variant='circular' height={20} width={20} />
            <Skeleton height={20} width={100} />
          </Stack>
        </Box>
      </Box>
      <Box px={4} py={1} pt={2}>
        <Skeleton width={100} />
        <Skeleton width={200} />
        <Box my={2}>
          <Skeleton height={40} />
        </Box>
      </Box>
      <Box px={4} py={1} pt={2}>
        <Skeleton width={100} />
        <Skeleton width={200} />
        <Box my={2}>
          <Skeleton height={40} />
        </Box>
      </Box>
      <Box px={4} py={1} pt={2}>
        <Skeleton width={100} />
        <Skeleton width={200} />
        <Box my={2}>
          <Skeleton height={40} />
        </Box>
      </Box>
    </Box>
  );
};

export default TriggerSettingSkeleton;
