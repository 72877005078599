import { Box, Button, Checkbox, FormControlLabel, Stack, styled, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import useBoolean from "../../hooks/useBoolean";
import ConntectingService from "../../services/call/Conntecting.service";
import { selectDialerState } from "../../state/features/dialer/dialerSelector";

const StyledCheckBox = styled(FormControlLabel)(({ theme }) => ({
  color: theme.palette.text.primary,
  "& span:last-child": {
    lineHeight: "20px",
  },
}));


const StyledContinue = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  "&:hover": {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const StyledFooter = styled(Stack)(({ theme }) => ({
  padding: "20px",
  borderTop: `1px solid ${theme.palette.other.outlinedBorderDarker}`,
}));

const SESSION_TYPE_REGULAR = 0;
const SESSION_TYPE_DISCONNECTED = 1;

const TermAndCondition = ({ onConfirm, onClose }) => {
  const { value: isCheck, setValue: setIsCheck } = useBoolean(false);
  const { value: disconnect, setValue: setDisconnect } = useBoolean(false);
  const {
    init: { sessionId },
  } = useSelector(selectDialerState);

  const handleClickConfirm = () => {
    ConntectingService.logTermAndConditios({
      sessionId: sessionId,
      sessionType: disconnect ? SESSION_TYPE_DISCONNECTED : SESSION_TYPE_REGULAR,
    });
    onConfirm();
  };

  return (
    <>
      <Box sx={{ padding: "40px 24px" }}>
        <Typography variant='h5' sx={{ mb: "15px" }}>
          Power Dialer Terms & Conditions
        </Typography>

        <Typography variant='body2' color={"text.secondary"} sx={{ mb: "15px" }}>
          Thank you for choosing our Power Dialer service. By using our Power Dialer, you agree to the following terms
          and conditions:
        </Typography>

        <Typography variant='body2' color={"text.secondary"} sx={{ mb: "15px" }}>
          <b>Use of Service:</b> Our Power Dialer service is intended solely for your internal business purposes. You
          may not use our service for any illegal or unauthorized purpose.
        </Typography>

        <Typography variant='body2' color={"text.secondary"} sx={{ mb: "20px" }}>
          <b>Compliance with Laws:</b> You agree to comply with all applicable laws, rules, and regulations when using
          our Power Dialer service.
        </Typography>

        <Typography variant='body2' color={"text.secondary"} sx={{ mb: "20px" }}>
          <b>Privacy:</b> We value your privacy and will only use your personal information in accordance with our
          Privacy Policy.
        </Typography>

        <Typography variant='body2' color={"text.secondary"} sx={{ mb: "20px" }}>
          <b>Payment:</b> You agree to pay all fees associated with the use of our Power Dialer service. Fees may vary
          based on the plan you select.
        </Typography>

        <Typography variant='body2' color={"text.secondary"} sx={{ mb: "20px" }}>
          <b>Termination:</b> We reserve the right to terminate your use of our Power Dialer service at any time, with
          or without cause.
        </Typography>

        <Typography variant='body2' color={"text.secondary"} sx={{ mb: "20px" }}>
          By using our Power Dialer service, you agree to these terms and conditions. If you do not agree to these terms
          and conditions, please do not use our service.
        </Typography>

        <StyledCheckBox
          control={
            <Checkbox
              size='small'
              checked={isCheck}
              onChange={() => {
                if (!isCheck) {
                  setIsCheck(!isCheck);
                }
              }}
            />
          }
          label='I agree to the terms & conditions'
        />
        <div style={{ marginTop: "10px" }}>
          <StyledCheckBox
            control={
              <Checkbox
                size='small'
                checked={disconnect}
                onChange={() => {
                  // if (!disconnect) {
                  //   setDisconnect(!disconnect);
                  // }
                  setDisconnect(!disconnect);
                }}
              />
            }
            label={
              <Typography>
                Don’t call numbers you connected with in a previous dialing session.
                <Box sx={{ fontWeight: 700 }}>(Live Answer = Connected | Voicemail = Connected)</Box>
              </Typography>
            }
          />
        </div>
      </Box>

      {/* add footer here */}

      <StyledFooter direction='row' justifyContent='space-between' alignItems='center'>
        <Typography variant='button' color='text.secondary' sx={{ cursor: "pointer" }} onClick={() => onClose()}>
          Cancel
        </Typography>

        <StyledContinue disabled={!isCheck} variant='contained' onClick={() => handleClickConfirm()}>
          Continue
        </StyledContinue>
      </StyledFooter>
    </>
  );
};
export default TermAndCondition;
