import React from "react";
import { Stack, styled } from "@mui/material";
import CustomPopOver from "../Popover/CustomPopOver";
import TriggerPersonalizedAccordion from "../Accordion/TriggerPersonalizedAccordion";
import { useGetAllPersonalizedTagsQuery } from "../../state/features/common/personalizedTag/personalizedTagApiSlice";

const StyledListWrapper = styled(Stack)(() => ({
  gap: "8px",
  maxWidth: "200px",
  width: "100%",
  padding: "8px 0",
}));

const StyledList = styled("ul")(({ theme }) => ({
  "& li": {
    margin: theme.spacing(0.5),
    cursor: "pointer",
    padding: "4px 15px",
    fontFamily: "Poppins,Arial,sans-serif",
    fontSize: "13px",
    color: theme.palette.text.secondary,
    textTransform: "capitalize",
    transition: "all 0.3s ease",
    borderRadius: theme.spacing(0.5),
    "&:hover": {
      borderColor: "transparent",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
}));

const Personalized = ({ onClick = () => {}, data = null }) => {
  const { data: personalizedTags = [] } = useGetAllPersonalizedTagsQuery();

  const renderData = (data || personalizedTags).map((eachGroup, eachGroupIndex) => (
    <div key={eachGroupIndex}>
      <TriggerPersonalizedAccordion title={eachGroup.title}>
        <StyledList>
          {eachGroup.tags.map((eachData, eachDataIndex) => (
            <li key={eachGroupIndex + "_" + eachDataIndex} onClick={() => onClick(eachData.value)}>
              {eachData.title}
            </li>
          ))}
        </StyledList>
      </TriggerPersonalizedAccordion>
    </div>
  ));

  return (
    <div>
      <CustomPopOver buttonText={"Personalized"} color={"inherit"}>
        <StyledListWrapper>{renderData}</StyledListWrapper>
      </CustomPopOver>
    </div>
  );
};

export default Personalized;
