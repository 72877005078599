import React, { useEffect, useRef, useState } from "react";
import { Avatar, Box, keyframes, Stack, styled, Typography, useTheme } from "@mui/material";
import { PlaceholderText } from "../../../../helpers/constant/Constants";
import WarningIcon from "@mui/icons-material/Warning";
import CoreTextField from "../../../../common/TextField/CoreTextField";
import Personalized from "../../../../common/Personalized/Personalized";
import { insertPersonalizedTag } from "../../../../helpers/utils/insertPersonalizedTag";
import AttachedFile from "../../../../common/attachedFile/AttachedFile";
import CustomTooltip from "../../../../common/Tooltip/CustomTooltip";
import { countSms } from "../../../../helpers/utils/countSms";
import { checkHasUniCode } from "../../../../helpers/utils/checkHasUniCode";
import UnicodeWarning from "./UnicodeWarning";

const pulse = keyframes`
  0% { padding: 0; opacity: 1; }
  50% { padding: 2px; opacity: 0.5; }
  100% { padding: 5px; opacity: 0.3; }
`;
const StyledAvatar = styled(Avatar)(({ theme, pulse }) => ({
  width: 24,
  height: 24,
  backgroundColor: theme.palette.error.light,
  position: "relative",
  overflow: "unset",
  opacity: 1,
  "&:after": {
    content: '" "',
    position: "absolute",
    width: 24,
    height: 24,
    backgroundColor: theme.palette.error.light,
    animationName: pulse,
    animationDuration: "1s",
    animationIterationCount: "infinite",
    borderRadius: "50%",
    boxSizing: "content-box",
  },
}));

const SendSms = ({
  value,
  onChangeValue = () => {},
  attachment = "",
  onChangeAttachment = () => {},
  isLoadingAttachment = false,
}) => {
  const inputRef = useRef(null);
  const [smsObj, setSmsObj] = useState({ sms: 0, length: 0 });
  const [hasUnicode, setHasUnicode] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    setSmsObj(countSms(value));
    setHasUnicode(checkHasUniCode(value));
  }, []);

  const handleChangeSms = (smsValue) => {
    onChangeValue(smsValue);
    setSmsObj(countSms(smsValue));
    setHasUnicode(checkHasUniCode(smsValue));
  };

  return (
    <Box p={2}>
      <Stack spacing={0.5}>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
          <Typography variant={"body2medium"}>Message</Typography>
          <Typography variant={"body2medium"}>
            Total SMS: {smsObj.length}/{smsObj.sms}
          </Typography>
        </Stack>

        <CoreTextField
          inputRef={inputRef}
          multiline
          fullWidth
          minRows={3}
          maxRows={4}
          placeholder={PlaceholderText.writeHere}
          value={value}
          onChange={(e) => handleChangeSms(e.target.value)}
          sx={{ "& .MuiOutlinedInput-root": { padding: "12px 14px" } }}
        ></CoreTextField>
      </Stack>

      <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} pt={2}>
        <AttachedFile fileUrl={attachment} onChange={onChangeAttachment} isLoading={isLoadingAttachment} />
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          {hasUnicode && (
            <CustomTooltip title={<UnicodeWarning />} arrow placement='top' backgroundcolor={theme.palette.error.dark}>
              <StyledAvatar pulse={pulse}>
                <WarningIcon color='error' sx={{ fontSize: "16px", zIndex: 9 }} />
              </StyledAvatar>
            </CustomTooltip>
          )}

          <Personalized
            onClick={(personalizedTag) => insertPersonalizedTag(personalizedTag, inputRef, handleChangeSms)}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default SendSms;
