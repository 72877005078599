import Center from "./Center/Center";
import { useWindowSize } from "../../hooks/useWindowSize";
import Styles from "./callConnected.module.scss";

const CallConnected = () => {
  const { width, height } = useWindowSize();

  return (
    <div className={`${Styles.mainWrapper} ${width <= 1140 ? "" : ""}`}>
      <Center screen={{ width: width, height: height }} />
    </div>
  );
};

export default CallConnected;
