import { all, put } from "redux-saga/effects";
import { call, takeEvery, takeLatest } from "@redux-saga/core/effects";
import {
  addDialerFailed,
  addDialerSuccess,
  cloneDialerFailed,
  cloneDialerSuccess,
  deleteDialerFailed,
  deleteDialerSuccess,
  getDialerListFailed,
  getDialerListSuccess,
  getMoreDialerListFailed,
  getMoreDialerListSuccess,
  updateDialerColorFailed,
  updateDialerColorSuccess,
  updateDialerFailed,
  updateDialerSuccess,
} from "./dialerListSlice";
import DialerService from "../../../services/Dialer.service";
import { toggleListSettingModal } from "../listSetting/listSettingModal/listSettingModalSlice";
import { showErrorToaster, showSuccessToaster } from "../../../helpers/utils/toaster";

function* foldersWatcher() {
  yield takeEvery("dialerList/getDialerList", getDialerListSaga);
  yield takeEvery("dialerList/getMoreDialerList", getMoreDialerListSaga);
  yield takeEvery("dialerList/addDialer", addDialerSaga);
  yield takeEvery("dialerList/deleteDialer", deleteDialerSaga);
  yield takeEvery("dialerList/updateDialer", updateDialerSaga);
  yield takeEvery("dialerList/cloneDialer", cloneDialerSaga);
  yield takeLatest("dialerList/updateDialerColor", updateDialerColorSaga);
}

function* getDialerListSaga(action) {
  try {
    const response = yield call(DialerService.getDialerList, action.payload);

    if (response.success) {
      yield put(getDialerListSuccess(response.data));
    } else {
      yield put(getDialerListFailed(response.message));
    }
  } catch (err) {
    yield put(getDialerListFailed("Network or server error"));
  }
}

function* getMoreDialerListSaga(action) {
  try {
    const response = yield call(DialerService.getDialerList, action.payload);

    if (response.success) {
      yield put(getMoreDialerListSuccess(response.data));
    } else {
      yield put(getMoreDialerListFailed(response.message));
    }
  } catch (err) {
    yield put(getMoreDialerListFailed("Network or server error"));
  }
}

function* addDialerSaga(action) {
  try {
    const isOpenSettings = action.payload.isOpenSettings;
    delete action.payload.isOpenSettings;
    const response = yield call(DialerService.addDialer, action.payload);

    if (response.success) {
      yield put(addDialerSuccess(response.data));
      showSuccessToaster(response.message);
      if (isOpenSettings) yield put(toggleListSettingModal({ open: true, listId: response.data?.id }));
    } else {
      yield put(addDialerFailed(response.message));
      showErrorToaster(response.message);
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

function* deleteDialerSaga(action) {
  try {
    const response = yield call(DialerService.deleteDialer, action.payload);

    if (response.success) {
      yield put(deleteDialerSuccess(action.payload));
      showSuccessToaster(response.message);
    } else {
      yield put(deleteDialerFailed());
      showErrorToaster(response.message);
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

function* updateDialerSaga(action) {
  try {
    const isOpenSettings = action.payload.isOpenSettings;
    delete action.payload.isOpenSettings;

    const response = yield call(DialerService.updateDialer, action.payload);

    if (response.success) {
      yield put(updateDialerSuccess(response.data));
      showSuccessToaster(response.message);

      if (isOpenSettings) yield put(toggleListSettingModal({ open: true, listId: response.data?.id }));
    } else {
      yield put(updateDialerFailed(response.message));
      showErrorToaster(response.message);
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

function* cloneDialerSaga(action) {
  try {
    const response = yield call(DialerService.cloneDialer, { id: action.payload?.id });

    if (response.success) {
      const payload = {
        ...action.payload,
        id: response.data.id,
      };

      yield put(cloneDialerSuccess(payload));
      showSuccessToaster("Successfully, duplicated power dialer list");
    } else {
      yield put(cloneDialerFailed(response.message));
      showErrorToaster(response.message);
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

function* updateDialerColorSaga(action) {
  try {
    yield put(updateDialerColorSuccess(action.payload));
    const response = yield call(DialerService.updateDialerColor, action.payload);

    if (response.success) {
      showSuccessToaster(response.message);
    } else {
      yield put(updateDialerColorFailed(response.message));
      showErrorToaster(response.message);
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

export default function* dialerListSaga() {
  yield all([foldersWatcher()]);
}
