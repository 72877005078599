import React, { useEffect, useRef, useState } from "react";
import RecordRTC, { StereoAudioRecorder } from "recordrtc";
import { Avatar, Box, Divider, Stack, styled, Typography } from "@mui/material";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import StopIcon from "@mui/icons-material/Stop";
import UploadIcon from "@mui/icons-material/Upload";
import CoreButton from "../Button/CoreButton";
import { LightCoreButton } from "../Button/LightCoreButton";
import AudioPlayer from "./AudioPlayer";
import { MusicIcon, VoiceIcon } from "../icon/Icons";
import { Texts } from "../../helpers/constant/Constants";
import { startRecord } from "../../helpers/utils/voiceRecorder";
import { showErrorToaster } from "../../helpers/utils/toaster";
import secondToReadableTime from "../../helpers/utils/secondToReadableTime";
import RecordingIcon from "../Icons/RecordingIcon";

const AudioControlButton = styled(CoreButton)(({ size }) => ({
  "& .MuiButton-startIcon": size === "small" ? { margin: "0 auto" } : {},
}));

const VoiceManagerBox = styled(Box)(({ theme }) => ({
  borderRadius: "4px",
  border: `1px solid ${theme.palette.other.outlinedBorder}`,
}));

const StyledList = styled("ul")(({ theme }) => ({
  marginLeft: theme.spacing(3),
  padding: "16px 0",
  "& li": {
    listStyleType: "disc !important",
    color: theme.palette.text.secondary,
  },
}));

const MAX_FILE_SIZE = 3145728; //1024*1024*3 --- 3MB

const VoiceManagerVmail = ({
  src = "",
  onRemove = () => {},
  onChange = () => {},
  onAction = () => {},
  showHeader = true,
  size = "large",
  canEdit = true,
}) => {
  const rtcObj = useRef(null);
  const intervalRef = useRef(null);
  const fileInputRef = useRef(null);
  const [stream, setStream] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [updateMessage, setUpdateMessage] = useState(Texts.noVoiceMessage);
  const [recordingTime, setRecordingTime] = useState(0);

  useEffect(() => {
    () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    if (recordingTime > 179) {
      stopRecord();
    }
  }, [recordingTime]);

  const initVoiceRecord = () => {
    startRecord()
      .then((mediaStream) => {
        if (!isRecording) {
          setIsRecording(true);
          setUpdateMessage("Recording...");
          setStream(mediaStream);
          rtcObj.current = RecordRTC(mediaStream, {
            type: "audio/webm",
            mimeType: "audio/webm",
            recorderType: StereoAudioRecorder,
            audioBitsPerSecond: 128000,
          });
          rtcObj.current.startRecording();
          intervalRef.current = setInterval(() => {
            setRecordingTime((time) => time + 1);
          }, 1000);
        }
      })
      .catch((e) => {
        showErrorToaster(e.message);
      });
  };

  const stopRecord = (stop = false) => {
    if (!stop && !isRecording) {
      return false;
    }

    if (recordingTime <= 5) {
      showErrorToaster("Minimum 5 seconds required");
      return;
    }

    rtcObj.current.stopRecording(() => {
      //Generate the url for preview
      let urlCreator = window.URL || window.webkitURL;
      let audioFileUrl = urlCreator.createObjectURL(rtcObj.current.blob);
      stream.getTracks().forEach((track) => track.stop());

      //Generate the blob for upload
      let blob = rtcObj.current instanceof Blob ? rtcObj.current : rtcObj.current.getBlob();
      blob = new File([blob], "recorded_audio.webm", {
        type: "audio/webm",
      });

      setUpdateMessage("recorded_audio.webm");
      setIsRecording(false);
      clearInterval(intervalRef.current);
      setRecordingTime(0);
      onChange(audioFileUrl, blob);
      return false;
    });
  };

  const onClickUploadFile = (e) => {
    e.preventDefault();
    if (fileInputRef != null) {
      fileInputRef.current.click();
    }
  };

  const handleFileUpload = (e) => {
    if (isRecording) {
      return false;
    }

    const file = e.target.files[0];

    if (file.size > MAX_FILE_SIZE) {
      showErrorToaster("Maximum upload size : 3MB");
      return;
    }

    setUpdateMessage(file.name);
    onChange(URL.createObjectURL(file), file);
  };

  return (
    <VoiceManagerBox p={2}>
      {showHeader && (
        <>
          <Stack direction={"row"} spacing={1} alignItems={"center"} pb={2}>
            <Avatar variant={"rounded"} sx={{ bgcolor: "other.dividerFillColor" }}>
              {src ? <MusicIcon /> : <VoiceIcon />}
            </Avatar>
            <Stack justifyContent={"center"}>
              <Typography variant={"body2medium"} color={"text.primary"}>
                {" "}
                {Texts.selectVoiceMessage}
              </Typography>
              <Typography variant={"chipLight"} color={"text.secondary"}>
                {updateMessage}
              </Typography>
            </Stack>
          </Stack>
          <Divider sx={{ opacity: "0.5" }} />
        </>
      )}

      {!src && canEdit ? (
        <Box>
          <Stack direction={"row"} spacing={2} alignItems={"center"} pt={2} onClick={onAction}>
            <AudioControlButton
              color={"secondary"}
              fullWidth
              startIcon={isRecording ? <RecordingIcon /> : <KeyboardVoiceIcon />}
              onClick={initVoiceRecord}
              disabled={isRecording}
              size={size}
              sx={{ bgcolor: isRecording ? "action.disabledBackground" : "other.secondaryHover" }}
            >
              {size !== "small" && <>{isRecording ? "Recording..." : "Record"}</>}
            </AudioControlButton>

            <CoreButton
              color={"error"}
              fullWidth
              startIcon={<StopIcon />}
              disabled={!isRecording}
              onClick={stopRecord}
              size={size}
              sx={{
                bgcolor: isRecording ? "error.light" : "action.disabledBackground",
                "& .MuiButton-startIcon": {
                  margin: !isRecording && size == "small" && "0 auto",
                },
                "&.MuiButton-sizeSmall": { lineHeight: "18px" },
              }}
            >
              {size !== "small" && <>Stop</>}
              {isRecording && <Box ml={1}>({secondToReadableTime(recordingTime)})</Box>}
            </CoreButton>
            <AudioControlButton
              color={"secondary"}
              fullWidth
              startIcon={<UploadIcon />}
              onClick={onClickUploadFile}
              disabled={isRecording}
              size={size}
              sx={{ bgcolor: isRecording ? "action.disabledBackground" : "other.secondaryHover" }}
            >
              {size !== "small" && <>Upload</>}
            </AudioControlButton>
            <input
              type='file'
              ref={fileInputRef}
              style={{ display: "none" }}
              accept='audio/mp3'
              onChange={handleFileUpload}
            />
          </Stack>
          <StyledList>
            <li>
              <Typography variant={"chipLight"} color={"text.secondary"}>
                Min duration: 5 sec, Max duration: 3 min
              </Typography>
            </li>
            <li>
              <Typography variant={"chipLight"} color={"text.secondary"}>
                Supported files: wav, mp3, flac
              </Typography>
            </li>
            <li>
              <Typography variant={"chipLight"} color={"text.secondary"}>
                Max file size: 3MB
              </Typography>
            </li>
          </StyledList>
        </Box>
      ) : (
        <Box pt={2}>
          <AudioPlayer src={src} />
          {canEdit && (
            <Stack direction={"row"} justifyContent={"flex-end"} pt={1}>
              <LightCoreButton color={"error"} size={"small"} sx={{ width: "max-content" }} onClick={onRemove}>
                Remove
              </LightCoreButton>
            </Stack>
          )}
        </Box>
      )}
    </VoiceManagerBox>
  );
};

export default VoiceManagerVmail;
