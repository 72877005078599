export const toUTF16 = (codePoint) => {
  var TEN_BITS = parseInt("1111111111", 2);
  function u(codeUnit) {
    return "\\u" + codeUnit.toString(16).toUpperCase();
  }

  if (codePoint <= 0xffff) {
    return u(codePoint);
  }
  codePoint -= 0x10000;

  // Shift right to get to most significant 10 bits
  var leadingSurrogate = 0xd800 | (codePoint >> 10);

  // Mask to get least significant 10 bits
  var trailingSurrogate = 0xdc00 | (codePoint & TEN_BITS);

  return u(leadingSurrogate) + u(trailingSurrogate);
  // return u(leadingSurrogate);
};
