import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputAdornment } from "@mui/material";
import { Search } from "@mui/icons-material";
import CoreTextField from "../../../common/TextField/CoreTextField";
import { debounceHandler } from "../../../helpers/utils/debounceHandler";
import { addContactSearchText } from "../../../state/features/contacts/contactsSlice";
import { selectContactsFilters } from "../../../state/features/contacts/contactsSelector";

const AddContactSearch = () => {
  const dispatch = useDispatch();
  const { searchText } = useSelector(selectContactsFilters) || {};
  const [search, setSearch] = useState(searchText);

  const doSearch = (value) => {
    dispatch(addContactSearchText(value));
  };

  // debounce implement for search input change
  const handleSearch = React.useMemo(() => {
    return debounceHandler(doSearch, 500);
  }, []);

  return (
    <CoreTextField
      fullWidth
      size={"small"}
      type={"search"}
      color={"secondary"}
      InputProps={{
        startAdornment: (
          <InputAdornment sx={{ mr: 0 }} position='start'>
            <Search />
          </InputAdornment>
        ),
      }}
      placeholder='Search'
      value={search}
      onChange={(e) => {
        setSearch(e.target.value);
        handleSearch(e.target.value);
      }}
      sx={{ minWidth: "320px", "& .MuiInputBase-inputSizeSmall": { height: "36px !important" } }}
    />
  );
};

export default AddContactSearch;
