import { Box, Button, styled, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CountDown from "../../../common/Countdown/CountDown";
import CustomMenuPype from "../../../common/custom-menu/CustomMenuPype";
import secondToReadableTime from "../../../helpers/utils/secondToReadableTime";
import { selectDialerState } from "../../../state/features/dialer/dialerSelector";
import {
  callTimer,
  DIALER_STEP,
  handleCallStep,
  handleInitStep,
  handleNextCall,
  resetForEndSingleCall,
} from "../../../state/features/dialer/dialerSlice";
import DialerKeypad from "../../MakeCall/Keypad/DialerKeypad";
import {
  CallEnd,
  CallHold,
  CallMute,
  CallTransfer,
  Keypad,
  RecordIcon,
} from "../../screens/auto-dialer-ongoing/IconsOngoing";
import Styles from "./footer.module.scss";

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.other.outlinedBorder,
  color: theme.palette.text.secondary,
  padding: "8px 16px",
  borderRadius: "4px",
  width: "126px",
  [theme.breakpoints.down("lg")]: {
    width: "72px",
    height: "42px",
  },
  "&:hover": {
    backgroundColor: theme.palette.other.outlinedBorder,
    color: theme.palette.text.secondary,
  },
  "&:focus": {
    backgroundColor: theme.palette.other.outlinedBorder,
    color: theme.palette.text.secondary,
  },
  "& .MuiButton-startIcon": {
    [theme.breakpoints.down("lg")]: {
      margin: "0 !important",
    },
  },
}));

const StyledEndBtn = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  color: theme.palette.error.contrastText,
  padding: "8px 16px",
  borderRadius: "4px",
  width: "126px",
  "&:hover": {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  "& .MuiButton-startIcon": {
    [theme.breakpoints.down("lg")]: {
      margin: "0 !important",
    },
  },
  [theme.breakpoints.down("lg")]: {
    width: "72px",
    height: "42px",
  },
}));

const Footer = ({ handleEndSigleCall, handleEndPowerDialer, sendDigit, callState, handleMute, handleHold }) => {
  const dispatch = useDispatch();
  const keypadRefs = useRef(null);
  const {
    init: { step, callStep },
    settingOverview: { data },
    send: { isShowActivity, isShowVideoModal },
  } = useSelector(selectDialerState);

  const [skipNextCallTimer, setSkipNextCallTimer] = useState(false);

  useEffect(() => {
    if (callStep === DIALER_STEP.CALL_END) {
      setSkipNextCallTimer(false);
    }
  }, [callStep]);

  const handleSendDigit = (digit) => {
    sendDigit(digit);
    if (keypadRefs?.current?.closeFromOutside) {
      return;
      // keypadRefs.current.closeFromOutside();
    }
  };
  const handleClickEndPowerDialer = () => {
    handleEndPowerDialer();
  };
  const handleEndActiveCall = () => {
    dispatch(handleCallStep(DIALER_STEP.CALL_END));
    handleEndSigleCall();
  };
  const goToNextCall = () => {
    dispatch(handleNextCall(true));
    dispatch(handleInitStep(DIALER_STEP.SHOW_WAITING_FOR_NEXT));
    dispatch(resetForEndSingleCall());
  };

  const renderFooterCenter = () => {
    if (step === DIALER_STEP.SHOW_CONNECTED_SCREEN && callStep === DIALER_STEP.CONNECTED) {
      return (
        <div className={Styles.footerMiddle}>
          <StyledButton sx={{ display: "none" }} variant='contained' startIcon={<CallTransfer />} disableElevation>
            <span className={Styles.footerButtonText}>Transfer</span>
          </StyledButton>

          <StyledButton
            onClick={handleHold}
            sx={{ display: "none" }}
            // sx={
            //   callState.hold
            //     ? {
            //         backgroundColor: "secondary.main",
            //         color: "common.white",
            //         "&:hover": {
            //           backgroundColor: "secondary.main",
            //           color: "common.white",
            //         },
            //         "&:focus": {
            //           backgroundColor: "secondary.main",
            //           color: "common.white",
            //         },
            //       }
            //     : {}
            // }
            variant='contained'
            startIcon={<CallHold fill={callState.hold ? "#fff" : "#546376"} />}
            disableElevation
          >
            <span className={Styles.footerButtonText}>Hold</span>
          </StyledButton>

          <StyledButton
            onClick={handleMute}
            sx={
              callState.mute
                ? {
                    backgroundColor: "secondary.main",
                    color: "common.white",
                    "&:hover": {
                      backgroundColor: "secondary.main",
                      color: "common.white",
                    },
                    "&:focus": {
                      backgroundColor: "secondary.main",
                      color: "common.white",
                    },
                  }
                : {}
            }
            variant='contained'
            startIcon={<CallMute fill={callState.mute ? "#fff" : "#546376"} />}
            disableElevation
          >
            <span className={Styles.footerButtonText}>Mute</span>
          </StyledButton>

          <CustomMenuPype
            popup={{
              id: "pdadDialerInboxKeypad",
              wrapperClass: Styles.drop,
              placement: "center",
              renderCustomContainer: <DialerKeypad onClickDigit={(digit) => handleSendDigit(digit)} />,
              onClick: () => {},
              height: "333px",
              width: "346px",
              padding: "24px",
              listStyle: {
                justifyContent: "center",
              },
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
              transformOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              wrapperClassInner: Styles.innerWrap,
            }}
            label={{
              renderCustom: (
                <StyledButton variant='contained' startIcon={<Keypad />} disableElevation>
                  <span className={Styles.footerButtonText}>Keypad</span>
                </StyledButton>
              ),
            }}
            ref={keypadRefs}
          />
        </div>
      );
    }
  };
  const renderFooterRight = () => {
    if (step === DIALER_STEP.SHOW_CONNECTING_SCREEN) {
      if (callStep === DIALER_STEP.CONNECTED) {
        return (
          <StyledEndBtn onClick={handleEndActiveCall} variant='contained' startIcon={<CallEnd />} disableElevation>
            <span className={Styles.footerButtonText}>End Call</span>
          </StyledEndBtn>
        );
      }
      return (
        <StyledEndBtn onClick={handleClickEndPowerDialer} variant='contained' startIcon={<CallEnd />} disableElevation>
          <span className={Styles.footerButtonText}>End Power Dialer</span>
        </StyledEndBtn>
      );
    }
    if (step === DIALER_STEP.SHOW_CONNECTED_SCREEN) {
      if (callStep === DIALER_STEP.CONNECTED) {
        return (
          <StyledEndBtn onClick={handleEndActiveCall} variant='contained' startIcon={<CallEnd />} disableElevation>
            <span className={Styles.footerButtonText}>End Call</span>
          </StyledEndBtn>
        );
      }
      if (callStep === DIALER_STEP.CALL_END) {
        return (
          <>
            {!isShowActivity &&
              !isShowVideoModal &&
              data?.moveToNextCall?.type &&
              data?.moveToNextCall?.type == 1 &&
              !skipNextCallTimer && (
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "5px" }}>
                  <Typography variant='body2medium'>
                    <CountDown
                      startValue={data?.moveToNextCall?.value ? data?.moveToNextCall?.value : 30}
                      endValue={0}
                      increment={-1}
                      callback={() => goToNextCall()}
                      format={(value) => {
                        return secondToReadableTime(value);
                      }}
                    />
                    <Typography
                      sx={{
                        cursor: "pointer",
                        padding: "2px 5px",
                        background: "red",
                        borderRadius: "4px",
                        color: "#fff",
                      }}
                      variant='body2medium'
                      onClick={() => setSkipNextCallTimer(true)}
                    >
                      Stop
                    </Typography>
                  </Typography>
                </Box>
              )}

            <StyledButton onClick={goToNextCall} variant='contained' disableElevation>
              <span className={Styles.footerButtonText}>Go to next call</span>
            </StyledButton>
            <StyledEndBtn onClick={handleClickEndPowerDialer} variant='contained' disableElevation>
              <span className={Styles.footerButtonText}>End Power Dialer</span>
            </StyledEndBtn>
          </>
        );
      }
      return (
        <StyledEndBtn onClick={handleEndActiveCall} variant='contained' startIcon={<CallEnd />} disableElevation>
          <span className={Styles.footerButtonText}>End Call</span>
        </StyledEndBtn>
      );
    }
    if (step === DIALER_STEP.SHOW_WAITING_FOR_NEXT) {
      return (
        <StyledEndBtn onClick={handleEndPowerDialer} variant='contained' startIcon={<CallEnd />} disableElevation>
          <span className={Styles.footerButtonText}>End Power Dialer</span>
        </StyledEndBtn>
      );
    }
  };

  return (
    <div className={`${Styles.footerWrapper}`}>
      {/* footer left */}
      <div className={Styles.footerLeft}>
        {callStep == DIALER_STEP.CONNECTING && (
          <CountDown
            startValue={0}
            endValue={30}
            increment={1}
            callback={() => {
              console.log("done", callTimer["ringingTime"]);
              window.handleCallAfterRinging();
            }}
            callbackOnEveryUpdate={(value) => {
              callTimer["ringingTime"] = value;
            }}
            format={() => {
              return "";
            }}
          />
        )}
        {callStep == DIALER_STEP.CONNECTED && (
          <div className={`${Styles.footerLeftSingle} ${Styles.footerLeftDuration}`}>
            <Typography variant='body2' color={"text.secondary"}>
              Call Duration:
            </Typography>

            <Box sx={{ width: "55px" }}>
              <Typography variant='body2medium'>
                <CountDown
                  startValue={0}
                  endValue={10000}
                  increment={1}
                  callbackOnEveryUpdate={(value) => {
                    callTimer["callingTime"] = value;
                  }}
                  callback={() => console.log("done")}
                  format={(value) => {
                    return secondToReadableTime(value);
                  }}
                />
              </Typography>
            </Box>
          </div>
        )}
        {callStep == DIALER_STEP.CONNECTED && data && parseInt(data?.callRecording) == 1 && (
          <div className={`${Styles.footerLeftRecord}`}>
            <Box component='span' sx={{ display: "flex" }}>
              <RecordIcon />
            </Box>

            <Typography
              sx={{ fontSize: 13, fontWeight: 500, color: "error.main" }}
              className={Styles.footerLeftRecordText}
            >
              Recording
            </Typography>
          </div>
        )}
      </div>

      {/* footer Middle */}
      {renderFooterCenter()}

      {/* footer right */}
      <div className={Styles.footerRight}>{renderFooterRight()}</div>
    </div>
  );
};

export default Footer;
