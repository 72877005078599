import React, { useRef } from "react";
import { Box, Stack, Typography } from "@mui/material";
import CoreTextField from "../../../../common/TextField/CoreTextField";
import BasicEditor from "../../../../common/Editor/BasicEditor";
import Personalized from "../../../../common/Personalized/Personalized";
import { insertPersonalizedTag } from "../../../../helpers/utils/insertPersonalizedTag";
import AttachedFile from "../../../../common/attachedFile/AttachedFile";
import { ATTACHMENT_TYPE } from "../../../../helpers/constant/Constants";

const SendEmail = ({
  subject,
  body,
  onChangeSubject = () => {},
  onChangeBody = () => {},
  attachment = "",
  onChangeAttachment = () => {},
  isLoadingAttachment = false,
}) => {
  const subjectInputRef = useRef(null);
  const emailBodyRef = useRef(null);

  return (
    <Box p={2}>
      <Stack spacing={1} mb={2}>
        <Typography variant={"body2medium"}>Subject</Typography>
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
          <CoreTextField
            inputRef={subjectInputRef}
            fullWidth
            placeholder={"Type email subject"}
            value={subject}
            onChange={(e) => onChangeSubject(e.target.value)}
          />
          <Personalized
            onClick={(personalizedTag) => insertPersonalizedTag(personalizedTag, subjectInputRef, onChangeSubject)}
          />
        </Stack>
      </Stack>
      <Stack spacing={1}>
        <Typography variant={"body2medium"}>Email Body</Typography>
        <BasicEditor body={body} onChangeBody={onChangeBody} ref={emailBodyRef} />
      </Stack>
      <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} pt={2}>
        <AttachedFile
          fileUrl={attachment}
          onChange={onChangeAttachment}
          isLoading={isLoadingAttachment}
          type={ATTACHMENT_TYPE.EMAIL}
        />
        <Personalized
          onClick={(personalizedTag) => insertPersonalizedTag(personalizedTag, emailBodyRef, onChangeBody)}
        />
      </Stack>
    </Box>
  );
};

export default SendEmail;
