import React, { useContext, useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
// import { Add } from "@mui/icons-material";
import SettingsIcon from "@mui/icons-material/Settings";
// import AddOrEditDialerList from "../DialerList/AddOrEditDialerList";
// import BasicModalWithHeader from "../../common/Modal/BasicModalWithHeader";
import CoreButton from "../../common/Button/CoreButton";
import { Buttons, Texts } from "../../helpers/constant/Constants";
import useBoolean from "../../hooks/useBoolean";
import BasicDrawer from "../../common/Drawer/BasicDrawer";
import DefaultDialerListSettings from "../DialerListSettings/DefaultDialerListSettings";
import AddNewGeneralScriptAndDefaultScript from "../GeneralScript/AddNewGeneralScriptAndDefaultScript";
import { useLocation } from "react-router-dom";
import DialPhoneIcon from "../../common/Icons/DialPhoneIcon";
import { HistoryProvider } from "../../App";

const PowerDialerHeader = () => {
  const history = useContext(HistoryProvider);
  const location = useLocation();
  const params = new URLSearchParams(location?.search);
  const open = params.get("open");
  const [defaultSettingActiveTab, setDefaultSettingActiveTab] = useState(0);
  // const { value: isShowModal, setTrue: setShowModal, setFalse: setCloseModal } = useBoolean(false);
  const {
    value: isShowDefaultDrawer,
    setTrue: setShowDefaultDrawer,
    setFalse: setCloseDefaultDrawer,
  } = useBoolean(false);
  const {
    value: isShowCreateScriptModal,
    setTrue: setShowCreateScriptModal,
    setFalse: setCloseCreateScriptModal,
  } = useBoolean(false);

  useEffect(() => {
    if (open && open.includes("default-settings")) {
      if (open === "default-settings:call-settings") {
        setDefaultSettingActiveTab(0);
      } else if (open === "default-settings:scripts") {
        setDefaultSettingActiveTab(2);
      }
      setShowDefaultDrawer();
      removeQueryParam(["open"]);
    }
  }, []);

  const removeQueryParam = (paramsToRemove) => {
    const searchParams = new URLSearchParams(location.search);
    paramsToRemove.forEach((param) => searchParams.delete(param));
    const newSearch = searchParams.toString();
    history.replace({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} mb={2}>
      <Box>
        <Box display='flex' alignItems='center' gap={"16px"}>
          <Box width={"60px"} height={"60px"}>
            <img
              src={`${process.env.REACT_APP_CDN_LINK}assets/images/releases/importV2/1.0.0/call_disposition_icon.png`}
              alt='logo'
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </Box>
          <Box>
            <Typography variant={"h4"} color={"text.primary"}>
              {Texts.mainHeader}
            </Typography>
            <Typography variant={"body2"} color={"text.secondary"}>
              {Texts.subHeader}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Stack direction={"row"} alignItems={"center"} spacing={2}>
        <CoreButton
          variant='outlined'
          size='large'
          color={"secondary"}
          startIcon={<SettingsIcon />}
          onClick={setShowDefaultDrawer}
          sx={{ minWidth: "max-content" }}
        >
          {Buttons.defaultSettings}
        </CoreButton>
        <CoreButton
          variant='outlined'
          size='large'
          color={"secondary"}
          startIcon={<DialPhoneIcon />}
          onClick={() => {
            history.push({
              pathname: "/power-dialer/call-logs",
              state: { back: "/power-dialer" },
            });
          }}
          sx={{ minWidth: "max-content" }}
        >
          {Buttons.viewCallLogs}
        </CoreButton>
        {/*<CoreButton*/}
        {/*  variant='contained'*/}
        {/*  size='large'*/}
        {/*  color={"secondary"}*/}
        {/*  startIcon={<Add />}*/}
        {/*  onClick={setShowModal}*/}
        {/*  sx={{ minWidth: "max-content" }}*/}
        {/*>*/}
        {/*  {Buttons.addNewPowerList}*/}
        {/*</CoreButton>*/}
      </Stack>

      {/*<BasicModalWithHeader title={Texts.createDialerListTitle} open={isShowModal} onClose={setCloseModal}>*/}
      {/*  <AddOrEditDialerList onClose={setCloseModal} isEditMode={false} />*/}
      {/*</BasicModalWithHeader>*/}
      <BasicDrawer open={isShowDefaultDrawer} toggleDrawer={setCloseDefaultDrawer} title={Texts.defaultSettings}>
        <DefaultDialerListSettings
          defaultSettingActiveTab={defaultSettingActiveTab}
          openGeneralScript={setShowCreateScriptModal}
        />
      </BasicDrawer>
      <BasicDrawer open={isShowCreateScriptModal} toggleDrawer={setCloseCreateScriptModal} title={"Add New Script"}>
        <AddNewGeneralScriptAndDefaultScript onCancel={setCloseCreateScriptModal} />
      </BasicDrawer>
    </Stack>
  );
};
export default PowerDialerHeader;
