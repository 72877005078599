import { all, put } from "redux-saga/effects";
import { call, takeEvery, delay, select } from "@redux-saga/core/effects";
import { errorHandler } from "../../../../helpers/utils/errorHandler";
import {
  addContactNoteFailed,
  addContactNoteSuccess,
  getContactLastNoteFailed,
  getContactLastNoteSuccess,
} from "./contactNoteSlice";
import ContactNoteService from "../../../../services/call/ContactNote.service";
import { selectConnectedState } from "../connected/connectedSelector";
import { showGlobalNotification } from "../../../../helpers/utils/showGlobalNotification";

function* contactNoteWatcher() {
  yield takeEvery("contactNote/getContactLastNote", getContactLastNoteSaga);
  yield takeEvery("contactNote/addContactNote", addContactNoteSaga);
}

function* getContactLastNoteSaga() {
  try {
    yield delay(3000);
    const response = {
      responseCode: 200,
      message: "success",
      success: true,
      data: {
        id: 1,
        message: "test @[Technical  Test](1) @[Shahidul Alam](8)",
      },
    };

    const { contactId } = yield select(selectConnectedState);
    console.log(contactId); /* will remove this line later */
    // const response = yield call(ContactNoteService.getContactLastNote, { contactId: contactId });

    if (response) {
      if (response.success) {
        yield put(getContactLastNoteSuccess(response.data));
      } else {
        yield put(getContactLastNoteFailed(response.message));
      }
    } else {
      yield put(getContactLastNoteFailed("Something went wrong !"));
      showGlobalNotification("Something went wrong! Try again later");
    }
  } catch (err) {
    errorHandler(err, "contactNoteSaga > getContactLastNoteSaga");
  }
}
function* addContactNoteSaga(action) {
  try {
    yield delay(2000);
    const response = {
      responseCode: 200,
      message: "success",
      success: true,
      data: {
        id: 1,
        message: action.payload?.message,
      },
    };

    const responsePrd = yield call(ContactNoteService.addContactNote, action.payload);
    console.log(responsePrd);

    if (response) {
      if (response.success) {
        yield put(addContactNoteSuccess(response.message));
        yield put(getContactLastNoteSuccess(response.data));
      } else {
        yield put(addContactNoteFailed(response.message));
      }
    } else {
      yield put(addContactNoteFailed("Something went wrong !"));
      showGlobalNotification("Something went wrong! Try again later");
    }
  } catch (err) {
    errorHandler(err, "contactNoteSaga > addContactNoteSaga");
  }
}

export default function* contactNoteSaga() {
  yield all([contactNoteWatcher()]);
}
