import React, { useEffect, useRef, useState } from "react";
import { ArrowBack } from "@mui/icons-material";
import { Button, Stack, styled, Typography, TextareaAutosize, Box } from "@mui/material";
import ButtonBack from "../../../screens/auto-dialer-ongoing/global/ButtonBack/ButtonBack";
import { PlaceholderText } from "../../../../helpers/constant/Constants";
// import { BookmarkIcon } from "../../../../common/icon/Icons";
import Styles from "./sendSms.module.scss";
import Personalized from "../../../../common/Personalized/Personalized";
import { insertPersonalizedTag } from "../../../../helpers/utils/insertPersonalizedTag";
import AttachedFile from "../../../../common/attachedFile/AttachedFile";
import { countSms } from "../../../../helpers/utils/countSms";
import { checkHasUniCode } from "../../../../helpers/utils/checkHasUniCode";
import CustomCircleLoader from "../../../../common/Loader/CustomCircleLoader";
import SendService from "../../../../services/common/Send.service";
import { showSuccessToaster } from "../../../../helpers/utils/toaster";
import { getCookie } from "../../../../helpers/Cookie";
import { selectDialerState } from "../../../../state/features/dialer/dialerSelector";
import { useDispatch, useSelector } from "react-redux";
import { handleSendOption, SEND_OPTIONS } from "../../../../state/features/dialer/dialerSlice";

const StyledTextArea = styled(TextareaAutosize)(({ theme }) => ({
  color: theme.palette.text.disabled,
  border: "none",
  outline: "none",
  width: "100%",
  padding: "10px",
  borderRadius: "4px",
  resize: "none",
}));

const StyledFooter = styled(Stack)(({ theme }) => ({
  marginTop: "12px",
  padding: "16px 24px 0 24px",
  borderTop: `1px solid ${theme.palette.other.outlinedBorderDarker}`,
}));

const SendSms = () => {
  const dispatch = useDispatch();
  const {
    init: { virtualNumberId },
    connected: { contactId },
  } = useSelector(selectDialerState);
  const [message, setMessage] = useState("");
  const [attachment, setAttachment] = useState(null);
  const [emojiExist, setEmojiExist] = useState(false);
  const [smsLength, setSmsLength] = useState(0);
  const [smsCount, setSmsCount] = useState(0);
  const [sending, setSending] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    let countResult = countSms(message);
    setSmsLength(countResult.length);
    setSmsCount(countResult.sms);

    if (checkHasUniCode(message.trim())) {
      setEmojiExist(true);
    } else {
      setEmojiExist(false);
    }
  }, [message]);

  const handleMessasgeChange = (value) => {
    setMessage(value);
  };

  const handleAttachment = (value) => {
    if (value != null && value !== "") {
      setAttachment(value);
    } else {
      setAttachment(value);
    }
  };

  const handleSendSms = async () => {
    const formData = new FormData();
    formData.append("message", message);
    formData.append("messageLevel", "reply");
    formData.append("virtual_number_id", virtualNumberId);
    formData.append("contact_id", contactId);
    formData.append("origin", 3);
    formData.append("schedule_type", 1);
    formData.append("api-key", getCookie(process.env.REACT_APP_ACCESS_TOKEN));
    if (attachment) {
      formData.append("message_type", 2);
      formData.append("qqfile", attachment);
      formData.append("content", 5);
    }
    const response = await SendService.makeConversation(formData);
    setSending(false);
    if (response.status === "success") {
      showSuccessToaster(response.html);
      setMessage("");
      setAttachment(null);
    }
  };

  return (
    <div className={Styles.sendSmsWrapper}>
      {/* Header */}
      <ButtonBack
        // backHandler={() => setSending(false)}
        backHandler={() => {
          setSending(false);
          dispatch(handleSendOption({ option: SEND_OPTIONS.default }));
        }}
        title='Send a SMS'
        icon={<ArrowBack sx={{ color: "action.active" }} />}
      />

      {/* Body */}
      <div className={Styles.sendSmsModalBody}>
        {/* Message */}
        <div className={Styles.message}>
          <Typography variant='body2medium' className={Styles.messageTitle}>
            Message
          </Typography>

          <Box sx={{ p: 1, border: "1px solid", borderColor: "other.outlinedBorderDarker", borderRadius: 1 }}>
            <StyledTextArea
              ref={inputRef}
              aria-label={PlaceholderText.writeHere}
              minRows={8}
              placeholder={PlaceholderText.writeHere}
              value={message}
              onChange={(e) => handleMessasgeChange(e.target.value)}
            />

            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <Box>
                <Typography variant='body2' fontSize={12}>
                  Total SMS: {smsLength}/{smsCount}
                </Typography>
              </Box>

              <Stack direction='row' alignItems='center' spacing={0.6}>
                <Personalized
                  onClick={(personalizedTag) =>
                    insertPersonalizedTag(personalizedTag, inputRef, (e) => handleMessasgeChange(e))
                  }
                />
                {/* <span className={Styles.bookmark}>
                  <BookmarkIcon />
                </span> */}
              </Stack>
            </Box>
          </Box>
        </div>

        {/* Attachment*/}
        <div className={Styles.attachment}>
          {emojiExist && (
            <Box sx={{ mt: 1 }}>
              <Typography variant='body2' mb={1}>
                <Box component={"span"} color='warning.main' fontWeight={600}>
                  Attention:
                </Box>{" "}
                Your Message Contains Unicode Characters !!!
              </Typography>
              <Typography variant='body2' fontWeight={600}>
                What is unicode?
              </Typography>
              <Typography variant='body2'>{`Unicode is a universal character encoding standard that assigns a code to every character and symbol. (Ex. Emojis, “, ››, ‘, ⧸, ⁃, ＠, ＃)`}</Typography>
              <Typography variant='body2'>{`When unicode characters are present the phone companies charge more to deliver your text. Sometimes hidden characters get added to text when you copy/paste from an outside system. These hidden characters aren't always obvious. When pasting text to our system we recommend you paste as plain text. `}</Typography>
            </Box>
          )}
          <div className={Styles.right}></div>

          <div className={Styles.left}>
            <AttachedFile nameOnly fileUrl={attachment?.name} onChange={handleAttachment} />
          </div>
        </div>
      </div>

      {/* Footer */}
      <StyledFooter direction='row' justifyContent='space-between' alignItems='center'>
        <Typography
          onClick={() => {
            setSending(false);
            dispatch(handleSendOption({ option: SEND_OPTIONS.default }));
          }}
          sx={{ cursor: "pointer" }}
          variant='buttonMedium'
          color='text.secondary'
        >
          Cancel
        </Typography>

        <Button
          sx={{ display: "flex", backgroundColor: "secondary.main", ":hover": { backgroundColor: "secondary.main" } }}
          variant='contained'
          onClick={() => handleSendSms()}
        >
          {sending && (
            <CustomCircleLoader fill='#fff' size='extra-small' style={{ display: "flex", marginRight: "5px" }} />
          )}
          {sending ? "Sending" : "Send"} SMS
        </Button>
      </StyledFooter>
    </div>
  );
};

export default SendSms;
