import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  AvatarGroup,
  Box,
  Checkbox,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import LoadingButton from "../../common/Button/LoadingButton";
import CoreButton from "../../common/Button/CoreButton";
import CoreTextField from "../../common/TextField/CoreTextField";
import DialerListSkeleton from "../../common/LoadingView/DialerListSkeleton";
import DialerContactsService from "../../services/DialerContacts.service";
import getFullName from "../../helpers/utils/getFullName";
import { showErrorToaster, showSuccessToaster } from "../../helpers/utils/toaster";
import { selectDialerContactsState } from "../../state/features/dialerContacts/dialerContactsSelector";
import {
  deselectAllContact,
  deselectAllDialer,
  toggleSelectSingleDialer,
} from "../../state/features/dialerContacts/dialerContactsSlice";
import { useGetDialerListQuery } from "../../state/features/common/dialer/dialerApiSlice";
import { selectDialerListCount } from "../../state/features/dialerList/dialerListSelector";

const StyledListItem = styled(ListItem)(({ theme }) => ({
  marginBottom: theme.spacing(0.25),
  paddingBottom: 0,
  paddingTop: 0,
  cursor: "pointer",
}));

const ContactShareContent = ({ onClose, contactIds = [] }) => {
  const dispatch = useDispatch();

  const { data: dialerContactList, selectedDialerIds = [] } = useSelector(selectDialerContactsState);
  const count = useSelector(selectDialerListCount);
  const { data: dialerListState, isLoading } = useGetDialerListQuery({ limit: count });
  const { data: dialerList = [] } = dialerListState?.data || {};
  const [search, setSearch] = useState("");
  const [isAdding, setAdding] = useState(false);

  const handleCancel = () => {
    onClose();
    dispatch(deselectAllContact());
    dispatch(deselectAllDialer());
  };

  const handleAddContactToOtherList = () => {
    setAdding(true);
    DialerContactsService.addContactsToOtherDialerList({
      dialerListIds: JSON.stringify(selectedDialerIds),
      contactIds: JSON.stringify(contactIds),
    })
      .then((response) => {
        if (response.success) {
          handleCancel();
          showSuccessToaster(response.message);
        } else showErrorToaster("Failed, something went wrong!");
      })
      .finally(() => setAdding(false));
  };

  // decide what to render
  let dialerListView = null;
  if (isLoading) {
    dialerListView = <DialerListSkeleton count={10} />;
  } else if (!isLoading && dialerList?.length > 0) {
    dialerListView = dialerList
      .filter((data) => {
        if (search && search.trim() === "") return true;
        else if (data.title.toLowerCase().indexOf(search.toLowerCase()) === -1) return false;
        else return true;
      })
      .map((dialerData) => {
        const { id, title } = dialerData || {};
        const isSelected = selectedDialerIds.indexOf(id) !== -1;

        return (
          <StyledListItem key={id} selected={isSelected} onClick={() => dispatch(toggleSelectSingleDialer(id))}>
            <Checkbox edge='start' checked={isSelected} tabIndex={-1} />
            <ListItemText primary={title} sx={{ color: "text.primary" }} />
          </StyledListItem>
        );
      });
  }

  const totalSelectedList = selectedDialerIds?.length || 0;
  return (
    <Box sx={{ p: 3 }}>
      <Stack alignItems={"center"}>
        <AvatarGroup max={4}>
          {dialerContactList.map((contact, index) => {
            const hasSelected = contactIds.includes(contact.id);
            if (!hasSelected) return null;

            const { first_name, last_name, email, avatar } = contact;
            let fullName = getFullName(first_name, last_name, "");
            if (!fullName) fullName = email || "";

            return (
              <Avatar key={index} alt={"avatar"} src={avatar}>
                {fullName[0]}
              </Avatar>
            );
          })}
        </AvatarGroup>
        <Typography variant={"subtitle1"} color={"text.primary"} mt={1}>
          {contactIds.length} {contactIds.length > 1 ? "Contacts" : "Contact"}
        </Typography>
        <Typography variant={"body2"} color={"text.secondary"}>
          You are wanted to add the contact to other list(s)
        </Typography>
      </Stack>

      <Stack spacing={1}>
        <Typography variant={"body2medium"} color={"text.primary"}>
          Select List ({totalSelectedList})
        </Typography>

        <CoreTextField
          fullWidth
          size={"small"}
          type={"search"}
          color={"secondary"}
          InputProps={{
            startAdornment: (
              <InputAdornment sx={{ mr: 0 }} position='start'>
                <Search />
              </InputAdornment>
            ),
          }}
          placeholder='Search'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          sx={{ "& .MuiInputBase-inputSizeSmall": { height: "36px !important" } }}
        />

        <List className='pdad-scroll-bar' sx={{ height: "180px", p: 0 }}>
          {dialerListView}
        </List>
      </Stack>

      <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={2} mt={2}>
        <CoreButton type={"button"} size={"large"} color={"inherit"} onClick={handleCancel}>
          Cancel
        </CoreButton>

        <div>
          <LoadingButton
            isLoading={isAdding}
            disabled={totalSelectedList === 0}
            size={"large"}
            color={"secondary"}
            onClick={handleAddContactToOtherList}
          >
            Add to the list
          </LoadingButton>
        </div>
      </Stack>
    </Box>
  );
};

export default ContactShareContent;
