import React from "react";
import { SvgIcon } from "@mui/material";

const RecordingIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M18.437 13.41a.704.704 0 1 0-1.41 0c0 2.72-2.307 4.933-5.027 4.933S6.973 16.13 6.973 13.41a.704.704 0 1 0-1.41 0c0 3.256 2.569 5.916 5.732 6.272v1.481H9.087a.704.704 0 1 0 0 1.41h5.826a.704.704 0 1 0 0-1.41h-2.208v-1.481c3.163-.357 5.732-3.016 5.732-6.272z' />
      <path d='M12 1.428c-1.943 0-3.618 1.581-3.618 3.524v8.457c0 1.943 1.675 3.524 3.618 3.524 1.943 0 3.618-1.581 3.618-3.524V4.952c0-1.943-1.675-3.524-3.618-3.524zm9.729 1.069a.706.706 0 0 0-.997.997 6.3 6.3 0 0 1 1.858 4.485 6.3 6.3 0 0 1-1.858 4.485.706.706 0 0 0 .997.997A7.705 7.705 0 0 0 24 7.978a7.7 7.7 0 0 0-2.271-5.481z' />
      <path d='M19.734 4.491a.706.706 0 0 0-.997.997 3.497 3.497 0 0 1 1.034 2.49c0 .939-.367 1.824-1.034 2.491a.706.706 0 0 0 .997.997 4.9 4.9 0 0 0 1.447-3.487 4.897 4.897 0 0 0-1.447-3.488zm-1.992 1.993a.706.706 0 0 0-.997.997.7.7 0 0 1 0 .996.706.706 0 0 0 .997.997c.4-.4.62-.931.62-1.495 0-.565-.22-1.095-.62-1.495zM1.41 7.978c0-1.694.66-3.287 1.858-4.485a.706.706 0 0 0-.997-.997A7.704 7.704 0 0 0 0 7.978a7.7 7.7 0 0 0 2.271 5.482.706.706 0 0 0 .997-.997A6.3 6.3 0 0 1 1.41 7.978z' />
      <path d='M4.229 7.978c0-.939.367-1.824 1.034-2.49a.706.706 0 0 0-.997-.997 4.896 4.896 0 0 0-1.447 3.487c0 1.316.513 2.555 1.447 3.487a.706.706 0 0 0 .997-.997 3.493 3.493 0 0 1-1.034-2.49zm2.819 0c0-.187.074-.364.207-.498a.706.706 0 0 0-.997-.997c-.4.4-.62.931-.62 1.494a2.1 2.1 0 0 0 .62 1.495.706.706 0 0 0 .997-.997.693.693 0 0 1-.207-.497z' />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </SvgIcon>
  );
};

export default RecordingIcon;
