import React, { useRef } from "react";
import { Avatar, Box, CircularProgress, IconButton, Stack, styled, Typography } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import { LightCoreButton } from "../Button/LightCoreButton";
import AudioFileIcon from "@mui/icons-material/AudioFile";
import CloseIcon from "@mui/icons-material/Close";
import { showErrorToaster } from "../../helpers/utils/toaster";
import { ATTACHMENT_TYPE } from "../../helpers/constant/Constants";
import { isValidSmsAttachment } from "../../helpers/utils/isValidSmsAttachment";
import { isValidEmailAttachment } from "../../helpers/utils/isValidEmailAttachment";

const StyledLink = styled("a")(({ theme }) => ({
  maxWidth: "350px",
  "&:hover": {
    textDecoration: "underline",
    color: theme.palette.text.primary,
  },
}));

const MAX_FILE_SIZE = 10485760; //1024*1024*10 --- 10MB
const FILE_EXTENSIONS = ".jpg, .jpeg, .png, .svg, .tiff, .pdf";
const OTHER_EMAIL_EXTENSIONS = ".doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .xml, .xml, .mp4, .webm, .mp3, .wav, .zip";

const AttachedFile = ({
  fileUrl,
  onChange = () => {},
  type = ATTACHMENT_TYPE.SMS,
  nameOnly = false,
  isLoading = false,
}) => {
  const fileInputRef = useRef(null);

  const handleUploadFile = (e) => {
    const file = e.target.files[0];

    if (file.size > MAX_FILE_SIZE) {
      showErrorToaster("Maximum upload size : 10MB");
      return;
    }

    if (type === ATTACHMENT_TYPE.SMS) {
      if (!isValidSmsAttachment(file.type)) {
        showErrorToaster("Only image and pdf files are valid.");
        return;
      }
    } else {
      if (!isValidEmailAttachment(file.type)) {
        showErrorToaster("Invalid attachment type");
        return;
      }
    }

    onChange(file);
  };

  if (isLoading) {
    return (
      <Stack direction={"row"} alignItems='center' spacing={1}>
        <CircularProgress size={20} sx={{ position: "relative" }} />
        <Typography variant='body2medium' color='text.secondary'>
          Uploading attachment
        </Typography>
      </Stack>
    );
  }

  return (
    <Box>
      {fileUrl && (
        <Stack direction={"column"} spacing={1} mb={1}>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Avatar variant={"rounded"} sx={{ bgcolor: "other.dividerFillColor", width: 24, height: 24 }}>
              <AudioFileIcon color={"action"} sx={{ fontSize: "16px" }} />
            </Avatar>
            {nameOnly ? (
              <Typography
                className={"text-truncate"}
                sx={{ maxWidth: "180px" }}
                variant='body2medium'
                color={"text.primary"}
                title={fileUrl}
              >
                {fileUrl}
              </Typography>
            ) : (
              <StyledLink className={"text-truncate"} href={fileUrl} target={"_blank"} rel='noreferrer'>
                <Typography variant='body2medium' color={"text.primary"} title={fileUrl}>
                  {fileUrl}
                </Typography>
              </StyledLink>
            )}

            <IconButton size={"small"} color={"error"} onClick={() => onChange(null)}>
              <CloseIcon sx={{ fontSize: "16px" }} />
            </IconButton>
          </Stack>
        </Stack>
      )}

      {!fileUrl && (
        <>
          <input
            ref={fileInputRef}
            type='file'
            style={{ display: "none" }}
            accept={`${FILE_EXTENSIONS},${type === ATTACHMENT_TYPE.EMAIL ? OTHER_EMAIL_EXTENSIONS : ""}`}
            onChange={handleUploadFile}
          />
          <LightCoreButton
            size='small'
            color={"secondary"}
            startIcon={<UploadIcon />}
            onClick={() => fileInputRef.current.click()}
          >
            Attach Files
          </LightCoreButton>
          <Typography variant={"chipLight"} color={"text.secondary"} mt={0.5} sx={{ display: "block" }}>
            Max size: 10MB
          </Typography>
        </>
      )}
    </Box>
  );
};

export default AttachedFile;
