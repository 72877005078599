import { Grid, Skeleton, Stack } from "@mui/material";
import React from "react";

const VoiceTemplateSkeleton = () => {
  return (
    <Grid container gap={2}>
      {Array.from({ length: 2 }).map((_, index) => (
        <Grid
          key={index}
          xs={12}
          borderRadius={2}
          p={2}
          sx={{
            border: "1px solid #E0E0E0",
            bgcolor: "#e0e0e017",
          }}
        >
          <Stack>
            <Stack mb={1}>
              <Skeleton variant={"text"} width={"60%"} height={20} />
              <Skeleton variant={"text"} width={"100%"} height={18} />
              <Skeleton variant={"text"} width={"100%"} height={18} />
            </Stack>
            <Skeleton variant={"rect"} width={"100%"} height={40} />
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
};

export default VoiceTemplateSkeleton;
