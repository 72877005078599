import httpRequest from "./api/httpRequest";

class DialerService {
  getDialerList(params) {
    return httpRequest.get(process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/lists", params);
  }

  addDialer(body) {
    return httpRequest.post(process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/list/create", body);
  }

  updateDialer(body) {
    return httpRequest.post(process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/list/update", body);
  }

  updateDialerColor(body) {
    return httpRequest.post(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/list/update/color",
      body
    );
  }

  cloneDialer(params) {
    return httpRequest.get(process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/list/duplicate", params);
  }

  deleteDialer(params) {
    return httpRequest.post(process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/list/delete", params);
  }
}

export default new DialerService();
