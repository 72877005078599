import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Box, FormControl, FormHelperText, Grid, MenuItem, Select, Stack, Typography } from "@mui/material";
import CoreTextField from "../../common/TextField/CoreTextField";
import LoadingButton from "../../common/Button/LoadingButton";
import AsteriskIcon from "../../common/Icons/AsterickIcon";
import CoreButton from "../../common/Button/CoreButton";
import { dialerListPattern } from "../../helpers/constant/registerPattern";
import { FOLDER_DEFAULT_COLOR } from "../../helpers/constant/folderContant";
import useBoolean from "../../hooks/useBoolean";
import {
  addDialer,
  resetAddDialerSuccess,
  resetUpdateDialerSuccess,
  updateDialer,
} from "../../state/features/dialerList/dialerListSlice";
import { selectDialerListState } from "../../state/features/dialerList/dialerListSelector";
import { useGetDialerListQuery } from "../../state/features/common/dialer/dialerApiSlice";
import { useGetVirtualNumberListQuery } from "../../state/features/common/virtualNumber/virtualNumberApiSlice";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddOrEditDialerList = ({ onClose, dialerInfo = {}, isEditMode = false }) => {
  const dispatch = useDispatch();
  const { isAdding, isAddSuccess, isUpdating, isUpdateSuccess, count } = useSelector(selectDialerListState);

  const { data: dialerListState } = useGetDialerListQuery({ limit: count }, { skip: isEditMode });
  const { data: dialerList = [] } = dialerListState?.data || {};

  const { data: virtualNumberListState } = useGetVirtualNumberListQuery();
  const { data: virtualNumberList = [] } = virtualNumberListState?.data || {};

  const { value: openSetting, setTrue: setOpenSettings, setFalse: setCloseSettings } = useBoolean(false);

  const { handleSubmit, setValue, control } = useForm();

  useEffect(() => {
    if (dialerInfo?.title) {
      setValue("title", dialerInfo.title);
      setValue("description", dialerInfo.description);
      setValue("virtual_number_id", dialerInfo.virtual_number_id);
      setValue("copySettingsId", dialerInfo.copySettingsId);
    }
  }, [dialerInfo]);

  useEffect(() => {
    if (isAddSuccess || isUpdateSuccess) {
      dispatch(resetAddDialerSuccess());
      dispatch(resetUpdateDialerSuccess());
      setCloseSettings();
      onClose();
    }
  }, [isAddSuccess, isUpdateSuccess]);

  const onSubmitAndOpenSettings = (data) => {
    data.isOpenSettings = true;
    onSubmit(data);
    setOpenSettings();
  };

  // handle submit add or edit dialer list
  const onSubmit = (data) => {
    const payload = {
      name: data.title,
      description: data.description,
      virtual_number_id: data.virtual_number_id,
      copySettingsId: data.copySettingsId,
      isOpenSettings: !!data.isOpenSettings,
    };

    if (isEditMode) {
      payload.id = dialerInfo.id;
      payload.color = dialerInfo.color;
      dispatch(updateDialer(payload));
    } else {
      payload.color = FOLDER_DEFAULT_COLOR;
      dispatch(addDialer(payload));
    }
  };

  return (
    <Box
      component='form'
      noValidate
      autoComplete='off'
      id='dialerListForm'
      onSubmit={handleSubmit(onSubmit)}
      sx={{ p: 3, bgcolor: "background.paper", height: "100%" }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name='title'
            defaultValue=''
            render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
              <>
                <Typography variant={"body2"} color={"text.primary"} sx={{ mb: 1 }}>
                  List Name <AsteriskIcon />
                </Typography>
                <CoreTextField
                  fullWidth
                  required
                  autoFocus
                  type={"text"}
                  size={"small"}
                  placeholder={"List name here"}
                  value={value}
                  onChange={onChange}
                  error={invalid}
                  helperText={invalid && error?.message}
                />
              </>
            )}
            rules={dialerListPattern.title}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            control={control}
            name='virtual_number_id'
            defaultValue=''
            render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
              <FormControl error={invalid} fullWidth>
                <Typography variant={"body2"} color={"text.primary"} sx={{ mb: 1 }}>
                  Which number to call from? <AsteriskIcon />
                </Typography>
                <Select
                  value={value}
                  onChange={onChange}
                  displayEmpty
                  size={"small"}
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={MenuProps}
                >
                  <MenuItem disabled value=''>
                    Select a virtual number
                  </MenuItem>
                  {virtualNumberList?.map((vn) => (
                    <MenuItem key={vn.id} value={vn.id}>
                      {vn.virtual_number}
                    </MenuItem>
                  ))}
                </Select>
                {error?.message && <FormHelperText error={invalid}>{error.message}</FormHelperText>}
              </FormControl>
            )}
            rules={dialerListPattern.virtual_number_id}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            control={control}
            name='description'
            defaultValue=''
            render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
              <>
                <Typography variant={"body2"} color={"text.primary"} sx={{ mb: 1 }}>
                  Description
                </Typography>
                <CoreTextField
                  fullWidth
                  type={"text"}
                  size={"small"}
                  multiline={true}
                  rows={3}
                  placeholder={"Write here..."}
                  value={value}
                  onChange={onChange}
                  error={invalid}
                  helperText={invalid && error?.message}
                />
              </>
            )}
            rules={dialerListPattern.description || null}
          />
        </Grid>

        {!isEditMode && (
          <Grid item xs={12}>
            <Controller
              control={control}
              name='copySettingsId'
              defaultValue=''
              render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                <FormControl error={invalid} fullWidth>
                  <Typography variant={"body2"} color={"text.primary"} sx={{ mb: 1 }}>
                    Copy Settings from
                  </Typography>
                  <Select
                    value={value}
                    onChange={onChange}
                    displayEmpty
                    size={"small"}
                    inputProps={{ "aria-label": "Without label" }}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value=''>None</MenuItem>
                    {dialerList?.map((dialer) => (
                      <MenuItem key={dialer.id} value={dialer.id}>
                        {dialer.title}
                      </MenuItem>
                    ))}
                  </Select>
                  {error?.message && <FormHelperText error={invalid}>{error.message}</FormHelperText>}
                </FormControl>
              )}
            />
          </Grid>
        )}
      </Grid>
      <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} mt={2}>
        <CoreButton type={"button"} size={"large"} color={"inherit"} onClick={onClose}>
          Cancel
        </CoreButton>

        <div>
          <LoadingButton
            isLoading={openSetting}
            size={"large"}
            color={"success"}
            variant={"text"}
            sx={{ mr: 2 }}
            onClick={handleSubmit(onSubmitAndOpenSettings)}
          >
            Save & Take me to settings
          </LoadingButton>
          <LoadingButton
            isLoading={!openSetting && (isAdding || isUpdating)}
            loadingText={"Saving..."}
            size={"large"}
            color={"success"}
            type={"submit"}
            form='dialerListForm'
          >
            Save
          </LoadingButton>
        </div>
      </Stack>
    </Box>
  );
};

export default AddOrEditDialerList;
