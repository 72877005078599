import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { selectDialerState } from "../../../state/features/dialer/dialerSelector";
import EachConversation from "./EachConversation/EachConversation";
import Styles from "./conversation.module.scss";

const ConversationItem = () => {
  const {
    conversation: { data, gettingData, getDataError },
    connected: { contact },
  } = useSelector(selectDialerState);

  const renderItem = () => {
    const view = [];
    if (data && Array.isArray(data)) {
      data.forEach((item, index) => {
        if (item && item.inOut && item.type) {
          view.push(<EachConversation item={item} index={index} Styles={Styles} contact={contact} />);
        }
      });
    }
    return view;
  };
  const renderView = () => {
    if (gettingData) {
      return (
        <Box sx={{ display: "grid", placeContent: "center", height: "100%", width: "100%" }}>
          <Typography variant='subtitle1' className={Styles.pdadTypoSubtitle1}>
            Please wait...
          </Typography>
        </Box>
      );
    }
    if (getDataError !== "") {
      return (
        <Typography variant='subtitle1' className={Styles.pdadTypoSubtitle1}>
          {getDataError}
        </Typography>
      );
    }
    if (data?.length === 0) {
      return (
        <Box sx={{ height: "100%", width: "100%" }}>
          <Box sx={{ display: "grid", placeContent: "center", height: "100%", width: "100%" }}>
            <Typography textAlign={"center"} variant='subtitle1' className={Styles.pdadTypoSubtitle1}>
              No Conversations found
            </Typography>
            <Typography textAlign={"center"} variant='body2' className={Styles.pdadTypoBody2Teg}>
              You are yet to communicate anything with the contact
            </Typography>
          </Box>
        </Box>
      );
    }
    return <div className={Styles.conversationWrapper}>{renderItem()}</div>;
  };

  return <div className={`${Styles.pdadDialerOmcMiddleImNoCon} pdad-scroll-bar`}>{renderView()}</div>;
};

export default ConversationItem;
