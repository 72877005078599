import React from "react";
import { alpha, Box, Stack, styled, Typography } from "@mui/material";

const StyledOption = styled(Box)(({ theme, selected }) => ({
  flex: "1 1 25%",
  height: "36px",
  border: `1.5px solid ${selected ? theme.palette.other.btnOutlinedBorder : theme.palette.other.dividerFillColor}`,
  backgroundColor: selected ? alpha(theme.palette.secondary.main, 0.2) : theme.palette.other.dividerFillColor,
  color: selected ? theme.palette.secondary.main : theme.palette.text.secondary,
  display: "grid",
  placeItems: "center",
  cursor: "pointer",
  "&:first-child": {
    borderRadius: "4px 0 0 4px",
  },
  "&:last-child": {
    borderRadius: "0 4px 4px 0",
  },
  "&:not(:first-child)": {
    borderLeft: !selected && "none",
  },
  "& .MuiTypography-root": {
    height: "unset !important",
  },
}));

const CallSettingTabOption = ({
  title = "",
  subTitle = "",
  options = [],
  selectedOption = "",
  onChange = () => {},
  children,
}) => {
  return (
    <Box px={4} py={2}>
      <Stack direction={"column"}>
        <Typography variant='body2medium' color={"text.primary"}>
          {title}
        </Typography>
        <Typography color='text.secondary' variant='chipLight'>
          {subTitle}
        </Typography>
      </Stack>
      <Stack direction={"row"} my={2} alignItems={"center"} sx={{ borderRadius: "4px" }}>
        {options.map((option) => (
          <StyledOption
            key={option.value}
            onClick={() => onChange(option.value)}
            selected={String(selectedOption) === option.value}
          >
            <Typography variant='body2' color={"text.primary"}>
              {" "}
              {option.title}
            </Typography>
          </StyledOption>
        ))}
      </Stack>
      {children}
    </Box>
  );
};

export default CallSettingTabOption;
