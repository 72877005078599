import localPersonalizedApiSlice from "./localPersonalizedApiSlice";

export const personalizedTagApiSlice = localPersonalizedApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllPersonalizedTags: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/container/user-custom-personalized-fields`,
        method: "POST",
        params: params,
      }),
    }),
  }),
});

export const { useGetAllPersonalizedTagsQuery } = personalizedTagApiSlice;
