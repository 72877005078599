import { Box } from "@mui/material";
import DialerListContacts from "./DialerListContacts";

const DialerContact = () => {
  return (
    <Box sx={{ width: "100%", height: "100%", mt: 2 }}>
      {/*<DialerContactFilters />*/}
      <DialerListContacts />
    </Box>
  );
};
export default DialerContact;
