import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Stack, styled, Typography } from "@mui/material";
import { getContactAvatar } from "../../../helpers/utils/getContactAvatar";
import { getContactName } from "../../../helpers/utils/getContactName";
import { DocIcon } from "../../MakeCall/IconsOngoing";
import CallScript from "../Conversation/CallScript/CallScript";
import Conversation from "../Conversation/Conversation";
import LeftSide from "../LeftSide/LeftSide";
import RightSide from "../RightSide/RightSide";
import ConversationStyles from "../Conversation/conversation.module.scss";
import { selectDialerState } from "../../../state/features/dialer/dialerSelector";
import Styles from "./center.module.scss";
import {
  callTimer,
  handleConnectedData,
  handleConnectedScreenComponent,
  storeBasicInit,
} from "../../../state/features/dialer/dialerSlice";
import { TimelineContentTypes } from "../../../helpers/constant/Constants";
import ConntectedService from "../../../services/call/Conntected.service";

const StyledButton = styled(Button)(({ theme }) => ({
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "24px",
  color: theme.palette.secondary.main,
  borderColor: theme.palette.secondary.main,
  "&:hover": {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    "& svg": {
      "& path": {
        fill: theme.palette.common.white,
      },
    },
  },
}));

const TAB_SCREENS = {
  default: 1,
  details: 2,
  conversation: 3,
  sms: 4,
  email: 5,
  voice: 6,
  addToCampaign: 7,
  addToTag: 8,
  addToList: 9,
  script: 10,
  rightSide: 11,
};

const Center = ({ screen: { width } }) => {
  const dispatch = useDispatch();
  const {
    connected: { contact: contactDetails, contactId },
    init: { listId, sessionId },
    send: { option },
  } = useSelector(selectDialerState);
  const [tabScreen, setTabScreen] = useState(TAB_SCREENS.default);

  useEffect(() => {
    if (width <= 1140) {
      dispatch(handleConnectedScreenComponent(TAB_SCREENS.default));
    } else {
      dispatch(handleConnectedData({ tab: TimelineContentTypes.script, activeScreen: null }));
    }
  }, [width]);

  useEffect(() => {
    getLastConnectedEmail();
    updateCallInformation();
  }, []);

  const handleTabChange = (tab) => {
    setTabScreen(tab);
  };

  window.handleTabScreen = (tab) => {
    if (tab) {
      setTabScreen(tab);
    } else {
      setTabScreen(TAB_SCREENS.default);
    }
  };

  const getLastConnectedEmail = () => {
    ConntectedService.getLastConnectedEmail({ contactId: contactId })
      .then((res) => {
        console.log(res);
        try {
          if (res.success) {
            dispatch(storeBasicInit({ connectedEmail: res.data.last_connect_email }));
          }
        } catch (err) {
          console.log(err);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const updateCallInformation = () => {
    ConntectedService.updateCallInformation({
      powerDialerListId: parseInt(listId),
      sessionId: sessionId,
      total_ringing_time: callTimer.ringingTime,
      total_session_time: callTimer.sessionTime,
    }).then((res) => {
      console.log(res);
      callTimer.ringingTime = 0;
    });
  };

  const renderTabScreens = () => {
    if (tabScreen === TAB_SCREENS.default) {
      return (
        <div className={Styles.tabScreenOne}>
          {option === 0 && (
            <Box p={"20px"} borderBottom='1px solid' borderColor='other.outlinedBorderDarker'>
              <Stack direction='row' alignItems='center' justifyContent='space-between'>
                <Typography variant='body2medium' sx={{ color: "action.active" }}>
                  Connected Contact
                </Typography>

                <StyledButton
                  onClick={() => handleTabChange(TAB_SCREENS.script)}
                  variant='outlined'
                  startIcon={<DocIcon />}
                >
                  View Script
                </StyledButton>
              </Stack>

              <div className={Styles.profile}>
                <div className={Styles.avatar}>
                  <Typography variant='body1'>
                    {getContactAvatar(
                      contactDetails?.first_name,
                      contactDetails?.last_name,
                      contactDetails?.email,
                      contactDetails?.number
                    )}
                  </Typography>
                </div>
                <div className={Styles.overflowHidden}>
                  <Typography variant='body1medium' className={Styles.truncate}>
                    {getContactName(contactDetails)}
                  </Typography>
                  {typeof contactDetails.lastCommunicationTime !== "undefined" &&
                    contactDetails.lastCommunicationTime != null && (
                      <Typography variant='body2' color='action.active' className={`${Styles.proStatus}`}>
                        Last Connection:
                        <span>
                          <Stack direction='row' alignItems='center' spacing={0.6} mt={0.8}>
                            <Typography sx={{ color: "action.active", fontSize: "11px" }} variant='chipLight'>
                              {window.globalTimezoneConversionToDifferentTimezone(
                                contactDetails.lastCommunicationTime,
                                "UTC",
                                "",
                                "ll h:mm A"
                              )}
                            </Typography>
                          </Stack>
                        </span>
                      </Typography>
                    )}
                </div>
              </div>

              <Stack direction='row' alignItems='center' justifyContent='center' spacing={1}>
                <Button
                  sx={{
                    color: "text.secondary",
                    borderColor: "action.active",
                    ":hover": {
                      color: "text.secondary",
                      borderColor: "action.active",
                    },
                  }}
                  variant='outlined'
                  onClick={() => handleTabChange(TAB_SCREENS.details)}
                >
                  Contact Details
                </Button>
                <Button
                  sx={{
                    color: "text.secondary",
                    paddingInline: "24px !important",
                    backgroundColor: "other.dividerFillColor",
                    ":hover": { color: "text.secondary", backgroundColor: "other.dividerFillColor" },
                  }}
                  variant='contained'
                  disableElevation
                  onClick={() => handleTabChange(TAB_SCREENS.conversation)}
                >
                  View History
                </Button>
              </Stack>
            </Box>
          )}

          <RightSide handleTabChange={handleTabChange} className={Styles.rightSide} width={width} />
        </div>
      );
    } else if (tabScreen === TAB_SCREENS.script) {
      return (
        <div>
          <CallScript Styles={ConversationStyles} />;
        </div>
      );
    } else if (tabScreen === TAB_SCREENS.details) {
      return (
        <Box sx={{ width: "100%" }}>
          <LeftSide screen={screen} />
        </Box>
      );
    } else if (tabScreen === TAB_SCREENS.conversation) {
      /* conversation */
      return (
        <div>
          <Conversation screen={screen} />
        </div>
      );
    } else if (tabScreen === TAB_SCREENS.rightSide) {
      /* right side */
      return <RightSide screen={screen} />;
    }
    return null;
  };
  const renderDefault = () => {
    return (
      <div className={`${Styles.centerWrapper}`}>
        <LeftSide />
        <Conversation />
        <RightSide />
      </div>
    );
  };
  const renderTabView = () => {
    return (
      <div className={`${Styles.centerWrapper} ${width <= 1140 ? "will add later" : ""}`}>{renderTabScreens()}</div>
    );
  };
  const renderView = () => {
    if (width <= 1140) {
      return renderTabView();
    }
    return renderDefault();
  };
  return renderView();
};

export default Center;
