import React from "react";
import { Stack, Typography } from "@mui/material";
import CustomDefaultSwitch from "../../../common/Switch/CustomDefaultSwitch";

const CallSettingSwitchOption = ({ title = "", subTitle = "", checked = false, onChange = () => {} }) => {
  return (
    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} px={4} py={2}>
      <Stack direction={"column"}>
        <Typography variant='body2medium' color={"text.primary"}>
          {title}
        </Typography>
        <Typography color='text.secondary' variant='chipLight'>
          {subTitle}
        </Typography>
      </Stack>
      <CustomDefaultSwitch checked={checked} onChange={onChange} />
    </Stack>
  );
};

export default CallSettingSwitchOption;
