import { createSlice } from "@reduxjs/toolkit";
import { CALL_START_FROM_BEGINNING } from "../../../../helpers/constant/CoreConstants";

const initialState = {
  initPowerDialerId: null,
  isShowTermAndConditions: false,
  isShowSetting: false,
  isShowResume: false,
  isStartInitiating: false,
  getSettingOverview: false,
  settingOverview: null,
  hasGetSettingOverviewError: false,
  getSettingOverviewError: "",
  callStartType: CALL_START_FROM_BEGINNING,
};

const initializeSlice = createSlice({
  name: "initialize",
  initialState: initialState,
  reducers: {
    startDailing: (state, action) => {
      state.initPowerDialerId = action.payload;
      state.isStartInitiating = true;
    },
    resetStartDailing: (state) => {
      state.initPowerDialerId = null;
      state.isStartInitiating = false;
    },
    getSettingOverview: (state, action) => {
      state.isShowSetting = true;
      state.getSettingOverview = true;
      state.isShowTermAndConditions = false;
      state.isShowResume = false;
      state.callStartType = action.payload;
    },
    getSettingOverviewSuccess: (state, action) => {
      state.settingOverview = action.payload;
      state.getSettingOverview = false;
      state.hasGetSettingOverviewError = false;
      state.getSettingOverviewError = "";
    },
    getSettingOverviewFailed: (state, action) => {
      state.settingOverview = null;
      state.getSettingOverview = false;
      state.hasGetSettingOverviewError = true;
      state.getSettingOverviewError = action.payload;
      // state.isShowSetting = false;
    },
    toggleShowTermAndCondition: (state, action) => {
      state.isShowTermAndConditions = action.payload;
    },
    toggleShowSetting: (state, action) => {
      state.isShowSetting = action.payload;
      state.getSettingOverview = false;
      state.settingOverview = null;
      state.hasGetSettingOverviewError = false;
      state.getSettingOverviewError = "";
    },
    toggleShowResume: (state, action) => {
      state.isShowResume = action.payload;
    },
    toggleStartInitiating: (state, action) => {
      state.isStartInitiating = action.payload;
    },
    resetStatesInitialize: (state) => {
      for (const property in initialState) {
        state[property] = initialState[property];
      }
    },
  },
});

export const {
  toggleShowTermAndCondition,
  toggleShowSetting,
  toggleShowResume,
  toggleStartInitiating,
  startDailing,
  getSettingOverview,
  getSettingOverviewSuccess,
  getSettingOverviewFailed,
  resetStartDailing,
  resetStatesInitialize,
} = initializeSlice.actions;

export default initializeSlice.reducer;
