import React from "react";
import { Box, Skeleton, Stack } from "@mui/material";
import ScriptSingleListSkeleton from "./ScriptSingleListSkeleton";

const ScriptSettingSkeleton = () => {
  return (
    <Box px={4} py={2}>
      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
        <Skeleton variant={"text"} width={100} height={30} />
        <Skeleton variant={"text"} width={118} height={50} />
      </Stack>
      <ScriptSingleListSkeleton />
      <ScriptSingleListSkeleton />
      <ScriptSingleListSkeleton />
    </Box>
  );
};

export default ScriptSettingSkeleton;
