import { all, put } from "redux-saga/effects";
import { call, delay, select, takeEvery, takeLatest } from "@redux-saga/core/effects";
import {
  getTriggerSettingFailed,
  getTriggerSettingSuccess,
  updateTriggerSetting,
  updateTriggerSettingLoading,
  updateTriggerSettingWithoutSaga,
} from "./triggerSettingSlice";
import { selectListSettingModal, selectTriggerSetting } from "../listSettingSelector";
import ListSettingService from "../../../../services/ListSetting.service";
import { showErrorToaster } from "../../../../helpers/utils/toaster";
import { ATTACHMENT_LOADING_KEY } from "../../../../helpers/constant/Constants";

function* triggerSettingWatcher() {
  yield takeEvery("triggerSetting/getTriggerSetting", getTriggerSettingSaga);
  yield takeLatest("triggerSetting/updateTriggerSetting", updateTriggerSettingSaga);
  yield takeEvery("triggerSetting/uploadAttachment", uploadAttachment);
  yield takeEvery("triggerSetting/uploadVoiceFile", uploadVoiceFile);
  yield takeEvery("triggerSetting/updateTriggerSettingOnCreateTag", updateTriggerSettingOnCreateTagSaga);
}

function* getTriggerSettingSaga() {
  try {
    const { listId } = yield select(selectListSettingModal);

    if (!listId) {
      yield put(getTriggerSettingFailed("No dialer list selected"));
      return;
    }

    const response = yield call(ListSettingService.getTriggerSetting, { id: listId });

    if (response.success) {
      yield put(getTriggerSettingSuccess(response.data));
    } else {
      yield put(getTriggerSettingFailed(response.message));
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

function* updateTriggerSettingSaga() {
  try {
    const { listId } = yield select(selectListSettingModal);
    const { settings } = yield select(selectTriggerSetting);
    yield delay(1000);

    const response = yield call(ListSettingService.updateTriggerSetting, { ...settings, id: listId });

    if (!response.success) {
      showErrorToaster(response.message);
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

function* updateTriggerSettingOnCreateTagSaga() {
  try {
    yield delay(1000);
    const { listId } = yield select(selectListSettingModal);
    const { settings } = yield select(selectTriggerSetting);

    const response = yield call(ListSettingService.updateTriggerSetting, { ...settings, id: listId });

    if (!response.success) {
      showErrorToaster(response.message);
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

function* uploadAttachment(action) {
  try {
    const { listId } = yield select(selectListSettingModal);
    if (!listId) {
      return;
    }

    const file = action.payload.file;
    const loadingKey = ATTACHMENT_LOADING_KEY[action.payload.key];

    //If remove attachment called
    if (file === null) {
      yield put(updateTriggerSettingLoading({ key: loadingKey, value: false }));
      yield put(updateTriggerSetting({ [action.payload.key]: "" }));
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("listId", listId);
    const response = yield call(ListSettingService.uploadAttachment, formData);

    if (response.success) {
      yield put(updateTriggerSettingLoading({ key: loadingKey, value: false }));
      yield put(updateTriggerSetting({ [action.payload.key]: response.data.file }));
    } else if (Array.isArray(response?.data?.message?.file)) {
      yield put(updateTriggerSettingLoading({ key: loadingKey, value: false }));
      showErrorToaster(response.data.message.file[0]);
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

function* uploadVoiceFile(action) {
  try {
    const { listId } = yield select(selectListSettingModal);
    if (!listId) {
      return;
    }

    const formData = new FormData();
    formData.append("file", action.payload.file);
    formData.append("listId", listId);
    yield put(updateTriggerSettingWithoutSaga({ [action.payload.key]: action.payload.url }));
    const response = yield call(ListSettingService.uploadVoiceFile, formData);

    if (response.success) {
      yield put(updateTriggerSetting({ [action.payload.key]: response.data.file }));
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

export default function* triggerSettingSaga() {
  yield all([triggerSettingWatcher()]);
}
