import { all, put } from "redux-saga/effects";
import { call, /* delay, */ select, takeEvery, takeLatest } from "@redux-saga/core/effects";
import {
  addScriptFromExistingSuccess,
  createScriptSettingSuccess,
  deleteScriptSuccess,
  getScriptSettingFailed,
  getScriptSettingSuccess,
  makeDefaultScriptSuccess,
  updateScriptSettingSuccess,
} from "./scriptSettingSlice";
import ListSettingService from "../../../../services/ListSetting.service";
import { showErrorToaster, showSuccessToaster } from "../../../../helpers/utils/toaster";
import { selectListSettingModal, selectScriptSetting } from "../listSettingSelector";
import { resetGeneralScript } from "../../listDefaultSetting/defaultCallSetting/defaultCallSettingSlice";

function* scriptSettingWatcher() {
  yield takeEvery("scriptSetting/getScriptSetting", getScriptSettingSaga);
  yield takeLatest("scriptSetting/updateScriptSetting", updateScriptSettingSaga);
  yield takeLatest("scriptSetting/makeDefaultScript", makeDefaultScriptSaga);
  yield takeLatest("scriptSetting/deleteScript", deleteScriptSaga);
  yield takeLatest("scriptSetting/addScriptFromExisting", addScriptFromExistingSaga);
}

function* getScriptSettingSaga() {
  try {
    const { listId } = yield select(selectListSettingModal);

    if (!listId) {
      yield put(getScriptSettingFailed("No dialer list selected"));
      return;
    }

    const response = yield call(ListSettingService.getScriptSetting, { id: listId });

    if (response.success) {
      yield put(getScriptSettingSuccess(response.data));
    } else {
      yield put(getScriptSettingFailed(response.message));
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

function* updateScriptSettingSaga(action) {
  try {
    const { listId } = yield select(selectListSettingModal);
    if (!listId) {
      return;
    }
    const { settings } = yield select(selectScriptSetting);
    const payloadObj = { ...action.payload };
    if (settings.scripts.length === 0) {
      payloadObj.is_default = 1;
    }
    const response = yield call(ListSettingService.updateScriptSetting, { ...payloadObj, id: listId });

    if (!response.success) {
      showErrorToaster(response.data.message.script[0]);
    } else {
      if (!action.payload.script_id) {
        yield put(createScriptSettingSuccess({ ...payloadObj, id: response.data.script_id }));
        showSuccessToaster("Script created successfully");
      } else {
        yield put(updateScriptSettingSuccess(action.payload));
        showSuccessToaster("Script updated successfully");
      }
      yield put(resetGeneralScript());
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

function* makeDefaultScriptSaga(action) {
  try {
    const { listId } = yield select(selectListSettingModal);
    if (!listId) {
      return;
    }
    const response = yield call(ListSettingService.makeDefaultScript, { ...action.payload, id: listId });

    if (!response.success) {
      showErrorToaster("Failed to make default script");
    } else {
      yield put(makeDefaultScriptSuccess(action.payload));
      yield put(resetGeneralScript());
      showSuccessToaster("Make default successfully");
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

function* deleteScriptSaga(action) {
  try {
    const { listId } = yield select(selectListSettingModal);
    if (!listId) {
      return;
    }
    const response = yield call(ListSettingService.deleteScript, { ...action.payload, id: listId });

    if (!response.success) {
      showErrorToaster("Failed to delete script");
    } else {
      yield put(deleteScriptSuccess(action.payload));
      yield put(resetGeneralScript());
      showSuccessToaster("Delete script successfully");
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

function* addScriptFromExistingSaga(action) {
  try {
    // yield delay(3000);
    // let local = true;
    // if (!local) {
    const response = yield call(ListSettingService.addScriptFromExistingApi, action.payload.payload);
    // }
    // const response = {
    //   success: true,
    //   message: "Script added successfully",
    //   data: action.payload.payload,
    // };

    if (response.success) {
      yield put(addScriptFromExistingSuccess(action.payload));
      yield put(resetGeneralScript());

      if (action.payload.callback) {
        action.payload.callback();
      }
    } else {
      showErrorToaster(response.message);
      // yield put(deleteCallScriptForDefaultFailed());
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

export default function* scriptSettingSaga() {
  yield all([scriptSettingWatcher()]);
}
