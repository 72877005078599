import React from "react";
import { Checkbox, FormControlLabel, IconButton, ListItem, ListItemText, Stack, Typography } from "@mui/material";
import CustomTooltip from "../../common/Tooltip/CustomTooltip";
import { DeleteIcon, EditIcon } from "../../common/icon/Icons";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCallScriptForDefault,
  deleteGeneralScript,
  storeGeneralScriptEditData,
} from "../../state/features/listDefaultSetting/defaultCallSetting/defaultCallSettingSlice";
import BasicModal from "../../common/Modal/BasicModal";
import DeleteGeneralScriptModal from "./DeleteGeneralScriptModal";
import useBoolean from "../../hooks/useBoolean";
import { selectDefaultCallSetting } from "../../state/features/listDefaultSetting/defaultListSettingSelector";

const EachGeneralScript = ({ script, values, index, defaultSetting, preview, handleCheck, listSetting }) => {
  const { value: isOpenDeleteModal, setTrue: onOpenDeleteModal, setFalse: onCloseDeleteModal } = useBoolean(false);

  const {
    generalScripts: { isDeleting },
    callScripts: { isAdding: isCallScriptsAdding, isDeleting: isCallScriptsDeleting },
  } = useSelector(selectDefaultCallSetting);

  const dispatch = useDispatch();

  const handleDelete = () => {
    onOpenDeleteModal();
  };

  const confirmDelete = () => {
    if (preview) {
      dispatch(
        deleteCallScriptForDefault({
          payload: {
            id: script.id,
            index: index,
          },
          // callback: () => onCloseDeleteModal(),
        })
      );
    } else {
      dispatch(
        deleteGeneralScript({
          payload: {
            id: script.id,
            index: index,
          },
          callback: () => onCloseDeleteModal(),
        })
      );
    }

    // onCloseDeleteModal();
    // preview ? dispatch(deleteScriptForDefaultSetting(script.id)) : dispatch(deleteGeneralScript(script.id));
  };

  const handleEdit = (script, index) => {
    dispatch(
      storeGeneralScriptEditData({
        index: index,
        data: script,
      })
    );
  };

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      handleCheck(true);
    } else {
      handleCheck(false);
    }
  };

  return (
    <>
      <ListItem
        // selected={defaultSetting && script.is_default}
        key={`${index}-${script?.id}`}
        sx={{
          py: 0,
          ":hover": { bgcolor: "action.actionHover" },
          ":not(:last-child)": { borderBottom: "3px solid white" },
        }}
        secondaryAction={
          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            {defaultSetting || listSetting ? (
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={isCallScriptsAdding}
                    checked={values.includes(script.id)}
                    onChange={(event) => handleCheckboxChange(event, script)}
                    name={script.title}
                  />
                }
              />
            ) : (
              <>
                {!preview && (
                  <>
                    <CustomTooltip title={"Edit"} arrow placement='top'>
                      <IconButton edge='end' aria-label='edit' onClick={() => handleEdit(script, index)}>
                        <EditIcon sx={{ fontSize: "20px" }} />
                      </IconButton>
                    </CustomTooltip>
                    <CustomTooltip title={"Delete"} arrow placement='top'>
                      <IconButton edge='end' aria-label='delete' onClick={() => handleDelete(script.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </CustomTooltip>{" "}
                  </>
                )}

                {preview && (
                  <CustomTooltip title={"Delete"} arrow placement='top'>
                    <IconButton edge='end' aria-label='delete' onClick={() => handleDelete(script.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </CustomTooltip>
                )}
              </>
            )}
          </Stack>
        }
      >
        <ListItemText
          sx={{ maxWidth: "80%", my: 2.5 }}
          primary={
            <CustomTooltip title={script.title} arrow placement='top'>
              <Typography variant={"body2medium"} color={"text.primary"} className={"text-truncate"}>
                {script.title}
              </Typography>
            </CustomTooltip>
          }
          secondary={
            <CustomTooltip title={script.description} arrow placement='top'>
              <Typography variant='body2' color='text.secondary' className='text-truncate'>
                {script.description}
              </Typography>
            </CustomTooltip>
          }
        />
      </ListItem>

      <BasicModal open={isOpenDeleteModal} onClose={onCloseDeleteModal}>
        <DeleteGeneralScriptModal
          onClose={onCloseDeleteModal}
          onConfirm={confirmDelete}
          isDeleting={isDeleting || isCallScriptsDeleting}
        />
      </BasicModal>
    </>
  );
};

export default EachGeneralScript;
