import React from "react";
import { Backdrop, Modal, Paper, styled } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { Close } from "@mui/icons-material";

const PaperStyle = styled(Paper)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.background.paper,
  boxShadow: "none",
  border: 0,
  outlined: 0,
  padding: theme.spacing(3),
}));

const BasicModal = ({ open, onClose, width = 500, disableOutSideClick = false, children, ...rest }) => {
  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={!disableOutSideClick ? onClose : () => false}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        {...rest}
      >
        <PaperStyle sx={{ minWidth: width }}>
          <IconButton color={"error"} onClick={onClose} sx={{ position: "absolute", top: "16px", right: "16px" }}>
            <Close />
          </IconButton>

          <Box>{children}</Box>
        </PaperStyle>
      </Modal>
    </React.Fragment>
  );
};

export default BasicModal;
