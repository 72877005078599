import { call, takeEvery } from "@redux-saga/core/effects";
import { all, put } from "redux-saga/effects";
import { getPersonalizedTagListFailed, getPersonalizedTagListSuccess } from "./PersonalizedTagSlice";
import PersonalizedTagService from "../../../../services/common/PersonalizedTag.service";

function* personalizedTagWatcher() {
  yield takeEvery("personalizedTag/getPersonalizedTagList", getPersonalizedTagListSaga);
}

function* getPersonalizedTagListSaga(action) {
  try {
    let localData = localStorage.getItem("global__custom__field__data");

    if (localData) {
      let parsedData = JSON.parse(localData);
      if (parsedData.data !== undefined) {
        let diffTime = Date.now() - Date.parse(parsedData.time);
        if (diffTime < 1000 * 60 * 60 * 24) {
          if (parsedData.data[0].group_value === "pype_calendar_widget") {
            parsedData.data.splice(0, 1);
          }
          let newFormatData = parsedData.data.map((eachGroup) => ({
            title: eachGroup.group_name,
            tags: eachGroup.list.map((eachField) => ({ title: eachField.field_label, value: eachField.value })),
          }));
          yield put(getPersonalizedTagListSuccess(newFormatData));
          return;
        }
      } else {
        localStorage.removeItem("global__custom__field__data");
      }
    }

    const response = yield call(PersonalizedTagService.getPersonalizedTags, action.payload);

    if (response.success) {
      yield put(getPersonalizedTagListSuccess(response.data));
    } else {
      yield put(getPersonalizedTagListFailed(response.message));
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

export default function* personalizedTagSaga() {
  yield all([personalizedTagWatcher()]);
}
