import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tags: [],
  isLoading: false,
  getError: "",
};

const contactTagsSlice = createSlice({
  name: "contactTags",
  initialState: initialState,
  reducers: {
    getContactTags: (state) => {
      state.isLoading = true;
    },
    getContactTagsSuccess: (state, action) => {
      state.tags = action.payload;
      state.isLoading = false;
      state.getError = "";
    },
    getContactTagsFailed: (state, action) => {
      state.tags = [];
      state.isLoading = false;
      state.getError = action.payload;
    },
  },
});

export const { getContactTags, getContactTagsSuccess, getContactTagsFailed } = contactTagsSlice.actions;

export default contactTagsSlice.reducer;
