import React, { useEffect, useState } from "react";
import { ArrowBack } from "@mui/icons-material";
import { Box, Button, Stack, styled, Typography } from "@mui/material";
import ButtonBack from "../../../screens/auto-dialer-ongoing/global/ButtonBack/ButtonBack";
import CustomMenuPype from "../../../../common/custom-menu/CustomMenuPype";
import { CarrotIcon } from "../../../../common/icon/Icons";
import Styles from "./addCampaign.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { selectDialerState } from "../../../../state/features/dialer/dialerSelector";
import ConntectedService from "../../../../services/call/Conntected.service";
import CustomCircleLoader from "../../../../common/Loader/CustomCircleLoader";
import { handleSendOption, SEND_OPTIONS } from "../../../../state/features/dialer/dialerSlice";
import { showGlobalNotification } from "../../../../helpers/utils/showGlobalNotification";

const StyledBox = styled(Box)(({ theme }) => ({
  padding: "16px 24px",
  borderTop: `1px solid ${theme.palette.other.outlinedBorderDarker}`,
}));

const AddCampaign = () => {
  const dispatch = useDispatch();
  const {
    connected: { contactId },
  } = useSelector(selectDialerState);

  const [data, setData] = useState({
    contactCampaign: [],
    list: [],
    pageNo: 1,
    search: "",
    needMore: false,
    gettingList: false,
    gettingError: "",
    selectedList: null,
    addingToList: false,
    addingToListError: "",
  });

  useEffect(() => {
    getCampaignList();
  }, []);

  const handleStateData = (payload) => {
    setData({ ...data, ...payload });
  };
  const getCampaignList = () => {
    handleStateData({ gettingList: true, gettingError: "" });
    ConntectedService.getUserCampaign({
      contactId: contactId,
      pageNo: 1,
      pageSize: 20,
      search: "",
      dropdown: true,
    })
      .then((res) => {
        console.log(res);
        try {
          if (res.success) {
            let formData = {};
            if (res.data.contactCampaigns) {
              if (data.pageNo == 1) {
                formData["contactCampaign"] = res.data.contactCampaigns;
                // handleStateData({ contactCampaign: res.data.contactCampaigns });
              }
            }
            if (res.data.campaigns) {
              formData["list"] = res.data.campaigns;
              // handleStateData({ list: res.data.campaigns });
              if (res.data.campaigns.length >= 20) {
                formData["needMore"] = true;
                formData["pageNo"] = data.pageNo + 1;
                // handleStateData({ needMore: true, pageNo: data.pageNo + 1 });
              }
            }
            formData["gettingList"] = false;
            formData["gettingError"] = "";
            handleStateData(formData);
            // handleStateData({ gettingList: false, gettingError: "" });
          } else {
            handleStateData({ gettingList: false, gettingError: "Something went wrong", needMore: true });
          }
        } catch (error) {
          handleStateData({ gettingList: false, gettingError: "Something went wrong" });
        }
      })
      .catch((error) => {
        console.log(error);
        handleStateData({ gettingList: false, gettingError: "Something went wrong" });
      });
  };
  const addToList = () => {
    if (data.selectedList == null) {
      showGlobalNotification("Please select a list", "warning");
      return;
    }
    const formData = {
      contactId: contactId,
      campaignId: data.selectedList?.value,
    };
    handleStateData({ addingToList: true, addingToListError: "" });
    ConntectedService.contactAddToCampaign(formData)
      .then((res) => {
        try {
          if (res.success) {
            handleStateData({ addingToList: false, addingToListError: "" });
            showGlobalNotification(res.message, "success");
          } else {
            handleStateData({ addingToList: false, addingToListError: res.message });
            showGlobalNotification(res.message);
          }
          console.log(res);
        } catch (error) {
          showGlobalNotification("Can not add to campaign !");
          handleStateData({ addingToList: false, addingToListError: "Something went wrong" });
        }
      })
      .catch((error) => {
        console.log(error);
        showGlobalNotification("Can not add to campaign !");
        handleStateData({ addingToList: false, addingToListError: "Something went wrong" });
      });
  };

  return (
    <div className={Styles.addCampaignWrapper}>
      <ButtonBack
        backHandler={() => {
          handleStateData({ addingToList: false });
          dispatch(handleSendOption({ option: SEND_OPTIONS.default }));
        }}
        title='Add to a campaign'
        icon={<ArrowBack sx={{ color: "action.active" }} />}
      />

      <StyledBox>
        <Typography variant='body2medium' sx={{ mb: "4px", display: "block" }}>
          Select a campaign
        </Typography>

        <div className={Styles.personalized}>
          <CustomMenuPype
            popup={{
              id: "connected-add-to-campaign",
              wrapperClass: Styles.popDlvnWrapper,
              placement: "center",
              optionClass: Styles.popDlvnItem,
              list: data.list,
              labelField: "title",
              isLoading: data.gettingList,
              onClick: (item) => {
                handleStateData({ selectedList: item });
              },
              height: "auto",
              width: "__parent__",
              maxHeight: "400px",
              listStyle: {
                justifyContent: "center",
              },
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center",
              },
              handleScroll: (e) => {
                console.log(e);
              },
              emptyListText: data.gettingError,
            }}
            onClose={() => {}}
            label={{
              renderCustom: (
                <div className={Styles.personalizedInput}>
                  <Typography variant='inputText'>
                    {data.selectedList != null ? data.selectedList?.title : "Select campaign"}
                  </Typography>
                  <Box component={"span"} sx={{ display: "flex" }}>
                    <CarrotIcon height='24' width='24' />
                  </Box>
                </div>
              ),
            }}
          />
        </div>
      </StyledBox>

      <Stack
        direction={"row"}
        justifyContent='space-between'
        alignItems='center'
        sx={{ paddingInline: "24px" }}
        className={Styles.footer}
      >
        <Typography
          variant='buttonMedium'
          color='text.secondary'
          onClick={() => {
            handleStateData({ addingToList: false });
            dispatch(handleSendOption({ option: SEND_OPTIONS.default }));
          }}
          sx={{ cursor: "pointer" }}
        >
          Cancel
        </Typography>

        <Button
          sx={{ backgroundColor: "secondary.main", ":hover": { backgroundColor: "secondary.main" } }}
          variant='contained'
          onClick={addToList}
        >
          {data.addingToList && (
            <CustomCircleLoader fill='#fff' size='extra-small' style={{ display: "flex", marginRight: "5px" }} />
          )}
          {data.addingToList ? "Saving" : "Save"} Change
        </Button>
      </Stack>
    </div>
  );
};

export default AddCampaign;
