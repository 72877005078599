const removeQueryParam = (paramsToRemove) => {
  const url = new URL(window.location.href);
  const searchParams = url.searchParams;

  paramsToRemove.forEach((param) => searchParams.delete(param));

  let newUrl = url.pathname;
  if (searchParams && searchParams.size !== 0) {
    newUrl += `?${searchParams.toString()}`;
  }
  window.history.replaceState(null, "", newUrl);
};

export default removeQueryParam;
