export const showGlobalNotification = (message, type = "error") => {
  if (window.showNotification) {
    window.showNotification(type, message);
  } else {
    console.log({
      type,
      message,
    });
  }
};
