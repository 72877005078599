import { all, put } from "redux-saga/effects";
import { call, delay, select, takeEvery, takeLatest } from "@redux-saga/core/effects";
import {
  addCallScriptForDefaultFailed,
  addCallScriptForDefaultSuccess,
  addGeneralScriptFailed,
  addGeneralScriptSuccess,
  deleteCallScriptForDefaultFailed,
  deleteCallScriptForDefaultSuccess,
  deleteGeneralScriptFailed,
  deleteGeneralScriptSuccess,
  getDefaultCallSettingFailed,
  getDefaultCallSettingSuccess,
  getGeneralScriptsFailed,
  getGeneralScriptsSuccess,
  getScriptForDefaultSettingFailed,
  getScriptForDefaultSettingSuccess,
  updateGeneralScriptFailed,
  updateGeneralScriptSuccess,
} from "./defaultCallSettingSlice";
import { showErrorToaster } from "../../../../helpers/utils/toaster";
import { selectDefaultCallSetting } from "../defaultListSettingSelector";
import DefaultListSettingService from "../../../../services/DefaultListSetting.service";
import { showGlobalNotification } from "../../../../helpers/utils/showGlobalNotification";

function* defaultCallSettingWatcher() {
  yield takeEvery("defaultCallSetting/getDefaultCallSetting", getDefaultCallSettingSaga);
  yield takeLatest("defaultCallSetting/updateDefaultCallSetting", updateDefaultCallSettingSaga);
  /* for general call scripts */
  yield takeLatest("defaultCallSetting/addGeneralScript", addGeneralScriptSaga);
  yield takeLatest("defaultCallSetting/getGeneralScripts", getGeneralScriptsSaga);
  yield takeLatest("defaultCallSetting/updateGeneralScript", updateGeneralScriptSaga);
  yield takeLatest("defaultCallSetting/deleteGeneralScript", deleteGeneralScriptSaga);
  yield takeLatest("defaultCallSetting/hasMoreGeneralScripts", hasMoreGeneralScriptsSaga);

  // For call script
  yield takeLatest("defaultCallSetting/getScriptForDefaultSetting", getCallScriptForDefaultSettingSaga);
  yield takeLatest("defaultCallSetting/addCallScriptForDefault", addCallScriptForDefaultSaga);
  yield takeLatest("defaultCallSetting/deleteCallScriptForDefault", deleteCallScriptForDefaultSaga);
}

function* getDefaultCallSettingSaga() {
  try {
    const response = yield call(DefaultListSettingService.getCallSetting);

    if (response.success) {
      yield put(getDefaultCallSettingSuccess(response.data));
    } else {
      yield put(getDefaultCallSettingFailed(response.message));
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

function* updateDefaultCallSettingSaga() {
  try {
    const { settings } = yield select(selectDefaultCallSetting);
    yield delay(1000);
    const response = yield call(DefaultListSettingService.updateCallSetting, settings);

    if (!response.success) {
      showErrorToaster(response.message);
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

/* for general call scripts */
function* addGeneralScriptSaga(action) {
  try {
    // yield delay(3000);
    /* in response : full data */
    // let local = true;
    // if (!local) {
    const response = yield call(DefaultListSettingService.addGeneralScriptApi, action.payload.payload);
    // console.log(responsePrd);
    // }
    // const response = {
    //   success: true,
    //   message: "Script added successfully",
    //   data: {
    //     id: new Date().toString(),
    //     ...action.payload.payload,
    //   },
    // };

    if (response.success) {
      yield put(addGeneralScriptSuccess(response.data));
      if (action.payload.callback) {
        action.payload.callback(response.data);
      }
    } else {
      // showErrorToaster(response.message);
      showGlobalNotification(response.message, "error");
      yield put(addGeneralScriptFailed());
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}
function* getGeneralScriptsSaga(action) {
  try {
    // yield delay(3000);
    // let local = true;
    /* 
      page: 1,
      perPage: 10,
      search: "",
    */
    // if (!local) {
    const response = yield call(DefaultListSettingService.getGeneralScriptsApi, action.payload);
    //   console.log(responsePrd);
    // }
    // const response = {
    //   success: true,
    //   message: "Get Data Successfully !",
    //   data: scriptList,
    // };

    if (response.success) {
      yield put(getGeneralScriptsSuccess(response.data));
    } else {
      yield put(getGeneralScriptsFailed());
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}
function* updateGeneralScriptSaga(action) {
  try {
    // yield delay(3000);
    // let local = true;
    // if (!local) {
    console.log(action.payload);
    const response = yield call(DefaultListSettingService.updateGeneralScriptApi, action.payload.payload);
    //   console.log(responsePrd);
    // }
    // const response = {
    //   success: true,
    //   message: "Script edited successfully",
    // };
    if (response.success) {
      yield put(updateGeneralScriptSuccess(action.payload.payload));
      if (action.payload.callback) {
        action.payload.callback();
      }
    } else {
      showErrorToaster(response.message);
      yield put(updateGeneralScriptFailed());
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}
function* deleteGeneralScriptSaga(action) {
  try {
    // yield delay(3000);
    // let local = true;
    // if (!local) {
    const response = yield call(DefaultListSettingService.deleteGeneralScriptApi, { id: action.payload.payload.id });
    // console.log(responsePrd);
    // }
    // const response = {
    //   success: true,
    //   message: "Script deleted successfully",
    //   data: action.payload.payload,
    // };
    if (response.success) {
      console.log(action.payload.payload);
      yield put(deleteGeneralScriptSuccess(action.payload.payload));
      if (action.payload.callback) {
        action.payload.callback();
      }
    } else {
      showErrorToaster(response.message);
      yield put(deleteGeneralScriptFailed());
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

function* hasMoreGeneralScriptsSaga(action) {
  try {
    const response = yield call(DefaultListSettingService.getGeneralScriptsApi, action.payload);
    if (response.success) {
      yield put(getGeneralScriptsSuccess(response.data));
    } else {
      yield put(getGeneralScriptsFailed());
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

/* for call scripts */
function* getCallScriptForDefaultSettingSaga(action) {
  try {
    const response = yield call(DefaultListSettingService.getCallScriptsApi, action.payload);
    if (response.success) {
      yield put(getScriptForDefaultSettingSuccess(response.data));
    } else {
      yield put(getScriptForDefaultSettingFailed());
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

function* addCallScriptForDefaultSaga(action) {
  try {
    const response = yield call(DefaultListSettingService.addCallScriptApi, { ids: action.payload.payload });
    if (response.success) {
      yield put(addCallScriptForDefaultSuccess(action.payload.payload));
      if (action.payload.callback) {
        action.payload.callback();
      }
    } else {
      showErrorToaster(response.message);
      yield put(addCallScriptForDefaultFailed());
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

function* deleteCallScriptForDefaultSaga(action) {
  console.log(action);
  try {
    // yield delay(3000);
    // let local = true;
    // if (!local) {
    const response = yield call(DefaultListSettingService.deleteCallScriptApi, { id: action.payload.payload?.id });
    // }
    // const response = {
    //   success: true,
    //   message: "Script deleted successfully",
    //   data: action.payload.payload,
    // };
    if (response.success) {
      yield put(deleteCallScriptForDefaultSuccess(action.payload.payload));
      if (action.payload.callback) {
        action.payload.callback();
      }
    } else {
      showErrorToaster(response.message);
      yield put(deleteCallScriptForDefaultFailed());
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

export default function* defaultCallSettingSaga() {
  yield all([defaultCallSettingWatcher()]);
}
