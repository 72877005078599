import httpRequest from "./api/httpRequest";

class ListSetting {
  getCallSetting(params) {
    return httpRequest.get(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/list/settings/call-setting",
      params
    );
  }

  updateCallSetting(params) {
    return httpRequest.post(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/list/settings/call-setting",
      params
    );
  }

  getTriggerSetting(params) {
    return httpRequest.get(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/list/settings/trigger-setting",
      params
    );
  }

  updateTriggerSetting(params) {
    return httpRequest.post(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/list/settings/trigger-setting",
      params
    );
  }

  getScriptSetting(params) {
    return httpRequest.get(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/list/settings/script-setting",
      params
    );
  }

  updateScriptSetting(params) {
    return httpRequest.post(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/list/settings/script-setting",
      params
    );
  }

  makeDefaultScript(params) {
    return httpRequest.post(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/list/settings/script/make-default",
      params
    );
  }

  deleteScript(params) {
    return httpRequest.post(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/list/settings/script/delete",
      params
    );
  }

  uploadAttachment(params) {
    return httpRequest.post(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/list/settings/attach-files",
      params
    );
  }

  uploadVoiceFile(params) {
    return httpRequest.post(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/list/settings/voice-upload",
      params
    );
  }

  addScriptFromExistingApi(params) {
    return httpRequest.post(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/general-script/add-to-power-dialer-script",
      params
    );
  }
}

export default new ListSetting();
