import { useDispatch, useSelector } from "react-redux";
import { Button, styled, Tabs, Typography } from "@mui/material";
import { ConversationTabs, TimelineContentTypes } from "../../../helpers/constant/Constants";
import ButtonBack from "../../screens/auto-dialer-ongoing/global/ButtonBack/ButtonBack";
import { ArrowBack, DocIcon } from "../../screens/auto-dialer-ongoing/IconsOngoing";
import Styles from "./conversation.module.scss";
import { useEffect } from "react";
import ConversationItem from "./ConversationItem";
import CallScript from "./CallScript/CallScript";
import {
  // getContactScript,
  getConversation,
  handleConnectedData,
  handleConversationState,
} from "../../../state/features/dialer/dialerSlice";
import { selectDialerState } from "../../../state/features/dialer/dialerSelector";
import { getContactName } from "../../../helpers/utils/getContactName";

const StyledButton = styled(Button)(({ theme }) => ({
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "24px",
  color: theme.palette.secondary.main,
  borderColor: theme.palette.secondary.main,
  "&:hover": {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    "& svg": {
      "& path": {
        fill: theme.palette.common.white,
      },
    },
  },
}));

const Conversation = () => {
  const dispatch = useDispatch();
  const {
    connected: { tab, lastConversation, contact: contactDetails },
    conversation: { script },
  } = useSelector(selectDialerState);

  useEffect(() => {
    if (tab === TimelineContentTypes.script && script == null) {
      // dispatch(getContactScript());
    } else {
      if (tab !== lastConversation) {
        dispatch(handleConnectedData({ lastConversation: tab }));
        dispatch(getConversation());
      }
    }
  }, [tab]);

  const renderTabs = () => {
    const view = [];
    ConversationTabs.forEach((item, index) => {
      if (item.isShow) {
        view.push(
          <li
            key={index}
            onClick={() => {
              if (item.id !== TimelineContentTypes.script) {
                dispatch(handleConnectedData({ tab: item.id }));
              } else {
                dispatch(handleConversationState({ type: item.id }));
                dispatch(handleConnectedData({ tab: item.id }));
              }
            }}
            className={`${Styles.pdadDialerOmcMiddleImTabLi} ${tab === item.id ? Styles.pdadDialerOmcActiveTab : ""}`}
          >
            <Typography sx={{ color: "text.secondary" }} variant='buttonMedium'>
              {item.title}
            </Typography>
          </li>
        );
      }
    });
    return view;
  };

  const renderView = () => {
    if (tab === TimelineContentTypes.script) {
      return <CallScript Styles={Styles} showOnlyData={true} />;
    }
    return <ConversationItem />;
  };

  return (
    <div className={`${Styles.pdadDialerOmcMiddleImWrapper}`}>
      <div className={`${Styles.pdadDialerOmcMiddleImTop}`}>
        <Typography
          fontWeight={500}
          variant='body2medium'
          sx={{ color: "action.active" }}
          className={`${Styles.tabHidden}`}
        >
          Conversation History
        </Typography>
        <div className={Styles.pdadDialerOmcMiddleImBack}>
          <ButtonBack
            backHandler={() => {
              if (window.handleTabScreen != undefined) {
                window.handleTabScreen();
              }
            }}
            border={false}
            icon={<ArrowBack />}
            title={`Contact Info: ${getContactName(contactDetails)}`}
          />
        </div>

        <StyledButton
          className={tab === TimelineContentTypes.script ? Styles.tabHiddenSelect : Styles.tabHidden}
          onClick={() => dispatch(handleConnectedData({ tab: TimelineContentTypes.script }))}
          variant='outlined'
          startIcon={<DocIcon />}
        >
          View Script
        </StyledButton>
      </div>

      <div className={Styles.pdadDialerOmcMiddleImTabWr}>
        <ul className={Styles.pdadDialerOmcMiddleImTabUl}>
          <Tabs variant='scrollable' scrollButtons='auto' aria-label='scrollable-contact-line-tabs'>
            {renderTabs()}
          </Tabs>
        </ul>
      </div>
      {renderView()}
      {/* <ConversationItem /> */}
    </div>
  );
};

export default Conversation;
