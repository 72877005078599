import { all, put } from "redux-saga/effects";
import { call, takeEvery } from "@redux-saga/core/effects";
import DialerAnalyticsService from "../../../services/DialerAnalytics.service";
import { getDialerAnalyticsFailed, getDialerAnalyticsSuccess } from "./dialerAnalyticsSlice";
import secondsToTime from "../../../helpers/utils/secondsToTime";

function* dialerAnalyticsWatcher() {
  yield takeEvery("dialerAnalytics/getDialerAnalytics", getDialerAnalyticsSaga);
}

function* getDialerAnalyticsSaga(action) {
  try {
    const response = yield call(DialerAnalyticsService.getDialerAnalytics, action.payload);

    // {
    //   "total_contacts": "2",
    //   "total_call_received": "1",
    //   "total_call_disconnect": "0",
    //   "total_estimate_session_time": "0",
    //   "total_ringing_time": "23",
    //   "total_dialing_session": "0",
    //   "total_on_going_call": "0",
    //   "total_waiting_time": "0",
    //   "total_out_bound_duration": "12"
    // }

    if (response.success) {
      const {
        total_contacts,
        total_call_received,
        total_call_disconnect,
        total_estimate_session_time,
        total_ringing_time,
        total_dialing_session,
        total_on_going_call,
        total_waiting_time,
        total_out_bound_duration,
      } = response.data || {};

      const result = {
        totalContacts: total_contacts || 0,
        totalCallReceived: total_call_received || 0,
        totalCallDisconnect: total_call_disconnect || 0,
        totalDialingSession: total_dialing_session || 0,
        totalOnGoingCall: secondsToTime(total_on_going_call || 0),
        totalEstimateSessionTime: secondsToTime(total_estimate_session_time || 0),
        totalRingingTime: secondsToTime(total_ringing_time || 0),
        totalWaitingTime: secondsToTime(total_waiting_time || 0),
        totalOutBoundDuration: secondsToTime(total_out_bound_duration || 0),

        totalOnGoingCallBySec: +total_on_going_call || 0,
        totalRingingTimeBySec: +total_ringing_time || 0,
        totalWaitingTimeBySec: +total_waiting_time || 0,
        totalOutBoundDurationBySec: +total_out_bound_duration || 0,
      };

      yield put(getDialerAnalyticsSuccess(result));
    } else {
      yield put(getDialerAnalyticsFailed(response.message));
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

export default function* dialerAnalyticsSaga() {
  yield all([dialerAnalyticsWatcher()]);
}
