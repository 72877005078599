import React from "react";
import InitializingService from "../../services/call/Initializing.service";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // let api = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/container/log/react-error`
    // sendRequest("post",api , {
    //     module : 'Dashboard',
    //     error : error,
    //     errorInfo : errorInfo.componentStack
    // })
    InitializingService.submitError({
      module: "power-dialer",
      error: error,
      errorInfo: errorInfo.componentStack,
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
