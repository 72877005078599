const NON_CUSTOM_FIELDS = [
  "pype_default_contact",
  "pype_default_user",
  "pype_date",
  "pype_agency",
  "user_cutomize_personalized_fields",
];

export const getLocalPersonalizedTags = () => {
  let localData = localStorage.getItem("global__custom__field__data");
  if (localData) {
    let parsedData = JSON.parse(localData);
    if (parsedData.data !== undefined) {
      let diffTime = Date.now() - Date.parse(parsedData.time);
      if (diffTime < 1000 * 60 * 60 * 24) {
        if (parsedData.data[0].group_value === "pype_calendar_widget") {
          parsedData.data.splice(0, 1);
        }
        let newFormatData = parsedData.data.map((eachGroup) => ({
          title: `${eachGroup.group_name} (${eachGroup.total})`,
          isCustomField: !NON_CUSTOM_FIELDS.includes(eachGroup.group_value),
          tags: eachGroup.list.map((eachField) => ({ title: eachField.field_label, value: eachField.value })),
        }));
        return newFormatData;
      }
    } else {
      localStorage.removeItem("global__custom__field__data");
    }
  }

  return null;
};
