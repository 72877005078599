import { all, put } from "redux-saga/effects";
import { call, takeEvery } from "@redux-saga/core/effects";
import {
  deletedDialerContactFailed,
  deletedDialerContactSuccess,
  getDialerContactsFailed,
  getDialerContactsSuccess,
} from "./dialerContactsSlice";
import DialerContactsService from "../../../services/DialerContacts.service";
import { showErrorToaster, showSuccessToaster } from "../../../helpers/utils/toaster";

function* dialerContactsWatcher() {
  yield takeEvery("dialerContacts/getDialerContacts", getDialerContactsSaga);
  yield takeEvery("dialerContacts/deletedDialerContact", deletedDialerContactSaga);
}

function* getDialerContactsSaga(action) {
  try {
    const response = yield call(DialerContactsService.getDialerContacts, action.payload);

    if (response.success) {
      yield put(getDialerContactsSuccess(response.data));
    } else {
      yield put(getDialerContactsFailed(response.message));
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

function* deletedDialerContactSaga(action) {
  try {
    const response = yield call(DialerContactsService.deletedDialerContact, action.payload);

    if (response.success) {
      yield put(deletedDialerContactSuccess());
      showSuccessToaster(response.message);
    } else {
      yield put(deletedDialerContactFailed(response.message));
      showErrorToaster(response.message);
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

export default function* dialerContactsSaga() {
  yield all([dialerContactsWatcher()]);
}
