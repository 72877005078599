import { useSelector } from "react-redux";
import { ArrowBack } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { getContactAvatar } from "../../../helpers/utils/getContactAvatar";
import { getContactName } from "../../../helpers/utils/getContactName";
import ButtonBack from "../../screens/auto-dialer-ongoing/global/ButtonBack/ButtonBack";
import { selectDialerState } from "../../../state/features/dialer/dialerSelector";
import Styles from "./contactDetails.module.scss";
import HrefLink from "../../../common/Link/HrefLink";
import { UserIcon } from "../../../common/icon/Icons";

const ContactDetails = () => {
  const {
    connected: { contact: contactDetails },
  } = useSelector(selectDialerState);
  if (contactDetails == null) {
    return null;
  }
  return (
    <div className={Styles.wrapper}>
      <span className={Styles.title}>
        <Typography
          variant='body2medium'
          sx={{ padding: "28px 24px 4px 24px", display: "block", color: "action.active" }}
        >
          Connected Contact
        </Typography>
      </span>

      <div className={Styles.backButtonSection}>
        <ButtonBack
          backHandler={() => {
            if (window.handleTabScreen != undefined) {
              window.handleTabScreen();
            }
          }}
          icon={<ArrowBack />}
          title={`Contact Info: ${getContactName(contactDetails)}`}
        />
      </div>

      <div className={Styles.profile}>
        <div className={Styles.avatar}>
          <Typography variant='body1'>
            {getContactAvatar(
              contactDetails?.first_name,
              contactDetails?.last_name,
              contactDetails?.email,
              contactDetails?.number
            )}
          </Typography>
        </div>
        <div className={Styles.pdadOverflowHidden}>
          <Typography variant='body1medium' className={Styles.truncate}>
            {getContactName(contactDetails)}
          </Typography>
          <Typography
            variant='body2'
            color='action.active'
            className={`${Styles.proStatus}`}
            sx={{
              cursor: "pointer",
              color: "#fff",
              background: "#006DF5",
              padding: "2px 4px",
              borderRadius: "4px",
              display: "flex",
            }}
          >
            <HrefLink href={`/contacts/${contactDetails.id}`} target={"_blank"}>
              <UserIcon fill={"#fff"} height={18} width={18} /> View Profile
            </HrefLink>
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default ContactDetails;
