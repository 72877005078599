import { all, put } from "redux-saga/effects";
import { call, delay, takeEvery } from "@redux-saga/core/effects";
import {
  addMainContactOnDialerListReset,
  addMainContactOnDialerListSuccess,
  getContactsFailed,
  getContactsSuccess,
  getMoreContactsFailed,
  getMoreContactsSuccess,
} from "./contactsSlice";
import ContactService from "../../../services/Contact.service";
import { setCookie } from "../../../helpers/Cookie";
import { showErrorToaster, showSuccessToaster } from "../../../helpers/utils/toaster";

function* contactsWatcher() {
  yield takeEvery("contacts/getContacts", getContactsSaga);
  yield takeEvery("contacts/getMoreContacts", getMoreContactsSaga);
  yield takeEvery("contacts/addMainContactOnDialerList", addMainContactOnDialerListSaga);
}

function* getContactsSaga(action) {
  try {
    // TODO set api access token in cookie for test
    if (process.env.REACT_APP_PRODUCTION_MODE !== "true") {
      setCookie(
        process.env.REACT_APP_ACCESS_TOKEN,
        "DGM2RNXDL8KS85JD066E",
        process.env.REACT_APP_ACCESS_TOKEN_VALIDITY
      );
      yield delay(1000);
    }

    const response = yield call(ContactService.getContactList, action.payload);

    if (response.status === "SUCCESS") {
      yield put(getContactsSuccess(response.data));

      // TODO set api access token in cookie for test
      if (process.env.REACT_APP_PRODUCTION_MODE !== "true") {
        setCookie(
          process.env.REACT_APP_ACCESS_TOKEN,
          "QW4RFEW1G1HRE1HH1E",
          process.env.REACT_APP_ACCESS_TOKEN_VALIDITY
        );
      }
    } else {
      yield put(getContactsFailed(response.message));
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

function* getMoreContactsSaga(action) {
  try {
    // TODO set api access token in cookie for test
    if (process.env.REACT_APP_PRODUCTION_MODE !== "true") {
      setCookie(
        process.env.REACT_APP_ACCESS_TOKEN,
        "DGM2RNXDL8KS85JD066E",
        process.env.REACT_APP_ACCESS_TOKEN_VALIDITY
      );
      yield delay(1000);
    }

    const response = yield call(ContactService.getContactList, action.payload);

    if (response.status === "SUCCESS") {
      yield put(getMoreContactsSuccess(response.data));

      // TODO set api access token in cookie for test
      if (process.env.REACT_APP_PRODUCTION_MODE !== "true") {
        setCookie(
          process.env.REACT_APP_ACCESS_TOKEN,
          "QW4RFEW1G1HRE1HH1E",
          process.env.REACT_APP_ACCESS_TOKEN_VALIDITY
        );
      }
    } else {
      yield put(getMoreContactsFailed(response.message));
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

function* addMainContactOnDialerListSaga(action) {
  try {
    const response = yield call(ContactService.addMainContactOnDialerList, action.payload);

    if (response.success) {
      yield put(addMainContactOnDialerListSuccess(response.data));
      showSuccessToaster(response.message);
    } else {
      yield put(addMainContactOnDialerListReset(response.message));
      showErrorToaster(response.message);
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

export default function* contactsSaga() {
  yield all([contactsWatcher()]);
}
