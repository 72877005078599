import React, { useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import { CloseDialer } from "../IconsOngoing";
import Styles from "./DialerKeypad.module.scss";
import useDelayCallback from "../../../hooks/useDelayCallback";

const KEY_PAD_DIGITS = [
  {
    label: 1,
    value: 1,
    span: "",
    class: `${Styles.keypadSingleNumCenter} ${Styles.keypadSingleNumber}`,
    isClickable: true,
  },
  {
    label: 2,
    value: 2,
    span: "ABC",
    class: Styles.keypadSingleNumber,
    isClickable: true,
  },
  {
    label: 3,
    value: 3,
    span: "DEF",
    class: Styles.keypadSingleNumber,
    isClickable: true,
  },
  {
    label: 4,
    value: 4,
    span: "GHI",
    class: Styles.keypadSingleNumber,
    isClickable: true,
  },
  {
    label: 5,
    value: 5,
    span: "JKL",
    class: Styles.keypadSingleNumber,
    isClickable: true,
  },
  {
    label: 6,
    value: 6,
    span: "MNO",
    class: Styles.keypadSingleNumber,
    isClickable: true,
  },
  {
    label: 7,
    value: 7,
    span: "PQRS",
    class: Styles.keypadSingleNumber,
    isClickable: true,
  },
  {
    label: 8,
    value: 8,
    span: "TUV",
    class: Styles.keypadSingleNumber,
    isClickable: true,
  },
  {
    label: 9,
    value: 9,
    span: "WXYZ",
    class: Styles.keypadSingleNumber,
    isClickable: true,
  },
  {
    label: "*",
    value: "*",
    span: "",
    class: `${Styles.keypadSingleNumCenter} ${Styles.keypadSingleNumber}`,
    isClickable: true,
  },
  {
    label: 0,
    value: 0,
    span: "+",
    class: Styles.keypadSingleNumber,
    isClickable: true,
  },
  {
    label: "#",
    value: "#",
    span: "",
    class: `${Styles.keypadSingleNumCenter} ${Styles.keypadSingleNumber}`,
    isClickable: true,
  },
];

const DialerKeypad = ({ onClickDigit }) => {
  const inputRef = useRef();
  const [input, setInput] = useState("");
  const [digit, setDigit] = useState("");
  const [inputFocus, setInputFocus] = useState(false);
  useDelayCallback(
    () => {
      if (digit !== "") {
        console.log(digit);
        onClickDigit(digit);
        inputRef?.current?.blur();
        setInputFocus(false);
        setDigit("");
      }
    },
    [digit],
    1500
  );

  const handleClick = (item) => {
    if (item.isClickable) {
      setDigit((prv) => `${prv}${item.value}`);
      setInput((prv) => `${prv}${item.value}`);
    }
  };
  const renderSpan = (item) => {
    if (item.span !== "") {
      return <span>{item.span}</span>;
    }
    return null;
  };

  const renderKeys = () => {
    let view = [];
    KEY_PAD_DIGITS.forEach((item, index) => {
      view.push(
        <Typography onClick={() => handleClick(item)} key={index} variant='body1' className={item.class}>
          {item.label}
          {renderSpan(item)}
        </Typography>
      );
    });
    return view;
  };

  return (
    <div className={Styles.keypadWrapper}>
      <div className={Styles.keypadTopSection} style={{ position: "relative" }}>
        <input
          onChange={(e) => {
            setDigit(e.target.value);
          }}
          onKeyDown={(e) => {
            setInput((prv) => `${prv}${e.key}`);
          }}
          onBlur={() => setInputFocus(false)}
          className={Styles.keypadTopInput}
          // value={input}
          value={digit}
          placeholder='Press digit'
          ref={inputRef}
          style={{
            zIndex: inputFocus ? 9 : 0,
          }}
        />
        <Box
          className={Styles.keypadTopIcon}
          onClick={() => {
            setDigit("");
            setInput("");
          }}
        >
          <CloseDialer />
        </Box>
        <input
          className={`${Styles.keypadTopInput} ${Styles.keypadTopInput2}`}
          onClick={() => {
            setInputFocus(true);
            inputRef?.current?.focus();
          }}
          style={{
            position: "absolute",
            width: "80%",
            height: "100%",
            left: 0,
            top: 0,
            zIndex: inputFocus ? 0 : 1,
            background: "white",
            display: inputFocus ? "none" : "flex",
            alignItems: "center",
            paddingLeft: "10px ",
            overflow: "hidden",
            overflowY: "auto",
          }}
          value={input}
          readOnly
          placeholder='Press digit'
        />
      </div>

      <div className={Styles.keypadNumbersWrapper}>{renderKeys()}</div>
    </div>
  );
};

export default DialerKeypad;
