export const isValidEmailAttachment = (type) => {
  return (
    type.match(/jpg/g) ||
    type.match(/jpeg/g) ||
    type.match(/png/g) ||
    type.match(/svg/g) ||
    type.match(/tiff/g) ||
    type.match(/pdf/g) ||
    type.match(/doc/g) ||
    type.match(/docx/g) ||
    type.match(/xls/g) ||
    type.match(/xlsx/g) ||
    type.match(/ppt/g) ||
    type.match(/pptx/g) ||
    type.match(/txt/g) ||
    type.match(/xml/g) ||
    type.match(/xml/g) ||
    type.match(/mp4/g) ||
    type.match(/webm/g) ||
    type.match(/mp3/g) ||
    type.match(/wav/g) ||
    type.match(/zip/g)
  );
};
