import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  scriptList: [
    {
      title: "Lorem ipsum dolor sit amet consectetur adipisicing - 1",

      script: "<p>asdasdasdasdasd</p>",
      is_default: 0,
      id: 186,
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus ex perferendis rem velit deleniti",
    },
    {
      title: "Lorem ipsum dolor sit amet - 2",
      script: "<p>fd</p>",
      is_default: 0,
      id: 185,
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus ex perferendis",
    },
    {
      title: "Lorem ipsum dolor sit amet consectetur adipisicing elit - 3",
      script: "<p>asf</p>",
      is_default: 1,
      id: 184,
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus ex perferendis rem velit deleniti perferendis rem velit deleniti",
    },
  ],
  editableScript: {},
};

const generalScriptSlice = createSlice({
  name: "generalScript",
  initialState: initialState,
  reducers: {
    addGeneralScript: (state, action) => {
      state.scriptList.push({ ...action.payload, id: state.scriptList.length + 1 });
    },
    deleteGeneralScript: (state, action) => {
      state.scriptList = state.scriptList.filter((script) => script.id !== action.payload);
    },

    updateGeneralScript: (state, action) => {
      let payloadObj = action.payload;
      let scriptObj = state.scriptList.find((obj) => obj.id === payloadObj.script_id);
      if (scriptObj) {
        scriptObj.title = payloadObj.title;
        scriptObj.description = payloadObj.description;
        scriptObj.script = payloadObj.script;
      }
    },

    addEditableScript: (state, action) => {
      state.editableScript = action.payload;
    },
  },
});

export const { addGeneralScript, deleteGeneralScript, updateGeneralScript, addEditableScript } =
  generalScriptSlice.actions;
export default generalScriptSlice.reducer;
