import React, { useEffect, useState } from "react";
import FineUploaderTraditional from "fine-uploader-wrappers";
import FileInput from "react-fine-uploader/file-input";
import ProgressBar from "react-fine-uploader/progress-bar";
import Filename from "react-fine-uploader/filename";
import Status from "react-fine-uploader/status";
import CancelButton from "react-fine-uploader/cancel-button";
import DeleteButton from "react-fine-uploader/delete-button";
import Filesize from "react-fine-uploader/filesize";
import "./uploader.css";
import { getCookie } from "../../helpers/Cookie";
import { showGlobalNotification } from "../../helpers/utils/showGlobalNotification";

const Uploader = ({ imagesList, updateComponent }) => {
  const [state, setState] = useState({
    submittedFiles: [],
    images: [],
    error: false,
    errorMessage: null,
    apiKeyInterval: null,
    uploader: null,
  });

  useEffect(() => {
    if (
      getCookie(process.env.REACT_APP_ACCESS_TOKEN) !== "" ||
      getCookie(process.env.REACT_APP_ACCESS_TOKEN) !== null
    ) {
      var uploader = new FineUploaderTraditional({
        options: {
          deleteFile: {
            enabled: true,
            endpoint: `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_VERSION}/contacts/deleted-upload-email-file`,
            params: { "api-key": getCookie(process.env.REACT_APP_ACCESS_TOKEN) },
          },
          request: {
            endpoint: `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_VERSION}/contacts/upload-email-file`,
            params: { "api-key": getCookie(process.env.REACT_APP_ACCESS_TOKEN) },
          },
          validation: {
            allowedExtensions: [
              "csv",
              "txt",
              "pdf",
              "mp3",
              "mp4",
              "mov",
              "wav",
              "webm",
              "3gp",
              "avi",
              "doc",
              "docx",
              "odt",
              "ppt",
              "pptx",
              "gif",
              "png",
              "jpg",
              "jpeg",
              "xls",
              "xlsx",
              "zip",
              "tar",
              "xz",
              "7z",
              "rar",
              "odp",
              "odg",
            ],
            itemLimit: 5,
            sizeLimit: 15728640,
            stopOnFirstInvalidFile: true,
          },
          messages: {
            typeError: "{file} has an invalid extension. Valid extensions[{extensions}].",
            sizeError: "{file} is too large, maximum file size is {sizeLimit}.",
            tooManyItemsError: "Too many items ({netItems}) would be uploaded. Item limit is {itemLimit}.",
          },
        },
      });
      uploaderCallback(uploader);
      setState({ ...state, uploader: uploader });
    } else {
      let interval = setInterval(() => {
        if (
          getCookie(process.env.REACT_APP_ACCESS_TOKEN) !== "" ||
          getCookie(process.env.REACT_APP_ACCESS_TOKEN) !== null
        ) {
          var uploader = new FineUploaderTraditional({
            options: {
              deleteFile: {
                enabled: false,
                endpoint: "",
              },
              request: {
                endpoint: `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_VERSION}/contacts/upload-email-file`,
                params: { "api-key": getCookie(process.env.REACT_APP_ACCESS_TOKEN) },
              },
              validation: {
                allowedExtensions: [
                  "csv",
                  "txt",
                  "pdf",
                  "mp3",
                  "mp4",
                  "mov",
                  "wav",
                  "webm",
                  "3gp",
                  "avi",
                  "doc",
                  "docx",
                  "odt",
                  "ppt",
                  "pptx",
                  "gif",
                  "png",
                  "jpg",
                  "jpeg",
                  "xls",
                  "xlsx",
                  "zip",
                  "tar",
                  "xz",
                  "7z",
                  "rar",
                  "odp",
                  "odg",
                ],
                itemLimit: 5,
                sizeLimit: 15728640,
                stopOnFirstInvalidFile: true,
              },
              messages: {
                typeError: "{file} has an invalid extension. Valid extensions[{extensions}].",
                sizeError: "{file} is too large, maximum file size is {sizeLimit}.",
                tooManyItemsError: "Too many items ({netItems}) would be uploaded. Item limit is {itemLimit}.",
              },
            },
          });
          uploaderCallback(uploader);
          setState({ ...state, uploader: uploader });
          clearInterval(state.apiKeyInterval);
          setState({ ...state, apiKeyInterval: null });
        }
      }, 1000);
      setState({ ...state, apiKeyInterval: interval });
    }
  }, []);

  useEffect(() => {
    if (updateComponent.length == 0) {
      setState({
        ...state,
        ...{
          image: [],
          submittedFiles: [],
          error: false,
        },
      });
    }
  }, [updateComponent]);

  const uploaderCallback = (uploader) => {
    uploader.on("statusChange", (id, oldStatus, newStatus) => {
      if (newStatus === "submitted") {
        const submittedFiles = state.submittedFiles;
        submittedFiles.push(id);
        setState({ ...state, submittedFiles: submittedFiles, error: false });
      } else if (isFileGone(newStatus)) {
        const submittedFiles = state.submittedFiles;
        const indexToRemove = submittedFiles.indexOf(id);

        submittedFiles.splice(indexToRemove, 1);
        setState({ ...state, submittedFiles: submittedFiles });
      }
    });

    uploader.on("complete", (id, fileName, response) => {
      const images = state.images;
      images.push({
        id: id,
        // 'name' : response.fileName,
        name: response.mediaUrl,
        file_original_name: fileName,
      });
      setState({ ...state, images: images });
      imagesList(state.images);
    });

    uploader.on("error", (event, id, name /* errorReason, xhrOrXdr */) => {
      setState({ ...state, error: true, errorMessage: name });
      showGlobalNotification(name);
    });

    uploader.on("delete", (id) => {
      const images = state.images;
      let deleteImage = images.filter((el) => el.id != id);

      setState({ ...state, images: deleteImage });
      imagesList(state.images);
    });

    uploader.on("cancel", (id) => {
      const images = state.images;
      let cancelImage = images.filter((el) => el.id != id);
      setState({ ...state, images: cancelImage });

      imagesList(state.images);
    });
  };

  return (
    <div>
      {state.error ? <div className='text-danger'>{state.errorMessage}</div> : ""}
      {state.uploader !== null && (
        <FileInput multiple uploader={state.uploader}>
          <button className='btn btn-sm btn-secondary mr-1 accent--bg--color '>
            <i className='la la-cloud-upload'></i> Attach Files
          </button>
          <span className='text-warning'>
            <i className={"fa fa-info rounded bg-warning p-1 text-light mt-1 mr-1"}></i>Max size 15Mb, Max 5 files.
          </span>
        </FileInput>
      )}
      <div className='uploaded-files'>
        {state.submittedFiles.map((id) => (
          <div key={id} className='d-flex align-items-center file-upload-stats'>
            <Filename id={id} uploader={state.uploader} />
            <div className='ml-auto d-flex align-items-center'>
              <ProgressBar id={id} uploader={state.uploader} />
              <Filesize id={id} uploader={state.uploader} />
              <CancelButton id={id} uploader={state.uploader} className={"btn btn-sm btn-outline-warning"} />
              <DeleteButton id={id} uploader={state.uploader} className={"btn btn-sm btn-outline-danger"} />
              <Status
                id={id}
                uploader={state.uploader}
                text={{
                  upload_successful: <i className={"fa fa-check-circle"}></i>,
                  upload_failed: <i className={"fa fa-remove"}></i>,
                  uploading: "",
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const isFileGone = (status) => {
  return ["canceled", "deleted"].indexOf(status) >= 0;
};

export default Uploader;
