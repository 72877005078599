import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Cell, Pie, PieChart } from "recharts";
import { Box, Skeleton, Stack, styled, Typography } from "@mui/material";
import { selectDialerAnalyticsState } from "../../state/features/dialerAnalytics/dialerAnalyticsSelector";
import { HelpIcon } from "../../common/icon/Icons";
import InfoModal from "./InfoModal";

const TotalDuration = styled(Stack)(() => ({
  position: "absolute",
  top: "calc(50% - 5px)",
  left: "4px",
  textAlign: "center",
  width: "100%",
}));

const ChartIdentifierColor = styled(Box)(({ backgroundColor }) => ({
  backgroundColor: backgroundColor,
  width: "12px",
  height: "12px",
  borderRadius: "50%",
}));

const COLORS = ["#0088FE", "#00C49F", "#FFBB28"];

const ReportContentList = ({ color = "", name, duration }) => {
  const [ringTimeInfoModalOpen, setRingTimeInfoModalOpen] = useState(false);
  return (
    <Stack direction={"row"} alignItems={"baseline"} justifyContent={"center"} spacing={1} sx={{ width: "180px" }}>
      <ChartIdentifierColor backgroundColor={color} />
      <div>
        <div style={{ display: "flex", gap: "6px", alignItems: "center", justifyContent: "center" }}>
          <Typography variant={"body1medium"}>{name}</Typography>
          <div onClick={() => setRingTimeInfoModalOpen(true)} style={{ cursor: "pointer", marginTop: "6px" }}>
            <HelpIcon />
          </div>
          {ringTimeInfoModalOpen && (
            <InfoModal
              open={ringTimeInfoModalOpen}
              onClose={() => setRingTimeInfoModalOpen(false)}
              title={
                "Total Ring Time represents the amount of time you spent waiting for a call to connect to a contact."
              }
              example={
                "If you call a single contact and the phone rings for 10 seconds before a call is connected, 10 seconds will be added to your total ring time."
              }
              anotherExample={
                "If you call 3 numbers at once and the phone rings for 30 seconds before a call is connected, 30 seconds will be added to your total ring time. (not 30 seconds x 3 calls= 90 seconds)"
              }
            />
          )}
        </div>
        <Typography variant={"body1"} color={"text.secondary"}>
          {duration}
        </Typography>
      </div>
    </Stack>
  );
};

const AnalyticsPieChart = () => {
  const { data: analyticReportData, isLoading } = useSelector(selectDialerAnalyticsState);
  const [talkTimeInfoModalOpen, setTalkTimeInfoModalOpen] = useState(false);

  const {
    totalRingingTime,
    // totalOnGoingCall,
    // totalWaitingTime,
    totalOutBoundDuration,
    // totalRingingTimeBySec,
    // totalOnGoingCallBySec,
    // totalWaitingTimeBySec,
    totalOutBoundDurationBySec,
  } = analyticReportData || {};

  // const totalTimeBySec = totalRingingTimeBySec + totalOnGoingCallBySec + totalWaitingTimeBySec;

  const pieChartData = [
    { name: "Ringing", value: totalOutBoundDurationBySec || 1, duration: totalOutBoundDurationBySec },
    // { name: "Ringing", value: totalTimeBySec === 0 ? 1 : totalRingingTimeBySec, duration: totalRingingTime },
    // { name: "Ongoing Call", value: totalTimeBySec === 0 ? 1 : totalOnGoingCallBySec, duration: totalOnGoingCall },
    // { name: "Waiting", value: totalTimeBySec === 0 ? 1 : totalWaitingTimeBySec, duration: totalWaitingTime },
  ];

  return (
    <Stack direction={"column-reverse"} alignItems={"center"} spacing={1} justifyContent={"center"}>
      <Box sx={{ position: "relative" }}>
        <PieChart width={400} height={400}>
          <Pie
            data={pieChartData}
            cx={200}
            cy={200}
            innerRadius={120}
            outerRadius={140}
            fill='#8884d8'
            paddingAngle={2}
            dataKey='value'
          >
            {pieChartData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
        <TotalDuration alignItems={"center"} justifyContent={"center"}>
          <div style={{ display: "flex", gap: "6px", alignItems: "center", justifyContent: "center" }}>
            <Typography variant={"body2medium"} color={"text.secondary"}>
              Total Talk Time
            </Typography>
            <div onClick={() => setTalkTimeInfoModalOpen(true)} style={{ cursor: "pointer", marginTop: "6px" }}>
              <HelpIcon />
            </div>
            {talkTimeInfoModalOpen && (
              <InfoModal
                open={talkTimeInfoModalOpen}
                onClose={() => setTalkTimeInfoModalOpen(false)}
                title={"Total Talk Time represents the amount of time spent having live conversations with a contact."}
                example={
                  "If you talk to contact John Doe for 2 minutes, contact Jane Doe for 3 minutes, and contact Sally Doe for 5 minutes, your total talk time would be displayed as 10 minutes."
                }
              />
            )}
          </div>
          <Typography variant={"h6"} color={"text.primary"} sx={{ fontSize: "16px" }}>
            {totalOutBoundDuration}
          </Typography>
        </TotalDuration>
      </Box>

      <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} spacing={2}>
        <ReportContentList
          color={COLORS[0]}
          name={"Total Ring Time"}
          duration={isLoading ? <Skeleton variant={"text"} width={48} /> : totalRingingTime}
        />
        {/*<Divider orientation='vertical' variant='middle' flexItem sx={{ height: "25px", alignSelf: "center" }} />*/}
        {/*<ReportContentList*/}
        {/*  color={COLORS[1]}*/}
        {/*  name={"Ongoing Call"}*/}
        {/*  duration={isLoading ? <Skeleton variant={"text"} width={80} /> : totalOnGoingCall}*/}
        {/*/>*/}
      </Stack>
    </Stack>
  );
};

export default AnalyticsPieChart;
