import apiSlice from "../../../apiSlice";
import {
  appendNewSelectedTag,
  updateTriggerSettingOnCreateTag,
} from "../../listSetting/triggerSetting/triggerSettingSlice";
import { ADD_TAG_FOR } from "../../../../helpers/constant/Constants";
import { showErrorToaster } from "../../../../helpers/utils/toaster";

export const tagApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTags: builder.query({
      query: (params) => ({
        url: process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/list/settings/tags",
        method: "GET",
        params: params,
      }),
    }),
    createTag: builder.mutation({
      query: (params) => ({
        url: process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/list/settings/tag/create",
        method: "POST",
        params: params,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const response = await queryFulfilled;

          if (response.data.success) {
            if (arg.for === ADD_TAG_FOR.TRIGGER) {
              dispatch(appendNewSelectedTag(response.data.data));
              dispatch(updateTriggerSettingOnCreateTag(response.data.data));
            }
            dispatch(
              apiSlice.util.updateQueryData("getTags", undefined, (draft) => {
                return {
                  ...draft,
                  data: {
                    ...draft.data,
                    tags: [...draft.data.tags, response.data.data],
                  },
                };
              })
            );
          } else {
            dispatch(showErrorToaster(response.data.message));
          }
        } catch (err) {
          //    do nothing
        }
      },
    }),
  }),
});

export const { useGetTagsQuery, useCreateTagMutation } = tagApiSlice;
