import httpRequest from "./api/httpRequest";

class DialerContactsService {
  getDialerContacts(body) {
    return httpRequest.post(process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/list/contacts", body);
  }

  deletedDialerContact(body) {
    return httpRequest.post(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/list/contact/delete",
      body
    );
  }

  addContactsToOtherDialerList(body) {
    return httpRequest.post(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/list/contact/add/another/list",
      body
    );
  }
}

export default new DialerContactsService();
