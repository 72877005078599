import { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { MentionsInput, Mention } from "react-mentions";
import AddNoteStyles from "./addNote.module.scss";
import ContactNoteService from "../../../../services/call/ContactNote.service";
import { selectDialerState } from "../../../../state/features/dialer/dialerSelector";
import { useDispatch, useSelector } from "react-redux";
import { showGlobalNotification } from "../../../../helpers/utils/showGlobalNotification";
import { geContactNoteSuccess, storeUserData } from "../../../../state/features/dialer/dialerSlice";
import InitializingService from "../../../../services/call/Initializing.service";

const AddNote = () => {
  const dispatch = useDispatch();
  const {
    init: { listId, sessionId },
    connected: { contactId },
    users: { data: users },
  } = useSelector(selectDialerState);
  const [data, setData] = useState({
    isAdding: false,
    newNote: "",
    noteId: null,
  });

  useEffect(() => {
    if (users.length === 0) {
      getUsers();
    }
  }, []);

  const getUsers = () => {
    InitializingService.getUserListForMention().then((response) => {
      console.log(response);
      if (response.success) {
        dispatch(storeUserData(response.data));
      }
    });
  };

  const handleChangeState = (payload) => {
    setData({ ...data, ...payload });
  };

  const onChangeText = (e) => {
    if ((e.key === "Enter" || e.keyCode === 13) && !e.shiftKey) {
      handleSaveNote();
    } else {
      handleChangeState({ newNote: e.target.value });
    }
  };
  const handleSaveNote = () => {
    if (data.newNote.trim() === "") {
      return;
    }
    if (data.isAdding) {
      return;
    }
    handleChangeState({ isAdding: true });
    ContactNoteService.addContactNote({
      id: data.noteId,
      powerDialerListId: listId,
      powerDialerSessionId: sessionId,
      contactId: contactId,
      message: data.newNote.trim(),
    })
      .then((response) => {
        if (response.success) {
          handleChangeState({
            isAdding: false,
            noteId: response.data.id,
          });
          dispatch(geContactNoteSuccess(response.data));
          let message = "Note " + (data.noteId == null ? "added" : "updated");
          showGlobalNotification(message, "success");
        } else {
          handleChangeState({ isAdding: false });
          showGlobalNotification("Something went wrong! Try again later");
        }
      })
      .catch((error) => {
        handleChangeState({ isAdding: false });
        console.log(error);
        showGlobalNotification("Something went wrong! Try again later");
      });
  };

  const renderButtonLabel = () => {
    return (
      (data.isAdding ? (data.noteId == null ? "Adding" : "Updating") : data.noteId == null ? "Add" : "Update") +
      " notes"
    );
  };

  return (
    <div>
      <Box sx={{ p: 1.2, border: "1px solid", borderColor: "other.outlinedBorderDarker", borderRadius: 1 }}>
        <MentionsInput
          cols={30}
          rows={30}
          value={data.newNote}
          onChange={onChangeText}
          onKeyUp={(e) => {
            if (data.isAdding) {
              return;
            }
            if ((e.key === "Enter" || e.keyCode === 13) && !e.shiftKey) {
              handleSaveNote();
            }
          }}
          markup='@[__display__](__id__) '
          placeholder={"Write here"}
          classNames={AddNoteStyles}
        >
          <Mention
            type='user'
            trigger='@'
            data={users}
            renderSuggestion={(suggestion, search, highlightedDisplay) => (
              <div className='userName'>{highlightedDisplay}</div>
            )}
            appendSpaceOnAdd={true}
            className={AddNoteStyles.secondWrapper}
          />
        </MentionsInput>

        <Box sx={{ textAlign: "right" }}>
          <Button
            variant='contained'
            sx={{ backgroundColor: "secondary.main", ":hover": { backgroundColor: "secondary.main" } }}
            onClick={handleSaveNote}
          >
            {renderButtonLabel()}
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default AddNote;
