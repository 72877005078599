import { toUTF16 } from "./toUTF16";

export const checkHasUniCode = (text) => {
  // eslint-disable-next-line no-control-regex
  if (!text.length) return false;
  if (text.charCodeAt(0) > 255) return true;
  for (var i = 0, n = text.length; i < n; i++) {
    if (text.charCodeAt(i) > 255) {
      return true;
    }
    // eslint-disable-next-line no-control-regex
    let regex = /[^\u0000-\u00FF]/;
    const codePoint = text.codePointAt(i);
    const hexCodePoint = codePoint.toString(16);
    let testing = toUTF16(hexCodePoint);
    if (regex.test(testing)) {
      return true;
    }
  }
  return false;
};
