import httpRequest from "../api/httpRequest";

class ContactTagsService {
  getContactTags(params) {
    return httpRequest.get(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/call/contact-tags",
      params
    );
  }
  removeContactTag(params) {
    return httpRequest.post(process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "tag/contact-tag-remove", params);
  }
  addContactTag(params) {
    return httpRequest.post(process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "tag/contact-tag-add", params);
  }
}

export default new ContactTagsService();
