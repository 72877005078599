import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import Styles from "./Header.module.scss";
import { PowerDialer, ZoomDialer } from "../IconsOngoing";
import { selectDialerState } from "../../../state/features/dialer/dialerSelector";
import {
  callTimer,
  DIALER_STEP,
  handleConnectingState,
  handleInitStep,
  handleUserData,
  updateContactOutcome,
} from "../../../state/features/dialer/dialerSlice";
import CustomMenuPype from "../../../common/custom-menu/CustomMenuPype";
import { CarrotIcon } from "../../../common/icon/Icons";
import InitializingService from "../../../services/call/Initializing.service";
import secondToReadableTime from "../../../helpers/utils/secondToReadableTime";
import CountDown from "../../../common/Countdown/CountDown";
import { TIME_LIMIT_CLOSE_CALL } from "../../../helpers/constant/Constants";

var connect_header_interval = null;

const Header = () => {
  const dispatch = useDispatch();
  const {
    init: { listInfo, showMinimizedHandler, step, callStep },
    users: { outcomes, gettingOutcome, selectedOutcome },
    connecting: { stats, hasStats },
    settingOverview: { data: setting },
  } = useSelector(selectDialerState);
  const [countDownValue, setCountDownValue] = useState(0);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (setting?.maximumCallTime != "240") {
      if (connect_header_interval == null) {
        connect_header_interval = setInterval(() => {
          if (callTimer.callingTime >= parseInt(setting?.maximumCallTime) * 60) {
            setShowAlert(true);
          } else {
            setShowAlert(false);
          }
        }, 1000);
      }
    }
    return () => clearInterval(connect_header_interval);
  }, []);

  useEffect(() => {
    if (stats && !hasStats) {
      setCountDownValue(parseInt(stats.total_session_time));
      dispatch(handleConnectingState({ hasStats: true }));
    }
  }, [stats]);

  useEffect(() => {
    if (outcomes.length == 0) {
      getUserOutcome();
    }
  }, []);

  const getUserOutcome = () => {
    InitializingService.getUserOutcome().then((response) => {
      console.log(response.success);
      if (response.success) {
        dispatch(handleUserData({ outcomes: response.data }));
      }
    });
  };

  const renderHints = () => {
    return "Ongoing...";
  };

  const renderSessionTime = () => {
    if (hasStats) {
      return (
        <Box sx={{ textAlign: "right" }}>
          <Typography variant='chipMedium' color='action.active'>
            Total Session Duration
          </Typography>
          <Typography variant='subtitle1' className={Styles.headerRightSubTitle}>
            <CountDown
              startValue={countDownValue}
              endValue={"__infinite__"}
              increment={1}
              callbackOnEveryUpdate={(value) => {
                callTimer["sessionTime"] = value;
              }}
              format={(value) => {
                return secondToReadableTime(value);
              }}
            />
          </Typography>
        </Box>
      );
    }
    return null;
  };

  return (
    <div className={Styles.headerWrapper}>
      <div className={Styles.headerLeft}>
        <span className={Styles.headerLeftIcon}>
          <PowerDialer />
        </span>
        <div>
          <Typography variant='chipMedium' color='action.active'>
            Power Dialer {renderHints()}
          </Typography>
          <Typography variant='subtitle1'>{listInfo?.title}</Typography>
        </div>
      </div>
      {showAlert &&
        callStep == DIALER_STEP.CONNECTED &&
        setting?.actionForMaxCallDuration == TIME_LIMIT_CLOSE_CALL.showAlert && (
          <Typography variant='h5' className={Styles.alertText}>
            Call duration limit is exceeded !
          </Typography>
        )}
      <div className={Styles.headerRight}>
        {step == DIALER_STEP.SHOW_CONNECTED_SCREEN && (
          <Box sx={{ textAlign: "right" }}>
            <CustomMenuPype
              popup={{
                id: "connected-user-outcome",
                wrapperClass: Styles.popDlvnWrapper,
                placement: "center",
                optionClass: Styles.popDlvnItem,
                list: outcomes,
                labelField: "outcome",
                isLoading: gettingOutcome,
                onClick: (item) => {
                  dispatch(updateContactOutcome(item));
                },
                height: "auto",
                width: "__parent__",
                maxHeight: "400px",
                listStyle: {
                  justifyContent: "center",
                },
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center",
                },
                emptyListText: "No outcome item",
              }}
              onClose={() => {}}
              label={{
                renderCustom: (
                  <div className={Styles.personalizedInput}>
                    <Typography variant='inputText'>
                      {selectedOutcome != null ? selectedOutcome?.outcome : "Select outcome"}
                    </Typography>
                    <Box component={"span"} sx={{ display: "flex" }}>
                      <CarrotIcon height='24' width='24' />
                    </Box>
                  </div>
                ),
              }}
            />
          </Box>
        )}
        {renderSessionTime()}
        {showMinimizedHandler && (
          <span
            className={Styles.headerRightIcon}
            onClick={() => {
              dispatch(handleInitStep(DIALER_STEP.SHOW_MINIMIZED_SCREEN));
            }}
          >
            <ZoomDialer />
          </span>
        )}
      </div>
    </div>
  );
};

export default React.memo(Header);
