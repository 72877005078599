import httpRequest from "../api/httpRequest";

class ConntectedService {
  getContactDetails(params) {
    return httpRequest.get(process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "connected/contact-details", params);
  }
  getMoreDetails(params) {
    return httpRequest.get(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "connected/contact-more-details",
      params
    );
  }
  getContactScript(params) {
    return httpRequest.get(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/call/contact-script",
      params
    );
  }
  getConversation(params) {
    return httpRequest.get(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/call/conversation",
      params
    );
  }
  sendConversation(params) {
    return httpRequest.get(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/call/make-conversation",
      params
    );
  }
  getUserCampaign(params) {
    return httpRequest.get(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/list/settings/campaigns",
      params
    );
  }
  contactAddToCampaign(params) {
    return httpRequest.post(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/call/add-contact-to-campaign",
      params
    );
  }
  getPowerDialerList(params) {
    return httpRequest.get(process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/lists", params);
  }
  addContactToAnotherList(params) {
    return httpRequest.post(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/list/contact/add/another/list",
      params
    );
  }
  getLastConnectedEmail(params) {
    return httpRequest.get(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/call/last-connected-email",
      params
    );
  }
  updateCallInformation(params) {
    return httpRequest.post(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/call/post-information",
      params
    );
  }
  /* for tag options */
  getAllTags(params) {
    return httpRequest.get(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/list/settings/tags",
      params
    );
  }
  getContactTags(params) {
    return httpRequest.get(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/call/contact-tags",
      params
    );
  }
  assignTagToContact(params) {
    return httpRequest.post(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/call/add-tags-to-contact",
      params
    );
  }
  /* for outside session staer */
  checkContactAddedToListForOutside(params) {
    return httpRequest.post(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/call/single/processing-check",
      params
    );
  }
}

export default new ConntectedService();
