import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  isError: false,
  error: "",
  isLoaded: false,
  settings: {
    callsAtATime: "",
    ringTime: "",
    maxCallDuration: "",
    transferCall: true,
    callRecording: false,
    actionForMaxCallDuration: 1,
  },
  generalScripts: {
    isShowing: false,
    isLoading: false,
    hasMore: false,
    loadingMore: false,
    perPage: 20,
    page: 1,
    search: "",
    data: [],
    selectedScriptsIds: [],
    /* for add script */
    isAdding: false,
    // For edit
    isEditing: false,
    editingIndex: null,
    editingData: null,
    // For delete
    isDeleting: false,
  },
  callScripts: {
    isGetLoading: false,
    scripts: [],
    scriptsIds: [],
    isDeletingScript: false,
    deletingScriptId: null,
    perPage: 20,
    page: 1,
    /* for add */
    isAdding: false,
    addingIds: [],
    /* for delete */
    isDeleting: false,
  },
};

const defaultCallSettingSlice = createSlice({
  name: "defaultCallSetting",
  initialState: initialState,
  reducers: {
    getDefaultCallSetting: (state) => {
      state.isLoading = true;
    },
    getDefaultCallSettingSuccess: (state, action) => {
      state.settings = { ...state.settings, ...action.payload };
      state.isLoading = false;
      state.isError = false;
      state.error = "";
      state.isLoaded = true;
    },
    getDefaultCallSettingFailed: (state, action) => {
      state.data = [];
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },
    updateDefaultCallSetting: (state, action) => {
      state.settings = { ...state.settings, ...action.payload };
    },
    resetDefaultCallSettingData: (state) => {
      state.isLoaded = false;
    },
    /* for general scripts */
    addGeneralScript: (state) => {
      state.generalScripts = { ...state.generalScripts, isAdding: true };
    },
    addGeneralScriptSuccess: (state, action) => {
      let old_data = [...state.generalScripts.data];
      old_data = [...old_data, action.payload];
      state.generalScripts = { ...state.generalScripts, data: old_data, isAdding: false };
    },
    addGeneralScriptFailed: (state) => {
      state.generalScripts = { ...state.generalScripts, isAdding: false };
    },
    getGeneralScripts: (state) => {
      state.generalScripts = { ...state.generalScripts, isLoading: true };
    },
    getGeneralScriptsSuccess: (state, action) => {
      let old_data = [...state.generalScripts.data];
      old_data = [...old_data, ...action.payload];
      let hasMoreFlag = false,
        page = state.generalScripts.page + 1;
      if (action.payload.length >= state.generalScripts.perPage) {
        hasMoreFlag = true;
      }
      state.generalScripts = {
        ...state.generalScripts,
        data: old_data,
        isLoading: false,
        loadingMore: false,
        hasMore: hasMoreFlag,
        page: page,
      };
    },
    getGeneralScriptsFailed: (state) => {
      state.generalScripts = { ...state.generalScripts, isLoading: false, loadingMore: false };
    },
    storeGeneralScriptEditData: (state, action) => {
      state.generalScripts = {
        ...state.generalScripts,
        editingIndex: action.payload.index,
        editingData: action.payload.data,
      };
    },
    resetGeneralScriptEditData: (state) => {
      state.generalScripts = {
        ...state.generalScripts,
        editingIndex: null,
        editingData: null,
        isEditing: false,
      };
    },
    resetGeneralScript: (state) => {
      state.generalScripts = {
        ...state.generalScripts,
        page: 1,
        search: "",
        data: [],
      };
    },
    updateGeneralScript: (state) => {
      state.generalScripts = { ...state.generalScripts, isEditing: true };
    },
    updateGeneralScriptSuccess: (state, action) => {
      let old_data = [...state.generalScripts.data];
      if (old_data[state.generalScripts.editingIndex]?.id === action.payload.id) {
        old_data[state.generalScripts.editingIndex] = {
          ...old_data[state.generalScripts.editingIndex],
          ...action.payload,
        };
      }
      state.generalScripts = {
        ...state.generalScripts,
        data: old_data,
        editingIndex: null,
        editingData: null,
        isEditing: false,
      };
    },
    updateGeneralScriptFailed: (state) => {
      state.generalScripts = { ...state.generalScripts, isEditing: false };
    },

    deleteGeneralScript: (state) => {
      state.generalScripts = { ...state.generalScripts, isDeleting: true };
    },
    deleteGeneralScriptSuccess: (state, action) => {
      const newData = state.generalScripts.data;
      if (newData[action.payload.index].id === action.payload.id) {
        newData.splice(action.payload.index, 1);
      }

      state.generalScripts = {
        ...state.generalScripts,
        data: newData,
        isDeleting: false,
      };
    },
    deleteGeneralScriptFailed: (state) => {
      state.generalScripts = { ...state.generalScripts, isDeleting: false };
    },
    toggleGeneralScriptPreview: (state, action) => {
      state.generalScripts = { ...state.generalScripts, isShowing: action.payload };
    },
    handleCallScriptStates: (state, action) => {
      state.callScripts = { ...state.callScripts, ...action.payload };
    },
    getScriptForDefaultSetting: (state) => {
      state.callScripts = { ...state.callScripts, isGetLoading: true };
    },
    getScriptForDefaultSettingSuccess: (state, action) => {
      let ids = [];
      action.payload.forEach((item) => ids.push(item.id));
      state.callScripts = {
        ...state.callScripts,
        scripts: action.payload,
        scriptsIds: ids,
        isGetLoading: false,
      };
    },
    getScriptForDefaultSettingFailed: (state) => {
      state.callScripts = {
        ...state.callScripts,
        isGetLoading: false,
      };
    },
    hasMoreGeneralScripts: (state) => {
      state.generalScripts = { ...state.generalScripts, loadingMore: true, hasMore: false };
    },
    /* for call script */
    /* addCallScript, removeCallScript, getCallScript */
    addCallScriptForDefault: (state) => {
      state.callScripts = { ...state.callScripts, isAdding: true };
    },
    addCallScriptForDefaultSuccess: (state, action) => {
      console.log(action);
      // let old_data = [...state.callScripts.scripts];
      // old_data = [...old_data, action.payload.payload];
      // state.callScripts = { ...state.callScripts, scripts: old_data };
      state.generalScripts = { ...state.generalScripts, selectedScriptsIds: [] };
      state.callScripts = { ...state.callScripts, isAdding: false };
    },

    addCallScriptForDefaultFailed: (state) => {
      state.callScripts = { ...state.callScripts, isAdding: false };
    },

    // deleteGeneralScript: (state) => {
    //   state.generalScripts = { ...state.generalScripts, isDeleting: true };
    // },

    deleteCallScriptForDefault: (state) => {
      state.callScripts = { ...state.callScripts, isDeleting: true };
    },

    deleteCallScriptForDefaultSuccess: (state, action) => {
      const newData = state.callScripts.scripts;
      if (newData[action.payload.index].id === action.payload.id) {
        newData.splice(action.payload.index, 1);
      }

      const myArray = [...state.callScripts.scriptsIds];
      const index = myArray.indexOf(action.payload.id);
      if (index !== -1) {
        myArray.splice(index, 1);
      }

      state.callScripts = {
        ...state.callScripts,
        scriptsIds: myArray,
        scripts: newData,
        isDeleting: false,
      };
    },

    deleteCallScriptForDefaultFailed: (state) => {
      state.callScripts = { ...state.callScripts, isDeleting: false };
    },

    handleSelectDefaultSettingForList: (state, action) => {
      state.generalScripts.selectedScriptsIds = action.payload;
    },
  },
});

export const {
  getDefaultCallSetting,
  getDefaultCallSettingSuccess,
  getDefaultCallSettingFailed,

  updateDefaultCallSetting,
  resetDefaultCallSettingData,
  /* for general scripts */
  addGeneralScript,
  addGeneralScriptSuccess,
  addGeneralScriptFailed,
  getGeneralScripts,
  getGeneralScriptsSuccess,
  getGeneralScriptsFailed,
  storeGeneralScriptEditData,
  resetGeneralScriptEditData,
  updateGeneralScript,
  updateGeneralScriptSuccess,
  updateGeneralScriptFailed,
  deleteGeneralScript,
  deleteGeneralScriptSuccess,
  deleteGeneralScriptFailed,
  resetGeneralScript,
  hasMoreGeneralScripts,

  /* for call script */
  addCallScriptForDefault,
  addCallScriptForDefaultSuccess,
  addCallScriptForDefaultFailed,
  deleteCallScriptForDefault,
  deleteCallScriptForDefaultSuccess,
  deleteCallScriptForDefaultFailed,
  handleSelectDefaultSettingForList,

  toggleGeneralScriptPreview,
  handleCallScriptStates,
  getScriptForDefaultSetting,
  getScriptForDefaultSettingSuccess,
  getScriptForDefaultSettingFailed,
} = defaultCallSettingSlice.actions;

export default defaultCallSettingSlice.reducer;
