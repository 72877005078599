import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Divider, FormControl, FormControlLabel, Stack, Typography } from "@mui/material";
import CallSettingTabOption from "./CallSettingTabOption";
import CallSettingSwitchOption from "./CallSettingSwitchOption";
import {
  ActionForCallTimeExceedOptions,
  maxCallTimeSettingOption,
  numberOfCallSettingOption,
  ringTimeSettingOption,
  Texts,
} from "../../../helpers/constant/Constants";
import CallSettingSkeleton from "../../../common/LoadingView/CallSettingSkeleton";
import { selectDefaultCallSetting } from "../../../state/features/listDefaultSetting/defaultListSettingSelector";
import {
  getDefaultCallSetting,
  updateDefaultCallSetting,
} from "../../../state/features/listDefaultSetting/defaultCallSetting/defaultCallSettingSlice";
import CustomRadioGroup from "../../../common/Radio/CustomRadioGroup";
import CustomRadio from "../../../common/Radio/CustomRadio";

const CallSettingDefaultTab = () => {
  const dispatch = useDispatch();
  const {
    isLoading,
    isLoaded,
    settings: {
      callsAtATime,
      ringTime,
      maxCallDuration,
      // transferCall,
      callRecording,
      actionForMaxCallDuration,
    },
  } = useSelector(selectDefaultCallSetting);

  useEffect(() => {
    if (!isLoaded) {
      dispatch(getDefaultCallSetting());
    }
  }, []);

  const updateSetting = (dataObj) => {
    dispatch(updateDefaultCallSetting(dataObj));
  };

  if (isLoading) {
    return <CallSettingSkeleton />;
  }

  return (
    <>
      <Box>
        {/* Number of calls at a time */}
        <CallSettingTabOption
          title={Texts.numberOfCall}
          subTitle={Texts.numberOfCallHints}
          options={numberOfCallSettingOption}
          selectedOption={callsAtATime}
          onChange={(value) => updateSetting({ callsAtATime: value })}
        />

        {/* Ring time */}
        <CallSettingTabOption
          title={Texts.ringTime}
          subTitle={Texts.ringTimeHints}
          options={ringTimeSettingOption}
          selectedOption={ringTime}
          onChange={(value) => updateSetting({ ringTime: value })}
        />

        {/* max call time */}
        <CallSettingTabOption
          title={Texts.maxCallTime}
          subTitle={Texts.maxCallTimeHints}
          options={maxCallTimeSettingOption}
          selectedOption={maxCallDuration}
          onChange={(value) => updateSetting({ maxCallDuration: value })}
        >
          <Stack direction={"row"} my={2}>
            <FormControl>
              <CustomRadioGroup
                value={actionForMaxCallDuration}
                onChange={(e) => updateSetting({ actionForMaxCallDuration: e.target.value })}
              >
                {ActionForCallTimeExceedOptions.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={<CustomRadio color='secondary' />}
                    label={
                      <Typography variant={"body2"} color={"text.primary"}>
                        {option.label}
                      </Typography>
                    }
                  />
                ))}
              </CustomRadioGroup>
            </FormControl>
          </Stack>
        </CallSettingTabOption>
      </Box>
      <Divider />
      <Box py={1}>
        {/* Transfer call */}
        {/*<CallSettingSwitchOption*/}
        {/*  title={Texts.transferCalls}*/}
        {/*  subTitle={Texts.transferCallsHints}*/}
        {/*  checked={Boolean(Number(transferCall))}*/}
        {/*  onChange={(e) => updateSetting({ transferCall: Number(e.target.checked) })}*/}
        {/*/>*/}

        {/* Transfer call */}
        <CallSettingSwitchOption
          title={Texts.callRecording}
          subTitle={Texts.callRecordingHints}
          checked={Boolean(Number(callRecording))}
          onChange={(e) => updateSetting({ callRecording: Number(e.target.checked) })}
        />
      </Box>
    </>
  );
};
export default CallSettingDefaultTab;
