import styled from "@emotion/styled";
import { Radio } from "@mui/material";

const CustomRadio = styled(Radio)(() => ({
  "&.MuiRadio-root": {
    padding: "5px",
  },
  '& [type="radio"]:not(:checked)+span, & [type="radio"]:checked+span': {
    paddingLeft: 0,
    display: "grid",
    placeItems: "center",
    "&::before, &::after": {
      content: `none`,
    },
  },
}));

export default CustomRadio;
