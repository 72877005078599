import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useGetDialerSessionListQuery } from "../../state/features/common/dialerSession/dialerSessionApiSlice";
import { setDialerAnalyticsSession } from "../../state/features/dialerAnalytics/dialerAnalyticsSlice";
import { selectDialerAnalyticSession } from "../../state/features/dialerAnalytics/dialerAnalyticsSelector";

const StyledMenu = styled(Menu)(() => ({
  "& .MuiList-root": {
    minWidth: "120px",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
}));

const SessionMenu = ({ dialerListId = "" }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const selectedSession = useSelector(selectDialerAnalyticSession);
  const { id: sessionId, name } = selectedSession || {};

  const { data: dialerSessionState } = useGetDialerSessionListQuery({ id: dialerListId });
  const { data: dialerSessionList = [] } = dialerSessionState?.data || {};

  useEffect(() => {
    return () => dispatch(setDialerAnalyticsSession({}));
  }, []);

  const handleOpenMenu = (event) => setAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);

  const handleSelectSession = (session) => {
    dispatch(setDialerAnalyticsSession(session));
    handleCloseMenu();
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <Typography
        variant='chipLight'
        color='text.secondary'
        aria-expanded={open ? "true" : undefined}
        onClick={handleOpenMenu}
        sx={{ fontWeight: 500, display: "inline-flex", cursor: "pointer" }}
      >
        {name || "All"} <ArrowDropDownIcon fontSize={"small"} />
      </Typography>

      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleCloseMenu}>
        <MenuItem value={""} onClick={() => handleSelectSession({})}>
          All
        </MenuItem>
        {dialerSessionList.map((session, index) => (
          <MenuItem key={index} selected={session.id === sessionId} onClick={() => handleSelectSession(session)}>
            {session.name}
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  );
};

export default SessionMenu;
