import React from "react";
import { Box, Drawer, Stack, Typography } from "@mui/material";
import { Clear } from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";

const BasicDrawer = ({ open, toggleDrawer, title = "", header, width = 850, children }) => {
  return (
    <Drawer
      className={"pdad_Basic_Drawer"}
      anchor={"right"}
      open={open}
      onClose={toggleDrawer}
      sx={{
        "& .MuiPaper-root": {
          maxWidth: `${width}px !important`,
          width: "100%",
        },
      }}
    >
      <Box sx={{ height: "100%" }}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} px={3} py={2}>
          <>
            {title && (
              <Typography variant={"subtitle1"} color={"text.primary"} sx={{ fontWeight: "500" }}>
                {title}
              </Typography>
            )}
            {header || null}
          </>

          <IconButton onClick={toggleDrawer} color={"error"}>
            <Clear />
          </IconButton>
        </Stack>

        <Divider />

        {children}
      </Box>
    </Drawer>
  );
};

export default BasicDrawer;
