import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import CoreButton from "../../common/Button/CoreButton";
import BasicEditor from "../../common/Editor/BasicEditor";
import AsteriskIcon from "../../common/Icons/AsterickIcon";
import Personalized from "../../common/Personalized/Personalized";
import CoreTextField from "../../common/TextField/CoreTextField";
import { SCRIPT_SETTING_PERSONALIZED_TAGS, Texts } from "../../helpers/constant/Constants";
import { scriptPattern } from "../../helpers/constant/registerPattern";
import { insertPersonalizedTag } from "../../helpers/utils/insertPersonalizedTag";
import { useGetAllPersonalizedTagsQuery } from "../../state/features/common/personalizedTag/personalizedTagApiSlice";
import { updateGeneralScript } from "../../state/features/listDefaultSetting/defaultCallSetting/defaultCallSettingSlice";
import { selectDefaultCallSetting } from "../../state/features/listDefaultSetting/defaultListSettingSelector";

const UpdateGeneralScript = ({ onCancel, selectedData }) => {
  const {
    generalScripts: { isEditing },
  } = useSelector(selectDefaultCallSetting);
  const dispatch = useDispatch();
  const editorRef = useRef(null);
  const [personalizedTags, setPersonalizedTags] = useState(null);
  const { data: personalizedQueryTags = [], isSuccess } = useGetAllPersonalizedTagsQuery();
  const { handleSubmit, setValue, control } = useForm();

  console.log(isEditing);
  useEffect(() => {
    let filteredTags = personalizedQueryTags.filter((eachGroup) => eachGroup.isCustomField === true);
    setPersonalizedTags([...SCRIPT_SETTING_PERSONALIZED_TAGS, ...filteredTags]);
  }, [isSuccess]);

  useEffect(() => {
    setValue("title", selectedData?.title || "");
    setValue("description", selectedData?.description || "");
    setValue("script", selectedData?.script || "");
    setValue("id", selectedData?.id || "");
  }, [selectedData]);

  const onSubmit = (data) => {
    dispatch(
      updateGeneralScript({
        payload: { ...data },
        callback: () => onCancel(),
      })
    );
  };

  return (
    <Box component='form' noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)} px={4} py={2}>
      <Controller
        control={control}
        name='id'
        defaultValue={selectedData?.id}
        render={({ field: { value } }) => <input value={value} type={"hidden"} />}
      />
      <Stack direction={"row"} spacing={2} mb={0.5}>
        <Stack spacing={1} sx={{ width: "100%" }}>
          <Controller
            control={control}
            name='title'
            defaultValue=''
            render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
              <>
                <Typography variant={"body2medium"} color={"text.primary"}>
                  Script title
                  <AsteriskIcon />
                </Typography>
                <CoreTextField
                  fullWidth
                  required
                  autoFocus
                  value={value}
                  onChange={onChange}
                  type={"text"}
                  size={"small"}
                  placeholder={"Script title here"}
                  inputProps={{ maxlength: 30 }}
                  error={invalid}
                  helperText={invalid && error?.message}
                />
              </>
            )}
            rules={scriptPattern.title}
          />
        </Stack>
        <Stack spacing={1} sx={{ width: "100%" }}>
          <Controller
            control={control}
            name='description'
            defaultValue=''
            render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
              <>
                <Typography variant={"body2medium"} color={"text.primary"}>
                  Script description
                </Typography>
                <CoreTextField
                  type={"text"}
                  size={"small"}
                  value={value}
                  onChange={onChange}
                  fullWidth
                  multiline
                  minRows={1}
                  maxRows={3}
                  placeholder={"Script description here"}
                  defaultValue={""}
                  inputProps={{ maxlength: 255 }}
                  sx={{ "& .MuiOutlinedInput-root": { padding: "7px 14px" } }}
                  error={invalid}
                  helperText={invalid && error?.message}
                />{" "}
              </>
            )}
            rules={scriptPattern.description}
          />
        </Stack>
      </Stack>
      <Stack spacing={1}>
        <Typography variant={"body2medium"} color={"text.primary"} mt={1.5}>
          {Texts.writeScript}
          <AsteriskIcon />
        </Typography>
        <Controller
          control={control}
          name='script'
          defaultValue=''
          render={({ field: { value }, fieldState: { invalid, error } }) => (
            <>
              <BasicEditor
                ref={editorRef}
                autoFocus={false}
                body={value}
                onChangeBody={(value) => setValue("script", value, { shouldValidate: true })}
              />
              {invalid && (
                <Typography color='error.main' variant='helperText'>
                  {error?.message}
                </Typography>
              )}
              <Stack direction={"row"} alignItems={"center"} spacing={2}>
                <Personalized
                  data={personalizedTags}
                  onClick={(personalizedTag) =>
                    insertPersonalizedTag(personalizedTag, editorRef, (value) =>
                      setValue("script", value, { shouldValidate: true })
                    )
                  }
                />
                <Stack direction={"row"} alignItems={"center"} spacing={2}>
                  <CoreButton variant={"outlined"} color={"error"} size={"small"} onClick={onCancel}>
                    Cancel
                  </CoreButton>
                  <CoreButton
                    type='submit'
                    variant={"contained"}
                    color={"success"}
                    size={"small"}
                    disabled={isEditing}
                    sx={{ "&.MuiButton-sizeSmall": { padding: "5px 10px" } }}
                  >
                    {isEditing ? "Updating" : "Update"} Script
                  </CoreButton>
                </Stack>
              </Stack>
            </>
          )}
          rules={scriptPattern.script}
        />
      </Stack>
    </Box>
  );
};

export default UpdateGeneralScript;
