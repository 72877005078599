import httpRequest from "../api/httpRequest";

class SendService {
  makeConversation(params) {
    const rootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/inbox`;
    return httpRequest.post(`${rootUrl}/make-conversation`, params);
  }

  makeVoiceConversation(params) {
    const rootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/inbox`;
    return httpRequest.post(`${rootUrl}/make-voice-conversation`, params);
  }
}

export default new SendService();
