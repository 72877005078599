import { useEffect, useState } from "react";

const useCountdown = ({
  startValue = 0,
  endValue = 60,
  increment = 1,
  callback = () => {},
  callbackOnEveryUpdate = () => {},
}) => {
  let endValueData = 60;
  if (endValue == "__infinite__") {
    endValueData = "__infinite__";
  } else {
    endValueData = parseInt(endValue);
  }

  const [countDown, setCountDown] = useState(startValue);
  useEffect(() => {
    let interval = 0;
    if (endValueData == "__infinite__") {
      interval = setInterval(() => {
        setCountDown(countDown + increment);
        callbackOnEveryUpdate(countDown + increment);
      }, 1000);
    } else {
      if (increment > 0 && startValue < endValue) {
        interval = setInterval(() => {
          setCountDown(countDown + increment);
          callbackOnEveryUpdate(countDown + increment);
        }, 1000);
        if (countDown >= endValue) {
          clearInterval(interval);
          setCountDown(endValue);
          callback();
        }
      } else if (increment < 0 && startValue > endValue) {
        interval = setInterval(() => {
          setCountDown(countDown + increment);
          callbackOnEveryUpdate(countDown + increment);
        }, 1000);
        if (countDown <= endValue) {
          clearInterval(interval);
          setCountDown(endValue);
          callback();
        }
      }
    }
    return () => clearInterval(interval);
  }, [countDown]);
  return countDown;
};
export { useCountdown };
