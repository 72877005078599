import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getCookie } from "../helpers/Cookie";

const baseQueryMiddleware = fetchBaseQuery({
  baseUrl: "/",
  prepareHeaders: async (headers /*, { getState, endpoint }*/) => {
    const token = getCookie(process.env.REACT_APP_ACCESS_TOKEN);
    // If we have a token set in state, let's assume that we should be passing it.
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const apiSlice = createApi({
  reducerPath: "powerDialerApi",
  baseQuery: async (args, api, extraOptions) => {
    const result = await baseQueryMiddleware(args, api, extraOptions);
    const { error } = result || {};
    if (error) {
      console.log("Error::: ", error);
    }

    return result;
  },
  tagTypes: [],
  endpoints: () => ({}),
});

export default apiSlice;
