import React, { useState } from "react";
import { Add } from "@mui/icons-material";
import { Stack } from "@mui/material";
import CoreButton from "../../../common/Button/CoreButton";
import AddOrEditVoiceTemplate from "./AddOrEditVoiceTemplate";
const EmptyState = ({ handleUpdateItem }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  return (
    <>
      <Stack
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        height={"calc(100vh - 120px)"}
        px={4}
        py={2}
      >
        <CoreButton variant='contained' startIcon={<Add />} onClick={() => setIsOpenModal(true)}>
          Add Voice Template
        </CoreButton>
      </Stack>

      <AddOrEditVoiceTemplate
        open={isOpenModal}
        onClose={() => {
          setIsOpenModal(false);
          handleUpdateItem(null);
        }}
        edit={{ id: null }}
      />
    </>
  );
};

export default EmptyState;
