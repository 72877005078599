export const getContactAvatar = (firstName, lastName, email = "", number = "") => {
  if (firstName === undefined || firstName == null) {
    firstName = "";
  }
  if (lastName === undefined || lastName == null) {
    lastName = "";
  }
  if (email === undefined || email == null) {
    email = "";
  }
  if (number === undefined || number == null) {
    number = "";
  }
  if (firstName !== "" && lastName !== "") {
    return firstName.substring(0, 1).toUpperCase() + "" + lastName.substring(0, 1).toUpperCase();
  } else if (firstName !== "") {
    return firstName.substring(0, 2).toUpperCase();
  } else if (lastName !== "") {
    return lastName.substring(0, 2).toUpperCase();
  } else if (email !== "") {
    return email.substring(0, 2).toUpperCase();
  } else if (number !== "") {
    return number.substring(0, 2).toUpperCase();
  }
  return "N/A";
};
