import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
import CallSettingTab from "./CallSettings/CallSettingTab";
import ScriptSettingTab from "./ScriptSettings/ScriptSettingTab";
import TriggerSettingTab from "./TriggerSettings/TriggerSettingTab";
import CustomTabs, { CustomTab } from "../../common/Tab/CustomTabs";
import { resetCallSettingData } from "../../state/features/listSetting/callSetting/callSettingSlice";
import { resetScriptSettingData } from "../../state/features/listSetting/scriptSetting/scriptSettingSlice";
import { resetTriggerSettingData } from "../../state/features/listSetting/triggerSetting/triggerSettingSlice";

const DialerListSettings = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetCallSettingData());
      dispatch(resetTriggerSettingData());
      dispatch(resetScriptSettingData());
    };
  }, []);

  return (
    <Box sx={{ height: "calc(100% - 74px)", overflowY: "scroll", "&::-webkit-scrollbar": { display: "none" } }}>
      <CustomTabs>
        <CustomTab title={"Call Settings"}>
          <CallSettingTab />
        </CustomTab>
        <CustomTab title={"Triggers"}>
          <TriggerSettingTab />
        </CustomTab>
        <CustomTab title={"Script"}>
          <ScriptSettingTab />
        </CustomTab>
      </CustomTabs>
    </Box>
  );
};
export default DialerListSettings;
