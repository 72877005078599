import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputAdornment,
  OutlinedInput,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { CarrotIcon, EditIcon, PreviewDocIcon, SettingIcon } from "../../common/icon/Icons";
import { ActionForIncomingCallOptions, ActionForMoveNextCallOptions } from "../../helpers/constant/Constants";
import VoiceManager from "../../common/VoiceManager/VoiceManager";
import {
  DIALER_STEP,
  getAllScripts,
  getSettingOverview,
  getSettingOverviewOnBackground,
  handleCallStep,
  handleEndCall,
  handleInitStep,
  handleMainScreen,
  storeAllScripts,
  storeDialerMainScriptFullData,
} from "../../state/features/dialer/dialerSlice";
import { selectDialerState } from "../../state/features/dialer/dialerSelector";
import CustomRadioGroup from "../../common/Radio/CustomRadioGroup";
import CustomRadio from "../../common/Radio/CustomRadio";
import Styles from "./settingOverview.module.scss";
import ListSettingService from "../../services/ListSetting.service";
import { showGlobalNotification } from "../../helpers/utils/showGlobalNotification";
import InitializingService from "../../services/call/Initializing.service";
import useDelayCallback from "../../hooks/useDelayCallback";
import CoreTextField from "../../common/TextField/CoreTextField";
import CustomMenuPype from "../../common/custom-menu/CustomMenuPype";
import CustomTooltip from "../../common/Tooltip/CustomTooltip";
import { selectScriptSetting } from "../../state/features/listSetting/listSettingSelector";

const StyledContinueBtn = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  "&:hover": {
    backgroundColor: theme.palette.secondary.main,
  },
}));
const StyledCloseBtn = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  "&:hover": {
    backgroundColor: theme.palette.error.main,
  },
}));
const InputSideText = styled(Typography)(({ theme }) => ({
  background: theme.palette.action.selected,
  padding: "6px 10px",
  marginRight: "-14px",
  borderRadius: "0 4px 4px 0",
}));

let firstLanding = false;

const SettingOverview = ({ onEdit, className }) => {
  const dispatch = useDispatch();
  const {
    settingOverview: { data: settingOverview, gettingData: isLoading, gettingError: error },
    init: { listId },
    contactScript: { gettingAllScripts, allScripts, mainScriptTitle, mainScript, mainScriptId },
  } = useSelector(selectDialerState);

  const {
    settings: { scripts: allCallScripts },
  } = useSelector(selectScriptSetting);

  const [updateData, setUpdateData] = useState({
    incomingActionType: null,
    moveToNextCallType: null,
    updatingIncoming: false,
    updatingNextCall: false,
    forwardNumber: "",
    fileData: null,
    fileUrl: "",
    skipTime: null,
  });
  const [hasData, setHasData] = useState(false);
  const [needToUpdate, setNeedToUpdate] = useState(false);

  useEffect(() => {
    firstLanding = false;
    if (allCallScripts && allCallScripts.length != 0) {
      dispatch(storeAllScripts(allCallScripts));
    } else {
      dispatch(getAllScripts());
    }

    dispatch(getSettingOverview());
    return () => {
      firstLanding = false;
      console.log("unmount");
      setNeedToUpdate(false);
      setHasData(false);
      setUpdateData({
        incomingActionType: null,
        moveToNextCallType: null,
        updatingIncoming: false,
        updatingNextCall: false,
        forwardNumber: "",
        fileData: null,
        fileUrl: "",
        skipTime: null,
      });
    };
  }, []);

  useEffect(() => {
    if (settingOverview) {
      setUpdateData({
        ...updateData,
        incomingActionType: settingOverview.incomingCallAction.type,
        moveToNextCallType: settingOverview.moveToNextCall.type,
        updatingIncoming: false,
        updatingNextCall: false,
        forwardNumber: settingOverview.incomingCallAction.type == 1 ? settingOverview.incomingCallAction.value : "",
        fileUrl: settingOverview.incomingCallAction.type == 2 ? settingOverview.incomingCallAction.value : "",
        skipTime: settingOverview.moveToNextCall.value,
      });
      try {
        setTimeout(() => {
          setHasData(true);
          setTimeout(() => {
            firstLanding = true;
          }, 3000);
        }, 0);
      } catch (error) {
        console.log(error);
        setHasData(true);
        firstLanding = true;
      }
    }
  }, [settingOverview]);

  useDelayCallback(() => {
    if (firstLanding) {
      updateIncomingSetting();
    }
  }, [updateData.forwardNumber]);
  useDelayCallback(() => {
    if (firstLanding) {
      updateNextCallSetting();
    }
  }, [updateData.skipTime]);
  useDelayCallback(() => {
    if (firstLanding) {
      if (updateData.incomingActionType == "3") {
        updateIncomingSetting();
      }
    }
  }, [updateData.incomingActionType]);
  useDelayCallback(() => {
    if (firstLanding) {
      if (updateData.moveToNextCallType == "2") {
        updateNextCallSetting();
      }
    }
  }, [updateData.moveToNextCallType]);

  const updateIncomingSetting = (file = null) => {
    console.log(updateData, updateData.incomingActionType);
    let formData = {
      listId: listId,
      action: "incomingCallAction",
      type: updateData.incomingActionType,
    };

    if (updateData.incomingActionType) {
      if (updateData.incomingActionType == "1") {
        /* forward */
        formData["forwardNumber"] = updateData.forwardNumber;
      } else if (updateData.incomingActionType == "2") {
        /* voice */
        formData["fileUrl"] = file;
      }
      setUpdateData({ ...updateData, updatingIncoming: true });
      InitializingService.updateSettingOverview(formData)
        .then((response) => {
          setUpdateData({ ...updateData, updatingIncoming: false });
          if (response.success) {
            setNeedToUpdate(true);
            showGlobalNotification("Setting updated", "success");
          } else {
            showGlobalNotification("Can not update setting !");
          }
        })
        .catch((error) => {
          setUpdateData({ ...updateData, updatingIncoming: false });
          showGlobalNotification("Can not update setting !");
          console.log(error);
        });
    }
  };
  const updateNextCallSetting = () => {
    let formData = {
      listId: listId,
      action: "moveToNextCall",
      type: updateData.moveToNextCallType,
    };

    if (updateData.moveToNextCallType) {
      if (updateData.moveToNextCallType == 1) {
        /* automatic */
        formData["timeValue"] = updateData.skipTime;
      }
      setUpdateData({ ...updateData, updatingNextCall: true });
      InitializingService.updateSettingOverview(formData)
        .then((response) => {
          console.log(response);
          setUpdateData({ ...updateData, updatingNextCall: false });
          if (response.success) {
            setNeedToUpdate(true);
            showGlobalNotification("Setting updated", "success");
          } else {
            showGlobalNotification("Can not update setting !");
          }
        })
        .catch((error) => {
          setUpdateData({ ...updateData, updatingNextCall: false });
          showGlobalNotification("Can not update setting !");
          console.log(error);
        });
    }
  };

  const uploadAudioFile = (blob) => {
    const formData = new FormData();
    formData.append("file", blob);
    formData.append("listId", listId);

    ListSettingService.uploadVoiceFile(formData).then((response) => {
      if (response.success) {
        setUpdateData({ ...updateData, fileUrl: response.data.file });
        updateIncomingSetting(response.data.file);
      }
    });
  };

  const renderIncomingCallActions = () => {
    const view = [];
    ActionForIncomingCallOptions.forEach((item, index) => {
      view.push(
        <FormControlLabel
          // disabled={item.value != settingOverview.incomingCallAction.type}
          key={index}
          value={parseInt(item.value)}
          control={<CustomRadio color='secondary' />}
          onClick={() => setUpdateData({ ...updateData, incomingActionType: item.value })}
          label={
            <Typography variant={"body2"} color={"text.primary"}>
              {item.label}
            </Typography>
          }
        />
      );
    });
    return view;
  };
  const renderMoveToNextCallActions = () => {
    const view = [];
    ActionForMoveNextCallOptions.forEach((item, index) => {
      view.push(
        <FormControlLabel
          // disabled={item.value != settingOverview.moveToNextCall.type}
          value={parseInt(item.value)}
          key={index}
          onClick={() => setUpdateData({ ...updateData, moveToNextCallType: item.value })}
          control={<CustomRadio color='secondary' />}
          label={
            <Typography variant={"body2"} color={"text.primary"}>
              {item.label}
            </Typography>
          }
        />
      );
    });
    return view;
  };

  if (isLoading) {
    return <div className={`${Styles.wrapper} ${Styles.center}`}>Please wait...</div>;
  }
  if (error !== "") {
    return (
      <div className={`${Styles.wrapper} ${Styles.center}`} style={{ flexDirection: "column" }}>
        <p style={{ marginBottom: "10px" }}>{error}</p>
        <StyledCloseBtn
          variant='contained'
          onClick={() => {
            dispatch(handleEndCall());
          }}
        >
          Close the window & end the call
        </StyledCloseBtn>
      </div>
    );
  }

  if (!hasData) {
    return <div className={`${Styles.wrapper} ${Styles.center}`}>Please wait...</div>;
  }

  if (settingOverview == null) {
    return (
      <div className={`${Styles.wrapper} ${Styles.center}`} style={{ flexDirection: "column" }}>
        <p style={{ marginBottom: "10px" }}>No setting found for this dialer.</p>
        <StyledCloseBtn
          variant='contained'
          onClick={() => {
            dispatch(handleEndCall());
          }}
        >
          Close the window & end the call
        </StyledCloseBtn>
      </div>
    );
  }

  return (
    <div className={`${Styles.wrapper} ${className} pdad-scroll-bar`}>
      <div className={`${Styles.bodyWrapper} ${Styles.bodyWrapper987}`}>
        <div className={Styles.modalContentWrapper}>
          <div className={Styles.leftWpr}>
            <span>
              <span className={Styles.leftHeader}>
                <SettingIcon />
                <Typography variant='body2'>Call Settings</Typography>
              </span>

              <div className={Styles.leftList} spacing={"24px"}>
                <Stack spacing={"3px"}>
                  <Typography variant='body2' color={"text.secondary"}>
                    Number of calls at a time
                  </Typography>
                  <Typography variant='body2medium'>{settingOverview.numberOfLines} Line(s)</Typography>
                </Stack>
                <Stack spacing={"3px"}>
                  <Typography variant='body2' color={"text.secondary"}>
                    Ring time
                  </Typography>
                  <Typography variant='body2medium'>{settingOverview.ringTime} seconds</Typography>
                </Stack>
                <Stack spacing={"3px"}>
                  <Typography variant='body2' color={"text.secondary"}>
                    Maximum call time
                  </Typography>
                  <Typography variant='body2medium'>{settingOverview.maximumCallTime} minutes</Typography>
                </Stack>
                <Stack spacing={"3px"} sx={{ display: "none" }}>
                  <Typography variant='body2' color={"text.secondary"}>
                    Transfer Calls
                  </Typography>
                  <Typography variant='body2medium'>
                    {parseInt(settingOverview.transferCall) === 1 ? "On" : "Off"}
                  </Typography>
                </Stack>
                <Stack spacing={"3px"}>
                  <Typography variant='body2' color={"text.secondary"}>
                    Call Recording
                  </Typography>
                  <Typography variant='body2medium'>
                    {parseInt(settingOverview.callRecording) === 1 ? "On" : "Off"}
                  </Typography>
                </Stack>
              </div>
            </span>

            <Box sx={{ alignSelf: "flex-end" }}>
              <button className={Styles.leftEdit} onClick={onEdit}>
                <EditIcon height='13.5' width='13.5' /> Edit Settings
              </button>
            </Box>
          </div>

          <span className={Styles.rightWrp}>
            <div className={Styles.sobrSettingList}>
              <span>
                <Typography variant='body2medium' className={Styles.sobrSlmTitle}>
                  How do you want to handle incoming calls during a dialing session?
                </Typography>

                <div className={Styles.sobrSlmRadio}>
                  <FormControl>
                    <CustomRadioGroup
                      aria-labelledby='incoming-call-action-label'
                      defaultValue={updateData.incomingActionType ? parseInt(updateData.incomingActionType) : ""}
                      name='incoming-call-action'
                    >
                      {renderIncomingCallActions()}
                    </CustomRadioGroup>
                  </FormControl>
                </div>
              </span>
              {updateData.incomingActionType == "1" && (
                <Stack direction={"column"} spacing={1} pr={8}>
                  <Typography variant={"body2medium"} color={"text.primary"}>
                    Forward to
                  </Typography>
                  <CoreTextField
                    placeholder='Please input forward number'
                    value={updateData.forwardNumber}
                    onChange={(e) => setUpdateData({ ...updateData, forwardNumber: e.target.value })}
                  />
                </Stack>
              )}
              {updateData.incomingActionType == "2" && (
                <VoiceManager
                  src={updateData.fileUrl}
                  onRemove={() => {}}
                  onChange={(audioFileUrl, blob) => {
                    console.log(audioFileUrl, blob);
                    uploadAudioFile(blob);
                  }}
                />
              )}
            </div>

            <div className={Styles.sobrSettingList}>
              <div>
                <Typography variant='body2medium' className={Styles.sobrSlmTitle}>
                  How do you want to move to the next call?
                </Typography>

                <div className={Styles.sobrSlmRadio}>
                  <FormControl>
                    <CustomRadioGroup
                      aria-labelledby='move-to-next-call-label'
                      defaultValue={updateData.moveToNextCallType ? parseInt(updateData.moveToNextCallType) : ""}
                      name='move-to-next-call'
                    >
                      {renderMoveToNextCallActions()}
                    </CustomRadioGroup>
                  </FormControl>
                </div>
              </div>

              {updateData.moveToNextCallType == "1" && (
                <div className={Styles.sobrSlAddon}>
                  <div className={Styles.sobrSlaLeft}>
                    <span>
                      <Typography variant='body2medium' sx={{ display: "block" }}>
                        Interval between calls
                      </Typography>
                      <Typography variant='body2medium' color='text.secondary'>
                        You can set the duration between calls
                      </Typography>
                    </span>
                  </div>
                  <div>
                    <OutlinedInput
                      id='outlined-adornment-weight'
                      endAdornment={
                        <InputAdornment position='end'>
                          <InputSideText variant={"inputText"} color={"text.secondary"}>
                            Sec
                          </InputSideText>
                        </InputAdornment>
                      }
                      aria-describedby='outlined-weight-helper-text'
                      type={"number"}
                      value={parseInt(updateData.skipTime)}
                      onChange={(e) => {
                        setUpdateData({ ...updateData, skipTime: parseInt(e.target.value) });
                      }}
                      sx={{
                        width: "150px",
                        "& .MuiOutlinedInput-input": {
                          height: "36px !important",
                          padding: "1px 0 1px 14px !important",
                        },
                      }}
                      inputProps={{ min: 1, max: 120 }}
                    />
                  </div>
                </div>
              )}
            </div>

            {/* call script */}
            <div className={Styles.sobrSettingList}>
              <Typography variant='body2medium' className={Styles.sobrSlmTitle}>
                Select your script below (optional):
              </Typography>

              <div className={Styles.personalized}>
                <CustomMenuPype
                  popup={{
                    id: "dialer-script-list-id",
                    wrapperClass: Styles.popDlvnWrapper,
                    placement: "center",
                    optionClass: Styles.popDlvnItem,
                    isLoading: gettingAllScripts,
                    list: allScripts,
                    labelField: "title",
                    onClick: (item) => {
                      if (mainScriptId != null) {
                        if (mainScriptId != item?.id) {
                          InitializingService.updateCallScriptForDialerList({
                            script_id: item?.id,
                            id: listId,
                          });
                        }
                      } else {
                        InitializingService.updateCallScriptForDialerList({
                          script_id: item?.id,
                          id: listId,
                        });
                      }

                      dispatch(
                        storeDialerMainScriptFullData({
                          script: item?.script,
                          title: item?.title,
                          id: item?.id,
                        })
                      );
                    },
                    height: "auto",
                    width: "__parent__",
                    maxHeight: "400px",
                    listStyle: {
                      justifyContent: "left",
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "center",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "center",
                    },
                    emptyListText: "No script added yet !",
                  }}
                  onClose={() => {}}
                  label={{
                    renderCustom: (
                      <div className={Styles.personalizedInput}>
                        <Typography variant='inputText' sx={{ color: "text.primary" }}>
                          {mainScript != null
                            ? mainScriptTitle != null
                              ? mainScriptTitle
                              : "Call Script"
                            : "Select a script"}
                        </Typography>
                        <Box component={"span"} sx={{ display: "flex" }}>
                          <CarrotIcon height='24' width='24' />
                        </Box>
                      </div>
                    ),
                  }}
                  wrapperClass={Styles.mainWrp}
                />

                <div className={Styles.scriptPreview}>
                  <CustomMenuPype
                    popup={{
                      renderCustomContainer: (
                        <>
                          {mainScript != null ? (
                            <div className={`${Styles.scriptContent} pdad-scroll-bar`}>
                              <Typography variant='body2' dangerouslySetInnerHTML={{ __html: mainScript }} />
                            </div>
                          ) : (
                            <Box
                              className={`${Styles.scriptContent}`}
                              sx={{ display: " grid", placeContent: "center" }}
                            >
                              <Typography variant='body2'>No script found !</Typography>
                            </Box>
                          )}
                        </>
                      ),
                      listStyle: {
                        justifyContent: "center",
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                      },
                      height: "500px",
                      width: "500px",
                      wrapperClassInner: Styles.settingOverviewPopupInner,
                    }}
                    onClose={() => {}}
                    label={{
                      renderCustom: (
                        <CustomTooltip arrow title='Preview script' placement='top'>
                          <div>
                            <PreviewDocIcon />
                          </div>
                        </CustomTooltip>
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
          </span>
        </div>
      </div>

      <Box className={Styles.footer}>
        <Typography
          variant='button'
          className={Styles.footerCancel}
          onClick={() => {
            dispatch(handleEndCall());
          }}
        >
          Cancel
        </Typography>

        <StyledContinueBtn
          variant='contained'
          disabled={updateData.updatingIncoming || updateData.updatingNextCall}
          onClick={() => {
            if (updateData.updatingIncoming || updateData.updatingNextCall) {
              return;
            }
            dispatch(handleInitStep(DIALER_STEP.SHOW_CONNECTING_SCREEN));
            dispatch(handleCallStep(DIALER_STEP.WAITING));
            dispatch(handleMainScreen(true));
            if (needToUpdate) {
              dispatch(getSettingOverviewOnBackground());
            }
          }}
        >
          Confirm & Start Dialing Session
        </StyledContinueBtn>
      </Box>
    </div>
  );
};

export default SettingOverview;
