import React, { useEffect, useState } from "react";
import { ArrowBack } from "@mui/icons-material";
import { Box, Button, Stack, styled, Typography } from "@mui/material";
import CustomMenuPype from "../../../../common/custom-menu/CustomMenuPype";
import { CarrotIcon } from "../../../../common/icon/Icons";
import ButtonBack from "../../../screens/auto-dialer-ongoing/global/ButtonBack/ButtonBack";
import Styles from "./addAnotherList.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { selectDialerState } from "../../../../state/features/dialer/dialerSelector";
import { handleSendOption, SEND_OPTIONS } from "../../../../state/features/dialer/dialerSlice";
import ConntectedService from "../../../../services/call/Conntected.service";
import { showGlobalNotification } from "../../../../helpers/utils/showGlobalNotification";
import CustomCircleLoader from "../../../../common/Loader/CustomCircleLoader";

const StyledBox = styled(Box)(({ theme }) => ({
  padding: "16px 24px",
  borderTop: `1px solid ${theme.palette.other.outlinedBorderDarker}`,
}));

const AddAnotherList = () => {
  const dispatch = useDispatch();
  const {
    init: { listId },
    connected: { contactId },
  } = useSelector(selectDialerState);
  const [data, setData] = useState({
    list: [],
    gettingList: false,
    gettingError: "",
    selectedList: null,
    addingToList: false,
    addingToListError: "",
  });

  useEffect(() => {
    getPowerDialerList();
  }, []);

  const handleStateData = (payload) => {
    setData({ ...data, ...payload });
  };
  const getPowerDialerList = () => {
    handleStateData({ gettingList: true, gettingError: "" });
    ConntectedService.getPowerDialerList()
      .then((res) => {
        try {
          if (listId) {
            let list = res.data.data.filter((item) => item.id != listId);
            handleStateData({ list: list, gettingList: false });
          } else {
            handleStateData({ list: res.data.data, gettingList: false });
          }
        } catch (error) {
          handleStateData({ gettingList: false, gettingError: "Something went wrong" });
        }
      })
      .catch((error) => {
        console.log(error);
        handleStateData({ gettingList: false, gettingError: "Something went wrong" });
      });
  };
  const addToList = () => {
    if (data.selectedList == null) {
      showGlobalNotification("Please select a list", "warning");
      return;
    }
    const formData = {
      dialerListIds: [data.selectedList?.id],
      contactIds: contactId ? [contactId] : [],
    };

    handleStateData({ addingToList: true, addingToListError: "" });
    ConntectedService.addContactToAnotherList(formData)
      .then((res) => {
        try {
          if (res.success) {
            handleStateData({ addingToList: false, addingToListError: "" });
            showGlobalNotification(res.message, "success");
          } else {
            handleStateData({ addingToList: false, addingToListError: res.message });
            showGlobalNotification(res.message);
          }
          console.log(res);
        } catch (error) {
          showGlobalNotification("Can not add contact to another list");
          handleStateData({ addingToList: false, addingToListError: "Something went wrong" });
        }
      })
      .catch((error) => {
        console.log(error);
        showGlobalNotification("Can not add contact to another list");
        handleStateData({ addingToList: false, addingToListError: "Something went wrong" });
      });
  };

  return (
    <div className={Styles.addCampaignWrapper}>
      <ButtonBack
        backHandler={() => {
          handleStateData({ addingToList: false });
          dispatch(handleSendOption({ option: SEND_OPTIONS.default }));
        }}
        title='Add to another list'
        icon={<ArrowBack sx={{ color: "action.active" }} />}
      />

      <StyledBox>
        <Typography variant='body2medium' sx={{ mb: "4px", display: "block" }}>
          Select a power dialer list
        </Typography>

        <div className={Styles.personalized}>
          <CustomMenuPype
            popup={{
              id: "connected-add-to-list",
              wrapperClass: Styles.popDlvnWrapper,
              placement: "center",
              optionClass: Styles.popDlvnItem,
              isLoading: data.gettingList,
              list: data.list,
              labelField: "title",
              onClick: (item) => {
                handleStateData({ selectedList: item });
              },
              height: "auto",
              width: "__parent__",
              maxHeight: "400px",
              listStyle: {
                justifyContent: "left",
              },
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center",
              },
              emptyListText: data.gettingError,
            }}
            onClose={() => {}}
            label={{
              renderCustom: (
                <div className={Styles.personalizedInput}>
                  <Typography variant='inputText'>
                    {data.selectedList != null ? data.selectedList?.title : "Select list"}
                  </Typography>
                  <Box component={"span"} sx={{ display: "flex" }}>
                    <CarrotIcon height='24' width='24' />
                  </Box>
                </div>
              ),
            }}
          />
        </div>
      </StyledBox>

      <Stack
        direction={"row"}
        justifyContent='space-between'
        alignItems='center'
        sx={{ paddingInline: "24px" }}
        className={Styles.footer}
      >
        <Typography
          variant='buttonMedium'
          color='text.secondary'
          onClick={() => {
            handleStateData({ addingToList: false });
            dispatch(handleSendOption({ option: SEND_OPTIONS.default }));
          }}
          sx={{ cursor: "pointer" }}
        >
          Cancel
        </Typography>

        <Button
          sx={{ backgroundColor: "secondary.main", ":hover": { backgroundColor: "secondary.main" } }}
          variant='contained'
          onClick={addToList}
        >
          {data.addingToList && (
            <CustomCircleLoader fill='#fff' size='extra-small' style={{ display: "flex", marginRight: "5px" }} />
          )}
          {data.addingToList ? "Saving" : "Save"} Change
        </Button>
      </Stack>
    </div>
  );
};

export default AddAnotherList;
