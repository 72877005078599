import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  note: null,
  isLoading: false,
  getError: "",

  isAdding: false,
  newNote: "",
  addError: "",
  addSuccess: "",

  users: [
    { id: 1, display: "first user" },
    { id: 2, display: "second user" },
    { id: 3, display: "third user" },
  ],
};

const contactNoteSlice = createSlice({
  name: "contactNote",
  initialState: initialState,
  reducers: {
    getContactLastNote: (state) => {
      state.isLoading = true;
    },
    getContactLastNoteSuccess: (state, action) => {
      state.note = action.payload;
      state.isLoading = false;
      state.getError = "";
    },
    getContactLastNoteFailed: (state, action) => {
      state.note = null;
      state.isLoading = false;
      state.getError = action.payload;
    },

    addContactNote: (state) => {
      state.isAdding = true;
      state.addError = "";
      state.addSuccess = "";
    },
    addContactNoteSuccess: (state, action) => {
      state.isAdding = false;
      state.addSuccess = action.payload;
      state.addError = "";
      state.newNote = "";
    },
    addContactNoteFailed: (state, action) => {
      state.isAdding = true;
      state.addError = action.payload;
    },
    storeNewNote: (state, action) => {
      state.newNote = action.payload;
    },
    resetNewData: (state) => {
      state.isAdding = false;
      state.addSuccess = "";
      state.addError = "";
      state.newNote = "";
    },

    reset: (state) => {
      state.note = null;
      state.isLoading = false;
      state.getError = "";
      state.isAdding = false;
      state.newNote = "";
      state.addError = "";
      state.addSuccess = "";
    },
  },
});

export const {
  getContactLastNote,
  getContactLastNoteSuccess,
  getContactLastNoteFailed,

  addContactNote,
  addContactNoteSuccess,
  addContactNoteFailed,
  storeNewNote,
  resetNewData,

  reset,
} = contactNoteSlice.actions;

export default contactNoteSlice.reducer;
