import React from "react";
import { Backdrop, Box, Modal, Paper } from "@mui/material";
import styled from "@emotion/styled";

const PaperStyle = styled(Paper)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.background.paper,
  boxShadow: "none",
  border: 0,
  outlined: 0,
  padding: theme.spacing(3),
}));

const CustomModal = ({ open, onClose, disableOutSideClick = false, children, style = {}, ...rest }) => {
  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={!disableOutSideClick ? onClose : () => false}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        onBackdropClick={!disableOutSideClick ? onClose : () => false}
        {...rest}
      >
        <PaperStyle sx={{ ...style }}>
          <Box>{children}</Box>
        </PaperStyle>
      </Modal>
    </React.Fragment>
  );
};

export default CustomModal;
