import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Stack, Typography } from "@mui/material";
import SmsIcon from "@mui/icons-material/Sms";
import EmailIcon from "@mui/icons-material/Email";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import CampaignIcon from "@mui/icons-material/Campaign";
import TagIcon from "@mui/icons-material/Tag";
import SendEmail from "./disconnectTriggers/SendEmail";
import SendSms from "./disconnectTriggers/SendSms";
import AddToCampaign from "./disconnectTriggers/AddToCampaign";
import AddToContactList from "./disconnectTriggers/AddToContactList";
import AddTags from "./disconnectTriggers/AddTags";
import TriggerSettingAccordion from "../../../common/Accordion/TriggerSettingAccordion";
import AddToListIcon from "../../../common/Icons/AddToListIcon";
import { selectTriggerSetting } from "../../../state/features/listSetting/listSettingSelector";

import {
  updateTriggerSetting,
  uploadAttachment,
  uploadVoiceFile,
} from "../../../state/features/listSetting/triggerSetting/triggerSettingSlice";
import { Texts } from "../../../helpers/constant/Constants";
import VoiceManagerV2 from "../../../common/VoiceManager/VoiceManagerV2";
import AudioPlayer from "../../../common/VoiceManager/AudioPlayer";
import { LightCoreButton } from "../../../common/Button/LightCoreButton";
import VoicemailTemplateService from "../../../services/call/VoicemailTemplate.service";
import { showGlobalNotification } from "../../../helpers/utils/showGlobalNotification";
import BasicSelect from "../../../common/Select/BasicSelect";

const DisconnectCallTrigger = () => {
  const dispatch = useDispatch();
  const {
    settings: {
      send_sms_trigger,
      send_sms_trigger_body,
      send_sms_trigger_attachment,
      send_email_trigger,
      send_email_trigger_subject,
      send_email_trigger_body,
      send_email_trigger_attachment,
      send_voice_mail_trigger,
      send_voice_mail_trigger_url,
      add_to_campaign_trigger,
      add_to_campaign_trigger_value,
      add_tag_trigger,
      add_tag_trigger_values,
      add_to_another_list_trigger,
      add_to_another_list_trigger_value,
    },
    isLoadingEmailAttachment,
    isLoadingSmsAttachment,
  } = useSelector(selectTriggerSetting);

  const changeSetting = (objData) => {
    dispatch(updateTriggerSetting(objData));
  };

  const [voicemailData, setVoicemailData] = useState([]);
  const [selectedVoicemail, setSelectedVoicemail] = useState(null);
  const [selectedDropdown, setSelectedDropdown] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await VoicemailTemplateService.getVoicemailTemplates({});
        if (response.success) {
          setVoicemailData(response.data);
        } else {
          setVoicemailData([]);
          showGlobalNotification(response.message);
        }
      } catch (error) {
        console.error(error);
        showGlobalNotification("Something went wrong! Try again later");
      }
    };
    fetchData();
  }, []);

  const handleSelectedDropdown = (e) => {
    const selectedValue = e.target.value;
    setSelectedDropdown(selectedValue);
    const item = voicemailData.find((dataItem) => dataItem.id === selectedValue);
    if (item?.url) {
      setSelectedVoicemail(item);
      changeSetting({ send_voice_mail_trigger_url: item.url });
    }
  };

  const formatData = (data) => {
    return data.map((item) => ({
      value: item.id,
      title: item.title,
    }));
  };

  return (
    <Box px={4} py={2}>
      <Stack direction={"column"}>
        <Typography variant='body2medium'>{Texts.actionForDisconnectCall}</Typography>
        <Typography color='text.secondary' variant='chipLight'>
          {Texts.actionForDisconnectCallHints}
        </Typography>
      </Stack>

      <Stack direction={"column"} spacing={2} mt={2}>
        {/* sms setting */}
        <TriggerSettingAccordion
          title={Texts.sendSMS}
          subTitle={Texts.sendSMSHintsTrigger}
          icon={<SmsIcon sx={{ color: "text.secondary" }} />}
          status={send_sms_trigger}
          onChangeStatus={(status) => changeSetting({ send_sms_trigger: status })}
        >
          <SendSms
            value={send_sms_trigger_body}
            onChangeValue={(value) => changeSetting({ send_sms_trigger_body: value })}
            isLoadingAttachment={isLoadingSmsAttachment}
            attachment={send_sms_trigger_attachment}
            onChangeAttachment={(file) => dispatch(uploadAttachment({ file, key: "send_sms_trigger_attachment" }))}
          />
        </TriggerSettingAccordion>

        {/* email setting */}
        <TriggerSettingAccordion
          title={Texts.sendEmail}
          subTitle={Texts.sendEmailHintsTrigger}
          icon={<EmailIcon sx={{ color: "text.secondary" }} />}
          status={send_email_trigger}
          onChangeStatus={(status) => changeSetting({ send_email_trigger: status })}
        >
          <SendEmail
            subject={send_email_trigger_subject}
            body={send_email_trigger_body}
            onChangeSubject={(value) => changeSetting({ send_email_trigger_subject: value })}
            onChangeBody={(value) => changeSetting({ send_email_trigger_body: value })}
            isLoadingAttachment={isLoadingEmailAttachment}
            attachment={send_email_trigger_attachment}
            onChangeAttachment={(file) => dispatch(uploadAttachment({ file, key: "send_email_trigger_attachment" }))}
          />
        </TriggerSettingAccordion>

        {/* voice setting */}
        <TriggerSettingAccordion
          title={Texts.sendVoice}
          subTitle={Texts.sendVoiceHintsTrigger}
          icon={<KeyboardVoiceIcon sx={{ color: "text.secondary" }} />}
          status={send_voice_mail_trigger}
          onChangeStatus={(status) => changeSetting({ send_voice_mail_trigger: status })}
        >
          {!send_voice_mail_trigger_url ? (
            <Stack>
              <Stack pl={2} mt={2}>
                <Typography variant='body2medium'>Record A New Message</Typography>
              </Stack>
              <VoiceManagerV2
                showHeader={false}
                border={false}
                src={send_voice_mail_trigger_url}
                onRemove={() => {
                  changeSetting({ send_voice_mail_trigger_url: "" });
                  setSelectedVoicemail(null);
                }}
                onChange={(url, file) => {
                  dispatch(uploadVoiceFile({ file, url, key: "send_voice_mail_trigger_url" }));
                  setSelectedVoicemail(null);
                }}
              />
              <Stack px={2} py={2}>
                <Typography mb={1} variant='body2medium'>
                  Select A Message From Your Library
                </Typography>
                <BasicSelect
                  data={formatData(voicemailData)}
                  value={selectedDropdown}
                  onChange={handleSelectedDropdown}
                  placeholder='Select a voice mail'
                />
              </Stack>
            </Stack>
          ) : (
            <Grid item xs={12} px={2} py={2}>
              {selectedVoicemail && (
                <Stack>
                  <Typography variant='body2medium'>{selectedVoicemail.title}</Typography>
                  <Typography color='text.secondary' variant='chipLight'>
                    {selectedVoicemail.description}
                  </Typography>
                </Stack>
              )}
              <AudioPlayer src={send_voice_mail_trigger_url} />
              <Stack direction='row' justifyContent='flex-end' pt={1}>
                <LightCoreButton
                  color='error'
                  size='small'
                  sx={{ width: "max-content" }}
                  onClick={() => {
                    changeSetting({ send_voice_mail_trigger_url: "" });
                    setSelectedVoicemail(null);
                    setSelectedDropdown(null);
                  }}
                >
                  Remove
                </LightCoreButton>
              </Stack>
            </Grid>
          )}
        </TriggerSettingAccordion>

        {/* campaign setting */}
        <TriggerSettingAccordion
          title={Texts.addToCampaignTrigger}
          subTitle={Texts.addToCampaignTriggerHints}
          icon={<CampaignIcon sx={{ color: "text.secondary" }} />}
          status={add_to_campaign_trigger}
          onChangeStatus={(status) => changeSetting({ add_to_campaign_trigger: status })}
        >
          <AddToCampaign
            selectedOption={add_to_campaign_trigger_value}
            onChange={(value) => changeSetting({ add_to_campaign_trigger_value: value })}
          />
        </TriggerSettingAccordion>

        {/* tag setting */}
        <TriggerSettingAccordion
          title={Texts.addTagTrigger}
          subTitle={Texts.addTagTriggerHints}
          icon={<TagIcon sx={{ color: "text.secondary" }} />}
          status={add_tag_trigger}
          onChangeStatus={(status) => changeSetting({ add_tag_trigger: status })}
        >
          <AddTags
            selectedTags={add_tag_trigger_values}
            onChange={(value) => changeSetting({ add_tag_trigger_values: value })}
          />
        </TriggerSettingAccordion>

        {/* add to another list */}
        <TriggerSettingAccordion
          title={Texts.addToListTrigger}
          subTitle={Texts.addToListTriggerHints}
          icon={<AddToListIcon sx={{ color: "text.secondary" }} />}
          status={add_to_another_list_trigger}
          onChangeStatus={(status) => changeSetting({ add_to_another_list_trigger: status })}
        >
          <AddToContactList
            selectedOption={add_to_another_list_trigger_value}
            onChange={(value) => changeSetting({ add_to_another_list_trigger_value: value })}
          />
        </TriggerSettingAccordion>
      </Stack>
    </Box>
  );
};
export default DisconnectCallTrigger;
