import React from "react";
import { Backdrop, Button, Modal, Paper, styled, Typography } from "@mui/material";
import Box from "@mui/material/Box";
const PaperStyle = styled(Paper)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.background.paper,
  boxShadow: "none",
  border: 0,
  outlined: 0,
  padding: theme.spacing(3),
}));

const InfoModal = ({
  open,
  onClose,
  disableOutSideClick = false,
  title = "",
  example = "",
  anotherExample = "",
  ...rest
}) => {
  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={!disableOutSideClick ? onClose : () => false}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        {...rest}
      >
        <PaperStyle sx={{ width: "500px" }}>
          <Box>
            <div>
              <Typography variant={"body1"} color={"#346fef"} style={{ marginBottom: "16px", fontWeight: 700 }}>
                {title}
              </Typography>
              {example && (
                <Typography variant={"body2"} color={"text.secondary"} style={{ marginBottom: "16px" }}>
                  <span style={{ fontWeight: 700 }}>Ex.</span> {example}
                </Typography>
              )}
              {anotherExample && (
                <Typography variant={"body2"} color={"text.secondary"} style={{ marginBottom: "16px" }}>
                  <span style={{ fontWeight: 700 }}>Ex.</span> {anotherExample}
                </Typography>
              )}
            </div>

            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button
                sx={{ backgroundColor: "secondary.main", ":hover": { backgroundColor: "secondary.main" } }}
                variant='contained'
                onClick={onClose}
              >
                Close
              </Button>
            </div>
          </Box>
        </PaperStyle>
      </Modal>
    </React.Fragment>
  );
};

export default InfoModal;
