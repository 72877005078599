import React from "react";
import { useSelector } from "react-redux";
import { Avatar, AvatarGroup, Box, Stack, Typography } from "@mui/material";
import CoreButton from "../../common/Button/CoreButton";
import getFullName from "../../helpers/utils/getFullName";
import { selectDialerContactsState } from "../../state/features/dialerContacts/dialerContactsSelector";

const ContactDeleteContent = ({ contactIds = [], buttonName = "Confirm", onConfirm, onClose }) => {
  const { data: dialerContactList } = useSelector(selectDialerContactsState);

  return (
    <Box>
      <Stack alignItems={"center"} py={5}>
        <AvatarGroup max={4}>
          {dialerContactList.map((contact, index) => {
            const hasSelected = contactIds.includes(contact.id);
            if (!hasSelected) return null;

            const { first_name, last_name, email, avatar } = contact;
            let fullName = getFullName(first_name, last_name, "");
            if (!fullName) fullName = email || "";

            return (
              <Avatar key={index} alt={"avatar"} src={avatar}>
                {fullName[0]}
              </Avatar>
            );
          })}
        </AvatarGroup>

        <Typography variant={"subtitle1"} color={"text.primary"} mt={1}>
          {contactIds.length} {contactIds.length > 1 ? "Contacts" : "Contact"}
        </Typography>
        <Typography variant={"body2"} color={"text.secondary"}>
          Are you sure want to remove the contacts from the list?
        </Typography>
      </Stack>

      <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"} spacing={2} sx={{ mt: 3 }}>
        <CoreButton color={"inherit"} size='large' onClick={onClose}>
          Cancel
        </CoreButton>
        <CoreButton variant='contained' color='error' size='large' onClick={onConfirm}>
          {buttonName}
        </CoreButton>
      </Stack>
    </Box>
  );
};

export default ContactDeleteContent;
