import { Box, styled, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppointmentIcon } from "../../../../common/icon/Icons";
import { getAccountData } from "../../../../helpers/utils/getAccountData";
import { selectDialerState } from "../../../../state/features/dialer/dialerSelector";
import { handleSendOption, SEND_OPTIONS } from "../../../../state/features/dialer/dialerSlice";

const StyledActionItem = styled(Box)(({ theme }) => ({
  color: "red",
  backgroundColor: theme.palette.other.outlinedBorder,

  border: `1px solid ${theme.palette.other.outlinedBorderDarker}`,
  borderRadius: "4px",

  display: "grid",
  placeContent: "center",

  textAlign: "center",
  padding: "0.5rem 1rem",
  cursor: "pointer",

  "&:hover": {
    backgroundColor: theme.palette.other.outlinedBorderDarker,
  },
}));

const thanksIoActive =
  getAccountData("customMenu")["63"] &&
  getAccountData("customMenu")["63"] &&
  getAccountData("customMenu")["63"] !== null &&
  getAccountData("customMenu")["63"] !== ""
    ? true
    : false;

const zenDirectActive =
  getAccountData("customMenu")["61"] &&
  getAccountData("customMenu")["61"] &&
  getAccountData("customMenu")["61"] !== null &&
  getAccountData("customMenu")["61"] !== ""
    ? true
    : false;

const AllActions = () => {
  const dispatch = useDispatch();
  const {
    connected: { contact, contactId },
  } = useSelector(selectDialerState);

  const handleShowActivityModal = () => {
    if (contact == null) {
      return;
    }
    if (window.openGlobalActivityModal == undefined) {
      return;
    }
    try {
      dispatch(handleSendOption({ isShowActivity: true }));
      let contact_details = { ...contact };
      if (contact_details["id"] == undefined) {
        contact_details["id"] = contactId;
      }
      if (contact_details["contact_id"] == undefined) {
        contact_details["contact_id"] = contactId;
      }
      if (contact_details["contactId"] == undefined) {
        contact_details["contactId"] = contactId;
      }
      if (contact_details["value"] == undefined) {
        contact_details["value"] = contact["number"];
      }
      contact_details["firstName"] = contact["first_name"];
      contact_details["lastName"] = contact["last_name"];
      console.log(contact_details);
      window.openGlobalActivityModal({
        contactDetails: contact_details,
        callBack: handleHideActivityModal,
        from: "power-dialer",
      });
    } catch (error) {
      console.log("error on add appointment");
    }
  };
  const handleHideActivityModal = () => {
    dispatch(handleSendOption({ isShowActivity: false }));
  };

  const handleShowVideoModal = () => {
    if (contact == null) {
      return;
    }
    if (window.sendCommunication == undefined) {
      return;
    }
    try {
      dispatch(handleSendOption({ isShowVideoModal: true }));
      let contact_details = { ...contact };
      if (contact_details["id"] == undefined) {
        contact_details["id"] = contactId;
      }
      if (contact_details["contact_id"] == undefined) {
        contact_details["contact_id"] = contactId;
      }
      if (contact_details["contactId"] == undefined) {
        contact_details["contactId"] = contactId;
      }
      if (contact_details["value"] == undefined) {
        contact_details["value"] = contact["number"];
      }
      contact_details["firstName"] = contact["first_name"];
      contact_details["lastName"] = contact["last_name"];
      window.sendCommunication(
        {
          open: true,
          contactId: contactId,
          contactDetails: contact_details,
          from: "power-dailer",
          responseCallback: (response) => {
            console.log(response);
            handleHideVideoModal();
          },
        },
        "videoEmail"
      );
    } catch (error) {
      console.log("error on add appointment");
    }
  };
  const handleHideVideoModal = () => {
    dispatch(handleSendOption({ isShowVideoModal: false }));
  };

  const directMailFlag = thanksIoActive || zenDirectActive;

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: "8px",
        marginTop: "10px",
      }}
    >
      <StyledActionItem onClick={() => dispatch(handleSendOption({ option: SEND_OPTIONS.sms }))}>
        <Typography variant='body2' color={"text.secondary"}>
          Send SMS
        </Typography>
      </StyledActionItem>

      <StyledActionItem onClick={() => dispatch(handleSendOption({ option: SEND_OPTIONS.email }))}>
        <Typography variant='body2' color={"text.secondary"}>
          Send Email
        </Typography>
      </StyledActionItem>

      <StyledActionItem onClick={() => dispatch(handleSendOption({ option: SEND_OPTIONS.voiceMessage }))}>
        <Typography variant='body2' color={"text.secondary"}>
          Ringless Voicemail
        </Typography>
      </StyledActionItem>

      <StyledActionItem onClick={() => dispatch(handleSendOption({ option: SEND_OPTIONS.addToCampaign }))}>
        <Typography variant='body2' color={"text.secondary"}>
          Add To Campaign
        </Typography>
      </StyledActionItem>

      <StyledActionItem onClick={() => dispatch(handleSendOption({ option: SEND_OPTIONS.addToTag }))}>
        <Typography variant='body2' color={"text.secondary"}>
          Add Tags
        </Typography>
      </StyledActionItem>

      <StyledActionItem onClick={() => dispatch(handleSendOption({ option: SEND_OPTIONS.addToAnotherList }))}>
        <Typography variant='body2' color={"text.secondary"}>
          Add to Another List
        </Typography>
      </StyledActionItem>

      {directMailFlag && (
        <StyledActionItem onClick={() => dispatch(handleSendOption({ option: SEND_OPTIONS.directMail }))}>
          <Typography variant='body2' color={"text.secondary"}>
            Send Direct Mail/Gifts
          </Typography>
        </StyledActionItem>
      )}

      <StyledActionItem onClick={handleShowVideoModal}>
        <Typography variant='body2' color={"text.secondary"}>
          Send Video Email
        </Typography>
      </StyledActionItem>

      <StyledActionItem
        onClick={handleShowActivityModal}
        sx={{
          display: "flex",
          gap: "15px",
          gridColumn: "1/-1",
          alignItems: "center",
          justifyContent: "left",
          backgroundColor: "secondary.main",
          "&:hover": {
            backgroundColor: "secondary.main",
          },
        }}
      >
        <Box component={"span"} sx={{ display: "flex", alignItems: "center" }}>
          <AppointmentIcon />
        </Box>
        <Typography variant='body2' color={"common.white"}>
          Schedule Appointment/Task
        </Typography>
      </StyledActionItem>
    </Box>
  );
};

export default AllActions;
