import httpRequest from "./api/httpRequest";

class DefaultListSetting {
  getCallSetting(params) {
    return httpRequest.get(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/list/default/call-setting",
      params
    );
  }

  updateCallSetting(params) {
    return httpRequest.post(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/list/default/call-setting",
      params
    );
  }

  /* for general scripts */
  getGeneralScriptsApi(params) {
    return httpRequest.get(process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/general-script", params);
  }
  addGeneralScriptApi(params) {
    return httpRequest.post(process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/general-script", params);
  }
  updateGeneralScriptApi(params) {
    return httpRequest.put(process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/general-script", params);
  }
  deleteGeneralScriptApi(params) {
    return httpRequest.delete(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/general-script",
      params
    );
  }

  /* for call scripts */
  getCallScriptsApi(params) {
    return httpRequest.get(process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/default-script", params);
  }

  addCallScriptApi(params) {
    return httpRequest.post(process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/default-script", params);
  }

  deleteCallScriptApi(params) {
    return httpRequest.delete(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/default-script",
      params
    );
  }
}

export default new DefaultListSetting();
