import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { formatUSNumber } from "../../../helpers/utils/formatUSNumber";
import { selectDialerState } from "../../../state/features/dialer/dialerSelector";
import { BusinessIcon, Link, Location, MailOutLine, Phone } from "../../screens/auto-dialer-ongoing/IconsOngoing";
import Styles from "./contactInfo.module.scss";

const ContactInfo = () => {
  const {
    connected: { contact },
  } = useSelector(selectDialerState);
  if (contact == null) {
    return null;
  }
  return (
    <div className={Styles.contactInfoWrapper}>
      <Typography variant='body2medium' component={"p"} color={"text.primary"} sx={{ pb: 3 }}>
        About
      </Typography>

      <span>
        <div className={Styles.aboutInfo}>
          <div className={Styles.aboutTitle}>
            <Box component='span' sx={{ display: "flex" }}>
              <BusinessIcon />
            </Box>
            <Typography variant='chipLight' color='text.secondary'>
              Company:
            </Typography>
          </div>

          <Typography variant='chipLight' sx={{ maxWidth: "170px", wordWrap: "break-word" }}>
            {contact?.company}
          </Typography>
        </div>
        <div className={Styles.aboutInfo}>
          <div className={Styles.aboutTitle}>
            <Box component='span' sx={{ display: "flex" }}>
              <MailOutLine />
            </Box>
            <Typography variant='chipLight' color='text.secondary'>
              Email:
            </Typography>
          </div>

          <Typography variant='chipLight' sx={{ maxWidth: "170px", wordWrap: "break-word" }}>
            {contact?.email}
          </Typography>
        </div>

        <div className={Styles.aboutInfo}>
          <div className={Styles.aboutTitle}>
            <Box component='span' sx={{ display: "flex" }}>
              <Phone />
            </Box>
            <Typography variant='chipLight' color='text.secondary'>
              Phone:
            </Typography>
          </div>

          <Typography variant='chipLight' sx={{ textAlign: "right" }}>
            {formatUSNumber(contact.number)}
          </Typography>
        </div>

        <div className={Styles.aboutInfo}>
          <div className={Styles.aboutTitle}>
            <Box component='span' sx={{ display: "flex" }}>
              <Location />
            </Box>
            <Typography variant="chipLight" color='text.secondary'>
              Address:
            </Typography>
          </div>

          <Typography variant='chipLight' sx={{ textAlign: "left", maxWidth: "120px", wordWrap: "break-word" }}>
            {contact.address}
          </Typography>
        </div>

        <div className={Styles.aboutInfo}>
          <div className={Styles.aboutTitle}>
            <Box component='span' sx={{ display: "flex" }}>
              <Location />
            </Box>
            <Typography variant='chipLight' color='text.secondary'>
              City & State:
            </Typography>
          </div>

          <Typography variant='chipLight' sx={{ textAlign: "left", maxWidth: "120px", wordWrap: "break-word" }}>
            {contact.city} {contact.state}
          </Typography>
        </div>


        <div className={Styles.aboutInfo}>
          <div className={Styles.aboutTitle}>
            <Box component='span' sx={{ display: "flex" }}>
              <Link />
            </Box>
            <Typography variant='chipLight' color='text.secondary'>
              URL:
            </Typography>
          </div>

          <Typography variant='chipLight' sx={{ textAlign: "left", maxWidth: "200px", wordWrap: "break-word" }}>
            {contact.url}
          </Typography>
        </div>
      </span>
    </div>
  );
};

export default ContactInfo;
