import { all, put } from "redux-saga/effects";
import { takeEvery, delay, select, call } from "@redux-saga/core/effects";
import { errorHandler } from "../../../helpers/utils/errorHandler";
import {
  DIALER_STEP,
  geContactNoteError,
  geContactNoteSuccess,
  geContactTagError,
  geContactTagSuccess,
  getContactScriptError,
  getContactScriptSuccess,
  getContactsError,
  getContactsSuccess,
  getConversationError,
  getConversationSuccess,
  getSettingOverviewError,
  getSettingOverviewSuccess,
  getStatsError,
  getStatsSuccess,
  handleCallStep,
  handleEndCall,
  handleInitStep,
  needToGetMoreConversation,
  storeAllScripts,
  storeAllScriptsError,
  storeBasicInit,
  // storeDialerMainScript,
  storeDialerMainScriptFullData,
  updateContactOutcomeSuccess,
} from "./dialerSlice";
import { showGlobalNotification } from "../../../helpers/utils/showGlobalNotification";
import { selectDialerState } from "./dialerSelector";
import InitializingService from "../../../services/call/Initializing.service";
import ConntectingService from "../../../services/call/Conntecting.service";
import ContactTagsService from "../../../services/call/ContactTags.service";
import ContactNoteService from "../../../services/call/ContactNote.service";
import ConversationService from "../../../services/call/Conversation.service";
import ConntectedService from "../../../services/call/Conntected.service";
import { TimelineContentTypes } from "../../../helpers/constant/Constants";
import apiSlice from "../../apiSlice";
import { selectSelectedDialerList } from "../dialerList/dialerListSelector";

function* dialerWatcher() {
  yield takeEvery("dialer/handleStartCall", handleStartCallSaga);
  yield takeEvery("dialer/getSettingOverviewOnBackground", getSettingOverviewOnBackgroundSaga);
  yield takeEvery("dialer/getSettingOverview", getSettingOverviewSaga);
  yield takeEvery("dialer/getStats", getStatsSaga);
  yield takeEvery("dialer/getContacts", getContactsSaga);
  yield takeEvery("dialer/geContactTags", geContactTagsSaga);
  yield takeEvery("dialer/geContactLastNote", geContactLastNoteSaga);
  yield takeEvery("dialer/getConversation", getConversationSaga);
  yield takeEvery("dialer/createNewSession", createNewSessionSaga);
  yield takeEvery("dialer/storeConnectedContactByDelay", storeConnectedContactByDelaySaga);
  yield takeEvery("dialer/getContactScript", getContactScriptSaga);
  yield takeEvery("dialer/updateContactOutcome", updateContactOutcomeSaga);
  yield takeEvery("dialer/getAllScripts", getAllScriptsSaga);
}

function* handleStartCallSaga(action) {
  try {
    const response = yield call(InitializingService.checkValidation, {
      powerDialerListId: action.payload.listId,
      virtualNumberId: action.payload.virtualNumberId,
    });
    console.log(response);
    if (response) {
      if (response.success) {
        // if (response.data.isValid) {
        if (response.data.is_valid) {
          yield put(storeBasicInit({ sessionId: response.data.sessionId, sessionInfo: response.data.sessionInfo }));
          if (response.data.startFromBeginning) {
            yield put(handleInitStep(DIALER_STEP.SHOW_TERM_CONDITION));
          } else {
            yield put(handleInitStep(DIALER_STEP.SHOW_RESUME));
          }
          // session re-fetch
          const { id } = yield select(selectSelectedDialerList);
          yield put(apiSlice.endpoints.getDialerSessionList.initiate({ id: id }, { forceRefetch: true }));
        } else {
          showGlobalNotification(response.message);
          yield put(handleEndCall());
        }
      } else {
        showGlobalNotification(response.message);
        yield put(handleEndCall());
      }
    } else {
      showGlobalNotification("Something went wrong! Try again later");
      yield put(handleEndCall());
    }
  } catch (err) {
    errorHandler(err, "dialerSaga > handleStartCallSaga");
    showGlobalNotification("Something went wrong! Try again later");
    yield put(handleEndCall());
  }
}
function* getSettingOverviewOnBackgroundSaga() {
  try {
    const {
      init: { listId, virtualNumberId },
    } = yield select(selectDialerState);
    const response = yield call(InitializingService.getSettingOverview, {
      powerDialerId: listId,
      virtualNumberId: virtualNumberId,
    });
    if (response) {
      if (response.success) {
        yield put(storeBasicInit({ virtualNumber: response.data.virtualNumber }));
        yield put(getSettingOverviewSuccess(response.data));
      } else {
        console.log("has error : dialerSaga > getSettingOverviewSaga");
      }
    } else {
      console.log("has error : dialerSaga > getSettingOverviewSaga");
    }
  } catch (err) {
    console.log(err, "dialerSaga > getSettingOverviewSaga");
  }
}
function* getSettingOverviewSaga() {
  try {
    const {
      init: { listId, virtualNumberId },
    } = yield select(selectDialerState);
    const response = yield call(InitializingService.getSettingOverview, {
      powerDialerId: listId,
      virtualNumberId: virtualNumberId,
    });
    if (response) {
      if (response.success) {
        yield put(storeBasicInit({ virtualNumber: response.data.virtualNumber }));
        yield put(getSettingOverviewSuccess(response.data));
        if (response.data?.script) {
          // yield put(storeDialerMainScript(response.data.script));
          yield put(
            storeDialerMainScriptFullData({
              script: response.data.script,
              title: response.data?.script_title,
              id: response.data?.script_id,
            })
          );
        }
      } else {
        yield put(getSettingOverviewError(response.message));
        showGlobalNotification(response.message);
      }
    } else {
      yield put(getSettingOverviewError("Something went wrong !"));
      showGlobalNotification("Something went wrong! Try again later");
    }
  } catch (err) {
    errorHandler(err, "dialerSaga > getSettingOverviewSaga");
    showGlobalNotification("Something went wrong! Try again later");
  }
}
function* getStatsSaga() {
  try {
    const {
      init: { listId, sessionCount, sessionId },
    } = yield select(selectDialerState);
    if (sessionCount > 0) {
      return;
    }
    const response = yield call(ConntectingService.getDialerStats, {
      id: listId,
      sessionId: sessionId,
      from: "callScreen",
    });
    if (response) {
      if (response.success) {
        yield put(getStatsSuccess(response.data));
      } else {
        yield put(getStatsError(response.message));
      }
    } else {
      yield put(getStatsError("Something went wrong !"));
      showGlobalNotification("Something went wrong! Try again later");
    }
  } catch (err) {
    errorHandler(err, "dialerSaga > getStatsSaga");
    showGlobalNotification("Something went wrong! Try again later");
  }
}
function* getContactsSaga() {
  try {
    yield delay(2000);

    yield put(handleCallStep(DIALER_STEP.FETCHING));
    const {
      init: { listId, sessionId, callStartType },
      connected: { lastContactedId },
    } = yield select(selectDialerState);
    const response = yield call(ConntectingService.getConnectingContacts, {
      powerDialerListId: listId,
      sessionId: sessionId,
      callStartType: callStartType,
      lastContactedId: lastContactedId,
    });
    if (response) {
      if (response.success) {
        yield put(getContactsSuccess(response.data));
      } else {
        yield put(getContactsError(response.message));
      }
    } else {
      yield put(getContactsError("Something went wrong !"));
      showGlobalNotification("Something went wrong! Try again later");
    }
  } catch (err) {
    errorHandler(err, "dialerSaga > getConnectingContactsSaga");
    showGlobalNotification("Something went wrong! Try again later");
  }
}
function* geContactTagsSaga() {
  try {
    const {
      connected: { contactId },
    } = yield select(selectDialerState);
    const response = yield call(ContactTagsService.getContactTags, { contactId: contactId });

    if (response) {
      if (response.success) {
        yield put(geContactTagSuccess(response.data));
      } else {
        yield put(geContactTagError(response.message));
      }
    } else {
      yield put(geContactTagError("Something went wrong !"));
      showGlobalNotification("Something went wrong! Try again later");
    }
  } catch (err) {
    errorHandler(err, "dialerSaga > geContactTagsSaga");
  }
}
function* geContactLastNoteSaga() {
  try {
    const {
      connected: { contactId },
    } = yield select(selectDialerState);
    const response = yield call(ContactNoteService.getContactLastNote, { contactId: contactId });

    if (response) {
      if (response.success) {
        yield put(geContactNoteSuccess(response.data));
      } else {
        yield put(geContactNoteError(response.message));
      }
    } else {
      yield put(geContactNoteError("Something went wrong !"));
    }
  } catch (err) {
    errorHandler(err, "dialerSaga > geContactLastNoteSaga");
  }
}
function* getConversationSaga() {
  try {
    const {
      connected: { contactId, tab },
      conversation: { type, pageSize, pageNo },
    } = yield select(selectDialerState);
    console.log(pageNo, type, tab);

    let messageType;
    // if (type == TimelineContentTypes.all) {
    if (tab == TimelineContentTypes.all) {
      messageType = [1, 2, 3, 4, 5, 25, 14, 21, 22, 23, 24];
    } else {
      // if (type == TimelineContentTypes.sms) {
      if (tab == TimelineContentTypes.sms) {
        messageType = [1, 2];
      } else if (tab == TimelineContentTypes.directMail) {
        messageType = [21, 22, 23, 24];
      } else {
        // messageType = [type];
        messageType = [tab];
      }
    }
    const response = yield call(ConversationService.getContactConversation, {
      contactId: contactId,
      limit: pageSize,
      // page: pageNo,
      page: 1,
      type: messageType,
    });

    if (response) {
      yield put(getConversationSuccess(response.data));
      if (response.data.length >= pageSize) {
        yield put(needToGetMoreConversation(true));
      }
    } else {
      yield put(getConversationError("Something went wrong !"));
    }
  } catch (err) {
    errorHandler(err, "dialerSaga > getConversationSaga");
  }
}
function* createNewSessionSaga(action) {
  try {
    const {
      init: { listId },
    } = yield select(selectDialerState);

    const response = yield call(InitializingService.createNewSession, {
      powerDialerListId: listId,
      sessionType: action.payload ? 1 : 0,
    });

    if (response) {
      if (response.success) {
        yield put(
          storeBasicInit({
            sessionId: response.data.sessionId,
            sessionInfo: response.data.sessionInfo,
            creatingNewSession: false,
          })
        );
        yield put(handleInitStep(DIALER_STEP.SHOW_SETTING));

        // session re-fetch
        const { id } = yield select(selectSelectedDialerList);
        yield put(apiSlice.endpoints.getDialerSessionList.initiate({ id: id }, { forceRefetch: true }));
      } else {
        yield put(storeBasicInit({ creatingNewSession: false }));
        showGlobalNotification(response.message);
        yield put(handleEndCall());
      }
    } else {
      yield put(storeBasicInit({ creatingNewSession: false }));
      showGlobalNotification(response.message);
      yield put(handleEndCall());
    }
  } catch (err) {
    errorHandler(err, "dialerSaga > getConversationSaga");
  }
}
function* storeConnectedContactByDelaySaga() {
  try {
    // yield delay(3000);
    yield delay(100);
    yield put(handleInitStep(DIALER_STEP.SHOW_CONNECTED_SCREEN));
  } catch (err) {
    console.log(err);
  }
}
function* getContactScriptSaga() {
  try {
    const {
      connected: { contactId },
      init: { listId },
    } = yield select(selectDialerState);
    const response = yield call(ConntectedService.getContactScript, { contactId: contactId, powerDialerId: listId });

    if (response) {
      if (response.success) {
        yield put(getContactScriptSuccess(response.data));
      } else {
        yield put(getContactScriptError(response.message));
      }
    } else {
      yield put(getContactScriptError("Something went wrong !"));
    }
  } catch (err) {
    yield put(getContactScriptError("Something went wrong !"));
    errorHandler(err, "dialerSaga > getContactScriptSaga");
  }
}
function* updateContactOutcomeSaga() {
  try {
    const {
      connected: { contactId },
      init: { listId, sessionId },
      users: { selectedOutcome, savedOutcomeId },
    } = yield select(selectDialerState);
    if (selectedOutcome == null) {
      return;
    }
    const response = yield call(InitializingService.updateContactOutcome, {
      savedOutcomeId: savedOutcomeId,
      contactId: contactId,
      powerDialerListId: listId,
      sessionId: sessionId,
      callOutcomeId: selectedOutcome.id,
    });

    if (response) {
      if (response.success) {
        yield put(updateContactOutcomeSuccess(response.data));
        showGlobalNotification("Contact outcome updated !", "success");
      } else {
        showGlobalNotification(response.message);
      }
    } else {
      showGlobalNotification(response.message);
    }
  } catch (err) {
    showGlobalNotification("Something went wrong !");
    errorHandler(err, "dialerSaga > getContactScriptSaga");
  }
}
function* getAllScriptsSaga() {
  try {
    const {
      init: { listId },
    } = yield select(selectDialerState);
    const response = yield call(InitializingService.getAllCallScriptForList, {
      powerDialerId: listId,
    });

    if (response) {
      if (response.success) {
        yield put(storeAllScripts(response.data));
      } else {
        yield put(storeAllScripts([]));
        yield put(storeAllScriptsError());
      }
    }
  } catch (err) {
    errorHandler(err, "dialerSaga > getAllScriptsSaga");
  }
}

export default function* dialerSaga() {
  yield all([dialerWatcher()]);
}
