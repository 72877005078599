import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import EachNote from "./EachNote";
import { selectDialerState } from "../../../state/features/dialer/dialerSelector";
import { geContactLastNote } from "../../../state/features/dialer/dialerSlice";

const ContactLastNote = () => {
  const dispatch = useDispatch();
  const {
    contactNote: { note, gettingNote: isLoading, gettingNoteError: getError },
  } = useSelector(selectDialerState);
  useEffect(() => {
    dispatch(geContactLastNote());
  }, []);
  const renderView = () => {
    if (isLoading) {
      return "Please wait...";
    }
    if (getError !== "") {
      return getError;
    }
    if (note && note.message !== undefined) {
      return <EachNote id={note.id} message={note?.message} />;
    }
    return "No note added yet !";
  };

  return (
    <Box sx={{ padding: "24px" }}>
      <Typography variant='body2medium' component={"p"} color={"text.primary"} sx={{ pb: 2 }}>
        Last Notes
      </Typography>

      <Typography variant='body2' color={"text.secondary"}>
        {renderView()}
      </Typography>
    </Box>
  );
};

export default ContactLastNote;
