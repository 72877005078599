import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  currentPage: 1,
  limit: 20,
  count: 0,
  isLoading: false,
  isDataFetching: false,
  isError: false,
  error: "",

  isAdding: false,
  isAddSuccess: false,

  isDeleting: false,

  isUpdating: false,
  isUpdateSuccess: false,

  selectedDialerList: {},
};

const dialerListSlice = createSlice({
  name: "dialerList",
  initialState: initialState,
  reducers: {
    getDialerList: (state) => {
      state.isLoading = true;
    },
    getDialerListSuccess: (state, action) => {
      state.data = action.payload.data;
      state.currentPage = +action.payload.currentPage;
      state.limit = +action.payload.limit;
      state.count = +action.payload.count;
      state.isLoading = false;
      state.isError = false;
      state.error = "";

      //state.selectedDialerList = action.payload.data?.length > 0 ? action.payload.data[0] : {};
    },
    getDialerListFailed: (state, action) => {
      state.data = [];
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },

    getMoreDialerList: (state) => {
      state.isDataFetching = true;
    },
    getMoreDialerListSuccess: (state, action) => {
      state.data = [...state.data, ...action.payload.data];
      state.currentPage = +action.payload.currentPage;
      state.limit = +action.payload.limit;
      state.count = +action.payload.count;
      state.isDataFetching = false;
    },
    getMoreDialerListFailed: (state, action) => {
      state.isError = true;
      state.error = action.payload;
      state.isDataFetching = false;
    },

    addDialer: (state) => {
      state.isAdding = true;
    },
    addDialerSuccess: (state, action) => {
      if (action.payload?.id) {
        state.data = [action.payload, ...state.data];
        state.count += 1;
      }
      state.isAdding = false;
      state.isAddSuccess = true;
    },
    addDialerFailed: (state) => {
      state.isAdding = false;
    },
    resetAddDialerSuccess: (state) => {
      state.isAddSuccess = false;
    },

    deleteDialer: (state) => {
      state.isDeleting = true;
    },
    deleteDialerSuccess: (state, action) => {
      state.isDeleting = false;
      if (action.payload?.id) {
        state.data = state.data.filter((dialer) => dialer.id !== action.payload.id);
        state.count -= 1;
        if (action.payload?.id === state.selectedDialerList.id) {
          state.selectedDialerList = action.payload.data?.length > 0 ? action.payload.data[0] : {};
        }
      }
    },
    deleteDialerFailed: (state) => {
      state.isDeleting = false;
    },

    updateDialer: (state) => {
      state.isUpdating = true;
    },
    updateDialerSuccess: (state, action) => {
      if (action.payload?.id) {
        state.data = state.data.map((dialer) => {
          if (dialer.id === action.payload.id) {
            dialer = { ...dialer, ...action.payload };
          }
          return dialer;
        });

        if (state.selectedDialerList.id === action.payload.id) {
          state.selectedDialerList = { ...state.selectedDialerList, ...action.payload };
        }
      }
      state.isUpdating = false;
      state.isUpdateSuccess = true;
    },
    updateDialerFailed: (state) => {
      state.isUpdating = false;
    },
    resetUpdateDialerSuccess: (state) => {
      state.isUpdateSuccess = false;
    },

    updateDialerColor: (state) => {
      state.isUpdating = true;
    },
    updateDialerColorSuccess: (state, action) => {
      if (action.payload?.id) {
        state.data = state.data.map((dialer) => {
          if (dialer.id === action.payload.id) {
            dialer.color = action.payload.color;
          }
          return dialer;
        });
      }
      state.isUpdating = false;
    },
    updateDialerColorFailed: (state) => {
      state.isUpdating = false;
    },

    cloneDialer: () => {},
    cloneDialerSuccess: (state, action) => {
      if (action.payload?.id) {
        state.data = [action.payload, ...state.data];
        state.count += 1;
      }
    },
    cloneDialerFailed: (state, action) => {
      state.error = action.payload;
    },

    onSelectDialerList: (state, action) => {
      state.selectedDialerList = action.payload;
    },
  },
});

export const {
  getDialerList,
  getDialerListSuccess,
  getDialerListFailed,

  getMoreDialerList,
  getMoreDialerListSuccess,
  getMoreDialerListFailed,

  addDialer,
  addDialerSuccess,
  addDialerFailed,
  resetAddDialerSuccess,

  deleteDialer,
  deleteDialerSuccess,
  deleteDialerFailed,

  updateDialer,
  updateDialerSuccess,
  updateDialerFailed,
  resetUpdateDialerSuccess,

  updateDialerColor,
  updateDialerColorSuccess,
  updateDialerColorFailed,

  cloneDialer,
  cloneDialerSuccess,
  cloneDialerFailed,

  onSelectDialerList,
} = dialerListSlice.actions;

export default dialerListSlice.reducer;
