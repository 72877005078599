import { styled, TextField } from "@mui/material";

const CoreTextField = styled(TextField)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& input": {
    height: "36px !important",
    padding: "0 14px !important",
    margin: "0 !important",
    border: "none !important",
    "&.MuiInputBase-inputSizeSmall": {
      padding: "0 12px !important",
    },
    "&:focus": {
      border: "none !important",
      boxShadow: "none !important",
    },
  },
  "& .MuiInputBase-root": {
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
      },
    },
  },
  "& .MuiFormHelperText-root": {
    marginLeft: 0,
  },
}));

export default CoreTextField;
