import React from "react";
import { Grid, Skeleton, Stack } from "@mui/material";

const VoiceTemplateListSkeleton = () => {
  return (
    <Stack>
      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} px={4} py={2}>
        <Stack direction={"column"} sx={{ width: "100%" }}>
          <Skeleton variant={"text"} width={"30%"} height={20} />
          <Skeleton variant={"text"} width={"70%"} height={18} />
        </Stack>

        <Skeleton variant={"rect"} width={"150px"} height={44} />
      </Stack>

      <Stack sx={{ height: "calc(100vh - 220px)", overflow: "auto" }} className='pdad-scroll-bar'>
        <Grid container gap={2} px={3}>
          {Array.from({ length: 2 }).map((_, index) => (
            <Grid item xs={12} border={"1px solid #E0E0E0"} borderRadius={2} p={2} key={index}>
              <Stack gap={2}>
                <Stack direction={"row"} alignItems={"flex-start"} justifyContent={"space-between"}>
                  <Stack gap={1} width={"100%"} pr={2}>
                    <Skeleton variant={"text"} width={"40%"} height={20} />
                    <Skeleton variant={"text"} width={"100%"} height={18} />
                    <Skeleton variant={"text"} width={"100%"} height={18} />
                  </Stack>
                  <Stack direction={"row"} alignItems={"flex-start"} justifyContent={"space-between"} gap={1}>
                    <Skeleton variant={"rect"} width={60} height={38} />
                    <Skeleton variant={"rect"} width={80} height={38} />
                  </Stack>
                </Stack>
                <Skeleton variant={"rect"} width={"100%"} height={40} />
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Stack>
  );
};

export default VoiceTemplateListSkeleton;
