import React, { useState } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import VoiceTemplateItem from "./VoiceTemplateItem";
import CoreButton from "../../../common/Button/CoreButton";
import AddOrEditVoiceTemplate from "./AddOrEditVoiceTemplate";

const VoiceTemplateList = ({ data, handleUpdateItem, removeItem }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleOnclose = (updateItem) => {
    setIsOpenModal(false);
    handleUpdateItem(updateItem);
  };

  return (
    <Stack>
      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} px={4} py={2}>
        <Stack direction={"column"}>
          <Typography variant='body2medium' color={"text.primary"}>
            Voice Mail Template
          </Typography>
          <Typography color='text.secondary' variant='chipLight'>
            Create a Voice Mail Template for ongoing connected call
          </Typography>
        </Stack>

        <CoreButton
          variant='outlined'
          size='large'
          color={"secondary"}
          onClick={() => setIsOpenModal(true)}
          sx={{ minWidth: "max-content" }}
        >
          Add Template
        </CoreButton>
      </Stack>

      <Stack sx={{ height: "calc(100vh - 220px)", overflow: "auto" }} className='pdad-scroll-bar'>
        <Grid container gap={2} px={3}>
          {data.map((item) => (
            <VoiceTemplateItem
              key={item.id}
              item={item}
              handleUpdateItem={(updateItem) => handleUpdateItem(updateItem)}
              removeItem={(id) => {
                removeItem(id);
              }}
            />
          ))}
        </Grid>
      </Stack>

      {isOpenModal && (
        <AddOrEditVoiceTemplate
          open={isOpenModal}
          onClose={(updateItem) => handleOnclose(updateItem)}
          edit={{ id: null }}
        />
      )}
    </Stack>
  );
};

export default VoiceTemplateList;
