import React, { useMemo } from "react";
import { Autocomplete, Box, createFilterOptions, Stack, styled, TextField, Typography } from "@mui/material";
import { ADD_TAG_FOR } from "../../../../helpers/constant/Constants";
import { useCreateTagMutation, useGetTagsQuery } from "../../../../state/features/common/tag/tagApiSlice";

const StyledAutoComplete = styled(Autocomplete)(() => ({
  "& .MuiOutlinedInput-root": {
    padding: 0,
  },
  "& .MuiChip-root": {
    height: "30px",
  },
}));

const filter = createFilterOptions();

const AddTags = ({ selectedTags = [], onChange = () => {} }) => {
  const [createTag] = useCreateTagMutation();
  const { data: tagState } = useGetTagsQuery();
  const { tags = [] } = tagState?.data || {};

  const renderSelectedTags = useMemo(() => {
    let tempTags = [];
    selectedTags.forEach((eachTag) => {
      let findTag = tags.find((tag) => tag.value === eachTag);
      if (findTag) {
        tempTags.push(findTag);
      }
    });
    return tempTags;
  }, [selectedTags, tags]);

  const handleChangeTag = (e, newValue) => {
    let newInputValue = newValue.find((obj) => obj.inputValue);
    if (newInputValue) {
      createTag({ name: newInputValue.inputValue, for: ADD_TAG_FOR.TRIGGER });
    } else {
      onChange(newValue.map((value) => value.value));
    }
  };

  return (
    <Box p={2}>
      <Stack spacing={1} mb={2}>
        <Typography variant={"body2medium"}>Select Tag</Typography>
        <StyledAutoComplete
          p={0}
          multiple
          limitTags={2}
          options={tags}
          getOptionLabel={(option) => option.title}
          value={renderSelectedTags}
          renderInput={(params) => <TextField {...params} fullWidth placeholder='Select tags' />}
          onChange={handleChangeTag}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;
            const isExisting = options.some((option) => inputValue === option.title);
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                inputValue,
                title: `Add "${inputValue}"`,
              });
            }

            return filtered;
          }}
        />
      </Stack>
    </Box>
  );
};

export default AddTags;
