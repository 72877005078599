import apiSlice from "../../../apiSlice";

export const dialerApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDialerList: builder.query({
      query: (params) => ({
        url: process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/lists",
        method: "GET",
        params: params,
      }),
    }),
  }),
});

export const { useGetDialerListQuery } = dialerApiSlice;
