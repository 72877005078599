import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Divider, Stack, styled, Typography } from "@mui/material";
import { Delete } from "@mui/icons-material";
import AddContactOptions from "./AddContactOptions";
import CoreButton from "../../common/Button/CoreButton";
import ButtonForIcon from "../../common/Button/ButtonForIcon";
import CustomTooltip from "../../common/Tooltip/CustomTooltip";
import FullPageDrawer from "../../common/Drawer/FullPageDrawer";
import BasicModalWithHeader from "../../common/Modal/BasicModalWithHeader";
import DialerAnalyticReport from "../DialerAnalyticReport/DialerAnalyticReport";
import { GraphViewIcon, SettingIcon, StartDialertIcon } from "../../common/icon/Icons";
import { Texts, Tooltips } from "../../helpers/constant/Constants";
import ShowSideBarIcon from "../../common/Icons/ShowSideBarIcon";
// import { selectConnectingState } from "../../state/features/call/connecting/connectingSelector";
import { selectDialerContactsState } from "../../state/features/dialerContacts/dialerContactsSelector";
import { selectDialerListState } from "../../state/features/dialerList/dialerListSelector";
import TermAndCondition from "../TermAndCondition/TermAndCondition";
import SettingOverview from "../SettingOverview/SettingOverview";
import ResumePowerDialer from "../ResumePowerDialer/ResumePowerDialer";
import ContactShareContent from "../DialerContact/ContactShareContent";
import ContactDeleteContent from "../DialerContact/ContactDeleteContent";
import DialerContactMultiFilter from "../DialerContact/DialerContactMultiFilter";
import CallScreen from "../CallScreen/CallScreen";
import CustomCircleLoader from "../../common/Loader/CustomCircleLoader";
import BasicModal from "../../common/Modal/BasicModal";
import { LightCoreButton } from "../../common/Button/LightCoreButton";
import DialPhoneIcon from "../../common/Icons/DialPhoneIcon";
import useBoolean from "../../hooks/useBoolean";
import { selectDialerState } from "../../state/features/dialer/dialerSelector";
// import { selectInitializeState } from "../../state/features/call/initialize/initializeSelector";
import {
  DIALER_STEP,
  handleEndCall,
  handleInitStep,
  handleStartCall,
  storeBasicInit,
} from "../../state/features/dialer/dialerSlice";
import { deletedDialerContact } from "../../state/features/dialerContacts/dialerContactsSlice";
import { toggleListSettingModal } from "../../state/features/listSetting/listSettingModal/listSettingModalSlice";
import { useGetDialerSessionListQuery } from "../../state/features/common/dialerSession/dialerSessionApiSlice";

const TopBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "16px",
  [theme.breakpoints.down("lg")]: { flexDirection: "column" },
}));

const ToolTipWrapperIfNeeded = ({ needWrapper = false, tooltipTex = "", children }) => {
  return needWrapper ? (
    <CustomTooltip title={tooltipTex} arrow placement='bottom'>
      {children}
    </CustomTooltip>
  ) : (
    children
  );
};

const RightSideHeader = ({ onChangeLeftSideTab, deviceError, isDeviceReady, setUpDevice, device, callEvent }) => {
  const dispatch = useDispatch();
  const { innerWidth } = window || {};

  // const { isConnected } = useSelector(selectConnectingState);
  const { selectedDialerList } = useSelector(selectDialerListState);
  const { title, id } = selectedDialerList || {};
  // const { isStartInitiating } = useSelector(selectInitializeState);
  // const { isShowCallScreen } = useSelector(selectConnectingState);
  const { selectedContactIds, count: totalContact, filters } = useSelector(selectDialerContactsState);
  const { value: isOpenDeleteModal, setTrue: onOpenDeleteModal, setFalse: onCloseDeleteModal } = useBoolean(false);
  const {
    value: isOpenShareContactModal,
    setTrue: onOpenShareContactModal,
    setFalse: onCloseShareContactModal,
  } = useBoolean(false);

  const { data: dialerSessionState } = useGetDialerSessionListQuery({ id: id });
  const { data: dialerSessionList = [] } = dialerSessionState?.data || {};
  const { session } = filters || {};

  let selectedSessionName = "";
  if (session) {
    const selectedSession = dialerSessionList.find((data) => data.id === session);
    selectedSessionName = selectedSession?.name || "";
  }

  const onConfirmDelete = () => {
    dispatch(deletedDialerContact({ ids: JSON.stringify(selectedContactIds), dialerListId: id }));
    onCloseDeleteModal();
  };

  const [isShowGraphModal, setIsShowGraphModal] = useState(false);

  /* after refactor */
  const {
    init: { step, mainScreen, callStep },
  } = useSelector(selectDialerState);

  const handleToggleSettingModal = (open) => {
    dispatch(toggleListSettingModal({ open, listId: id }));
  };

  return (
    <Box>
      <TopBox>
        <Stack direction='row' justifyContent='space-between' spacing={2} sx={{ width: "100%", flex: 1 }}>
          <Stack direction='row' alignItems={"center"} sx={{ gap: { xs: 2, lg: 0 } }}>
            <Box sx={{ display: { xs: "inline-flex", lg: "none" }, cursor: "pointer" }} onClick={onChangeLeftSideTab}>
              <ShowSideBarIcon />
            </Box>
            <Stack>
              <Stack direction={"row"} spacing={2} alignItems={"center"}>
                <Typography variant={"subtitle1"}>{title}</Typography>
                {selectedSessionName && (
                  <>
                    <Divider orientation='vertical' variant='middle' flexItem />
                    <Typography variant={"body2"}>{selectedSessionName}</Typography>
                  </>
                )}
              </Stack>

              <Typography variant={"body2"}>
                {totalContact} {totalContact > 1 ? "Contacts" : "Contact"}
              </Typography>
            </Stack>
          </Stack>

          <Stack direction={"row"} alignItems='center' spacing={2}>
            {selectedContactIds?.length > 0 && (
              <Stack direction='row' alignItems='center' spacing={2}>
                <ToolTipWrapperIfNeeded needWrapper={innerWidth <= 1600} tooltipTex={"Add to other list"}>
                  <LightCoreButton
                    variant='text'
                    color={"secondary"}
                    startIcon={<DialPhoneIcon />}
                    sx={{ minWidth: "max-content" }}
                    onClick={onOpenShareContactModal}
                  >
                    {innerWidth > 1600 && "Add to other list "}({selectedContactIds.length})
                  </LightCoreButton>
                </ToolTipWrapperIfNeeded>

                <ToolTipWrapperIfNeeded needWrapper={innerWidth <= 1600} tooltipTex={"Remove from List"}>
                  <LightCoreButton
                    variant='text'
                    color={"error"}
                    startIcon={<Delete />}
                    sx={{ minWidth: "max-content" }}
                    onClick={onOpenDeleteModal}
                  >
                    {innerWidth > 1600 && "Remove from List "} ({selectedContactIds.length})
                  </LightCoreButton>
                </ToolTipWrapperIfNeeded>
              </Stack>
            )}

            <DialerContactMultiFilter />

            <CustomTooltip title={Tooltips.graphView} arrow placement='bottom'>
              <ButtonForIcon variant='text' onClick={() => setIsShowGraphModal(true)} startIcon={<GraphViewIcon />} />
            </CustomTooltip>

            <CustomTooltip title={Tooltips.settingView} arrow placement='bottom'>
              <ButtonForIcon
                variant='text'
                onClick={() => handleToggleSettingModal(true)}
                startIcon={<SettingIcon />}
              />
            </CustomTooltip>
          </Stack>
        </Stack>
        <Stack direction={"row"} alignItems='center' spacing={2} sx={{ width: { xs: "100%", lg: "auto" } }}>
          <AddContactOptions />
          <CoreButton
            variant='contained'
            color={"secondary"}
            disabled={totalContact == 0}
            startIcon={
              step !== DIALER_STEP.STOP ? (
                <CustomCircleLoader size='extra-small' fill='#fff' style={{ display: "flex" }} />
              ) : (
                <StartDialertIcon />
              )
            }
            onClick={() => {
              if (totalContact == 0) {
                return;
              }
              if (step === DIALER_STEP.STOP) {
                dispatch(
                  storeBasicInit({
                    listInfo: selectedDialerList,
                    listId: selectedDialerList?.id,
                    virtualNumberId: selectedDialerList?.virtual_number_id,
                  })
                );
                dispatch(
                  handleStartCall({
                    listId: selectedDialerList?.id,
                    sessionId: null,
                    virtualNumberId: selectedDialerList?.virtual_number_id,
                  })
                );
              }
            }}
            sx={{ width: { xs: "50%", lg: "auto" } }}
          >
            {step !== DIALER_STEP.STOP ? (callStep == DIALER_STEP.CONNECTED ? "Running" : "Starting") : "Start"} Power
            Dialer
          </CoreButton>
        </Stack>
      </TopBox>
      {/* for call start */}
      {/* term and condition */}
      <BasicModalWithHeader
        open={step === DIALER_STEP.SHOW_TERM_CONDITION}
        onClose={() => {
          dispatch(handleEndCall());
        }}
        title={Texts.termAndCondition}
        childrenWrpClass='pdad-scroll-bar'
      >
        <TermAndCondition
          onConfirm={() => {
            dispatch(handleInitStep(DIALER_STEP.SHOW_SETTING));
          }}
          onClose={() => {
            dispatch(handleEndCall());
          }}
        />
      </BasicModalWithHeader>
      {/* resume call */}
      <BasicModalWithHeader
        open={step === DIALER_STEP.SHOW_RESUME}
        onClose={() => {
          dispatch(handleEndCall());
        }}
        title={Texts.resumeCall}
      >
        <ResumePowerDialer />
      </BasicModalWithHeader>
      {/* basic setting preview */}
      <BasicModalWithHeader
        className={"pdad_Basic_Drawer"}
        open={step === DIALER_STEP.SHOW_SETTING}
        onClose={() => {
          dispatch(handleEndCall());
        }}
        title={Texts.settingOverview}
        disableOutSideClick={true}
      >
        <SettingOverview
          className={"pdad_Basic_Drawer"}
          onEdit={() => {
            handleToggleSettingModal(true);
            dispatch(handleInitStep(DIALER_STEP.STOP));
            dispatch(handleEndCall());
          }}
        />
      </BasicModalWithHeader>
      <BasicModal open={isShowGraphModal} onClose={() => setIsShowGraphModal(false)}>
        <DialerAnalyticReport dialerListId={id} />
      </BasicModal>
      <FullPageDrawer open={mainScreen} onClose={() => {}} extraPropsActive={true}>
        <CallScreen
          deviceError={deviceError}
          isDeviceReady={isDeviceReady}
          setUpDevice={setUpDevice}
          device={device}
          callEvent={callEvent}
        />
      </FullPageDrawer>
      <BasicModalWithHeader
        title={"Add to other power dialler list"}
        open={isOpenShareContactModal}
        onClose={onCloseShareContactModal}
      >
        <ContactShareContent onClose={onCloseShareContactModal} contactIds={selectedContactIds} />
      </BasicModalWithHeader>
      <BasicModal open={isOpenDeleteModal} onClose={onCloseDeleteModal}>
        <ContactDeleteContent
          contactIds={selectedContactIds}
          buttonName={"Yes Delete"}
          onClose={onCloseDeleteModal}
          onConfirm={onConfirmDelete}
        />
      </BasicModal>
    </Box>
  );
};
export default RightSideHeader;
