import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Stack, Typography } from "@mui/material";
import SessionMenu from "./SessionMenu";
import AnalyticReportLeft from "./AnalyticReportLeft";
import AnalyticReportRight from "./AnalyticReportRight";
import { getDialerAnalytics } from "../../state/features/dialerAnalytics/dialerAnalyticsSlice";
import { selectDialerAnalyticSession } from "../../state/features/dialerAnalytics/dialerAnalyticsSelector";

const DialerAnalyticReport = ({ dialerListId = "" }) => {
  const dispatch = useDispatch();
  const selectedSession = useSelector(selectDialerAnalyticSession);
  const { id: sessionId } = selectedSession || {};

  useEffect(() => {
    if (dialerListId) {
      const payload = { id: dialerListId, sessionId: sessionId };
      dispatch(getDialerAnalytics(payload));
    }
  }, [dialerListId, sessionId]);

  return (
    <>
      <Stack direction='column' justifyContent={"center"} pb={2}>
        <Stack direction={"row"} spacing={0.5}>
          <Typography variant='chipLight' color='text.secondary' sx={{ fontWeight: 500 }}>
            Analytics /
          </Typography>

          <SessionMenu dialerListId={dialerListId} />
        </Stack>
        <Typography variant='subtitle1' color='text.primary' sx={{ fontWeight: 500 }}>
          Analytics
        </Typography>
      </Stack>

      <Stack p={2} sx={{ bgcolor: "other.dividerFillColor", flexDirection: { xs: "column", md: "row" }, gap: "16px" }}>
        <AnalyticReportLeft />
        <AnalyticReportRight />
      </Stack>
    </>
  );
};
export default DialerAnalyticReport;
