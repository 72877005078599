import React, { useState } from "react";
import BasicModalWithHeader from "../../../common/Modal/BasicModalWithHeader";
import { Button, Divider, Grid, Stack, Typography } from "@mui/material";
import AsteriskIcon from "../../../common/Icons/AsterickIcon";
import CoreTextField from "../../../common/TextField/CoreTextField";
import AudioPlayer from "../../../common/VoiceManager/AudioPlayer";
import { LightCoreButton } from "../../../common/Button/LightCoreButton";
import VoiceManagerV2 from "../../../common/VoiceManager/VoiceManagerV2";
import CoreButton from "../../../common/Button/CoreButton";
import { showGlobalNotification } from "../../../helpers/utils/showGlobalNotification";
import VoicemailTemplateService from "../../../services/call/VoicemailTemplate.service";

const AddOrEditVoiceTemplate = ({ open, onClose, edit }) => {
  const [title, setTitle] = useState(edit?.title || "New Voice Template");
  const [description, setDescription] = useState(edit?.description || "");
  const [url, setUrl] = useState(edit?.url || "");
  const [file, setFile] = useState(null);
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const validateTitle = (value) => {
    if (!value) {
      setTitleError("Title is required");
    } else if (value.length > 50) {
      setTitleError("Title cannot exceed 50 characters");
    } else {
      setTitleError("");
    }
  };

  const validateDescription = (value) => {
    if (value.length > 255) {
      setDescriptionError("Description cannot exceed 255 characters");
    } else {
      setDescriptionError("");
    }
  };

  const handleSubmit = () => {
    validateTitle(title);
    validateDescription(description);

    if (!edit.id && !file) {
      return showGlobalNotification("Please record or upload audio file");
    }

    if (titleError || descriptionError) {
      return;
    }

    const formData = new FormData();
    if (file) {
      formData.append("file", file);
    }
    if (edit.id) {
      formData.append("voicemailTemplateId", edit.id);
    }
    formData.append("title", title);
    formData.append("description", description);

    saveData(formData);
  };

  const saveData = async (payload) => {
    setSubmitting(true);
    try {
      const response = await VoicemailTemplateService.saveVoicemailTemplate(payload);
      setSubmitting(false);

      if (response.success) {
        const updatedData = edit.id ? { ...edit, ...response.data.updateData } : null;
        onClose(updatedData);
        showGlobalNotification(response.message, "success");
      } else {
        showGlobalNotification(response.message);
      }
    } catch (error) {
      console.error(error);
      setSubmitting(false);
      showGlobalNotification("Something went wrong! Try again later");
    }
  };
  return (
    <BasicModalWithHeader
      title={edit.id ? "Edit Voice Template" : "Create Voice Template"}
      open={open}
      onClose={onClose}
    >
      <Grid container spacing={2} p={3}>
        <Grid item xs={12}>
          <Typography variant={"body2"} color={"text.primary"} sx={{ mb: 1 }}>
            Title <AsteriskIcon />
          </Typography>
          <CoreTextField
            fullWidth
            required
            autoFocus
            type={"text"}
            size={"small"}
            placeholder={"List name here"}
            value={title}
            onChange={(event) => {
              setTitle(event.target.value);
              validateTitle(event.target.value);
            }}
            error={Boolean(titleError)}
            helperText={titleError}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant={"body2"} color={"text.primary"} sx={{ mb: 1 }}>
            Description
          </Typography>
          <CoreTextField
            fullWidth
            type={"text"}
            size={"small"}
            multiline={true}
            rows={3}
            placeholder={"Write here..."}
            value={description}
            onChange={(event) => {
              setDescription(event.target.value);
              validateDescription(event.target.value);
            }}
            error={Boolean(descriptionError)}
            helperText={descriptionError}
          />
        </Grid>

        {!url && (
          <Grid item xs={12}>
            <VoiceManagerV2
              onChange={(fileUrl, bolb) => {
                setUrl(fileUrl);
                setFile(bolb);
              }}
            />
          </Grid>
        )}

        {url && (
          <Grid item xs={12}>
            <AudioPlayer src={url} />
            <Stack direction={"row"} justifyContent={"flex-end"} pt={1}>
              <LightCoreButton
                color={"error"}
                size={"small"}
                sx={{ width: "max-content" }}
                onClick={() => {
                  setUrl("");
                  setFile(null);
                }}
              >
                Remove
              </LightCoreButton>
            </Stack>
          </Grid>
        )}

        <Grid item xs={12}>
          <Divider />
          <Stack direction={"row"} justifyContent={"space-between"} pt={2}>
            <CoreButton type={"button"} size={"large"} color={"inherit"} onClick={onClose}>
              Cancel
            </CoreButton>
            <Button
              disabled={Boolean(descriptionError) || Boolean(titleError) || submitting}
              variant='contained'
              color={"success"}
              sx={{ width: "max-content" }}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </BasicModalWithHeader>
  );
};

export default AddOrEditVoiceTemplate;
