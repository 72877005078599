import React from "react";
import { styled } from "@mui/material";

const StyledAudio = styled("audio")(() => ({
  width: "100%",
  "&::-webkit-media-controls-enclosure": {
    borderRadius: "4px",
  },
}));

const AudioPlayer = ({ src, ...rest }) => {
  return (
    <StyledAudio {...rest} controls>
      <source src={src} type='audio/ogg' />
      <source src={src} type='audio/mpeg' />
      Your browser does not support the audio element.
    </StyledAudio>
  );
};

export default AudioPlayer;
