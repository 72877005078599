import { Box, Skeleton } from "@mui/material";
import React from "react";

const CallSettingSkeleton = () => {
  return (
    <Box>
      <Box px={4} py={3}>
        <Skeleton width={100} />
        <Skeleton width={200} />
        <Box>
          <Skeleton height={60} />
        </Box>
      </Box>
      <Box px={4} py={3}>
        <Skeleton width={100} />
        <Skeleton width={200} />
        <Box>
          <Skeleton height={60} />
        </Box>
      </Box>
      <Box px={4} py={3}>
        <Skeleton width={100} />
        <Skeleton width={200} />
        <Box>
          <Skeleton height={60} />
        </Box>
      </Box>
      <Box px={4} py={3}>
        <Skeleton width={100} />
        <Skeleton width={200} />
        <Box>
          <Skeleton height={60} />
        </Box>
      </Box>
      <Box px={4} py={3}>
        <Skeleton width={100} />
        <Skeleton width={200} />
        <Box>
          <Skeleton height={60} />
        </Box>
      </Box>
      <Box px={4} py={3}>
        <Skeleton width={100} />
        <Skeleton width={200} />
        <Box>
          <Skeleton height={60} />
        </Box>
      </Box>
      <Box px={4} py={3}>
        <Skeleton width={100} />
        <Skeleton width={200} />
        <Box>
          <Skeleton height={60} />
        </Box>
      </Box>
    </Box>
  );
};

export default CallSettingSkeleton;
