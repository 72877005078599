import React from "react";
import { CircularProgress, Stack } from "@mui/material";

const CircleLoader = ({ color = "secondary" }) => {
  return (
    <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} sx={{ height: "60vh" }}>
      <CircularProgress color={color} />
    </Stack>
  );
};

export default CircleLoader;
