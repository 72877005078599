import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import Styles from "./contactTags.module.scss";
import { geContactTags } from "../../../state/features/dialer/dialerSlice";
import { selectDialerState } from "../../../state/features/dialer/dialerSelector";

const ContactTags = () => {
  const dispatch = useDispatch();
  const {
    contactTags: { tags, gettingTag: isLoading, getTagError: getError },
  } = useSelector(selectDialerState);

  useEffect(() => {
    dispatch(geContactTags());
  }, []);
  const renderEmpty = (message) => {
    return <div>{message}</div>;
  };
  const renderTags = () => {
    const view = [];
    tags.forEach((item, index) => {
      view.push(
        <Typography variant='chipMedium' key={`${index}-${item.id}`} className={`${Styles.tagItem}`}>
          {item.title}
        </Typography>
      );
    });
    return view;
  };
  const renderView = () => {
    if (isLoading) {
      return renderEmpty("Please wait...");
    }
    if (getError !== "") {
      return renderEmpty(getError);
    }
    if (tags.length === 0) {
      return renderEmpty("No tag found !");
    }
    return renderTags();
  };
  return (
    <Box className={Styles.tagWrapper}>
      <Typography variant='body2medium' component={"p"} color={"text.primary"} sx={{ pb: 2 }}>
        Tags
      </Typography>

      <div className={Styles.tagsWrap}>{renderView()}</div>
    </Box>
  );
};

export default ContactTags;
