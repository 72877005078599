import { Box, Stack, Typography } from "@mui/material";
import CoreButton from "../../common/Button/CoreButton";
import { Add } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";

const GeneralScriptHeader = ({ openModal }) => {
  const [goBack, setGoBack] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (history.location.state !== undefined) {
      setGoBack(true);
    }
  }, []);

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} mb={2}>
      <Box>
        <Typography variant={"h4"} color={"text.primary"}>
          Power dialer general scripts
        </Typography>
        <Typography variant={"body2"} color={"text.secondary"}>
          Now you can see your all power dialer general scripts here
        </Typography>
      </Box>

      <Stack direction={"row"} alignItems={"center"} spacing={2}>
        {goBack && (
          <CoreButton
            variant='outlined'
            size='large'
            color={"secondary"}
            onClick={history.goBack}
            sx={{ minWidth: "max-content" }}
          >
            Back Button
          </CoreButton>
        )}

        <CoreButton
          variant='contained'
          size='large'
          color={"secondary"}
          startIcon={<Add />}
          onClick={openModal}
          sx={{ minWidth: "max-content" }}
        >
          Add Script
        </CoreButton>
      </Stack>
    </Stack>
  );
};
export default GeneralScriptHeader;
