import React from "react";
import { Divider, IconButton, Modal, Paper, Stack, Box, styled, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";

const PaperStyle = styled(Paper)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.background.paper,
  border: 0,
  boxShadow: 0,
  borderRadius: "4px",
  paddingBottom: "4px",
}));

const BasicModalWithHeader = ({
  title = "",
  subTitle = "",
  open,
  onClose,
  width = 700,
  disableOutSideClick = false,
  children,
  childrenWrpClass = "",
  ...rest
}) => {
  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={!disableOutSideClick ? onClose : () => false}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        {...rest}
      >
        <PaperStyle sx={{ minWidth: width }}>
          <Stack direction={"row"} justifyContent='space-between' alignItems='center' sx={{ padding: "16px 24px" }}>
            <Stack direction='column' justifyContent={"center"} spacing={1}>
              <Typography variant='subtitle1' color='text.primary' sx={{ fontWeight: 500 }}>
                {title}
              </Typography>
              {subTitle && (
                <Typography variant='body2' color='text.secondary'>
                  {subTitle}
                </Typography>
              )}
            </Stack>

            <IconButton color={"error"} onClick={onClose}>
              <Close fontSize={"medium"} />
            </IconButton>
          </Stack>
          <Divider />

          <Box sx={{ maxHeight: "80vh", overflowY: "auto" }} className={childrenWrpClass}>
            {children}
          </Box>
        </PaperStyle>
      </Modal>
    </React.Fragment>
  );
};

export default BasicModalWithHeader;
