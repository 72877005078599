import { all, put } from "redux-saga/effects";
import { /* call, */ delay, /* select, */ takeEvery } from "@redux-saga/core/effects";
import { errorHandler } from "../../../../helpers/utils/errorHandler";
import {
  getSettingOverviewFailed,
  getSettingOverviewSuccess,
  resetStartDailing,
  toggleShowResume,
  toggleShowTermAndCondition,
  toggleStartInitiating,
} from "./initializeSlice";
// import InitializingService from "../../../../services/call/Initializing.service";
import { showGlobalNotification } from "../../../../helpers/utils/showGlobalNotification";

function* intializeWatcher() {
  yield takeEvery("initialize/startDailing", startDailingSaga);
  yield takeEvery("initialize/getSettingOverview", getSettingOverviewSaga);
}

function* startDailingSaga(/* action */) {
  try {
    yield delay(2000);
    const isValid = Math.ceil(Math.random() * (10 - 5 + 1) + 5) % 2,
      startFromBeginning = Math.ceil(Math.random() * (10 - 5 + 1) + 5) % 2;

    const response = {
      responseCode: 200,
      message: "success",
      success: true,
      data: {
        isValid: isValid === 1 ? true : false,
        startFromBeginning: startFromBeginning === 1 ? true : false,
      },
    };
    console.log(response);

    // const response = yield call(InitializingService.checkValidation, { powerDialerId: action.payload });
    if (response) {
      if (response.success) {
        if (response.data.isValid) {
          if (response.data.startFromBeginning) {
            yield put(toggleShowTermAndCondition(true));
          } else {
            yield put(toggleShowResume(true));
          }
          yield put(toggleStartInitiating(false));
        } else {
          showGlobalNotification(response.message);
          yield put(resetStartDailing());
        }
      } else {
        showGlobalNotification(response.message);
        yield put(resetStartDailing());
      }
    } else {
      showGlobalNotification("Something went wrong! Try again later");
      yield put(resetStartDailing());
    }
  } catch (err) {
    errorHandler(err, "initializeSaga > startDailingSaga");
    showGlobalNotification("Something went wrong! Try again later");
  }
}
function* getSettingOverviewSaga(/* action */) {
  try {
    yield delay(3000);
    const response = {
      responseCode: 200,
      message: "error in getting data",
      success: true,
      data: {
        numberOfLines: 3,
        ringTime: 12,
        maximumCallTime: 10,
        transferCall: 1,
        callRecording: 0,
        incomingCallAction: {
          type: 1,
          value: "12343234543",
        },
        moveToNextCall: {
          type: 1,
          value: "10",
        },
      },
    };
    // const response = yield call(InitializingService.getSettingOverview, { powerDialerId: action.payload });
    if (response) {
      if (response.success) {
        yield put(getSettingOverviewSuccess(response.data));
      } else {
        yield put(getSettingOverviewFailed(response.message));
        showGlobalNotification(response.message);
      }
    } else {
      yield put(getSettingOverviewFailed("Something went wrong !"));
      showGlobalNotification("Something went wrong! Try again later");
    }
  } catch (err) {
    errorHandler(err, "initializeSaga > getSettingOverviewSaga");
    showGlobalNotification("Something went wrong! Try again later");
  }
}

export default function* intializeSaga() {
  yield all([intializeWatcher()]);
}
