import React from "react";
import { Avatar, Grid, Paper, Stack, Typography } from "@mui/material";
import { HelpIcon } from "../../common/icon/Icons";
import CustomTooltip from "../../common/Tooltip/CustomTooltip";

const AnalyticsDataCard = ({ fullWidth = false, icon, count, title, helperText = "" }) => {
  return (
    <Grid item xs={fullWidth ? 12 : 6} md={12}>
      <Paper elevation={0}>
        <Stack direction={"row"} alignItems={"center"} spacing={2} sx={{ padding: { xs: "8px 16px", md: "16px" } }}>
          <Avatar variant={"rounded"} sx={{ bgcolor: "other.secondaryHover", borderRadius: "0 4px 4px 0" }}>
            {icon}
          </Avatar>
          <Stack direction={"column"}>
            <Typography variant='h5' sx={{ width: "max-content" }}>
              {count}
            </Typography>
            <div style={{ display: "flex", gap: "6px", alignItems: "center", justifyContent: "center" }}>
              <Typography color='text.secondary' variant='body2'>
                {title}
              </Typography>
              {helperText && (
                <CustomTooltip arrow title={helperText} placement='top'>
                  <div style={{ cursor: "pointer", marginTop: "6px" }}>
                    <HelpIcon />
                  </div>
                </CustomTooltip>
              )}
            </div>
          </Stack>
        </Stack>
      </Paper>
    </Grid>
  );
};

export default AnalyticsDataCard;
