import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { WarningRounded } from "@mui/icons-material";
import CoreButton from "../Button/CoreButton";

const DeleteModalContent = ({ title, subTitle, description, buttonName = "Confirm", onConfirm, onClose }) => {
  return (
    <Box sx={{ textAlign: "center", mt: 1 }}>
      <WarningRounded sx={{ fontSize: "72px" }} color='error' />

      <Typography variant={"h4"} color={"text.primary"}>
        {title}
      </Typography>
      <Typography variant={"subtitle1"} color={"text.secondary"} sx={{ pt: 1 }}>
        {subTitle}
      </Typography>
      <Typography variant={"body2"} color={"text.secondary"}>
        {description}
      </Typography>

      <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"} spacing={2} sx={{ mt: 3 }}>
        <CoreButton color={"inherit"} size='large' onClick={onClose}>
          Cancel
        </CoreButton>
        <CoreButton variant='contained' color='error' size='large' onClick={onConfirm}>
          {buttonName}
        </CoreButton>
      </Stack>
    </Box>
  );
};

export default DeleteModalContent;
