import React from "react";
import { Box, Drawer } from "@mui/material";

const FullPageDrawer = ({
  anchor = "top",
  open,
  onClose,
  height = "96vh",
  zIndex = "1200",
  children,
  disableBackdropClick = false,
  extraPropsActive = false,
}) => {
  const toggleDrawer = () => (event, reason) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    if (disableBackdropClick && reason === "backdropClick") {
      return;
    }
    if (onClose) {
      onClose();
    }
  };
  let extra_props = {};
  if (extraPropsActive !== undefined && extraPropsActive) {
    extra_props["disableEnforceFocus"] = true;
    extra_props["disableAutoFocus"] = true;
  }
  return (
    <Drawer
      anchor={anchor}
      open={open}
      onClose={toggleDrawer(false)}
      sx={{
        zIndex: `${zIndex} !important`,
        "& .MuiPaper-root": {
          width: "98%",
          height: height,
          margin: "8px auto",
          borderRadius: "4px",
        },
      }}
      {...extra_props}
    >
      <Box>{children}</Box>
    </Drawer>
  );
};

export default FullPageDrawer;
