import React from "react";
import { Stack, Typography } from "@mui/material";

const UnicodeWarning = () => {
  return (
    <Stack direction={"column"} spacing={0.3}>
      <Typography variant={"subtitle2"} color={"common.white"}>
        Attention:
      </Typography>
      <Typography variant={"overline"} color={"common.white"}>
        Your Message Contains Unicode Characters !!!
      </Typography>
      <br />
      <Typography variant={"caption"} color={"common.white"}>
        What is unicode?
      </Typography>
      <Typography variant={"tooltip"} color={"common.white"} align={"justify"}>
        Unicode is a universal character encoding standard that assigns a code to every character and symbol. (Ex.
        Emojis, “, ››, ‘, ⧸, ⁃, ＠, ＃).When unicode characters are present the phone companies charge more to deliver
        your text. Sometimes hidden characters get added to text when you copy/paste from an outside system. These
        hidden characters are not always obvious. When pasting text to our system we recommend you paste as plain text.
      </Typography>
    </Stack>
  );
};

export default UnicodeWarning;
