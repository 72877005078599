import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, FormControl, FormControlLabel, Checkbox, Stack, styled, Typography } from "@mui/material";
import { CallStartType } from "../../helpers/constant/Constants";
import { selectDialerState } from "../../state/features/dialer/dialerSelector";
import {
  createNewSession,
  DIALER_STEP,
  handleEndCall,
  handleInitStep,
  storeBasicInit,
} from "../../state/features/dialer/dialerSlice";
import CustomCircleLoader from "../../common/Loader/CustomCircleLoader";
import CustomRadio from "../../common/Radio/CustomRadio";
import CustomRadioGroup from "../../common/Radio/CustomRadioGroup";
import useBoolean from "../../hooks/useBoolean";

const StyledContinue = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  "&:hover": {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const StyledFooter = styled(Stack)(({ theme }) => ({
  padding: "20px",
  borderTop: `1px solid ${theme.palette.other.outlinedBorderDarker}`,
}));

const StyledCheckBox = styled(FormControlLabel)(({ theme }) => ({
  color: theme.palette.text.primary,
  "& span:last-child": {
    lineHeight: "20px",
  },
}));

const ResumePowerDialer = () => {
  const dispatch = useDispatch();
  const {
    init: { callStartType: type, creatingNewSession: isLoading },
  } = useSelector(selectDialerState);
  const { value: disconnect, setValue: setDisconnect } = useBoolean(false);

  const renderTypes = () => {
    const view = [];
    CallStartType.forEach((item, index) => {
      view.push(
        <FormControlLabel
          key={index}
          value={item.value}
          control={<CustomRadio color='secondary' />}
          onClick={() => dispatch(storeBasicInit({ callStartType: item.value }))}
          label={
            <Typography variant={"body2"} color={"text.primary"}>
              {item.title}
            </Typography>
          }
        />
      );
    });
    return view;
  };

  return (
    <>
      <Box sx={{ padding: "40px 24px" }}>
        <Typography variant='h5' sx={{ mb: "15px" }}>
          How do you want to resume?
        </Typography>

        <Typography variant='body2' color={"text.secondary"} sx={{ mb: "20px" }}>
          Looks like you ended the power dialer before the session’s finished. Now you can choose how you want to resume
        </Typography>

        <Box sx={{ padding: "6px 24px" }}>
          <FormControl>
            <CustomRadioGroup aria-labelledby='call-resume-type-label' name='call-resume-type' defaultValue={type}>
              {renderTypes()}
            </CustomRadioGroup>
          </FormControl>
        </Box>

        {type == 2 && (
          <Box sx={{ padding: "6px 24px" }}>
            <StyledCheckBox
              control={
                <Checkbox
                  size='small'
                  checked={disconnect}
                  onChange={() => {
                    // if (!disconnect) {
                    //   setDisconnect(!disconnect);
                    // }
                    setDisconnect(!disconnect);
                  }}
                />
              }
              label={
                <Typography>
                  Don’t call numbers you connected with in a previous dialing session.
                  <Box sx={{ fontWeight: 700 }}>(Live Answer = Connected | Voicemail = Connected)</Box>
                </Typography>
              }
            />
          </Box>
        )}
      </Box>

      <StyledFooter direction='row' justifyContent='space-between' alignItems='center'>
        <Typography
          variant='button'
          color='text.secondary'
          sx={{ cursor: "pointer" }}
          onClick={() => dispatch(handleEndCall())}
        >
          Cancel
        </Typography>

        <StyledContinue
          variant='contained'
          disabled={isLoading}
          onClick={() => {
            if (type === 2) {
              dispatch(createNewSession(disconnect));
            } else {
              dispatch(handleInitStep(DIALER_STEP.SHOW_SETTING));
            }
          }}
        >
          {isLoading && (
            <CustomCircleLoader fill='#fff' size='extra-small' style={{ display: "flex", marginRight: "5px" }} />
          )}
          Continue
        </StyledContinue>
      </StyledFooter>
    </>
  );
};

export default ResumePowerDialer;
