import { all, put } from "redux-saga/effects";
import { /* call, */ select, takeEvery, delay } from "@redux-saga/core/effects";
// import ConntectingService from "../../../../services/call/Conntecting.service";
import { errorHandler } from "../../../../helpers/utils/errorHandler";
import { getContactFailed, getContactSuccess, getStatsFailed, getStatsSuccess } from "./connectingSlice";
import { showGlobalNotification } from "../../../../helpers/utils/showGlobalNotification";
import { selectConnectingState } from "./connectingSelector";
// import { selectInitializeState } from "../initialize/initializeSelector";

function* connectingWatcher() {
  yield takeEvery("connecting/getContacts", getConnectingContactsSaga);
  yield takeEvery("connecting/getStats", getDialerStatsSaga);
}

function* getConnectingContactsSaga(/* action */) {
  try {
    yield delay(3000);
    const response = {
      responseCode: 200,
      message: "success",
      success: true,
      data: [
        {
          id: 1,
          firstName: "first name",
          lastName: "last name",
          email: "email@mail.com",
          number: "",
          company: "Wayne Enterprise",
          address: "",
          city: "",
          state: "",
          zipCode: "",
          lastCommunicationTime: null,
          url: "",
        },
        {
          id: 2,
          firstName: "first name 2",
          lastName: "last name 2",
          email: "email@mail.com2",
          number: "",
          company: "",
          address: "",
          city: "",
          state: "",
          zipCode: "",
          lastCommunicationTime: null,
          url: "",
        },
        {
          id: 3,
          firstName: "first name 3",
          lastName: "last name 3",
          email: "email@mail.com 3",
          number: "",
          company: "",
          address: "",
          city: "",
          state: "",
          zipCode: "",
          lastCommunicationTime: null,
          url: "",
        },
        {
          id: 4,
          firstName: "first name 4",
          lastName: "last name 4",
          email: "email@mail.com 4",
          number: "",
          company: "",
          address: "",
          city: "",
          state: "",
          zipCode: "",
          lastCommunicationTime: null,
          url: "",
        },
        {
          id: 5,
          firstName: "first name 5",
          lastName: "last name 5",
          email: "email@mail.com 5",
          number: "",
          company: "",
          address: "",
          city: "",
          state: "",
          zipCode: "",
          lastCommunicationTime: null,
          url: "",
        },
        {
          id: 6,
          firstName: "first name 6",
          lastName: "last name 6",
          email: "email@mail.com 6",
          number: "",
          company: "",
          address: "",
          city: "",
          state: "",
          zipCode: "",
          lastCommunicationTime: null,
          url: "",
        },
        {
          id: 7,
          firstName: "first name 7",
          lastName: "last name 7",
          email: "email@mail.com 7",
          number: "",
          company: "",
          address: "",
          city: "",
          state: "",
          zipCode: "",
          lastCommunicationTime: null,
          url: "",
        },
        {
          id: 8,
          firstName: "first name 8",
          lastName: "last name 8",
          email: "email@mail.com 8",
          number: "",
          company: "",
          address: "",
          city: "",
          state: "",
          zipCode: "",
          lastCommunicationTime: null,
          url: "",
        },
        {
          id: 9,
          firstName: "first name 9",
          lastName: "last name 9",
          email: "email@mail.com 9",
          number: "",
          company: "",
          address: "",
          city: "",
          state: "",
          zipCode: "",
          lastCommunicationTime: null,
          url: "",
        },
        {
          id: 10,
          firstName: "first name 10",
          lastName: "last name 10",
          email: "email@mail.com 10",
          number: "",
          company: "",
          address: "",
          city: "",
          state: "",
          zipCode: "",
          lastCommunicationTime: null,
          url: "",
        },
      ],
    };

    // const { initPowerDialerId } = yield select(selectInitializeState);
    const { isShowCallScreen } = yield select(selectConnectingState);
    // const response = yield call(ConntectingService.getConnectingContacts, {powerDialerId: initPowerDialerId});
    if (!isShowCallScreen) {
      return;
    }
    if (response) {
      if (response.success) {
        yield put(getContactSuccess(response.data));
      } else {
        yield put(getContactFailed(response.message));
      }
    } else {
      yield put(getContactFailed("Something went wrong !"));
      showGlobalNotification("Something went wrong! Try again later");
    }
  } catch (err) {
    errorHandler(err, "connectingSaga > getConnectingContactsSaga");
    showGlobalNotification("Something went wrong! Try again later");
  }
}
function* getDialerStatsSaga(/* action */) {
  try {
    yield delay(1000);
    const response = {
      responseCode: 200,
      message: "success",
      success: true,
      data: {
        totalSessionDuration: 120,
        totalContacts: 100,
        totalConnected: 12,
        totalDisconnected: 88,
      },
    };

    // const { initPowerDialerId } = yield select(selectInitializeState);
    const { isShowCallScreen } = yield select(selectConnectingState);
    // const response = yield call(ConntectingService.getDialerStats, { powerDialerId: initPowerDialerId });
    if (!isShowCallScreen) {
      return;
    }
    if (response) {
      if (response.success) {
        yield put(getStatsSuccess(response.data));
      } else {
        yield put(getStatsFailed(response.message));
      }
    } else {
      yield put(getContactFailed("Something went wrong !"));
      showGlobalNotification("Something went wrong! Try again later");
    }
  } catch (err) {
    errorHandler(err, "connectingSaga > getDialerStatsSaga");
    showGlobalNotification("Something went wrong! Try again later");
  }
}

export default function* connectingSaga() {
  yield all([connectingWatcher()]);
}
