import httpRequest from "../api/httpRequest";

class VoicemailTemplateService {
  getVoicemailTemplates(params) {
    return httpRequest.get(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/voicemail-templates",
      params
    );
  }

  saveVoicemailTemplate(params) {
    return httpRequest.post(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/voicemail-templates",
      params
    );
  }

  deleteVoicemailTemplate(params) {
    return httpRequest.delete(
      process.env.REACT_APP_BACKEND_URL_POWER_DIALER_API + "power-dialer/voicemail-templates",
      params
    );
  }
}

export default new VoicemailTemplateService();
