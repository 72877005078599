import React from "react";
import { useSelector } from "react-redux";
import { Box, Stack, styled, Typography } from "@mui/material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import UploadIcon from "@mui/icons-material/Upload";
import AddContactFromList from "../AddContact/AddContactFromList/AddContactFromList";
import { LightCoreButton } from "../../common/Button/LightCoreButton";
import BasicDrawer from "../../common/Drawer/BasicDrawer";
import useBoolean from "../../hooks/useBoolean";
import { Texts } from "../../helpers/constant/Constants";
import { selectSelectedDialerList } from "../../state/features/dialerList/dialerListSelector";

const ImageWrapper = styled(Box)(() => ({
  height: "224px",
  width: "310px",
  textAlign: "center",
  "& img": {
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },
}));

const IconWrapper = styled(Box)(() => ({
  height: "auto",
  width: "60%",
  textAlign: "center",
  "& img": {
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },
}));

const DialerContactEmptyPage = ({ image, icon, title, description }) => {
  const { id } = useSelector(selectSelectedDialerList);

  const {
    value: isShowAddContactModal,
    setTrue: setShowAddContactModal,
    setFalse: setCloseAddContactModal,
  } = useBoolean(false);

  const onOpenImportContactPage = () => {
    window.open("/power-dialer/import/" + id);
  };

  return (
    <Stack direction={"column"} alignItems={"center"} justifyContent={"center"} spacing={1} pb={2}>
      {image && (
        <ImageWrapper>
          <img src={image} alt='Not Found' />
        </ImageWrapper>
      )}
      {icon && <IconWrapper>{icon}</IconWrapper>}
      <Typography variant='h5' color={"text.primary"} mt={4}>
        {title}
      </Typography>
      <Typography variant='body1medium' color='text.secondary' sx={{ maxWidth: "520px", textAlign: "center" }}>
        {description}
      </Typography>
      <Stack direction={"row"} spacing={2} alignItems={"center"}>
        <LightCoreButton
          variant={"text"}
          color={"secondary"}
          startIcon={<GroupAddIcon />}
          onClick={setShowAddContactModal}
        >
          Add from contact list
        </LightCoreButton>
        <LightCoreButton
          variant={"text"}
          color={"secondary"}
          startIcon={<UploadIcon />}
          onClick={onOpenImportContactPage}
        >
          Import Contacts
        </LightCoreButton>
      </Stack>

      <BasicDrawer open={isShowAddContactModal} toggleDrawer={setCloseAddContactModal} title={Texts.addContactFromList}>
        <AddContactFromList onClose={setCloseAddContactModal} />
      </BasicDrawer>
    </Stack>
  );
};

export default DialerContactEmptyPage;
