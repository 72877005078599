import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import CoreButton from "../../common/Button/CoreButton";

const DeleteGeneralScriptModal = ({ onConfirm, onClose, isDeleting }) => {
  return (
    <Box>
      <Stack alignItems={"center"} py={5}>
        <Typography variant={"body2"} color={"text.secondary"}>
          Are you sure want to remove the List from the list?
        </Typography>
      </Stack>

      <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"} spacing={2} sx={{ mt: 3 }}>
        <CoreButton color={"inherit"} size='large' onClick={onClose}>
          Cancel
        </CoreButton>
        <CoreButton disabled={isDeleting} variant='contained' color='error' size='large' onClick={onConfirm}>
          {isDeleting ? "Deleting" : "Confirm"}
        </CoreButton>
      </Stack>
    </Box>
  );
};

export default DeleteGeneralScriptModal;
