import { all, put } from "redux-saga/effects";
import { call, takeEvery, select, delay } from "@redux-saga/core/effects";
import {
  getConversationFailed,
  getConversationMore,
  getConversationSuccess,
  toogleGettingMoreConversation,
} from "../connected/connectedSlice";
import { errorHandler } from "../../../../helpers/utils/errorHandler";
import ConntectedService from "../../../../services/call/Conntected.service";
import { showGlobalNotification } from "../../../../helpers/utils/showGlobalNotification";
import { selectConnectedState } from "../connected/connectedSelector";
import { selectSendState } from "./sendSelector";
import { sendEmail, sendVoice, storeCampaign, storeEmail, storeVoice } from "./sendSlice";

function* sendWatcher() {
  yield takeEvery("send/sendSms", sendSmsSaga);
  yield takeEvery("send/sendEmail", sendEmailSaga);
  yield takeEvery("send/sendVoice", sendVoiceSaga);
  yield takeEvery("send/getCampaigns", getCampaignsSaga);
  yield takeEvery("send/getMoreCampaigns", getCampaignsSaga);
  yield takeEvery("send/addToCampaign", addToCampaignSaga);
}

function* sendSmsSaga() {
  try {
    yield delay(3000);
    const response = {
      responseCode: 200,
      message: "Sms send successfully !",
      success: true,
      data: {},
    };

    const { contactId } = yield select(selectConnectedState);
    const {
      sms: { message, type, attachment },
    } = yield select(selectSendState);

    const responsePrd = yield call(ConntectedService.sendConversation, {
      contactId: contactId,
      type: type,
      message: message,
      attachment: attachment,
    });
    console.log(responsePrd);

    if (response) {
      if (response.success) {
        yield put(getConversationSuccess(response.data));
        if (response.data && response.data.length >= 20) {
          yield put(toogleGettingMoreConversation(true));
        }
      } else {
        yield put(getConversationFailed(response.message));
      }
      yield put(getConversationMore(false));
    } else {
      yield put(getConversationFailed("Something went wrong !"));
      showGlobalNotification("Something went wrong! Try again later");
    }
  } catch (err) {
    errorHandler(err, "connectedSaga > getConversationSaga");
    showGlobalNotification("Something went wrong! Try again later");
  }
}
function* sendEmailSaga() {
  try {
    yield delay(3000);

    const response = {
      responseCode: 200,
      message: "Sms send successfully !",
      success: true,
      data: {},
    };

    const { contactId } = yield select(selectConnectedState);
    const {
      email: { subject, type, body, attachmentFile },
    } = yield select(selectSendState);

    const responsePrd = yield call(ConntectedService.sendConversation, {
      contactId: contactId,
      type: type,
      subject: subject,
      body: body,
      attachmentFile: attachmentFile,
    });
    console.log(responsePrd);

    if (response) {
      if (response.success) {
        yield put(
          storeEmail({
            subject: "",
            body: "",
            attachmentFile: [],
            sending: false,
          })
        );
        showGlobalNotification(response.message, "success");
      } else {
        yield put(sendEmail(false));
        showGlobalNotification(response.message);
      }
    } else {
      yield put(sendEmail(false));
      showGlobalNotification("Something went wrong! Try again later");
    }
  } catch (err) {
    errorHandler(err, "connectedSaga > sendEmailSaga");
    showGlobalNotification("Something went wrong! Try again later");
  }
}
function* sendVoiceSaga() {
  try {
    yield delay(3000);

    const response = {
      responseCode: 200,
      message: "voice send successfully !",
      success: true,
      data: {},
    };

    const { contactId } = yield select(selectConnectedState);
    const {
      voice: { type, attachmentFile, blobFile },
    } = yield select(selectSendState);

    const responsePrd = yield call(ConntectedService.sendConversation, {
      contactId: contactId,
      type: type,
      attachmentFile: attachmentFile,
      blobFile: blobFile,
    });
    console.log(responsePrd);

    if (response) {
      if (response.success) {
        yield put(
          storeVoice({
            attachmentFile: "",
            sending: false,
            blobFile: null,
          })
        );
        showGlobalNotification(response.message, "success");
      } else {
        yield put(sendVoice(false));
        showGlobalNotification(response.message);
      }
    } else {
      yield put(sendVoice(false));
      showGlobalNotification("Something went wrong! Try again later");
    }
  } catch (err) {
    errorHandler(err, "connectedSaga > sendVoiceSaga");
    showGlobalNotification("Something went wrong! Try again later");
  }
}
function* getCampaignsSaga() {
  try {
    yield delay(3000);

    const response = {
      responseCode: 200,
      message: "get campaign data",
      success: true,
      data: {
        campaign: [{ id: 1, title: "test" }],
        contactCampaign: [{ id: 1, title: "test" }],
      },
    };

    const { contactId } = yield select(selectConnectedState);
    const {
      addToCampaign: { pageNo, search },
    } = yield select(selectSendState);

    const responsePrd = yield call(ConntectedService.getUserCampaign, {
      contactId: contactId,
      pageNo: pageNo,
      pageSize: 20,
      search: search,
    });
    console.log(responsePrd);

    if (response) {
      if (response.success) {
        yield put(
          storeCampaign({
            contactCampaigns: response.data.contactCampaign,
            campaigns: response.data.campaign,
            gettingCampaign: false,
            pageNo: pageNo + 1,
            gettingMoreCampaign: false,
            needToGetMore: response.data.campaign.length >= 20 ? true : false,
          })
        );
      } else {
        yield put(storeCampaign({ gettingCampaign: false }));
        showGlobalNotification(response.message);
      }
    } else {
      yield put(storeCampaign({ gettingCampaign: false }));
      showGlobalNotification("Something went wrong! Try again later");
    }
  } catch (err) {
    errorHandler(err, "connectedSaga > getCampaignsSaga");
    showGlobalNotification("Something went wrong! Try again later");
  }
}
function* addToCampaignSaga() {
  try {
    yield delay(3000);

    const response = {
      responseCode: 200,
      message: "contact added to campaign successfully",
      success: true,
      data: [],
    };

    const { contactId } = yield select(selectConnectedState);
    const {
      addToCampaign: { selectedCampaign },
    } = yield select(selectSendState);

    const responsePrd = yield call(ConntectedService.contactAddToCampaign, {
      contactId: contactId,
      campaignId: selectedCampaign,
    });
    console.log(responsePrd);

    if (response) {
      if (response.success) {
        yield put(
          storeCampaign({
            isAdding: false,
          })
        );
      } else {
        yield put(storeCampaign({ isAdding: false }));
        showGlobalNotification(response.message);
      }
    } else {
      yield put(storeCampaign({ isAdding: false }));
      showGlobalNotification("Something went wrong! Try again later");
    }
  } catch (err) {
    errorHandler(err, "connectedSaga > addToCampaignSaga");
    showGlobalNotification("Something went wrong! Try again later");
  }
}

export default function* sendSaga() {
  yield all([sendWatcher()]);
}
