import React, { useRef, useState } from "react";
import { ArrowBack } from "@mui/icons-material";
import { Button, Stack, styled, Typography } from "@mui/material";
import ButtonBack from "../../../screens/auto-dialer-ongoing/global/ButtonBack/ButtonBack";
import Styles from "./sendEmail.module.scss";
import Personalized from "../../../../common/Personalized/Personalized";
import { insertPersonalizedTag } from "../../../../helpers/utils/insertPersonalizedTag";
import CustomEditor from "../../../../common/Editor/CustomEditor";
import Uploader from "../../../../common/Uploader/Uploader";
import CustomCircleLoader from "../../../../common/Loader/CustomCircleLoader";
import SendService from "../../../../services/common/Send.service";
import { showSuccessToaster } from "../../../../helpers/utils/toaster";
import { selectDialerState } from "../../../../state/features/dialer/dialerSelector";
import { useDispatch, useSelector } from "react-redux";
import { getCookie } from "../../../../helpers/Cookie";
import { handleSendOption, SEND_OPTIONS } from "../../../../state/features/dialer/dialerSlice";

const StyledFooter = styled(Stack)(({ theme }) => ({
  marginTop: "24px",
  padding: "16px 24px 0 24px",
  borderTop: `1px solid ${theme.palette.other.outlinedBorderDarker}`,
}));

const SendEmail = () => {
  const dispatch = useDispatch();
  const {
    init: { connectedEmail },
    connected: { contactId },
  } = useSelector(selectDialerState);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState();
  const [attachmentFile, setAttachmentFile] = useState([]);
  const [sending, setSending] = useState(false);

  const subjectRef = useRef(null);
  const bodyRef = useRef(null);

  const handleSubjectChange = (value) => {
    setSubject(value);
  };
  const handleBodyChange = (value) => {
    setBody(value);
  };
  const handleAttachment = (images) => {
    setAttachmentFile(images);
  };
  const hanldeOnSend = async () => {
    setSending(true);
    const formData = new FormData();
    formData.append("message", body);
    formData.append("subject", subject);
    formData.append("messageLevel", "email");
    formData.append("email", connectedEmail);
    formData.append("contact_id", contactId);
    formData.append("origin", 3);
    formData.append("schedule_type", 1);
    formData.append("api-key", getCookie(process.env.REACT_APP_ACCESS_TOKEN));
    const response = await SendService.makeConversation(formData);
    setSending(false);
    if (response.status === "success") {
      showSuccessToaster(response.html);
      setBody("");
      setSubject("");
      setAttachmentFile([]);
    }
  };

  return (
    <div className={Styles.sendEmailWrapper}>
      {/* Header */}
      <ButtonBack
        backHandler={() => {
          setSending(false);
          dispatch(handleSendOption({ option: SEND_OPTIONS.default }));
        }}
        title='Send an Email'
        icon={<ArrowBack sx={{ color: "action.active" }} />}
      />

      {/* Body */}
      <div className={Styles.sendModalBody}>
        {/* Subject */}
        <div className={Styles.semSubject}>
          <Typography variant='body2medium' className={Styles.semsTitle}>
            Subject
          </Typography>

          <div className={Styles.semsContent}>
            <input
              value={subject}
              ref={subjectRef}
              className={Styles.semscInput}
              type='text'
              placeholder='Type email subject'
              onChange={(e) => handleSubjectChange(e.target.value)}
            />
            <Personalized
              onClick={(personalizedTag) =>
                insertPersonalizedTag(personalizedTag, subjectRef, (e) => handleSubjectChange(e))
              }
            />
          </div>
        </div>

        {/* Message */}
        <div className={Styles.semMessage}>
          <Typography variant='body2medium' className={Styles.semsTitle}>
            Email Body
          </Typography>

          <CustomEditor ref={bodyRef} body={body} onChangeBody={(value) => handleBodyChange(value)} />
          <Personalized
            onClick={(personalizedTag) =>
              insertPersonalizedTag(personalizedTag, bodyRef, (value) => handleBodyChange(value))
            }
          />
        </div>

        {/* Attachment*/}
        <div className={Styles.semAttachment} style={{ display: "none" }}>
          <Uploader imagesList={(list) => handleAttachment(list)} updateComponent={attachmentFile} />
        </div>
      </div>

      {/* Footer */}
      <StyledFooter direction='row' justifyContent='space-between' alignItems='center'>
        <Typography
          onClick={() => {
            setSending(false);
            dispatch(handleSendOption({ option: SEND_OPTIONS.default }));
          }}
          sx={{ cursor: "pointer" }}
          variant='buttonMedium'
          color='text.secondary'
        >
          Cancel
        </Typography>

        <Button
          onClick={hanldeOnSend}
          sx={{ backgroundColor: "secondary.main", ":hover": { backgroundColor: "secondary.main" } }}
          variant='contained'
        >
          {sending && (
            <CustomCircleLoader fill='#fff' size='extra-small' style={{ display: "flex", marginRight: "5px" }} />
          )}
          {sending ? "Sending" : "Send"} Email
        </Button>
      </StyledFooter>
    </div>
  );
};

export default SendEmail;
