import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  isError: false,
  error: "",
  data: [],
};

const personalizedTagSlice = createSlice({
  name: "personalizedTag",
  initialState: initialState,
  reducers: {
    getPersonalizedTagList: (state) => {
      state.isLoading = true;
    },
    getPersonalizedTagListSuccess: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    },
    getPersonalizedTagListFailed: (state, action) => {
      state.data = [];
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },
    updatePersonalizedTagList: (state, action) => {
      state.settings = { ...state.settings, ...action.payload };
    },
  },
});

export const {
  getPersonalizedTagList,
  getPersonalizedTagListSuccess,
  getPersonalizedTagListFailed,
  updatePersonalizedTagList,
} = personalizedTagSlice.actions;

export default personalizedTagSlice.reducer;
