import { all, put } from "redux-saga/effects";
import { call, delay, select, takeEvery, takeLatest } from "@redux-saga/core/effects";
import { getCallSettingFailed, getCallSettingSuccess } from "./callSettingSlice";
import ListSettingService from "../../../../services/ListSetting.service";
import { showErrorToaster } from "../../../../helpers/utils/toaster";
import { selectCallSetting, selectListSettingModal } from "../listSettingSelector";

function* callSettingWatcher() {
  yield takeEvery("callSetting/getCallSetting", getCallSettingSaga);
  yield takeLatest("callSetting/updateCallSetting", updateCallSettingSaga);
}

function* getCallSettingSaga() {
  try {
    const { listId } = yield select(selectListSettingModal);

    if (!listId) {
      yield put(getCallSettingFailed("No dialer list selected"));
      return;
    }

    const response = yield call(ListSettingService.getCallSetting, { id: listId });

    if (response.success) {
      yield put(getCallSettingSuccess(response.data));
    } else {
      yield put(getCallSettingFailed(response.message));
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

function* updateCallSettingSaga() {
  try {
    const { listId } = yield select(selectListSettingModal);
    const { settings } = yield select(selectCallSetting);
    yield delay(1000);
    const response = yield call(ListSettingService.updateCallSetting, { ...settings, id: listId });

    if (!response.success) {
      showErrorToaster(response.message);
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

export default function* callSettingSaga() {
  yield all([callSettingWatcher()]);
}
