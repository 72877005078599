import ContactDetails from "./ContactDetails";
import ContactInfo from "./ContactInfo";
import ContactLastNote from "./ContactLastNote";
import ContactTags from "./ContactTags";
import Styles from "./leftside.module.scss";

const LeftSide = ({ className = "" }) => {
  return (
    <div className={`${Styles.wrapper} ${className} pdad-scroll-bar`}>
      <ContactDetails />

      <div className={Styles.sectionWrapper}>
        <ContactInfo />
        <ContactTags />
        <ContactLastNote />
      </div>
    </div>
  );
};

export default LeftSide;
