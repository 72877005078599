import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import AddContactFromList from "../AddContact/AddContactFromList/AddContactFromList";
import { LightCoreButton } from "../../common/Button/LightCoreButton";
import { AddUserIcon, CarrotIcon, ContactIcon, UploadIcon } from "../../common/icon/Icons";
import BasicDrawer from "../../common/Drawer/BasicDrawer";
import CustomPopoverMenu, {
  CustomPopoverMenuButton,
  CustomPopoverMenuItem,
} from "../../common/PopoverMenu/CustomPopoverMenu";
import useBoolean from "../../hooks/useBoolean";
import { Buttons, Texts } from "../../helpers/constant/Constants";
import { selectSelectedDialerList } from "../../state/features/dialerList/dialerListSelector";

const AddContactOptions = () => {
  const {
    value: isShowAddContactModal,
    setTrue: setShowAddContactModal,
    setFalse: setCloseAddContactModal,
  } = useBoolean(false);
  const { id } = useSelector(selectSelectedDialerList);

  const onOpenImportContactPage = () => {
    window.open("/power-dialer/import/" + id);
  };

  return (
    <Box sx={{ width: { xs: "50%", lg: "auto" } }}>
      <CustomPopoverMenu position={"bottom"}>
        <CustomPopoverMenuButton>
          <Box>
            <LightCoreButton
              fullWidth
              variant='text'
              color={"secondary"}
              startIcon={<ContactIcon />}
              endIcon={<CarrotIcon />}
            >
              {Buttons.addContacts}
            </LightCoreButton>
          </Box>
        </CustomPopoverMenuButton>

        <CustomPopoverMenuItem
          title='Add from contact list'
          icon={<AddUserIcon />}
          onClick={setShowAddContactModal}
          onClickHide={true}
        />

        <CustomPopoverMenuItem
          title='Import contacts'
          icon={<UploadIcon />}
          onClick={onOpenImportContactPage}
          onClickHide={true}
        />
      </CustomPopoverMenu>

      <BasicDrawer open={isShowAddContactModal} toggleDrawer={setCloseAddContactModal} title={Texts.addContactFromList}>
        <AddContactFromList onClose={setCloseAddContactModal} />
      </BasicDrawer>
    </Box>
  );
};

export default AddContactOptions;
