import { styled } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ backgroundcolor = "#15008A" }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: backgroundcolor,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: backgroundcolor,
      fontSize: 11,
    },
  })
);

export default CustomTooltip;
