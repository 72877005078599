import { Box, Button, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectDialerListState } from "../../state/features/dialerList/dialerListSelector";
import { onSelectDialerList } from "../../state/features/dialerList/dialerListSlice";
import Styles from "./outsidecall.module.scss";

import Lottie from "react-lottie";
import animationData from "./waiting.json";
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  renderer: "svg",
};

const OutsideCall = ({ readyToStart, data, handleStartCall, onClose }) => {
  const dispatch = useDispatch();
  const { data: dialerList } = useSelector(selectDialerListState);
  useEffect(() => {
    if (dialerList != null && dialerList.length > 0) {
      handleStoreSelectedList();
    }
  }, [dialerList, readyToStart]);

  const handleStoreSelectedList = () => {
    if (readyToStart && data) {
      if (dialerList != null && dialerList.length > 0) {
        const listId = data?.listId;
        for (let i = 0; i < dialerList.length; i++) {
          if (dialerList[i].id == listId) {
            dispatch(onSelectDialerList(dialerList[i]));
            handleStartCall();
          }
        }
      }
    }
  };
  const targetItem = () => {
    if (data.from == "tagList") {
      return (
        <>
          from{" "}
          <b>
            <i>{data["tagName"]}</i>
          </b>
        </>
      );
    }
    if (data.from == "myList") {
      return (
        <>
          from{" "}
          <b>
            <i>{data["myListName"]}</i>
          </b>
        </>
      );
    }
    if (data.from == "dealStage") {
      return (
        <>
          from{" "}
          <b>
            <i>{data["dealStageName"]}</i>
          </b>
        </>
      );
    }
  };

  return (
    <Stack direction={"row"} alignItems='stretch' spacing={4} py={5} px={3} className={Styles.wrapper}>
      <Box>
        <Lottie options={defaultOptions} height={200} width={200} />
        {/* <img height={200} src={"https://s3.us-east-1.amazonaws.com/pypepro/user/userAvatar/1-v9167c7531.gif"} /> */}
      </Box>
      <Stack direction='column' alignItems='center' justifyContent='center' spacing={2}>
        <Typography variant='h5'>Please wait...</Typography>
        {data && (
          <Box>
            <Typography variant='body2' mb={1} sx={{ textAlign: "center" }}>
              We are pulling contact for{" "}
              <b>
                <i>{data["listName"]}</i>
              </b>{" "}
              {targetItem()}
            </Typography>
            <Typography variant='body2' sx={{ textAlign: "center" }}>
              This process will take sometimes. Please be patience.
            </Typography>
            <Typography variant='caption' component={"p"} sx={{ marginTop: "10px" }}>
              <Box component={"b"} sx={{ fontWeight: "bolder", color: "error.main" }}>
                Note:
              </Box>{" "}
              If you close the window, call session will not start.
            </Typography>
          </Box>
        )}
        <Stack direction='column' alignItems='center' justifyContent='center' spacing={1} py={3}>
          <Button variant='outlined' onClick={onClose}>
            Close the window
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
export default OutsideCall;
