import React from "react";
import { Box, Stack } from "@mui/material";
import AddContactSearch from "./AddContactSearch";

const FilterOptions = () => {
  return (
    <Box sx={{ px: 3, pt: 2, pb: 0 }}>
      <Stack direction={"row"} alignItems={"center"} spacing={2} sx={{ mb: 1.5 }}>
        <AddContactSearch />
      </Stack>
    </Box>
  );
};
export default FilterOptions;
