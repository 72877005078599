import styled from "@emotion/styled";
import { RadioGroup } from "@mui/material";

const CustomRadioGroup = styled(RadioGroup)(() => ({
  '& [type="radio"]:not(:checked)+span, & [type="radio"]:checked+span': {
    paddingLeft: 0,
    "&::before, &::after": {
      content: `none`,
    },
  },
}));

export default CustomRadioGroup;
