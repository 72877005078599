//There are no depedency with this file, you can move it freely :)
export const startRecord = async () => {
  if (!openMic()) {
    throw Error("Unable to open the recorder, permission denied!");
  }
  return navigator.mediaDevices.getUserMedia({ audio: true });
};

const openMic = () => {
  try {
    return !!(
      navigator.mediaDevices ||
      navigator.mediaDevices.getUserMedia({ audio: true }) ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia
    );
  } catch (e) {
    return false;
  }
};
