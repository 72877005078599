import * as React from "react";
import { FormControl, MenuItem, Select, Typography } from "@mui/material";

const ITEM_HEIGHT = 34;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const BasicSelect = ({
  data = {},
  value = "",
  onChange,
  color = "primary",
  valueKey = "value",
  titleKey = "title",
  placeholder = "Please select an option",
}) => {
  return (
    <FormControl sx={{ minWidth: 120 }} size='small' fullWidth color={color}>
      <Select
        displayEmpty
        labelId='select-small'
        id='select-small'
        value={value}
        onChange={onChange}
        MenuProps={MenuProps}
      >
        <MenuItem value={value === null ? null : ""} disabled>
          <Typography variant='body2' color='text.secondary'>
            {placeholder}
          </Typography>
        </MenuItem>
        {data.map((data, index) => {
          return (
            <MenuItem key={index} value={data[valueKey]} title={data[titleKey]} className='dropdownMenu'>
              {data[titleKey]}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
export default BasicSelect;
