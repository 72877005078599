import React from "react";
import { Avatar, Stack, Typography } from "@mui/material";

const SingleContact = ({ contact = {} }) => {
  let { fullName, number, email, avatar = "", company_name = "" } = contact || {};

  if (!fullName) fullName = email || "N/A";

  return (
    <Stack direction={"row"} alignItems={"center"} spacing={1}>
      <Avatar rounded={"true"} src={avatar} alt={"avatar"}>
        {fullName[0]?.toUpperCase()}
      </Avatar>

      <Stack>
        <Typography variant={"body2medium"} color={"text.primary"}>
          {fullName}
        </Typography>
        <Typography variant={"chipLight"} color={"text.secondary"}>
          {number}
        </Typography>
        <Typography variant={"chipLight"} color={"text.secondary"}>
          {company_name}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default SingleContact;
