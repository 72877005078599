import * as React from "react";
import Popover from "@mui/material/Popover";
import { Box, styled, useTheme } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CoreButton from "../Button/CoreButton";

const StyledInheritButton = styled(CoreButton)(({ theme }) => ({
  "&.MuiButton-outlinedInherit": {
    borderColor: theme.palette.other.outlinedBorderDarker,
  },
}));

const CustomPopOver = ({ buttonText, children, color = "secondary" }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <StyledInheritButton
        variant={"outlined"}
        color={color}
        aria-describedby={id}
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
      >
        {buttonText}
      </StyledInheritButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ "& .MuiPopover-paper": { maxHeight: "245px", height: "100%" } }}
      >
        <Box sx={{ background: theme.palette.other.greyLight }} className={"pdad-scroll-bar"}>
          {children}
        </Box>
      </Popover>
    </div>
  );
};
export default CustomPopOver;
