import React from "react";
import { Box, Skeleton, Stack } from "@mui/material";

const ScriptSingleListSkeleton = () => {
  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      sx={{ width: "100%", py: 2.5, px: 1 }}
    >
      <Stack direction={"row"} alignItems={"center"} spacing={2}>
        <Box>
          <Skeleton variant={"text"} width={250} height={20} />
          <Skeleton variant={"text"} width={300} height={20} />
        </Box>
      </Stack>
      <Stack direction={"row"} alignItems={"center"} spacing={2}>
        <Skeleton variant={"circular"} width={25} height={25} />
        <Skeleton variant={"circular"} width={25} height={25} />
        <Skeleton variant={"circular"} width={25} height={25} />
      </Stack>
    </Stack>
  );
};

export default ScriptSingleListSkeleton;
