import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { PersonalizationDefault } from "../../../../helpers/constant/PersonalizationDefault";
import { getCookie } from "../../../../helpers/Cookie";
import { getLocalPersonalizedTags } from "../../../../helpers/utils/getLocalPersonalizedTags";
import { getModifiedCustomFields } from "../../../../helpers/utils/getModifiedCustomFields";
import { getUserPersonalizedTag } from "../../../../helpers/utils/getUserPersonalizedTag";

const baseQueryMiddleware = fetchBaseQuery({
  baseUrl: "/",
});

export const localPersonalizedApiSlice = createApi({
  reducerPath: "localPersonalizedApi",
  baseQuery: async (args, api, extraOptions) => {
    const localData = getLocalPersonalizedTags();
    if (localData !== null) {
      return { data: localData };
    }

    args.params = {
      "api-key": getCookie(process.env.REACT_APP_ACCESS_TOKEN),
      get_custom_data: true,
      get_personalized_data: true,
      field_name: ["*"],
    };

    const result = await baseQueryMiddleware(args, api, extraOptions);

    let responseData = PersonalizationDefault;

    if (result.data.custom_field) {
      responseData = [...responseData, ...getModifiedCustomFields(result.data.custom_field)];
    }

    if (result.data.personalized_field) {
      responseData.push(getUserPersonalizedTag(result.data.personalized_field));
    }

    //Update count on each groups
    responseData.forEach((eachGroup) => {
      eachGroup.title = `${eachGroup.title} (${eachGroup.tags.length})`;
    });

    return { data: responseData };
  },
  tagTypes: [],
  endpoints: () => ({}),
});

export default localPersonalizedApiSlice;
