import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  size: 50,
  currentPage: 1,
  hasMore: true,
  isLoading: false,
  isDataFetching: false,
  isError: false,
  error: "",

  isAdding: false,
  isAddSuccess: false,

  selectedContactIds: [],
  filters: {
    searchText: "",
    tagIds: [],
    campaignIds: [],
  },
};

const contactsSlice = createSlice({
  name: "contacts",
  initialState: initialState,
  reducers: {
    getContacts: (state) => {
      state.isLoading = true;
    },
    getContactsSuccess: (state, action) => {
      state.data = action.payload.contactInfos;
      state.currentPage = action.payload.currentPage;
      state.isLoading = false;
      state.isError = false;
      state.error = "";
      state.hasMore = !(action.payload.totalPages === 0 || action.payload.contactInfos?.length < state.size);
    },
    getContactsFailed: (state, action) => {
      state.data = [];
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },

    getMoreContacts: (state) => {
      state.isDataFetching = true;
    },
    getMoreContactsSuccess: (state, action) => {
      state.data = [...state.data, ...action.payload.contactInfos];
      state.currentPage = action.payload.currentPage;
      state.isDataFetching = false;
      state.hasMore = !(action.payload.totalPages === 0 || action.payload.contactInfos?.length < state.size);
    },
    getMoreContactsFailed: (state, action) => {
      state.isError = true;
      state.error = action.payload;
      state.isDataFetching = false;
    },

    addMainContactOnDialerList: (state) => {
      state.isAdding = true;
    },
    addMainContactOnDialerListSuccess: (state) => {
      state.isAdding = false;
      state.isAddSuccess = true;
    },
    addMainContactOnDialerListReset: (state) => {
      state.isAdding = false;
      state.isAddSuccess = false;
    },

    // contacts selection actions
    selectAllContact: (state) => {
      state.selectedContactIds = state.data.map((inboxForm) => inboxForm.id);
    },
    deselectAllContact: (state) => {
      state.selectedContactIds = [];
    },
    toggleSelectSingleContact: (state, action) => {
      const currentIndex = state.selectedContactIds.findIndex((selectedId) => selectedId === action.payload);
      if (currentIndex !== -1) {
        state.selectedContactIds.splice(currentIndex, 1);
      } else {
        state.selectedContactIds.push(action.payload);
      }
    },

    resetContactSlice: (state) => {
      state.data = [];
      state.isLoading = false;
      state.currentPage = 1;
      state.hasMore = true;
      state.isError = false;
      state.error = "";
      state.selectedContactIds = [];
      state.filters = {
        searchText: "",
        tagIds: [],
        campaignIds: [],
      };
    },

    // set filters
    addContactSearchText: (state, action) => {
      state.filters.searchText = action.payload;
    },

    toggleSelectContactTag: (state, action) => {
      const currentIndex = state.filters.tagIds.findIndex((selectedId) => selectedId === action.payload);
      if (currentIndex !== -1) {
        state.filters.tagIds.splice(currentIndex, 1);
      } else {
        state.filters.tagIds.push(action.payload);
      }
    },
    deselectAllContactTag: (state) => {
      state.filters.tagIds = [];
    },
    toggleSelectContactCampaign: (state, action) => {
      const currentIndex = state.filters.campaignIds.findIndex((selectedId) => selectedId === action.payload);
      if (currentIndex !== -1) {
        state.filters.campaignIds.splice(currentIndex, 1);
      } else {
        state.filters.campaignIds.push(action.payload);
      }
    },
    deselectAllContactCampaign: (state) => {
      state.filters.campaignIds = [];
    },
  },
});

export const {
  getContacts,
  getContactsSuccess,
  getContactsFailed,

  getMoreContacts,
  getMoreContactsSuccess,
  getMoreContactsFailed,

  addMainContactOnDialerList,
  addMainContactOnDialerListSuccess,
  addMainContactOnDialerListReset,

  resetContactSlice,

  selectAllContact,
  deselectAllContact,
  toggleSelectSingleContact,

  addContactSearchText,
  toggleSelectContactTag,
  deselectAllContactTag,
  toggleSelectContactCampaign,
  deselectAllContactCampaign,
} = contactsSlice.actions;

export default contactsSlice.reducer;
