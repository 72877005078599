import React, { useEffect, useState } from "react";

const getHtmlText = (message) => {
  let regix = /\@.*?\)/g; // eslint-disable-line
  let matchStrign = message.match(regix);
  if (matchStrign != null && Array.isArray(matchStrign)) {
    matchStrign.map((str) => {
      // eslint-disable-line array-callback-return
      let eachStr = str.match(/@\[(.*)\]\(.*\)/);
      if (eachStr != null) message = message.replace(str, "<b>" + "@" + eachStr[1] + "</b>");
    });
  }
  return message.replace(/\n/g, "<br />");
};

const EachNote = ({ id, message }) => {
  const [htmlText, setHtmlText] = useState("");

  useEffect(() => {
    setHtmlText(getHtmlText(message));
  }, [message]);

  return <p key={id} dangerouslySetInnerHTML={{ __html: htmlText }} />;
};

export default EachNote;
