import { createSlice } from "@reduxjs/toolkit";
import { nanoid } from "nanoid";

const initialState = {
  data: [],
  page: 1,
  limit: 10,
  count: 0,
  isLoading: false,
  isError: false,
  error: "",

  renderId: "",
  isDeleting: false,

  selectedContactIds: [],
  selectedDialerIds: [],
  filters: {
    searchText: "",
    session: "",
    outcome: "",
    connected: "",
  },
};

const dialerContactsSlice = createSlice({
  name: "dialerContacts",
  initialState: initialState,
  reducers: {
    getDialerContacts: (state) => {
      state.isLoading = true;
    },
    getDialerContactsSuccess: (state, action) => {
      state.data = action.payload.data;
      state.limit = action.payload.limit;
      state.count = action.payload.currentPage === 1 ? action.payload.count : state.count;
      state.page = action.payload.currentPage;
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    },
    getDialerContactsFailed: (state, action) => {
      state.data = [];
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },
    resetDialerContacts: (state) => {
      state.data = [];
      state.page = 1;
      state.renderId = "";
      state.isLoading = false;
      state.isError = false;
      state.error = "";
      state.selectedContactIds = [];
      state.selectedDialerIds = [];
      state.filters = {
        searchText: "",
        session: "",
        outcome: "",
        connected: "",
      };
    },

    reFetchDialerContacts: (state) => {
      state.renderId = nanoid(5);
    },

    deletedDialerContact: (state) => {
      state.isDeleting = true;
    },
    deletedDialerContactSuccess: (state) => {
      state.isDeleting = false;
      state.renderId = nanoid(5);
    },
    deletedDialerContactFailed: (state) => {
      state.isDeleting = false;
    },

    // contacts selection actions
    selectAllContact: (state) => {
      state.selectedContactIds = state.data.map((inboxForm) => inboxForm.id);
    },
    deselectAllContact: (state) => {
      state.selectedContactIds = [];
    },
    toggleSelectSingleContact: (state, action) => {
      const currentIndex = state.selectedContactIds.findIndex((selectedId) => selectedId === action.payload);
      if (currentIndex !== -1) {
        state.selectedContactIds.splice(currentIndex, 1);
      } else {
        state.selectedContactIds.push(action.payload);
      }
    },

    // contacts selection actions
    selectAllDialer: (state) => {
      state.selectedDialerIds = state.data.map((inboxForm) => inboxForm.id);
    },
    deselectAllDialer: (state) => {
      state.selectedDialerIds = [];
    },
    toggleSelectSingleDialer: (state, action) => {
      const currentIndex = state.selectedDialerIds.findIndex((selectedId) => selectedId === action.payload);
      if (currentIndex !== -1) {
        state.selectedDialerIds.splice(currentIndex, 1);
      } else {
        state.selectedDialerIds.push(action.payload);
      }
    },

    // set filters
    addDialerContactSearchText: (state, action) => {
      state.filters.searchText = action.payload;
    },
    addDialerContactSession: (state, action) => {
      state.filters.session = action.payload;
    },
    addDialerContactOutcomeStatus: (state, action) => {
      state.filters.outcome = action.payload;
    },
    addDialerContactConnectedStatus: (state, action) => {
      state.filters.connected = action.payload;
    },
    resetDialerContactFilters: (state) => {
      state.filters = {
        session: "",
        outcome: "",
        connected: "",
      };
    },
  },
});

export const {
  getDialerContacts,
  getDialerContactsSuccess,
  getDialerContactsFailed,
  resetDialerContacts,
  reFetchDialerContacts,

  deletedDialerContact,
  deletedDialerContactSuccess,
  deletedDialerContactFailed,

  selectAllContact,
  deselectAllContact,
  toggleSelectSingleContact,

  selectAllDialer,
  deselectAllDialer,
  toggleSelectSingleDialer,

  addDialerContactSearchText,
  addDialerContactSession,
  addDialerContactOutcomeStatus,
  addDialerContactConnectedStatus,
  resetDialerContactFilters,
} = dialerContactsSlice.actions;

export default dialerContactsSlice.reducer;
