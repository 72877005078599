import React from "react";
import { Link } from "@mui/material";

const HrefLink = ({ href = "", target = "_blank", disabled = false, children, ...rest }) => {
  return (
    <Link
      href={href}
      target={target}
      sx={{
        textDecoration: "none",
        color: "inherit",
        pointerEvents: disabled && "none",
        cursor: disabled && "default",
        display: "inline-flex",
      }}
      {...rest}
    >
      {children || null}
    </Link>
  );
};

export default HrefLink;
