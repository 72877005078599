export const initModule = () => {
  String.prototype.showLinkTag = function (remove = false) {
    let link = this;
    let element_exist = document.getElementById("meis_hyper_tag_show_link");
    if (element_exist) {
      if (remove) {
        element_exist.classList.add("hide_meishtsl");
        element_exist.innerText = "";
      } else {
        element_exist.innerText = window.location.origin + link;
        element_exist.classList.remove("hide_meishtsl");
      }
      if (link === "") {
        element_exist.classList.add("hide_meishtsl");
      }
    } else {
      let container = document.createElement("span");
      container.className = "meis_hyper_tag_show_link";
      container.id = "meis_hyper_tag_show_link";
      if (remove) {
        container.classList.add("hide_meishtsl");
        container.innerText = "";
      } else {
        container.innerText = window.location.origin + link;
        container.classList.remove("hide_meishtsl");
      }
      if (link === "") {
        element_exist.classList.add("hide_meishtsl");
      }
      document.body.appendChild(container);
    }
    document.body.style.position = "relative";
    return false;
  };
  if (window.setCollapsedMenu) {
    window.setCollapsedMenu(false);
  }
};
