import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Divider,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomSolidSwitch from "../Switch/CustomSolidSwitch";

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  borderRadius: "4px",
  border: `1px solid ${theme.palette.other.outlinedBorder}`,
  "&:before": { content: "none" },
}));

const AccordionDivider = styled(Divider)(({ theme }) => ({
  margin: "0 16px",
  opacity: theme.palette.action.disabledOpacity,
}));

export default function TriggerSettingAccordion({
  children,
  expanded,
  icon,
  title,
  subTitle,
  status,
  onChangeStatus = () => {},
}) {
  const [isExpanded, setIsExpanded] = useState(Boolean(expanded));

  return (
    <StyledAccordion expanded={isExpanded && status} onChange={() => setIsExpanded(!isExpanded)} elevation={0}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} sx={{ width: "100%" }}>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Avatar variant={"rounded"} sx={{ bgcolor: "other.dividerFillColor", borderRadius: "0 4px 4px 0" }}>
              {icon}
            </Avatar>
            <Stack direction={"column"}>
              <Typography variant='body2medium' color={"text.primary"}>
                {title}
              </Typography>
              <Typography color='text.secondary' variant='chipLight'>
                {subTitle}
              </Typography>
            </Stack>
          </Stack>
          <CustomSolidSwitch
            checked={status}
            onChange={(e) => {
              onChangeStatus(e.target.checked);
              setIsExpanded(e.target.checked);
            }}
          />
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        <Typography variant='body2' color={"text.secondary"} component='div'>
          <AccordionDivider />
          {children}
        </Typography>
      </AccordionDetails>
    </StyledAccordion>
  );
}
